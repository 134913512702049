import React, { useState, useEffect } from 'react';
import {
    Box,
    Container,
    Paper,
    TextField,
    Button,
    Typography,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Tab,
    Tabs,
    CircularProgress,
    Alert,
    useTheme
} from '@mui/material';
import { protectedFetch } from '../../auth';

const validSubjects = ["english", "mathematics", "science", "hsie"];
const validStages = ["early stage 1", "stage 1", "stage 2", "stage 3", "stage 4", "stage 5"];
const validYears = [
    "kindergarten", "year 1", "year 2", "year 3", "year 4", "year 5",
    "year 6", "year 7", "year 8", "year 9", "year 10"
];
const validAges = Array.from({length: 12}, (_, i) => i + 5); // 5 to 16

const TextLeveling = () => {
    const theme = useTheme();
    const [text, setText] = useState('');
    const [subject, setSubject] = useState('');
    const [levelingMethod, setLevelingMethod] = useState(0); // 0: stage, 1: year, 2: age
    const [selectedStage, setSelectedStage] = useState('');
    const [selectedYear, setSelectedYear] = useState('');
    const [selectedAge, setSelectedAge] = useState('');
    const [customInstructions, setCustomInstructions] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [result, setResult] = useState(null);

    // Effect to handle result updates
    useEffect(() => {
        if (result) {
            console.log('Result updated in state:', result);
        }
    }, [result]);

    const handleLevelingMethodChange = (event, newValue) => {
        setLevelingMethod(newValue);
        // Reset all selection values when changing method
        setSelectedStage('');
        setSelectedYear('');
        setSelectedAge('');
    };

    const handleSubmit = async () => {
        setLoading(true);
        setError(null);
        setResult(null);
        
        // Clear any previous results from the UI
        await new Promise(resolve => setTimeout(resolve, 0));

        try {
            // Validate required fields
            if (!text) {
                throw new Error('Please enter text to level');
            }
            if (!subject) {
                throw new Error('Please select a subject');
            }

            // Build request body with required fields
            const requestBody = {
                text,
                subject: subject.toLowerCase(), // API expects lowercase
                customInstructions: customInstructions || undefined
            };

            // Add the appropriate leveling field based on selected method
            switch (levelingMethod) {
                case 0: // Stage
                    if (!selectedStage) {
                        throw new Error('Please select a stage');
                    }
                    requestBody.level = selectedStage.toLowerCase(); // API expects lowercase
                    break;
                case 1: // Year
                    if (!selectedYear) {
                        throw new Error('Please select a year');
                    }
                    requestBody.year = selectedYear.toLowerCase(); // API expects lowercase
                    break;
                case 2: // Age
                    if (!selectedAge) {
                        throw new Error('Please select an age');
                    }
                    requestBody.age = parseInt(selectedAge);
                    break;
                default:
                    throw new Error('Please select a leveling method');
            }

            console.log('Request body:', requestBody);
            const response = await protectedFetch(async () => {
                const token = localStorage.getItem('token');
                const url = `${process.env.REACT_APP_BASE_URL}/api/text-leveling/level`;
                console.log('Making request to:', url);
                const res = await fetch(url, {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(requestBody)
                });

                if (!res.ok) {
                    const contentType = res.headers.get("content-type");
                    if (contentType && contentType.includes("application/json")) {
                        const errorData = await res.json();
                        throw new Error(errorData.message || errorData.error || 'Failed to level text');
                    } else {
                        const text = await res.text();
                        console.error('Raw error response:', text);
                        throw new Error(`Server error: ${res.status} ${res.statusText}`);
                    }
                }

                const contentType = res.headers.get("content-type");
                if (!contentType || !contentType.includes("application/json")) {
                    const text = await res.text();
                    console.error('Raw response:', text);
                    throw new Error('Expected JSON response but got something else');
                }

                const jsonResponse = await res.json();
                console.log('Full API Response:', {
                    status: res.status,
                    headers: Object.fromEntries(res.headers.entries()),
                    data: jsonResponse
                });
                return jsonResponse;
            });

            console.log('Processed Response:', response);
            // Ensure we're setting the state with the correct data structure
            const resultData = response.data || response;
            console.log('Setting result to:', resultData);
            
            // Force a re-render by using a new object
            setResult({...resultData});
        } catch (err) {
            console.error('Error details:', {
                message: err.message,
                stack: err.stack,
                response: err.response,
                data: err.data
            });
            setError(err.message || 'An error occurred while leveling the text');
        } finally {
            setLoading(false);
        }
    };

    const isSubmitDisabled = () => {
        if (!text || !subject) return true;
        
        switch (levelingMethod) {
            case 0:
                return !selectedStage;
            case 1:
                return !selectedYear;
            case 2:
                return !selectedAge;
            default:
                return true;
        }
    };

    return (
        <Container maxWidth="lg" sx={{ py: 4 }}>
            <Paper elevation={2} sx={{ p: 3, borderRadius: 2 }}>
                <Typography variant="h5" gutterBottom>
                    Text Leveling
                </Typography>

                {/* Text Input */}
                <TextField
                    fullWidth
                    multiline
                    rows={6}
                    value={text}
                    onChange={(e) => setText(e.target.value)}
                    label="Text to Level"
                    placeholder="Enter the text you want to level..."
                    variant="outlined"
                    sx={{ mb: 3 }}
                />

                {/* Subject Selection */}
                <FormControl fullWidth sx={{ mb: 3 }}>
                    <InputLabel>Subject</InputLabel>
                    <Select
                        value={subject}
                        onChange={(e) => setSubject(e.target.value)}
                        label="Subject"
                    >
                        {validSubjects.map((sub) => (
                            <MenuItem key={sub} value={sub} sx={{ textTransform: 'capitalize' }}>
                                {sub.toUpperCase()}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                {/* Leveling Method Tabs */}
                <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 3 }}>
                    <Tabs 
                        value={levelingMethod} 
                        onChange={handleLevelingMethodChange}
                        variant="fullWidth"
                    >
                        <Tab label="By Stage" />
                        <Tab label="By Year" />
                        <Tab label="By Age" />
                    </Tabs>
                </Box>

                {/* Stage/Year/Age Selection */}
                <FormControl fullWidth sx={{ mb: 3 }}>
                    <InputLabel>
                        {levelingMethod === 0 ? 'Stage' : levelingMethod === 1 ? 'Year' : 'Age'}
                    </InputLabel>
                    <Select
                        value={
                            levelingMethod === 0 ? selectedStage :
                            levelingMethod === 1 ? selectedYear :
                            selectedAge
                        }
                        onChange={(e) => {
                            if (levelingMethod === 0) setSelectedStage(e.target.value);
                            else if (levelingMethod === 1) setSelectedYear(e.target.value);
                            else setSelectedAge(e.target.value);
                        }}
                        label={levelingMethod === 0 ? 'Stage' : levelingMethod === 1 ? 'Year' : 'Age'}
                    >
                        {levelingMethod === 0 && validStages.map((stage) => (
                            <MenuItem key={stage} value={stage} sx={{ textTransform: 'capitalize' }}>
                                {stage.toUpperCase()}
                            </MenuItem>
                        ))}
                        {levelingMethod === 1 && validYears.map((year) => (
                            <MenuItem key={year} value={year} sx={{ textTransform: 'capitalize' }}>
                                {year.toUpperCase()}
                            </MenuItem>
                        ))}
                        {levelingMethod === 2 && validAges.map((age) => (
                            <MenuItem key={age} value={age}>
                                {age} years old
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                {/* Custom Instructions */}
                <TextField
                    fullWidth
                    multiline
                    rows={3}
                    value={customInstructions}
                    onChange={(e) => setCustomInstructions(e.target.value)}
                    label="Custom Instructions (Optional)"
                    placeholder="Add any specific leveling instructions..."
                    variant="outlined"
                    sx={{ mb: 3 }}
                />

                {/* Submit Button */}
                <Button
                    variant="contained"
                    onClick={handleSubmit}
                    disabled={isSubmitDisabled() || loading}
                    fullWidth
                    size="large"
                    sx={{ mb: 2 }}
                >
                    {loading ? <CircularProgress size={24} color="inherit" /> : 'Level Text'}
                </Button>

                {/* Error Display */}
                {error && (
                    <Alert severity="error" sx={{ mb: 2 }}>
                        {error}
                    </Alert>
                )}

                {/* Results Display */}
                {console.log('Current result state:', result)}
                {result && !result.leveledText && (
                    <Alert severity="info" sx={{ mb: 2 }}>
                        Raw result: {JSON.stringify(result)}
                    </Alert>
                )}
                {result?.leveledText && (
                    <Paper 
                        variant="outlined" 
                        sx={{ 
                            p: 2,
                            bgcolor: theme.palette.background.default
                        }}
                    >
                        <Typography variant="h6" gutterBottom>
                            Leveled Text
                        </Typography>
                        <Typography 
                            variant="body1" 
                            sx={{ 
                                whiteSpace: 'pre-wrap',
                                mb: 2
                            }}
                        >
                            {result.leveledText}
                        </Typography>

                        <Typography variant="h6" gutterBottom>
                            Metadata
                        </Typography>
                        <Box sx={{ pl: 2 }}>
                            {result.metadata?.targetAgeRange && (
                                <Typography variant="body2" color="text.secondary">
                                    <strong>Target Age Range:</strong> {result.metadata.targetAgeRange.join(' - ')} years
                                </Typography>
                            )}
                            {result.metadata?.levelingInfo?.determinedStage && (
                                <Typography variant="body2" color="text.secondary">
                                    <strong>Stage:</strong> {result.metadata.levelingInfo.determinedStage}
                                </Typography>
                            )}
                            {result.metadata?.typicalTopics?.length > 0 && (
                                <Typography variant="body2" color="text.secondary">
                                    <strong>Typical Topics:</strong> {result.metadata.typicalTopics.join(', ')}
                                </Typography>
                            )}
                            {result.metadata?.subjectKeyVerbs?.length > 0 && (
                                <Typography variant="body2" color="text.secondary">
                                    <strong>Subject Key Verbs:</strong> {result.metadata.subjectKeyVerbs.join(', ')}
                                </Typography>
                            )}
                        </Box>
                    </Paper>
                )}
            </Paper>
        </Container>
    );
};

export default TextLeveling;