import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import '../../css/EditRecordModal.css'; // Ensure you link to your custom CSS file

const EditRecordModal = ({ show, onHide, record, onSave }) => {
    const [formData, setFormData] = useState({
        name: '',
        topic: '',
        state: '',
        location: '',
        date: '',
        user: '',
        comment: '',
    });

    useEffect(() => {
        if (record) {
            setFormData({
                name: record.name || '',
                topic: record.topic || '',
                state: record.state || '',
                location: record.location || '',
                date: record.date ? new Date(record.date).toISOString() : '',
                user: record.user || '',
                comment: record.comment || '',
            });
        }
    }, [record]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleSubmit = () => {
        const updatedRecord = { ...record, ...formData };
        onSave(updatedRecord);
        onHide();
    };

    return (
        <Modal 
            show={show} 
            onHide={onHide} 
            centered 
            dialogClassName="custom-modal"
            backdrop="static"
            keyboard={false}
            animation={true}
            size="lg"
            aria-labelledby="edit-record-modal"
            enforceFocus={true}
            restoreFocus={true}
            autoFocus={true}
        >
            <Modal.Header closeButton className="custom-modal-header">
                <Modal.Title>Edit Record</Modal.Title>
            </Modal.Header>
            <Modal.Body className="custom-modal-body">
                <Form>
                    <Form.Group controlId="formName">
                        <Form.Label>Name</Form.Label>
                        <Form.Control
                            type="text"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            className="custom-input"
                        />
                    </Form.Group>

                    <Form.Group controlId="formTopic">
                        <Form.Label>Topic</Form.Label>
                        <Form.Control
                            type="text"
                            name="topic"
                            value={formData.topic}
                            onChange={handleChange}
                            className="custom-input"
                        />
                    </Form.Group>

                    <Form.Group controlId="formState">
                        <Form.Label>State</Form.Label>
                        <Form.Control
                            type="text"
                            name="state"
                            value={formData.state}
                            onChange={handleChange}
                            className="custom-input"
                        />
                    </Form.Group>

                    <Form.Group controlId="formLocation">
                        <Form.Label>Location</Form.Label>
                        <Form.Control
                            type="text"
                            name="location"
                            value={formData.location}
                            onChange={handleChange}
                            className="custom-input"
                        />
                    </Form.Group>

                    <Form.Group controlId="formComment">
                        <Form.Label>Comment</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={3}
                            name="comment"
                            value={formData.comment}
                            onChange={handleChange}
                            className="custom-textarea"
                        />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer className="custom-modal-footer">
                <Button variant="secondary" onClick={onHide} className="custom-cancel-button">
                    Cancel
                </Button>
                <Button variant="primary" onClick={handleSubmit} className="custom-save-button">
                    Save Changes
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default EditRecordModal;
