import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
  IconButton,
  Tabs,
  Tab,
  Divider,
  Paper,
  Grid,
  Chip,
  CircularProgress,
  useTheme,
  useMediaQuery
} from '@mui/material';
import {
  Close as CloseIcon,
  Print as PrintIcon,
  Edit as EditIcon,
  Download as DownloadIcon,
  Assignment as AssignmentIcon,
  PhotoCamera as PhotoCameraIcon,
  Upload as UploadIcon
} from '@mui/icons-material';
import { format } from 'date-fns';
import { protectedApiCall } from '../../auth';
import WorksheetSubmissions from './components/WorksheetSubmissions';

const WorksheetDetail = ({ open, onClose, worksheet, onRefresh }) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [activeTab, setActiveTab] = useState(0);
  const [loading, setLoading] = useState(false);
  const [worksheetData, setWorksheetData] = useState(null);

  useEffect(() => {
    if (open && worksheet) {
      loadWorksheetDetails();
    }
  }, [open, worksheet]);

  const loadWorksheetDetails = async () => {
    if (!worksheet || !worksheet._id) return;
    
    setLoading(true);
    try {
      const response = await protectedApiCall(`/api/worksheets/${worksheet._id}`, 'GET');
      setWorksheetData(response.data);
    } catch (error) {
      console.error('Error loading worksheet details:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handlePrintWorksheet = () => {
    // Implement print functionality
    console.log('Print worksheet:', worksheetData);
  };

  const handleDownloadPDF = () => {
    // Implement PDF download
    console.log('Download PDF:', worksheetData);
  };

  if (loading) {
    return (
      <Dialog
        open={open}
        onClose={onClose}
        fullScreen={fullScreen}
        maxWidth="md"
        fullWidth
      >
        <DialogContent sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '300px' }}>
          <CircularProgress />
        </DialogContent>
      </Dialog>
    );
  }

  if (!worksheetData) {
    return null;
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullScreen={fullScreen}
      maxWidth="md"
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: 2,
          maxHeight: '90vh'
        }
      }}
    >
      <DialogTitle sx={{ m: 0, p: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h6" noWrap>
          {worksheetData.title}
        </Typography>
        <Box>
          <IconButton
            aria-label="print"
            onClick={handlePrintWorksheet}
            sx={{ mr: 1 }}
          >
            <PrintIcon />
          </IconButton>
          <IconButton
            aria-label="download"
            onClick={handleDownloadPDF}
            sx={{ mr: 1 }}
          >
            <DownloadIcon />
          </IconButton>
          <IconButton
            aria-label="close"
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      
      <Box sx={{ px: 3, borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={activeTab} onChange={handleTabChange} aria-label="worksheet tabs">
          <Tab label="Details" />
          <Tab label="Questions" />
          <Tab label="Submissions" />
        </Tabs>
      </Box>
      
      <DialogContent dividers>
        {activeTab === 0 && (
          <Box>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <Paper sx={{ p: 2, height: '100%' }}>
                  <Typography variant="subtitle1" gutterBottom fontWeight="medium">
                    Worksheet Information
                  </Typography>
                  <Divider sx={{ mb: 2 }} />
                  
                  <Box sx={{ mb: 1 }}>
                    <Typography variant="body2" color="text.secondary">
                      Subject
                    </Typography>
                    <Typography variant="body1">
                      {worksheetData.subject}
                    </Typography>
                  </Box>
                  
                  <Box sx={{ mb: 1 }}>
                    <Typography variant="body2" color="text.secondary">
                      Grade
                    </Typography>
                    <Typography variant="body1">
                      {worksheetData.grade}
                    </Typography>
                  </Box>
                  
                  <Box sx={{ mb: 1 }}>
                    <Typography variant="body2" color="text.secondary">
                      Created
                    </Typography>
                    <Typography variant="body1">
                      {worksheetData.createdAt ? format(new Date(worksheetData.createdAt), 'MMMM d, yyyy') : 'N/A'}
                    </Typography>
                  </Box>
                  
                  <Box sx={{ mb: 1 }}>
                    <Typography variant="body2" color="text.secondary">
                      Status
                    </Typography>
                    <Chip 
                      label={worksheetData.status || 'Active'} 
                      size="small"
                      color={worksheetData.status === 'Draft' ? 'default' : 'success'}
                      sx={{ mt: 0.5 }}
                    />
                  </Box>
                </Paper>
              </Grid>
              
              <Grid item xs={12} sm={6}>
                <Paper sx={{ p: 2, height: '100%' }}>
                  <Typography variant="subtitle1" gutterBottom fontWeight="medium">
                    Description
                  </Typography>
                  <Divider sx={{ mb: 2 }} />
                  
                  <Typography variant="body1">
                    {worksheetData.description || 'No description provided.'}
                  </Typography>
                </Paper>
              </Grid>
              
              <Grid item xs={12}>
                <Paper sx={{ p: 2 }}>
                  <Typography variant="subtitle1" gutterBottom fontWeight="medium">
                    Actions
                  </Typography>
                  <Divider sx={{ mb: 2 }} />
                  
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
                    <Button
                      variant="outlined"
                      startIcon={<EditIcon />}
                    >
                      Edit Worksheet
                    </Button>
                    <Button
                      variant="outlined"
                      startIcon={<PrintIcon />}
                      onClick={handlePrintWorksheet}
                    >
                      Print Worksheet
                    </Button>
                    <Button
                      variant="outlined"
                      startIcon={<DownloadIcon />}
                      onClick={handleDownloadPDF}
                    >
                      Download PDF
                    </Button>
                    <Button
                      variant="outlined"
                      startIcon={<UploadIcon />}
                      onClick={() => setActiveTab(2)} // Switch to Submissions tab
                    >
                      Upload Submissions
                    </Button>
                  </Box>
                </Paper>
              </Grid>
            </Grid>
          </Box>
        )}
        
        {activeTab === 1 && (
          <Box>
            <Typography variant="subtitle1" gutterBottom>
              Questions ({worksheetData.questions ? worksheetData.questions.length : 0})
            </Typography>
            
            {worksheetData.questions && worksheetData.questions.length > 0 ? (
              worksheetData.questions.map((question, index) => (
                <Paper 
                  key={index} 
                  sx={{ 
                    p: 2, 
                    mb: 2, 
                    border: '1px solid', 
                    borderColor: 'divider'
                  }}
                >
                  <Box sx={{ display: 'flex', alignItems: 'flex-start', mb: 1 }}>
                    <Box sx={{ flexGrow: 1 }}>
                      <Typography variant="subtitle1" fontWeight="medium">
                        {index + 1}. {question.questionText}
                      </Typography>
                      <Typography variant="caption" color="text.secondary">
                        {question.questionType} • {question.points} {question.points === 1 ? 'point' : 'points'}
                      </Typography>
                    </Box>
                  </Box>
                  
                  {question.questionType === 'multiple-choice' && (
                    <Box sx={{ ml: 3, mt: 1 }}>
                      <Grid container spacing={1}>
                        {question.options.map((option, optIndex) => (
                          <Grid item xs={12} sm={6} key={optIndex}>
                            <Typography variant="body2">
                              {String.fromCharCode(65 + optIndex)}. {option}
                            </Typography>
                          </Grid>
                        ))}
                      </Grid>
                      <Typography variant="body2" sx={{ mt: 1, color: 'success.main' }}>
                        Correct: {question.correctAnswer}
                      </Typography>
                    </Box>
                  )}
                  
                  {(question.questionType === 'short-answer' || question.questionType === 'long-answer') && (
                    <Box sx={{ ml: 3, mt: 1 }}>
                      <Typography variant="body2" color="text.secondary">
                        Answer Guidelines: {question.answerGuidelines}
                      </Typography>
                    </Box>
                  )}
                </Paper>
              ))
            ) : (
              <Box sx={{ textAlign: 'center', py: 4 }}>
                <AssignmentIcon sx={{ fontSize: 60, color: 'text.secondary', mb: 2 }} />
                <Typography variant="body1" color="text.secondary">
                  No questions found for this worksheet.
                </Typography>
              </Box>
            )}
          </Box>
        )}
        
        {activeTab === 2 && (
          <WorksheetSubmissions worksheet={worksheetData} />
        )}
      </DialogContent>
      
      <DialogActions sx={{ px: 3, py: 2 }}>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default WorksheetDetail;