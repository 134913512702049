import React, { useState } from 'react';
import {
  Box,
  Typography,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  Paper,
  Grid,
  Divider,
  Alert,
  Accordion,
  AccordionSummary,
  AccordionDetails
} from '@mui/material';
import {
  Add as AddIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  ExpandMore as ExpandMoreIcon,
  DragIndicator as DragIndicatorIcon
} from '@mui/icons-material';

const QUESTION_TYPES = [
  { value: 'multiple-choice', label: 'Multiple Choice' },
  { value: 'short-answer', label: 'Short Answer' },
  { value: 'long-answer', label: 'Long Answer' }
];

const QuestionEditor = ({ questions, onAddQuestion, onUpdateQuestion, onRemoveQuestion, error }) => {
  const [newQuestion, setNewQuestion] = useState({
    questionText: '',
    questionType: 'multiple-choice',
    options: ['', '', '', ''],
    correctAnswer: '',
    answerGuidelines: '',
    points: 1
  });
  const [editMode, setEditMode] = useState(false);
  const [editIndex, setEditIndex] = useState(null);
  const [expanded, setExpanded] = useState(false);
  const [formErrors, setFormErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewQuestion(prev => ({
      ...prev,
      [name]: value
    }));
    
    // Clear error when field is updated
    if (formErrors[name]) {
      setFormErrors(prev => ({
        ...prev,
        [name]: null
      }));
    }
  };

  const handleOptionChange = (index, value) => {
    const updatedOptions = [...newQuestion.options];
    updatedOptions[index] = value;
    
    setNewQuestion(prev => ({
      ...prev,
      options: updatedOptions
    }));
  };

  const handleAddOption = () => {
    setNewQuestion(prev => ({
      ...prev,
      options: [...prev.options, '']
    }));
  };

  const handleRemoveOption = (index) => {
    const updatedOptions = [...newQuestion.options];
    updatedOptions.splice(index, 1);
    
    setNewQuestion(prev => ({
      ...prev,
      options: updatedOptions
    }));
  };

  const validateForm = () => {
    const errors = {};
    
    if (!newQuestion.questionText.trim()) {
      errors.questionText = 'Question text is required';
    }
    
    if (newQuestion.questionType === 'multiple-choice') {
      // Check if at least 2 options are provided
      const filledOptions = newQuestion.options.filter(opt => opt.trim() !== '');
      if (filledOptions.length < 2) {
        errors.options = 'At least 2 options are required';
      }
      
      // Check if correct answer is provided
      if (!newQuestion.correctAnswer.trim()) {
        errors.correctAnswer = 'Correct answer is required';
      }
    } else {
      // For short and long answer questions
      if (!newQuestion.answerGuidelines.trim()) {
        errors.answerGuidelines = 'Answer guidelines are required for marking';
      }
    }
    
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = () => {
    if (!validateForm()) return;
    
    if (editMode && editIndex !== null) {
      onUpdateQuestion(editIndex, newQuestion);
      setEditMode(false);
      setEditIndex(null);
    } else {
      onAddQuestion(newQuestion);
    }
    
    // Reset form
    setNewQuestion({
      questionText: '',
      questionType: 'multiple-choice',
      options: ['', '', '', ''],
      correctAnswer: '',
      answerGuidelines: '',
      points: 1
    });
    
    setExpanded(false);
  };

  const handleEditQuestion = (index) => {
    setNewQuestion(questions[index]);
    setEditMode(true);
    setEditIndex(index);
    setExpanded(true);
  };

  const handleCancelEdit = () => {
    setNewQuestion({
      questionText: '',
      questionType: 'multiple-choice',
      options: ['', '', '', ''],
      correctAnswer: '',
      answerGuidelines: '',
      points: 1
    });
    setEditMode(false);
    setEditIndex(null);
  };

  const handleAccordionChange = (event, isExpanded) => {
    setExpanded(isExpanded);
  };

  return (
    <Box>
      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}
      
      <Box sx={{ mb: 3 }}>
        <Typography variant="h6" gutterBottom>
          Questions ({questions.length})
        </Typography>
        
        {questions.length > 0 ? (
          <Box>
            {questions.map((question, index) => (
              <Paper 
                key={index} 
                sx={{ 
                  p: 2, 
                  mb: 2, 
                  border: '1px solid', 
                  borderColor: 'divider',
                  position: 'relative'
                }}
              >
                <Box sx={{ display: 'flex', alignItems: 'flex-start', mb: 1 }}>
                  <DragIndicatorIcon 
                    sx={{ 
                      color: 'text.secondary', 
                      mr: 1,
                      cursor: 'grab',
                      mt: 0.5
                    }} 
                  />
                  <Box sx={{ flexGrow: 1 }}>
                    <Typography variant="subtitle1" fontWeight="medium">
                      {index + 1}. {question.questionText}
                    </Typography>
                    <Typography variant="caption" color="text.secondary">
                      {QUESTION_TYPES.find(t => t.value === question.questionType)?.label || question.questionType} 
                      • {question.points} {question.points === 1 ? 'point' : 'points'}
                    </Typography>
                  </Box>
                  <Box>
                    <IconButton 
                      size="small" 
                      onClick={() => handleEditQuestion(index)}
                      sx={{ mr: 1 }}
                    >
                      <EditIcon fontSize="small" />
                    </IconButton>
                    <IconButton 
                      size="small" 
                      onClick={() => onRemoveQuestion(index)}
                      color="error"
                    >
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  </Box>
                </Box>
                
                {question.questionType === 'multiple-choice' && (
                  <Box sx={{ ml: 5, mt: 1 }}>
                    <Grid container spacing={1}>
                      {question.options.map((option, optIndex) => (
                        <Grid item xs={12} sm={6} key={optIndex}>
                          <Typography variant="body2">
                            {String.fromCharCode(65 + optIndex)}. {option}
                          </Typography>
                        </Grid>
                      ))}
                    </Grid>
                    <Typography variant="body2" sx={{ mt: 1, color: 'success.main' }}>
                      Correct: {question.correctAnswer}
                    </Typography>
                  </Box>
                )}
                
                {(question.questionType === 'short-answer' || question.questionType === 'long-answer') && (
                  <Box sx={{ ml: 5, mt: 1 }}>
                    <Typography variant="body2" color="text.secondary">
                      Answer Guidelines: {question.answerGuidelines}
                    </Typography>
                  </Box>
                )}
              </Paper>
            ))}
          </Box>
        ) : (
          <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
            No questions added yet. Use the form below to add questions.
          </Typography>
        )}
      </Box>
      
      <Divider sx={{ mb: 3 }} />
      
      <Accordion expanded={expanded} onChange={handleAccordionChange}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="question-form-content"
          id="question-form-header"
        >
          <Typography variant="subtitle1">
            {editMode ? 'Edit Question' : 'Add New Question'}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                name="questionText"
                label="Question Text"
                fullWidth
                multiline
                rows={2}
                value={newQuestion.questionText}
                onChange={handleChange}
                error={!!formErrors.questionText}
                helperText={formErrors.questionText}
                required
              />
            </Grid>
            
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth required>
                <InputLabel>Question Type</InputLabel>
                <Select
                  name="questionType"
                  value={newQuestion.questionType}
                  onChange={handleChange}
                  label="Question Type"
                >
                  {QUESTION_TYPES.map(type => (
                    <MenuItem key={type.value} value={type.value}>
                      {type.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            
            <Grid item xs={12} sm={6}>
              <TextField
                name="points"
                label="Points"
                type="number"
                fullWidth
                value={newQuestion.points}
                onChange={handleChange}
                InputProps={{ inputProps: { min: 1, max: 100 } }}
              />
            </Grid>
            
            {newQuestion.questionType === 'multiple-choice' && (
              <Grid item xs={12}>
                <Typography variant="subtitle2" gutterBottom>
                  Answer Options
                </Typography>
                {formErrors.options && (
                  <Typography variant="caption" color="error" sx={{ display: 'block', mb: 1 }}>
                    {formErrors.options}
                  </Typography>
                )}
                
                {newQuestion.options.map((option, index) => (
                  <Box key={index} sx={{ display: 'flex', mb: 2, alignItems: 'center' }}>
                    <Typography sx={{ mr: 2, width: 20 }}>
                      {String.fromCharCode(65 + index)}.
                    </Typography>
                    <TextField
                      fullWidth
                      size="small"
                      value={option}
                      onChange={(e) => handleOptionChange(index, e.target.value)}
                      placeholder={`Option ${String.fromCharCode(65 + index)}`}
                    />
                    <IconButton 
                      size="small" 
                      onClick={() => handleRemoveOption(index)}
                      disabled={newQuestion.options.length <= 2}
                      sx={{ ml: 1 }}
                    >
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  </Box>
                ))}
                
                <Button
                  startIcon={<AddIcon />}
                  onClick={handleAddOption}
                  sx={{ mt: 1 }}
                  size="small"
                >
                  Add Option
                </Button>
                
                <FormControl fullWidth sx={{ mt: 3 }} error={!!formErrors.correctAnswer} required>
                  <InputLabel>Correct Answer</InputLabel>
                  <Select
                    name="correctAnswer"
                    value={newQuestion.correctAnswer}
                    onChange={handleChange}
                    label="Correct Answer"
                  >
                    {newQuestion.options.map((option, index) => (
                      <MenuItem 
                        key={index} 
                        value={option}
                        disabled={!option.trim()}
                      >
                        {String.fromCharCode(65 + index)}. {option || '(Empty)'}
                      </MenuItem>
                    ))}
                  </Select>
                  {formErrors.correctAnswer && (
                    <Typography variant="caption" color="error">
                      {formErrors.correctAnswer}
                    </Typography>
                  )}
                </FormControl>
              </Grid>
            )}
            
            {(newQuestion.questionType === 'short-answer' || newQuestion.questionType === 'long-answer') && (
              <Grid item xs={12}>
                <TextField
                  name="answerGuidelines"
                  label="Answer Guidelines for Marking"
                  fullWidth
                  multiline
                  rows={3}
                  value={newQuestion.answerGuidelines}
                  onChange={handleChange}
                  error={!!formErrors.answerGuidelines}
                  helperText={formErrors.answerGuidelines || "Provide guidelines for what constitutes a correct answer"}
                  required
                />
              </Grid>
            )}
            
            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2, mt: 2 }}>
              {editMode && (
                <Button onClick={handleCancelEdit} color="inherit">
                  Cancel
                </Button>
              )}
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                startIcon={editMode ? <EditIcon /> : <AddIcon />}
              >
                {editMode ? 'Update Question' : 'Add Question'}
              </Button>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default QuestionEditor;