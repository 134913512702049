import React, { createContext, useState, useEffect } from 'react';

export const ThemeContext = createContext();

const themeStyles = {
    'royal-elegance': {
        '--primary-color': '#5D478B', /* Softer indigo */
        '--secondary-color': '#9F84BD', /* Muted violet */
        '--accent-color': '#D4B76C', /* Muted gold */
        '--text-color': '#3A3A3A', /* Softer dark gray */
        '--background-color': '#FFFFFF', /* White */
        '--light-background': '#F8F5FA', /* Very light lavender */
        '--success-color': '#5B9B7A', /* Muted sea green */
        '--danger-color': '#B85C5C', /* Muted burgundy */
        '--border-color': '#E8E5ED', /* Light purple-gray */
    },
    'ocean-breeze': {
        '--primary-color': '#0077B6',
        '--secondary-color': '#00B4D8',
        '--accent-color': '#90E0EF',
        '--text-color': '#023E8A',
        '--background-color': '#FFFFFF',
        '--light-background': '#CAF0F8',
        '--success-color': '#76C893',
        '--danger-color': '#d00000',
        '--border-color': '#ddd',
    },
    'sunset-glow': {
        '--primary-color': '#8B4513', /* Saddle Brown - more muted orange */
        '--secondary-color': '#CD853F', /* Peru - softer orange */
        '--accent-color': '#DEB887', /* Burlywood - muted gold */
        '--text-color': '#4A4A4A',
        '--background-color': '#FFFFFF',
        '--light-background': '#FAF3E8', /* Lighter, less saturated */
        '--success-color': '#6B8E23', /* Olive Drab - earthier green */
        '--danger-color': '#B22222', /* Firebrick - deeper red */
        '--border-color': '#E8E0D8', /* Warmer gray */
    },
    'forest-calm': {
        '--primary-color': '#3A755F', /* Lighter forest green */
        '--secondary-color': '#5B9C7F', /* Softer light green */
        '--accent-color': '#98C9A3', /* Lighter mint green */
        '--text-color': '#2C4A3E', /* Lighter dark green */
        '--background-color': '#FFFFFF',
        '--light-background': '#E8F5EC', /* Even lighter background */
        '--success-color': '#7CAA63', /* Lighter olive green */
        '--danger-color': '#E66767', /* Softer red */
        '--border-color': '#E2E8E5', /* Slight green tint */
    },
    'citrus-fresh': {
        '--primary-color': '#D48E3C', /* Muted orange */
        '--secondary-color': '#E6946E', /* Muted coral */
        '--accent-color': '#F2C76E', /* Muted gold */
        '--text-color': '#4A4A4A',
        '--background-color': '#FFFFFF',
        '--light-background': '#FDF8E9', /* Very light warm beige */
        '--success-color': '#689F38', /* Muted lime */
        '--danger-color': '#C0392B', /* Deeper, muted red */
        '--border-color': '#EBE6DD', /* Warm gray */
    },
    'cool-mint': {
        '--primary-color': '#20B2AA', /* Light Sea Green */
        '--secondary-color': '#66CDAA', /* Medium Aquamarine */
        '--accent-color': '#AFEEEE', /* Pale Turquoise */
        '--text-color': '#2E2E2E', /* Dark Gray */
        '--background-color': '#FFFFFF', /* White */
        '--light-background': '#E0FFFF', /* Light Cyan */
        '--success-color': '#3CB371', /* Medium Sea Green */
        '--danger-color': '#CD5C5C', /* Indian Red */
        '--border-color': '#ddd', /* Light Gray */
    },
    'earthy-tones': {
        '--primary-color': '#6B4226', /* Brown */
        '--secondary-color': '#8B5A2B', /* Saddle Brown */
        '--accent-color': '#D2B48C', /* Tan */
        '--text-color': '#2E2E2E', /* Dark Gray */
        '--background-color': '#FFFFFF', /* White */
        '--light-background': '#F5F5DC', /* Beige */
        '--success-color': '#556B2F', /* Dark Olive Green */
        '--danger-color': '#8B0000', /* Dark Red */
        '--border-color': '#ddd', /* Light Gray */
    },
};

export const ThemeProvider = ({ children }) => {
    const [theme, setTheme] = useState(() => localStorage.getItem('theme-choice') || 'ocean-breeze');

    useEffect(() => {
        const root = document.documentElement;
        const styles = themeStyles[theme];

        for (const style in styles) {
            root.style.setProperty(style, styles[style]);
        }
    }, [theme]);

    return (
        <ThemeContext.Provider value={{ theme, setTheme }}>
            {children}
        </ThemeContext.Provider>
    );
};
