import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';

const FormattedContent = ({ content, preview = false }) => {
    const theme = useTheme();

    const parseMarkdown = (text) => {
        const lines = text.split('\n');
        const sections = [];
        let currentTable = null;

        for (let i = 0; i < lines.length; i++) {
            const line = lines[i].trim();
            if (!line) {
                if (currentTable) {
                    sections.push(currentTable);
                    currentTable = null;
                }
                continue;
            }

            // Handle headers
            if (line.startsWith('#')) {
                if (currentTable) {
                    sections.push(currentTable);
                    currentTable = null;
                }
                sections.push({
                    type: 'header',
                    content: line.replace(/^#+\s*/, '').trim(),
                    level: (line.match(/^#+/) || [''])[0].length
                });
                continue;
            }

            // Handle bullet points
            if (line.startsWith('- ') || line.startsWith('* ')) {
                if (currentTable) {
                    sections.push(currentTable);
                    currentTable = null;
                }
                sections.push({
                    type: 'bullet',
                    content: line.replace(/^[-*]\s+/, '')
                });
                continue;
            }

            // Handle tables
            if (line.includes('|')) {
                if (!currentTable) {
                    currentTable = {
                        type: 'table',
                        rows: []
                    };
                }
                // Skip separator lines
                if (!line.match(/^\|[-|\s]+\|$/)) {
                    currentTable.rows.push(
                        line.split('|')
                            .filter(cell => cell.trim())
                            .map(cell => cell.trim())
                    );
                }
                continue;
            }

            // Regular paragraph
            if (currentTable) {
                sections.push(currentTable);
                currentTable = null;
            }
            sections.push({
                type: 'paragraph',
                content: line
            });
        }

        if (currentTable) {
            sections.push(currentTable);
        }

        return sections;
    };

    // URL regex pattern to detect URLs in text
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    
    // Function to detect URLs in text and convert them to link objects
    const formatLinks = (text) => {
        if (!text || typeof text !== 'string') {
            return [{ type: 'text', content: text || '', key: 0 }];
        }
        
        const parts = [];
        let lastIndex = 0;
        let match;
        
        // Find all URLs in the text
        while ((match = urlRegex.exec(text)) !== null) {
            // Add the text before the URL
            if (match.index > lastIndex) {
                parts.push({
                    type: 'text',
                    content: text.substring(lastIndex, match.index),
                    key: `text-${lastIndex}`
                });
            }
            
            // Add the URL as a link
            parts.push({
                type: 'link',
                content: match[0],
                url: match[0],
                key: `link-${match.index}`
            });
            
            lastIndex = match.index + match[0].length;
        }
        
        // Add any remaining text after the last URL
        if (lastIndex < text.length) {
            parts.push({
                type: 'text',
                content: text.substring(lastIndex),
                key: `text-${lastIndex}`
            });
        }
        
        return parts;
    };
    
    const formatBoldText = (text) => {
        if (!text || typeof text !== 'string') {
            console.log('Invalid text for formatBoldText:', text);
            return [{ type: 'text', content: '', key: 0 }];
        }
        
        // First split by bold markers
        const parts = text.split(/(\*\*.*?\*\*)/g);
        let result = [];
        
        // Process each part to handle bold text
        parts.forEach((part, index) => {
            if (part.startsWith('**') && part.endsWith('**')) {
                // For bold text, we don't look for links inside
                result.push({
                    type: 'bold',
                    content: part.slice(2, -2),
                    key: `bold-${index}`
                });
            } else if (part.trim()) {
                // For regular text, look for URLs
                const linkParts = formatLinks(part);
                result = result.concat(linkParts);
            } else if (part) {
                // Empty or whitespace parts
                result.push({
                    type: 'text',
                    content: part,
                    key: `text-${index}`
                });
            }
        });
        
        return result;
    };
    
    // Helper function to render formatted parts
    const renderFormattedParts = (parts) => {
        return parts.map(part => {
            if (part.type === "bold") {
                return <Box component="strong" key={part.key}>{part.content}</Box>;
            } else if (part.type === "link") {
                return (
                    <Box 
                        component="a" 
                        key={part.key} 
                        href={part.url} 
                        target="_blank" 
                        rel="noopener noreferrer"
                        sx={{ 
                            color: theme.palette.primary.main,
                            textDecoration: 'underline',
                            '&:hover': {
                                textDecoration: 'none'
                            }
                        }}
                    >
                        {part.content}
                    </Box>
                );
            } else {
                return <React.Fragment key={part.key}>{part.content}</React.Fragment>;
            }
        });
    };

    if (!content) {
        console.log('No content provided to FormattedContent');
        return null;
    }

    // Try to parse as JSON if it looks like JSON
    if (content.trim().startsWith('{') && content.trim().endsWith('}')) {
        try {
            const jsonContent = JSON.parse(content);
            // Convert JSON to markdown format
            const markdownContent = Object.entries(jsonContent)
                .map(([key, value]) => {
                    if (typeof value === 'object') {
                        return `## ${key}\n${JSON.stringify(value, null, 2)}`;
                    }
                    return `## ${key}\n${value}`;
                })
                .join('\n\n');
            content = markdownContent;
        } catch (e) {
            console.log('Failed to parse JSON content:', e);
        }
    }

    if (typeof content !== 'string') {
        console.log('Content is not a string:', content);
        return null;
    }

    const sections = parseMarkdown(content);

    return (
        <Box sx={{ 
            maxWidth: preview ? 'none' : 800, 
            mx: preview ? 0 : "auto", 
            p: preview ? 0 : 2,
            fontFamily: theme.typography.fontFamily,
            "& strong": {
                color: theme.palette.text.primary,
                fontWeight: theme.typography.fontWeightMedium
            }
        }}>
            {sections.map((section, index) => {
                const formattedParts = section.type === 'table-row'
                    ? section.content || []
                    : formatBoldText(section.content);

                switch (section.type) {
                    case 'table':
                        return (
                            <Box 
                                key={index} 
                                component="table" 
                                sx={{ 
                                    width: "100%", 
                                    borderCollapse: "collapse", 
                                    mb: 3,
                                    "& th, & td": { 
                                        border: `1px solid ${theme.palette.divider}`, 
                                        p: 1.5, 
                                        textAlign: "left", 
                                        fontSize: theme.typography.body2.fontSize 
                                    },
                                    "& th": { 
                                        bgcolor: theme.palette.background.default, 
                                        fontWeight: theme.typography.fontWeightMedium, 
                                        color: theme.palette.text.primary 
                                    },
                                    "& tr:nth-of-type(even)": { 
                                        bgcolor: theme.palette.action.hover 
                                    },
                                    "& tr:hover": { 
                                        bgcolor: theme.palette.action.hover 
                                    }
                                }}
                            >
                                <Box component="tbody">
                                    {section.rows.map((row, rowIndex) => {
                                        const isHeader = rowIndex === 0;
                                        return (
                                            <Box 
                                                key={rowIndex} 
                                                component="tr"
                                            >
                                                {row.map((cell, cellIndex) => (
                                                    <Box
                                                        key={cellIndex}
                                                        component={isHeader ? "th" : "td"}
                                                    >
                                                        {cell}
                                                    </Box>
                                                ))}
                                            </Box>
                                        );
                                    })}
                                </Box>
                            </Box>
                        );

                    case 'header':
                        return (
                            <Typography 
                                key={index} 
                                variant="h6" 
                                sx={{ 
                                    mt: 3, 
                                    mb: 2, 
                                    color: theme.palette.text.primary,
                                    fontWeight: theme.typography.fontWeightMedium,
                                    borderBottom: `1px solid ${theme.palette.divider}`,
                                    pb: 1
                                }}
                            >
                                {renderFormattedParts(formattedParts)}
                            </Typography>
                        );

                    case 'bullet':
                        return (
                            <Box 
                                component="ul" 
                                key={index} 
                                sx={{ 
                                    pl: 4,
                                    mb: 1,
                                    mt: 0
                                }}
                            >
                                <Box 
                                    component="li"
                                    sx={{ 
                                        color: theme.palette.text.secondary,
                                        ...theme.typography.body1,
                                        mb: 0.5
                                    }}
                                >
                                    {renderFormattedParts(formattedParts)}
                                </Box>
                            </Box>
                        );

                    default:
                        return (
                            <Typography 
                                key={index} 
                                sx={{ 
                                    mb: 2, 
                                    color: theme.palette.text.secondary,
                                    ...theme.typography.body1,
                                    lineHeight: 1.7
                                }}
                            >
                                {renderFormattedParts(formattedParts)}
                            </Typography>
                        );
                }
            })}
        </Box>
    );
};

export default FormattedContent;
