import React from 'react';
import {
    Box,
    ToggleButtonGroup,
    ToggleButton,
    Typography,
} from '@mui/material';

const YearSelector = ({ selectedYear, onYearChange }) => {
    // Generate years (current year - 1, current year, next 3 years)
    const currentYear = new Date().getFullYear();
    const years = [
        currentYear - 1,
        currentYear,
        currentYear + 1,
        currentYear + 2,
        currentYear + 3
    ];

    return (
        <Box sx={{ mb: 3 }}>
            <Typography 
                variant="subtitle2" 
                sx={{ 
                    mb: 1,
                    color: 'text.secondary',
                    fontWeight: 500
                }}
            >
                Select Year
            </Typography>
            <ToggleButtonGroup
                value={selectedYear}
                exclusive
                onChange={(e, value) => value && onYearChange(value)}
                aria-label="year selection"
                sx={{
                    '& .MuiToggleButton-root': {
                        px: 2,
                        py: 0.5,
                        '&.Mui-selected': {
                            bgcolor: 'primary.main',
                            color: 'primary.contrastText',
                            '&:hover': {
                                bgcolor: 'primary.dark',
                            }
                        }
                    }
                }}
            >
                {years.map(year => (
                    <ToggleButton 
                        key={year} 
                        value={year.toString()}
                        sx={{
                            '&:hover': {
                                bgcolor: 'primary.lighter',
                            }
                        }}
                    >
                        {year}
                    </ToggleButton>
                ))}
            </ToggleButtonGroup>
        </Box>
    );
};

export default YearSelector;