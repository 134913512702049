import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Box,
  IconButton,
  Stepper,
  Step,
  StepLabel,
  useTheme,
  useMediaQuery
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import QuestionEditor from './components/QuestionEditor';

const SUBJECTS = [
  'Mathematics',
  'English',
  'Science',
  'History',
  'Geography',
  'Art',
  'Music',
  'Physical Education',
  'Religious Studies',
  'Technology',
  'Languages',
  'Other'
];

const GRADES = [
  'Kindergarten',
  'Year 1',
  'Year 2',
  'Year 3',
  'Year 4',
  'Year 5',
  'Year 6',
  'Year 7',
  'Year 8',
  'Year 9',
  'Year 10',
  'Year 11',
  'Year 12'
];

const CreateWorksheet = ({ open, onClose, onSave }) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [activeStep, setActiveStep] = useState(0);
  const [worksheetData, setWorksheetData] = useState({
    title: '',
    description: '',
    subject: '',
    grade: '',
    questions: []
  });
  const [errors, setErrors] = useState({});

  const steps = ['Worksheet Details', 'Add Questions', 'Preview'];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setWorksheetData(prev => ({
      ...prev,
      [name]: value
    }));
    
    // Clear error when field is updated
    if (errors[name]) {
      setErrors(prev => ({
        ...prev,
        [name]: null
      }));
    }
  };

  const handleAddQuestion = (question) => {
    setWorksheetData(prev => ({
      ...prev,
      questions: [...prev.questions, question]
    }));
  };

  const handleUpdateQuestion = (index, updatedQuestion) => {
    setWorksheetData(prev => {
      const updatedQuestions = [...prev.questions];
      updatedQuestions[index] = updatedQuestion;
      return {
        ...prev,
        questions: updatedQuestions
      };
    });
  };

  const handleRemoveQuestion = (index) => {
    setWorksheetData(prev => {
      const updatedQuestions = [...prev.questions];
      updatedQuestions.splice(index, 1);
      return {
        ...prev,
        questions: updatedQuestions
      };
    });
  };

  const validateStep = () => {
    const newErrors = {};
    
    if (activeStep === 0) {
      if (!worksheetData.title.trim()) newErrors.title = 'Title is required';
      if (!worksheetData.subject) newErrors.subject = 'Subject is required';
      if (!worksheetData.grade) newErrors.grade = 'Grade is required';
    } else if (activeStep === 1) {
      if (worksheetData.questions.length === 0) {
        newErrors.questions = 'At least one question is required';
      }
    }
    
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleNext = () => {
    if (validateStep()) {
      setActiveStep(prevStep => prevStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep(prevStep => prevStep - 1);
  };

  const handleSubmit = () => {
    if (validateStep()) {
      onSave(worksheetData);
    }
  };

  const renderStepContent = () => {
    switch (activeStep) {
      case 0:
        return (
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                name="title"
                label="Worksheet Title"
                fullWidth
                value={worksheetData.title}
                onChange={handleChange}
                error={!!errors.title}
                helperText={errors.title}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="description"
                label="Description"
                fullWidth
                multiline
                rows={3}
                value={worksheetData.description}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth error={!!errors.subject} required>
                <InputLabel>Subject</InputLabel>
                <Select
                  name="subject"
                  value={worksheetData.subject}
                  onChange={handleChange}
                  label="Subject"
                >
                  {SUBJECTS.map(subject => (
                    <MenuItem key={subject} value={subject}>{subject}</MenuItem>
                  ))}
                </Select>
                {errors.subject && (
                  <Typography variant="caption" color="error">
                    {errors.subject}
                  </Typography>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth error={!!errors.grade} required>
                <InputLabel>Grade</InputLabel>
                <Select
                  name="grade"
                  value={worksheetData.grade}
                  onChange={handleChange}
                  label="Grade"
                >
                  {GRADES.map(grade => (
                    <MenuItem key={grade} value={grade}>{grade}</MenuItem>
                  ))}
                </Select>
                {errors.grade && (
                  <Typography variant="caption" color="error">
                    {errors.grade}
                  </Typography>
                )}
              </FormControl>
            </Grid>
          </Grid>
        );
      case 1:
        return (
          <QuestionEditor
            questions={worksheetData.questions}
            onAddQuestion={handleAddQuestion}
            onUpdateQuestion={handleUpdateQuestion}
            onRemoveQuestion={handleRemoveQuestion}
            error={errors.questions}
          />
        );
      case 2:
        return (
          <Box>
            <Typography variant="h6" gutterBottom>
              Worksheet Preview
            </Typography>
            <Box sx={{ mb: 2 }}>
              <Typography variant="subtitle1" fontWeight="bold">
                {worksheetData.title}
              </Typography>
              <Typography variant="body2" color="text.secondary" gutterBottom>
                {worksheetData.description}
              </Typography>
              <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
                <Typography variant="body2">
                  <strong>Subject:</strong> {worksheetData.subject}
                </Typography>
                <Typography variant="body2">
                  <strong>Grade:</strong> {worksheetData.grade}
                </Typography>
              </Box>
            </Box>
            
            <Typography variant="subtitle1" gutterBottom>
              Questions ({worksheetData.questions.length})
            </Typography>
            
            {worksheetData.questions.map((question, index) => (
              <Box key={index} sx={{ mb: 2, p: 2, border: '1px solid', borderColor: 'divider', borderRadius: 1 }}>
                <Typography variant="body1" gutterBottom>
                  <strong>{index + 1}.</strong> {question.questionText}
                </Typography>
                
                {question.questionType === 'multiple-choice' && (
                  <Box sx={{ ml: 3 }}>
                    {question.options.map((option, optIndex) => (
                      <Typography key={optIndex} variant="body2" sx={{ mb: 0.5 }}>
                        {String.fromCharCode(65 + optIndex)}. {option}
                      </Typography>
                    ))}
                    <Typography variant="body2" sx={{ mt: 1, color: 'success.main' }}>
                      Correct Answer: {question.correctAnswer}
                    </Typography>
                  </Box>
                )}
                
                {question.questionType === 'short-answer' && (
                  <Box sx={{ ml: 3 }}>
                    <Typography variant="body2" sx={{ fontStyle: 'italic' }}>
                      Short answer question
                    </Typography>
                    <Typography variant="body2" sx={{ mt: 1, color: 'success.main' }}>
                      Answer Guidelines: {question.answerGuidelines}
                    </Typography>
                  </Box>
                )}
                
                {question.questionType === 'long-answer' && (
                  <Box sx={{ ml: 3 }}>
                    <Typography variant="body2" sx={{ fontStyle: 'italic' }}>
                      Long answer question
                    </Typography>
                    <Typography variant="body2" sx={{ mt: 1, color: 'success.main' }}>
                      Answer Guidelines: {question.answerGuidelines}
                    </Typography>
                  </Box>
                )}
              </Box>
            ))}
          </Box>
        );
      default:
        return null;
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullScreen={fullScreen}
      maxWidth="md"
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: 2,
          maxHeight: '90vh'
        }
      }}
    >
      <DialogTitle sx={{ m: 0, p: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h6">Create New Worksheet</Typography>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      
      <Box sx={{ px: 3 }}>
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>
      
      <DialogContent dividers>
        {renderStepContent()}
      </DialogContent>
      
      <DialogActions sx={{ px: 3, py: 2 }}>
        <Button onClick={onClose} color="inherit">
          Cancel
        </Button>
        <Box sx={{ flex: '1 1 auto' }} />
        {activeStep > 0 && (
          <Button onClick={handleBack}>
            Back
          </Button>
        )}
        {activeStep < steps.length - 1 ? (
          <Button onClick={handleNext} variant="contained">
            Next
          </Button>
        ) : (
          <Button onClick={handleSubmit} variant="contained" color="primary">
            Create Worksheet
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default CreateWorksheet;