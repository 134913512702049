import {jwtDecode} from "jwt-decode";
import axios from "axios";

export const setToken = (token, refreshToken, user) => {
    const decodedToken = jwtDecode(token);
    console.log('Decoded Access Token:', decodedToken);

    // Ensure user has consistent id field
    const userData = {
        ...user,
        id: user?._id || user?.id || decodedToken?.id || decodedToken?.sub,
    };
    console.log('Storing user data:', userData);

    localStorage.setItem('token', token);
    localStorage.setItem('refreshToken', refreshToken);
    localStorage.setItem('user', JSON.stringify(userData));
};

export const getToken = () => {
    return localStorage.getItem('token');
};

export const getRefreshToken = () => {
    return localStorage.getItem('refreshToken');
};

export const clearToken = () => {
    console.log('Clearing all app state...');
    
    // Clear all user-specific storage items
    const userKeys = Object.keys(localStorage).filter(key => 
        key.includes('_selectedClass') ||
        key.includes('_selectedName') ||
        key.includes('_selectedStudent')
    );
    userKeys.forEach(key => {
        console.log('Clearing user storage:', key);
        localStorage.removeItem(key);
    });

    // Clear general app state
    const appKeys = [
        'token',
        'refreshToken',
        'user',
        'selectedClass',
        'selectedClassId',
        'dateRange',
        'currentDate',
        'selectedYear',
        'selectedTerms',
        'selectedName'
    ];
    
    appKeys.forEach(key => {
        console.log('Clearing app state:', key);
        localStorage.removeItem(key);
    });
    
    console.log('All app state cleared');
};

export const isAuthenticated = () => {
    return !!localStorage.getItem('token');
};

export const logout = (navigate) => {
    clearToken();
    navigate('/login');
};

export const isAdmin = () => {
    // Example logic to check if the user is an admin
    const user = JSON.parse(localStorage.getItem("user"));
    return user && user.role === 'admin';
};

export const isTokenExpired = (buffer = 0) => {
    const token = localStorage.getItem('token');
    if (!token) return true;

    try {
        const decodedToken = jwtDecode(token);
        const currentTime = Date.now() / 1000; // Convert to seconds
        // Add buffer time (in seconds) to check if token will expire soon
        return decodedToken.exp < (currentTime + buffer);
    } catch (error) {
        console.error('Error decoding token:', error);
        return true;
    }
};

export const refreshAccessToken = async () => {
    const refreshToken = localStorage.getItem('refreshToken');
    if (!refreshToken) {
        return null;
    }

    try {
        const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/auth/refresh-token`, {
            refreshToken
        });

        const { token, refreshToken: newRefreshToken, user } = response.data;

        // Update tokens and user data in localStorage
        setToken(token, newRefreshToken, user || JSON.parse(localStorage.getItem('user')));

        return token;
    } catch (error) {
        console.error('Token refresh failed:', error);
        // If refresh fails, clear tokens and redirect to login
        clearToken();
        return null;
    }
};

export const checkTokenValidity = async (navigate) => {
    const token = localStorage.getItem('token');
    if (!token) {
        if (navigate) navigate('/login');
        return false;
    }

    try {
        // First, check if token is expired locally
        if (isTokenExpired()) {
            // Attempt to refresh the token
            const newToken = await refreshAccessToken();
            if (!newToken) {
                if (navigate) navigate('/login');
                return false;
            }
            // If refresh successful, continue with the new token
        }

        // Validate token with backend
        await axios.get(`${process.env.REACT_APP_BASE_URL}/auth/validate-token`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        });

        return true;
    } catch (error) {
        console.error('Token validation failed:', error);
        if (navigate) navigate('/login');
        return false;
    }
};

let refreshPromise = null;

const getValidToken = async (navigate) => {
    // Check if token will expire in the next 30 seconds
    if (isTokenExpired(30)) {
        // If there's already a refresh in progress, wait for it
        if (refreshPromise) {
            await refreshPromise;
        } else {
            // Start a new refresh
            refreshPromise = refreshAccessToken();
            try {
                await refreshPromise;
            } finally {
                refreshPromise = null;
            }
        }
    }
    return localStorage.getItem('token');
};

export const protectedFetch = async (callback, navigate = null) => {
    try {
        const token = await getValidToken(navigate);
        const result = await callback();
        return result;
    } catch (error) {
        console.error('Protected Fetch Failed:', error);
        if (navigate) navigate('/login');
        throw error;
    }
};

export const protectedApiCall = async (endpoint, method = 'GET', data = null, isFileUpload = false, navigate = null) => {
    try {
        // Get a valid token before making the API call
        const token = await getValidToken(navigate);
        
        // For DELETE requests, ensure we're not sending a body
        // This helps prevent JSON parsing errors
        const config = {
            method,
            url: `${process.env.REACT_APP_BASE_URL}${endpoint}`,
            headers: {
                'Authorization': `Bearer ${token}`,
                ...(isFileUpload ? {} : { 'Content-Type': 'application/json' }),
            },
            data: (method !== 'GET' && method !== 'DELETE') ? data : undefined,
            params: method === 'GET' && data ? data : undefined,
            ...(isFileUpload ? { transformRequest: [(data, headers) => data] } : {}),
        };

        try {
            const response = await axios(config);
            return response;
        } catch (error) {
            // If the call fails with 401/403, try refreshing token once
            if (error.response && (error.response.status === 401 || error.response.status === 403)) {
                const newToken = await refreshAccessToken();
                if (newToken) {
                    // Retry the API call with the new token
                    config.headers = {
                        'Authorization': `Bearer ${newToken}`,
                        ...(isFileUpload ? {} : { 'Content-Type': 'application/json' }),
                    };
                    const response = await axios(config);
                    return response;
                }
            }
            console.error('Protected API Call Failed:', error);
            if (error.response) {
                console.error('Error Response:', error.response.data);
                console.error('Error Status:', error.response.status);
                console.error('Error Headers:', error.response.headers);
            }
            // Enhance error with response data if available
            if (error.response?.data) {
                error.message = error.response.data.message || error.response.data.error || error.message;
            }
            throw error;
        }
    } catch (error) {
        if (navigate) navigate('/login');
        throw error;
    }
};



