import React, { useEffect, useState, useRef } from 'react';
import {
  Box,
  Typography,
  Paper,
  Grid,
  Divider,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Button,
  CircularProgress,
  ButtonGroup,
  Tooltip,
  useTheme,
  Snackbar,
  Alert
} from '@mui/material';
import { 
  Print as PrintIcon, 
  Refresh as RefreshIcon,
  PictureAsPdf as PdfIcon,
  Download as DownloadIcon,
  ContentCopy as ContentCopyIcon
} from '@mui/icons-material';
import { protectedApiCall } from '../../auth';

/**
 * A printable version of a worksheet for students to use
 */
const PrintableWorksheet = ({ worksheet, schoolLogo }) => {
  const theme = useTheme();
  const [pageStyle, setPageStyle] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [fullWorksheet, setFullWorksheet] = useState(null);
  const printableContentRef = useRef(null);
  const [toastOpen, setToastOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [toastSeverity, setToastSeverity] = useState('success');
  
  // Fetch the complete worksheet data with questions
  useEffect(() => {
    if (worksheet && worksheet._id) {
      fetchWorksheetDetails(worksheet._id);
    }
  }, [worksheet]);
  
  const fetchWorksheetDetails = async (worksheetId) => {
    setLoading(true);
    setError(null);
    
    try {
      console.log('Fetching complete worksheet data for ID:', worksheetId);
      const response = await protectedApiCall(`/api/worksheets/${worksheetId}`, 'GET');
      console.log('Fetched worksheet details:', response.data);
      setFullWorksheet(response.data);
    } catch (err) {
      console.error('Error fetching worksheet details:', err);
      setError('Failed to load the complete worksheet. Please try again.');
    } finally {
      setLoading(false);
    }
  };
  
  // Create print-specific styles when component mounts
  useEffect(() => {
    // Create a style element for print media
    const style = document.createElement('style');
    style.innerHTML = `
      /* Styles for both print and screen */
      .print-only {
        display: none;
      }
      
      @media print {
        @page {
          size: A4;
          margin: 0.8cm; /* Reduced from 1cm for more compact layout */
        }
        body {
          -webkit-print-color-adjust: exact !important;
          print-color-adjust: exact !important;
        }
        .MuiPaper-root {
          box-shadow: none !important;
        }
        .no-print {
          display: none !important;
        }
        .page-break {
          page-break-after: always;
        }
        .answer-space {
          border: 1px solid #ddd;
          min-height: 80px; /* Reduced from 100px */
          margin-top: 5px; /* Reduced from 8px */
          margin-bottom: 12px; /* Reduced from 16px */
        }
        .multiple-choice-option {
          display: flex;
          align-items: center;
          margin-bottom: 5px; /* Reduced from 8px */
        }
        .multiple-choice-option .MuiFormControlLabel-root {
          margin-right: 0;
        }
        /* Show print-only elements and hide screen-only elements when printing */
        .print-only {
          display: block !important;
        }
        .screen-only {
          display: none !important;
        }
        
        /* Style for option circles */
        .option-circle {
          display: inline-block;
          width: 16px;
          height: 16px;
          border: 1px solid #000;
          border-radius: 50%;
          margin-right: 8px;
          vertical-align: middle;
        }
      }
    `;
    document.head.appendChild(style);
    
    // Store the style element reference for cleanup
    setPageStyle(style);
    
    return () => {
      // Clean up the style element when component unmounts
      if (pageStyle) {
        document.head.removeChild(pageStyle);
      }
    };
  }, []);
  
  // Function to create a clean, formatted worksheet for printing
  // This function is used for both the actual printing and the preview
  const createFormattedWorksheet = (worksheet, forPdf = false) => {
    if (!worksheet) return '';
    
    // Get worksheet data
    const title = worksheet.title || 'Worksheet';
    const subject = worksheet.subject || '';
    const grade = worksheet.grade || '';
    const description = worksheet.description || '';
    const questions = worksheet.questions || [];
    
    // Create HTML for each question
    const questionsHtml = questions.map((question, index) => {
      const questionText = question.questionText || question.text || question.question || `Question ${index + 1}`;
      const questionType = question.questionType || question.type || 'multiple-choice';
      
      // Get options for multiple choice questions
      let optionsHtml = '';
      if ((questionType === 'multiple-choice' || questionType === 'multiple_choice') && 
          question.options && Array.isArray(question.options) && question.options.length > 0) {
        
        optionsHtml = question.options.map((option, optIndex) => {
          const optionText = typeof option === 'string' ? option : `Option ${optIndex + 1}`;
          return `
            <div class="option">
              <div class="circle"></div>
              <div class="option-text">${optionText}</div>
            </div>
          `;
        }).join('');
      }
      
      // Create answer space for short/long answer questions (with reduced heights for more compact layout)
      let answerSpaceHtml = '';
      if (questionType === 'short-answer' || questionType === 'short_answer') {
        answerSpaceHtml = `<div class="answer-space" style="height: 60px;"></div>`; // Reduced from 80px
      } else if (questionType === 'long-answer' || questionType === 'long_answer' || questionType === 'essay') {
        answerSpaceHtml = `<div class="answer-space" style="height: 150px;"></div>`; // Reduced from 200px
      } else if (questionType !== 'multiple-choice' && questionType !== 'multiple_choice') {
        answerSpaceHtml = `<div class="answer-space" style="height: 90px;"></div>`; // Reduced from 120px
      }
      
      return `
        <div class="question">
          <div class="question-text">${index + 1}. ${questionText}</div>
          ${optionsHtml ? `<div class="options">${optionsHtml}</div>` : ''}
          ${answerSpaceHtml}
          ${question.points ? `<div class="points">(${question.points} ${question.points === 1 ? 'point' : 'points'})</div>` : ''}
        </div>
      `;
    }).join('');
    
    // Create the full HTML document
    return `
      <!DOCTYPE html>
      <html>
        <head>
          <title>${title}</title>
          <style>
            @page {
              size: A4;
              margin: 0.8cm; /* Reduced from 1.5cm for more compact layout */
            }
            body {
              font-family: Arial, sans-serif;
              line-height: 1.3; /* Reduced from 1.5 for more compact text */
              color: #333;
              max-width: 800px;
              margin: 0 auto;
              padding: 0;
            }
            .worksheet {
              padding: 0;
            }
            .header {
              display: flex;
              justify-content: space-between;
              align-items: flex-start;
              margin-bottom: 15px; /* Reduced from 20px */
            }
            .title-section {
              text-align: center;
              flex: 1;
            }
            .worksheet-title {
              font-size: 18px;
              font-weight: bold;
              margin: 0 0 3px 0; /* Reduced from 5px */
            }
            .worksheet-subtitle {
              font-size: 14px;
              margin: 0;
              color: #555;
            }
            .logo {
              max-width: 70px; /* Reduced from 80px */
              max-height: 70px; /* Reduced from 80px */
              margin-right: 10px; /* Reduced from 15px */
            }
            .student-info {
              width: 200px;
              text-align: left;
            }
            .student-info p {
              margin: 3px 0; /* Reduced from 5px */
              display: flex;
            }
            .student-info span {
              display: inline-block;
              min-width: 50px;
            }
            .student-info .line {
              flex: 1;
              border-bottom: 1px solid #000;
              margin-left: 5px;
              min-width: 150px;
            }
            .divider {
              border-top: 1px solid #999;
              margin: 10px 0; /* Reduced from 15px */
            }
            .description {
              margin-bottom: 15px; /* Reduced from 20px */
              font-style: italic;
              color: #555;
            }
            .questions {
              margin-top: 15px; /* Reduced from 20px */
            }
            .question {
              margin-bottom: 18px; /* Reduced from 25px */
              page-break-inside: avoid;
            }
            .question-text {
              font-weight: bold;
              margin-bottom: 8px; /* Reduced from 10px */
            }
            .options {
              margin-left: 20px; /* Reduced from 25px */
              margin-top: 8px; /* Reduced from 10px */
              display: flex;
              flex-wrap: wrap; /* Allow options to wrap into columns */
            }
            .option {
              display: flex;
              align-items: center;
              margin-bottom: 8px; /* Reduced from 12px */
              width: 48%; /* Set width to create two columns */
              margin-right: 2%;
            }
            /* For mobile or print with limited width */
            @media (max-width: 500px) {
              .option {
                width: 100%;
                margin-right: 0;
              }
            }
            .circle {
              display: inline-block;
              width: 12px; /* Reduced from 14px */
              height: 12px; /* Reduced from 14px */
              border: 1.5px solid #000;
              border-radius: 50%;
              margin-right: 8px; /* Reduced from 10px */
              flex-shrink: 0;
            }
            .option-text {
              flex: 1;
            }
            .answer-space {
              border: 1px solid #aaa;
              margin: 8px 0 12px 20px; /* Reduced from 10px 0 15px 25px */
              background-color: #f9f9f9;
            }
            .points {
              text-align: right;
              font-size: 12px;
              color: #666;
              font-style: italic;
            }
            .footer {
              margin-top: 20px; /* Reduced from 30px */
              font-size: 11px; /* Reduced from 12px */
              text-align: center;
              color: #777;
              page-break-inside: avoid;
            }
            .page-number {
              position: running(pageNumber);
              text-align: center;
            }
            .page-count {
              position: running(pageCount);
            }
            @page {
              @bottom-center {
                content: "Page " counter(page) " of " counter(pages);
                font-size: 10pt;
                color: #777;
              }
            }
            ${forPdf ? `
            .instructions {
              background-color: #f8f9fa;
              border: 1px solid #ddd;
              padding: 15px;
              margin-bottom: 20px;
              border-radius: 4px;
            }
            .instructions h2 {
              margin-top: 0;
              color: #1976d2;
            }
            .instructions ol {
              margin-bottom: 0;
            }
            @media print {
              .instructions {
                display: none;
              }
            }
            ` : ''}
          </style>
        </head>
        <body>
          ${forPdf ? `
          <div class="instructions">
            <h2>Save as PDF Instructions</h2>
            <ol>
              <li>Press <strong>Ctrl+P</strong> (Windows/Linux) or <strong>⌘+P</strong> (Mac) to open the print dialog</li>
              <li>Select <strong>"Save as PDF"</strong> or <strong>"Microsoft Print to PDF"</strong> as the destination</li>
              <li>Click <strong>"Save"</strong> or <strong>"Print"</strong></li>
              <li>Choose a location to save the PDF file</li>
            </ol>
          </div>
          ` : ''}
          <div class="worksheet">
            <div class="header">
              ${schoolLogo ? `<img src="${schoolLogo}" alt="School Logo" class="logo">` : ''}
              <div class="title-section">
                <h1 class="worksheet-title">${title}</h1>
                <h2 class="worksheet-subtitle">${subject}${subject && grade ? ' - ' : ''}${grade}</h2>
              </div>
              <div class="student-info">
                <p><span>Name:</span><span class="line"></span></p>
                <p><span>Date:</span><span class="line"></span></p>
              </div>
            </div>
            
            ${description ? `<div class="description">${description}</div>` : ''}
            
            <div class="divider"></div>
            
            <div class="questions">
              ${questionsHtml}
            </div>
            
            <div class="footer">
              <div class="page-number"></div>
            </div>
          </div>
        </body>
      </html>
    `;
  };

  // Function to show toast notifications
  const showToast = (message, severity = 'success') => {
    setToastMessage(message);
    setToastSeverity(severity);
    setToastOpen(true);
  };

  // Function to handle toast close
  const handleToastClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setToastOpen(false);
  };

  // Function to copy worksheet as rich text specifically formatted for Word
  const copyAsRichText = () => {
    if (!printableContentRef.current) {
      showToast('Nothing to copy', 'error');
      return;
    }

    try {
      const listener = function(e) {
        // Extract worksheet data
        const worksheetTitle = processedWorksheet?.title || 'Worksheet';
        const worksheetSubject = processedWorksheet?.subject || '';
        const worksheetGrade = processedWorksheet?.grade || '';
        const worksheetDescription = processedWorksheet?.description || '';
        
        // Extract questions directly from the processed worksheet data
        let questions = [];
        
        // First try to get questions from the processed worksheet data
        if (processedWorksheet && processedWorksheet.questions && Array.isArray(processedWorksheet.questions)) {
          console.log('Using questions from processedWorksheet:', processedWorksheet.questions);
          
          // Map the questions to our expected format
          questions = processedWorksheet.questions.map((q, index) => {
            // Get the question text
            const text = q.questionText || q.text || q.question || `Question ${index + 1}`;
            
            // Get the points if available
            const points = q.points ? `(${q.points} ${q.points === 1 ? 'point' : 'points'})` : '';
            
            // Get options if it's multiple choice
            const options = q.options && Array.isArray(q.options) ? 
                          q.options.map(opt => typeof opt === 'string' ? opt : (opt.text || '')) :
                          [];
            
            return {
              text: text,
              points: points,
              options: options,
              hasOptions: options.length > 0,
              type: q.questionType || q.type || (options.length > 0 ? 'multiple-choice' : 'short-answer')
            };
          });
        }
        
        // If no questions were found in the processed data, try to extract them from the DOM
        if (questions.length === 0) {
          console.log('No questions found in processedWorksheet, extracting from DOM');
          
          // Extract text content from the entire document as a fallback
          const allText = printableContentRef.current.textContent;
          
          // Try to extract questions using regex patterns
          const questionPattern = /(\d+\.\s+)([^(]+)(\(\d+\s+points?\))?/g;
          let match;
          let extractedQuestions = [];
          
          while ((match = questionPattern.exec(allText)) !== null) {
            extractedQuestions.push({
              text: match[2].trim(),
              points: match[3] ? match[3].trim() : '',
              options: [],
              hasOptions: false
            });
          }
          
          console.log('Extracted questions using regex:', extractedQuestions);
          
          // If we found questions using regex, use those
          if (extractedQuestions.length > 0) {
            questions = extractedQuestions;
          } else {
            // Otherwise, try to extract from DOM elements
            const questionElements = printableContentRef.current.querySelectorAll('.question') || 
                                    printableContentRef.current.querySelectorAll('div > div > div');
            
            questionElements.forEach((questionEl, index) => {
              // Get question text
              const questionTextEl = questionEl.querySelector('.question-text') || 
                                    questionEl.querySelector('div:first-child');
              const questionText = questionTextEl ? questionTextEl.textContent.trim() : `Question ${index + 1}`;
              
              // Get points if available
              const pointsEl = questionEl.querySelector('.points') || 
                              (questionEl.querySelector('div:last-child')?.textContent.includes('point') ? 
                               questionEl.querySelector('div:last-child') : null);
              const points = pointsEl ? pointsEl.textContent.trim() : '';
              
              // Check if it's multiple choice
              const optionsContainer = questionEl.querySelector('.options') || 
                                      (questionEl.querySelectorAll('div')[1]?.querySelectorAll('div').length > 0 ? 
                                       questionEl.querySelectorAll('div')[1] : null);
              
              let options = [];
              if (optionsContainer) {
                const optionElements = optionsContainer.querySelectorAll('.option') || 
                                      optionsContainer.querySelectorAll('div');
                
                optionElements.forEach(optionEl => {
                  const optionTextEl = optionEl.querySelector('.option-text') || 
                                      optionEl.querySelector('div:last-child');
                  if (optionTextEl) {
                    options.push(optionTextEl.textContent.trim());
                  }
                });
              }
              
              questions.push({
                text: questionText,
                points: points,
                options: options,
                hasOptions: options.length > 0
              });
            });
          }
        }
        
        // Ensure we have at least some questions
        if (questions.length === 0) {
          // Create a dummy question as a last resort
          questions.push({
            text: 'No questions could be extracted. Please try again or download as PDF.',
            points: '',
            options: [],
            hasOptions: false
          });
        }
        
        console.log('Final questions for copying:', questions);
        
        // Create a Word-friendly HTML structure
        // Word prefers tables for consistent layout
        let wordHTML = `
          <!DOCTYPE html>
          <html>
          <head>
            <meta charset="UTF-8">
            <title>${worksheetTitle}</title>
            <style>
              body { 
                font-family: Arial, sans-serif; 
                font-size: 11pt; 
                line-height: 1.5; 
                margin: 0;
                padding: 0;
              }
              table { 
                border-collapse: collapse; 
                width: 100%; 
                table-layout: fixed;
              }
              td { 
                padding: 8px; 
                vertical-align: top; 
              }
              .title { 
                font-size: 16pt; 
                font-weight: bold; 
                text-align: center; 
                margin-bottom: 5px;
              }
              .subtitle { 
                font-size: 12pt; 
                text-align: center; 
                margin-top: 0;
              }
              .question { 
                font-weight: bold; 
                margin-bottom: 10px;
              }
              .answer-space { 
                border: 1px solid #aaa; 
                height: 80px; 
                background-color: #f9f9f9; 
                margin: 10px 0 15px 25px;
              }
              .points { 
                font-style: italic; 
                color: #666; 
                text-align: right; 
                font-size: 9pt;
                margin-top: 5px;
              }
              .option-circle { 
                display: inline-block; 
                width: 12px; 
                height: 12px; 
                border: 1.5px solid #000; 
                border-radius: 50%; 
                margin-right: 8px; 
              }
              .question-spacer {
                height: 20px;
                display: block;
                width: 100%;
              }
              .page-footer {
                text-align: center;
                font-size: 9pt;
                color: #777;
                margin-top: 20px;
                border-top: 1px solid #eee;
                padding-top: 10px;
              }
              /* Word-specific styles */
              @page {
                size: A4;
                margin: 2cm;
              }
              /* Ensure proper spacing between questions */
              div[style*="margin-bottom: 30px"] {
                page-break-inside: avoid;
              }
            </style>
          </head>
          <body>
            <!-- Header Table -->
            <table border="0" cellpadding="8" cellspacing="0" width="100%" style="margin-bottom: 20px;">
              <tr>
                <td width="15%">
                  ${schoolLogo ? `<img src="${schoolLogo}" alt="School Logo" style="max-width: 70px; max-height: 70px;">` : ''}
                </td>
                <td width="70%" style="text-align: center;">
                  <div class="title">${worksheetTitle}</div>
                  <div class="subtitle">${worksheetSubject}${worksheetSubject && worksheetGrade ? ' - ' : ''}${worksheetGrade}</div>
                </td>
                <td width="15%" style="text-align: right;">
                  <table border="0" cellpadding="2" cellspacing="0" width="100%">
                    <tr>
                      <td width="40%"><b>Name:</b></td>
                      <td width="60%" style="border-bottom: 1px solid #000;">&nbsp;</td>
                    </tr>
                    <tr>
                      <td width="40%"><b>Date:</b></td>
                      <td width="60%" style="border-bottom: 1px solid #000;">&nbsp;</td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
        `;
        
        // Add description if available
        if (worksheetDescription) {
          wordHTML += `
            <div style="margin-bottom: 20px; font-style: italic; color: #555;">
              ${worksheetDescription}
            </div>
          `;
        }
        
        // Add horizontal rule
        wordHTML += `<hr style="border-top: 1px solid #999; margin: 15px 0;">`;
        
        // Add questions
        wordHTML += `<div style="margin-top: 20px;">`;
        
        questions.forEach((question, index) => {
          // Start question with a table structure for better Word compatibility
          wordHTML += `
            <div style="margin-bottom: 30px;">
              <table border="0" cellpadding="0" cellspacing="0" width="100%">
                <tr>
                  <td>
                    <div class="question">${index + 1}. ${question.text}</div>
                  </td>
                </tr>
              </table>
          `;
          
          // Add options if it's multiple choice
          if (question.hasOptions && question.options.length > 0) {
            wordHTML += `<table border="0" cellpadding="4" cellspacing="0" width="100%" style="margin-left: 20px; margin-top: 10px;">`;
            
            // Create rows with two options per row (if possible)
            for (let i = 0; i < question.options.length; i += 2) {
              wordHTML += `<tr>`;
              
              // First option
              wordHTML += `
                <td width="50%" style="padding-bottom: 8px;">
                  <table border="0" cellpadding="0" cellspacing="0" width="100%">
                    <tr>
                      <td width="20" valign="top">
                        <div class="option-circle">&nbsp;</div>
                      </td>
                      <td>${question.options[i]}</td>
                    </tr>
                  </table>
                </td>
              `;
              
              // Second option (if exists)
              if (i + 1 < question.options.length) {
                wordHTML += `
                  <td width="50%" style="padding-bottom: 8px;">
                    <table border="0" cellpadding="0" cellspacing="0" width="100%">
                      <tr>
                        <td width="20" valign="top">
                          <div class="option-circle">&nbsp;</div>
                        </td>
                        <td>${question.options[i + 1]}</td>
                      </tr>
                    </table>
                  </td>
                `;
              } else {
                wordHTML += `<td width="50%">&nbsp;</td>`;
              }
              
              wordHTML += `</tr>`;
            }
            
            wordHTML += `</table>`;
          } else {
            // Add answer space for non-multiple choice questions using a table for better Word compatibility
            wordHTML += `
              <table border="0" cellpadding="0" cellspacing="0" width="100%" style="margin: 10px 0 15px 25px;">
                <tr>
                  <td style="border: 1px solid #aaa; background-color: #f9f9f9; height: 80px; width: 100%;">
                    &nbsp;
                  </td>
                </tr>
              </table>
            `;
          }
          
          // Add points if available using a table for better Word compatibility
          if (question.points) {
            wordHTML += `
              <table border="0" cellpadding="0" cellspacing="0" width="100%">
                <tr>
                  <td style="text-align: right; font-style: italic; color: #666; font-size: 9pt;">
                    ${question.points}
                  </td>
                </tr>
              </table>
            `;
          }
          
          // End question
          wordHTML += `</div>`;
          
          // Add extra spacing between questions using a table row for better Word compatibility
          wordHTML += `
            <table border="0" cellpadding="0" cellspacing="0" width="100%">
              <tr><td style="height: 30px;">&nbsp;</td></tr>
            </table>
          `;
        });
        
        wordHTML += `</div>`;
        
        // Add footer with page number
        wordHTML += `
            <div class="page-footer">
              <table border="0" cellpadding="0" cellspacing="0" width="100%">
                <tr>
                  <td style="text-align: center; font-size: 9pt; color: #777;">
                    Page 1 of 1
                  </td>
                </tr>
              </table>
            </div>
          </body>
          </html>
        `;

        e.clipboardData.setData("text/html", wordHTML);
        e.clipboardData.setData("text/plain", printableContentRef.current.innerText);
        e.preventDefault();
      };

      document.addEventListener("copy", listener);
      document.execCommand("copy");
      document.removeEventListener("copy", listener);
      showToast('Worksheet copied to clipboard - optimized for Word');
    } catch (err) {
      console.error('Failed to copy:', err);
      showToast('Copy failed - try downloading as PDF', 'error');
    }
  };

  // Function to handle printing
  const handlePrint = () => {
    // Open a new window for printing
    const printWindow = window.open('', '_blank');
    
    if (!printWindow) {
      showToast('Please allow pop-ups to print the worksheet', 'warning');
      return;
    }
    
    // Create the formatted worksheet HTML
    const formattedWorksheet = createFormattedWorksheet(processedWorksheet);
    
    // Write the HTML to the new window
    printWindow.document.write(formattedWorksheet);
    
    // Wait for content to load then print
    printWindow.document.close();
    printWindow.focus();
    
    // Print after a short delay to ensure content is loaded
    setTimeout(() => {
      printWindow.print();
      // Close the window after printing (or if user cancels)
      printWindow.onafterprint = () => printWindow.close();
    }, 1000);
  };
  
  // Function to generate and download a PDF using the browser's print-to-PDF functionality
  const handleDownloadPdf = () => {
    // Open a new window for printing to PDF
    const printWindow = window.open('', '_blank');
    
    if (!printWindow) {
      showToast('Please allow pop-ups to generate the PDF', 'warning');
      return;
    }
    
    // Create the formatted worksheet HTML with PDF instructions
    const formattedWorksheet = createFormattedWorksheet(processedWorksheet, true);
    
    // Write the HTML to the new window
    printWindow.document.write(formattedWorksheet);
    
    // Wait for content to load
    printWindow.document.close();
    printWindow.focus();
  };
  
  // Process and normalize the worksheet data
  const [processedWorksheet, setProcessedWorksheet] = useState(null);
  
  useEffect(() => {
    // Use the full worksheet data if available, otherwise use the basic worksheet data
    const worksheetToProcess = fullWorksheet || worksheet;
    
    if (worksheetToProcess) {
      console.log('PrintableWorksheet - processing worksheet data:', worksheetToProcess);
      
      // Create a copy of the worksheet to modify
      const processed = { ...worksheetToProcess };
      
      // Check if questions exist
      if (!processed.questions || !Array.isArray(processed.questions) || processed.questions.length === 0) {
        console.log('No questions array found, looking for questions in other properties');
        
        // Look for questions in different possible locations
        if (processed.data && processed.data.questions && Array.isArray(processed.data.questions)) {
          processed.questions = processed.data.questions;
          console.log('Found questions in worksheet.data.questions');
        } else if (processed.content && processed.content.questions && Array.isArray(processed.content.questions)) {
          processed.questions = processed.content.questions;
          console.log('Found questions in worksheet.content.questions');
        } else if (processed.worksheet && processed.worksheet.questions && Array.isArray(processed.worksheet.questions)) {
          processed.questions = processed.worksheet.questions;
          console.log('Found questions in worksheet.worksheet.questions');
        } else if (processed.questionText || processed.text) {
          // If there's a single question text that might contain multiple questions
          const fullText = processed.questionText || processed.text;
          console.log('Found a single question text that might contain multiple questions:', fullText);
          
          // Try to parse multiple questions from the text
          try {
            // Split by question numbers (1., 2., etc.)
            const questionRegex = /(\d+\.\s+(?:QUESTION:\s+)?)(.*?)(?=\d+\.\s+(?:QUESTION:\s+)?|$)/gs;
            const matches = [...fullText.matchAll(questionRegex)];
            
            if (matches && matches.length > 0) {
              processed.questions = matches.map(match => {
                const questionText = match[2].trim();
                return { questionText };
              });
              console.log('Parsed questions from text:', processed.questions);
            } else {
              // If no matches, create a single question
              processed.questions = [{ questionText: fullText }];
            }
          } catch (e) {
            console.error('Error parsing questions from text:', e);
            processed.questions = [{ questionText: fullText }];
          }
        } else {
          // Create an empty questions array as a fallback
          processed.questions = [];
          console.warn('No questions found in any expected location');
        }
      }
      
      console.log('PrintableWorksheet - processed worksheet:', processed);
      console.log('PrintableWorksheet - processed questions:', processed.questions);
      
      setProcessedWorksheet(processed);
    }
  }, [worksheet, fullWorksheet]);
  
  // If no worksheet is provided, show a message
  if (!worksheet) {
    return (
      <Box sx={{ p: 3, textAlign: 'center' }}>
        <Typography variant="h6">No worksheet selected</Typography>
      </Box>
    );
  }
  
  // If we're loading the worksheet details, show a loading indicator
  if (loading) {
    return (
      <Box sx={{ p: 3, textAlign: 'center' }}>
        <CircularProgress size={40} sx={{ mb: 2 }} />
        <Typography variant="h6">Loading worksheet details...</Typography>
      </Box>
    );
  }
  
  // If there was an error loading the worksheet details, show an error message
  if (error) {
    return (
      <Box sx={{ p: 3, textAlign: 'center' }}>
        <Typography variant="h6" color="error" gutterBottom>
          {error}
        </Typography>
        <Button 
          variant="contained" 
          startIcon={<RefreshIcon />}
          onClick={() => worksheet._id && fetchWorksheetDetails(worksheet._id)}
          sx={{ mt: 2 }}
        >
          Try Again
        </Button>
      </Box>
    );
  }
  
  // If the worksheet is still being processed, show a loading message
  if (!processedWorksheet) {
    return (
      <Box sx={{ p: 3, textAlign: 'center' }}>
        <Typography variant="h6">Processing worksheet data...</Typography>
      </Box>
    );
  }
  
  return (
    <Box>
      {/* Toast notification */}
      <Snackbar 
        open={toastOpen} 
        autoHideDuration={4000} 
        onClose={handleToastClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleToastClose} severity={toastSeverity} sx={{ width: '100%' }}>
          {toastMessage}
        </Alert>
      </Snackbar>

      {/* Print, Copy, and PDF buttons - will not be printed */}
      <Box sx={{ mb: 3, display: 'flex', justifyContent: 'space-between' }} className="no-print">
        <ButtonGroup variant="contained">
          <Tooltip title="Print the worksheet">
            <Button
              color="primary"
              startIcon={<PrintIcon />}
              onClick={handlePrint}
            >
              Print
            </Button>
          </Tooltip>
          <Tooltip title="Open in a new window to save as PDF">
            <Button
              color="secondary"
              startIcon={<PdfIcon />}
              onClick={handleDownloadPdf}
            >
              Save as PDF
            </Button>
          </Tooltip>
        </ButtonGroup>
        
        <ButtonGroup>
          <Tooltip title="Copy for Word/Google Docs">
            <Button
              variant="outlined"
              startIcon={<ContentCopyIcon />}
              onClick={copyAsRichText}
            >
              Copy Document
            </Button>
          </Tooltip>
          <Button
            variant="outlined"
            startIcon={<RefreshIcon />}
            onClick={() => worksheet._id && fetchWorksheetDetails(worksheet._id)}
          >
            Refresh
          </Button>
        </ButtonGroup>
      </Box>
      
      {/* Printable content preview - using compact styling to match printed output */}
      <Paper 
        ref={printableContentRef} 
        sx={{ 
          p: 2, /* Reduced from p: 3 */
          maxWidth: '210mm', 
          mx: 'auto',
          border: '1px solid #ddd',
          boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
          bgcolor: 'white'
        }}
      >
        {/* Preview header - with compact styling */}
        <Box sx={{ mb: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
          {schoolLogo && (
            <Box sx={{ maxWidth: '70px', maxHeight: '70px', mr: 1.5 }}>
              <img 
                src={schoolLogo} 
                alt="School Logo" 
                style={{ maxWidth: '100%', maxHeight: '100%' }} 
              />
            </Box>
          )}
          
          <Box sx={{ flex: 1, textAlign: 'center' }}>
            <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 0.3 }}>
              {processedWorksheet.title || 'Worksheet'}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {processedWorksheet.subject || ''}
              {processedWorksheet.subject && processedWorksheet.grade ? ' - ' : ''}
              {processedWorksheet.grade || ''}
            </Typography>
          </Box>
          
          {/* Student name field */}
          <Box sx={{ width: '200px' }}>
            <Box sx={{ display: 'flex', mb: 0.5 }}>
              <Typography variant="body2" sx={{ minWidth: '50px' }}>Name:</Typography>
              <Box sx={{ flex: 1, borderBottom: '1px solid #000', ml: 1 }}></Box>
            </Box>
            <Box sx={{ display: 'flex' }}>
              <Typography variant="body2" sx={{ minWidth: '50px' }}>Date:</Typography>
              <Box sx={{ flex: 1, borderBottom: '1px solid #000', ml: 1 }}></Box>
            </Box>
          </Box>
        </Box>
        
        {/* Description */}
        {processedWorksheet.description && (
          <Box sx={{ mb: 2 }}>
            <Typography variant="body2" sx={{ fontStyle: 'italic', color: 'text.secondary' }}>
              {processedWorksheet.description}
            </Typography>
          </Box>
        )}
        
        <Divider sx={{ mb: 2, borderColor: '#999' }} />
        
        {/* Questions */}
        <Box>
          {/* Debug info */}
          {(!processedWorksheet.questions || processedWorksheet.questions.length === 0) && (
            <Box sx={{ p: 1.5, mb: 1.5, bgcolor: 'error.light', color: 'error.contrastText', borderRadius: 1 }} className="no-print">
              <Typography variant="subtitle2">
                No questions found in worksheet data. Check console for details.
              </Typography>
            </Box>
          )}
          
          {/* Render questions if they exist */}
          {processedWorksheet.questions && processedWorksheet.questions.map((question, index) => {
            // Get question text - handle different possible structures
            const questionText = question.questionText || question.text || question.question || 
                                `Question ${index + 1}`;
            
            // Get question type - default to multiple-choice if not specified
            const questionType = question.questionType || question.type || 'multiple-choice';
            
            // Get options - handle different possible structures and formats
            let options = [];
            if (question.options && Array.isArray(question.options)) {
              options = question.options;
            } else if (question.choices && Array.isArray(question.choices)) {
              options = question.choices;
            } else if (typeof questionText === 'string' && questionText.includes('A.')) {
              // Try to extract options from the question text
              const optionMatches = questionText.match(/([A-D]\..*?)(?=[A-D]\.|$)/g);
              if (optionMatches) {
                options = optionMatches.map(o => o.trim());
              }
            }
            
            return (
              <Box 
                key={index} 
                sx={{ 
                  mb: 2, /* Reduced from mb: 3 */
                  pb: 0.5 /* Reduced from pb: 1 */
                }}
              >
                <Typography variant="body1" fontWeight="bold" sx={{ mb: 0.5 }}>
                  {index + 1}. {questionText}
                </Typography>
                
                {/* Multiple choice questions - in two columns for compact layout */}
                {(questionType === 'multiple-choice' || questionType === 'multiple_choice') && options.length > 0 && (
                  <Box sx={{ 
                    ml: 2, 
                    mt: 0.5,
                    display: 'flex',
                    flexWrap: 'wrap'
                  }}>
                    {options.map((option, optIndex) => {
                      const optionText = typeof option === 'string' ? option : `Option ${optIndex + 1}`;
                      return (
                        <Box 
                          key={optIndex} 
                          sx={{ 
                            mb: 1, 
                            display: 'flex', 
                            alignItems: 'center',
                            width: '48%',
                            mr: '2%'
                          }}
                        >
                          <Box 
                            sx={{ 
                              width: 12, 
                              height: 12, 
                              borderRadius: '50%', 
                              border: '1.5px solid #000',
                              mr: 1,
                              flexShrink: 0
                            }} 
                          />
                          <Typography variant="body2">{optionText}</Typography>
                        </Box>
                      );
                    })}
                  </Box>
                )}
                
                {/* Short answer questions - with reduced height */}
                {(questionType === 'short-answer' || questionType === 'short_answer') && (
                  <Box sx={{ ml: 2, mt: 0.5 }}>
                    <Box sx={{ 
                      height: '60px', /* Reduced from 80px */
                      border: '1px solid #aaa',
                      bgcolor: '#f9f9f9',
                      borderRadius: '2px'
                    }} />
                  </Box>
                )}
                
                {/* Long answer questions - with reduced height */}
                {(questionType === 'long-answer' || questionType === 'long_answer' || questionType === 'essay') && (
                  <Box sx={{ ml: 2, mt: 0.5 }}>
                    <Box sx={{ 
                      height: '150px', /* Reduced from 200px */
                      border: '1px solid #aaa',
                      bgcolor: '#f9f9f9',
                      borderRadius: '2px'
                    }} />
                  </Box>
                )}
                
                {/* Default answer space for unknown question types - with reduced height */}
                {(questionType !== 'multiple-choice' && 
                  questionType !== 'multiple_choice' && 
                  questionType !== 'short-answer' && 
                  questionType !== 'short_answer' && 
                  questionType !== 'long-answer' && 
                  questionType !== 'long_answer' && 
                  questionType !== 'essay') && (
                  <Box sx={{ ml: 2, mt: 0.5 }}>
                    <Box sx={{ 
                      height: '90px', /* Reduced from 120px */
                      border: '1px solid #aaa',
                      bgcolor: '#f9f9f9',
                      borderRadius: '2px'
                    }} />
                  </Box>
                )}
                
                {/* Points */}
                {question.points && (
                  <Typography 
                    variant="body2" 
                    sx={{ 
                      textAlign: 'right',
                      color: 'text.secondary',
                      fontStyle: 'italic',
                      fontSize: '0.8rem',
                      mt: 0.5
                    }}
                  >
                    ({question.points} {question.points === 1 ? 'point' : 'points'})
                  </Typography>
                )}
              </Box>
            );
          })}
        </Box>
        
        {/* Footer with page number */}
        <Box sx={{ mt: 4, textAlign: 'center', borderTop: '1px solid #eee', pt: 2 }}>
          <Typography variant="caption" color="text.secondary">
            Page 1 of 1
          </Typography>
        </Box>
      </Paper>
    </Box>
  );
};

export default PrintableWorksheet;