import React, { useState, useEffect } from 'react';
import {
    Box,
    Paper,
    Typography,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Grid,
    Card,
    CardContent,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    CircularProgress,
    Alert,
} from '@mui/material';
import {
    Timeline as TimelineIcon,
    Group as GroupIcon,
    Description as DescriptionIcon,
    Route as RouteIcon,
    Leaderboard as LeaderboardIcon,
} from '@mui/icons-material';
import { Line } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
} from 'chart.js';
import { protectedApiCall } from '../../../auth';

// Register ChartJS components
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

const AnalyticsDashboard = () => {
    const [timeframe, setTimeframe] = useState('7d');
    const [stats, setStats] = useState(null);
    const [timeline, setTimeline] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    // Fetch stats and timeline data
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const [statsResponse, timelineResponse] = await Promise.all([
                    protectedApiCall(`/api/analytics/usage-stats?timeframe=${timeframe}`, 'GET'),
                    protectedApiCall(`/api/analytics/usage-timeline?timeframe=${timeframe}&interval=1d`, 'GET')
                ]);

                setStats(statsResponse.data);
                setTimeline(timelineResponse.data);
                setError(null);
            } catch (err) {
                console.error('Error fetching analytics:', err);
                setError('Failed to fetch analytics data');
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [timeframe]);

    // Prepare timeline chart data
    const timelineData = {
        labels: timeline.map(t => new Date(t.start).toLocaleDateString()),
        datasets: [
            {
                label: 'Web Logins',
                data: timeline.map(t => t.web_logins),
                borderColor: 'rgb(75, 192, 192)',
                tension: 0.1
            },
            {
                label: 'App Logins',
                data: timeline.map(t => t.app_logins),
                borderColor: 'rgb(255, 99, 132)',
                tension: 0.1
            },
            {
                label: 'Active Users',
                data: timeline.map(t => t.active_users),
                borderColor: 'rgb(53, 162, 235)',
                tension: 0.1
            }
        ]
    };

    const timelineOptions = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'Usage Timeline'
            }
        },
        scales: {
            y: {
                beginAtZero: true
            }
        }
    };

    if (loading) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '400px' }}>
                <CircularProgress />
            </Box>
        );
    }

    if (error) {
        return (
            <Alert severity="error" sx={{ m: 2 }}>
                {error}
            </Alert>
        );
    }

    return (
        <Box sx={{ p: 3 }}>
            <Box sx={{ mb: 3, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant="h5" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <LeaderboardIcon color="primary" />
                    Analytics Dashboard
                </Typography>
                <FormControl sx={{ minWidth: 120 }}>
                    <InputLabel>Timeframe</InputLabel>
                    <Select
                        value={timeframe}
                        label="Timeframe"
                        onChange={(e) => setTimeframe(e.target.value)}
                    >
                        <MenuItem value="24h">Last 24 Hours</MenuItem>
                        <MenuItem value="7d">Last 7 Days</MenuItem>
                        <MenuItem value="30d">Last 30 Days</MenuItem>
                        <MenuItem value="90d">Last 90 Days</MenuItem>
                    </Select>
                </FormControl>
            </Box>

            {/* Summary Cards */}
            <Grid container spacing={3} sx={{ mb: 3 }}>
                <Grid item xs={12} md={3}>
                    <Card>
                        <CardContent>
                            <Typography variant="h6" sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 2 }}>
                                <GroupIcon color="primary" />
                                Total Users
                            </Typography>
                            <Typography variant="h4">{stats?.total_users || 0}</Typography>
                            <Box sx={{ mt: 2 }}>
                                <Typography variant="body2" color="text.secondary">
                                    Active Web: {stats?.active_users?.web || 0}
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    Active Mobile: {stats?.active_users?.mobile || 0}
                                </Typography>
                            </Box>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>

            {/* Timeline Chart */}
            <Paper sx={{ p: 3, mb: 3 }}>
                <Typography variant="h6" sx={{ mb: 2, display: 'flex', alignItems: 'center', gap: 1 }}>
                    <TimelineIcon color="primary" />
                    Usage Timeline
                </Typography>
                <Box sx={{ height: 400 }}>
                    <Line data={timelineData} options={timelineOptions} />
                </Box>
            </Paper>

            {/* Leaderboards */}
            <Grid container spacing={3}>
                {/* Documents Generated Leaderboard */}
                <Grid item xs={12} md={6}>
                    <Paper sx={{ p: 3 }}>
                        <Typography variant="h6" sx={{ mb: 2, display: 'flex', alignItems: 'center', gap: 1 }}>
                            <DescriptionIcon color="primary" />
                            Top Document Generators
                        </Typography>
                        <TableContainer>
                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Rank</TableCell>
                                        <TableCell>User</TableCell>
                                        <TableCell align="right">Documents</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {stats?.top_users?.by_documents_generated?.map((user, index) => (
                                        <TableRow key={user.id}>
                                            <TableCell>{index + 1}</TableCell>
                                            <TableCell>{user.name}</TableCell>
                                            <TableCell align="right">{user.count}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Paper>
                </Grid>

                {/* Page Visits Leaderboard */}
                <Grid item xs={12} md={6}>
                    <Paper sx={{ p: 3 }}>
                        <Typography variant="h6" sx={{ mb: 2, display: 'flex', alignItems: 'center', gap: 1 }}>
                            <RouteIcon color="primary" />
                            Most Active Users
                        </Typography>
                        <TableContainer>
                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Rank</TableCell>
                                        <TableCell>User</TableCell>
                                        <TableCell align="right">Page Visits</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {stats?.top_users?.by_page_visits?.map((user, index) => (
                                        <TableRow key={user.id}>
                                            <TableCell>{index + 1}</TableCell>
                                            <TableCell>{user.name}</TableCell>
                                            <TableCell align="right">{user.count}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Paper>
                </Grid>

                {/* Feature Usage Heatmap */}
                <Grid item xs={12}>
                    <Paper sx={{ p: 3 }}>
                        <Typography variant="h6" sx={{ mb: 2 }}>Feature Usage</Typography>
                        <Grid container spacing={2}>
                            {Object.entries(stats?.feature_usage || {}).map(([feature, data]) => (
                                <Grid item xs={12} sm={6} md={3} key={feature}>
                                    <Card>
                                        <CardContent>
                                            <Typography variant="subtitle1">{feature}</Typography>
                                            <Typography variant="h6">{data.total_uses} uses</Typography>
                                            <Typography variant="body2" color="text.secondary">
                                                {data.unique_users} unique users
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            ))}
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        </Box>
    );
};

export default AnalyticsDashboard;