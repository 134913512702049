import React, { useState, useEffect } from 'react';
import {
    Box,
    Paper,
    Typography,
    TextField,
    Button,
    IconButton,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
    Tooltip,
    Alert,
    CircularProgress,
    InputAdornment,
    Chip,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Grid,
    Card,
    CardContent,
    List,
    ListItem,
    ListItemText,
    Divider,
} from '@mui/material';
import {
    Edit as EditIcon,
    Delete as DeleteIcon,
    Search as SearchIcon,
    Add as AddIcon,
    Person as PersonIcon,
    School as SchoolIcon,
    Assessment as AssessmentIcon,
    Visibility as VisibilityIcon,
    Description as DescriptionIcon,
    Subject as SubjectIcon,
    Timeline as TimelineIcon,
    Login as LoginIcon,
    Refresh as RefreshIcon,
    Route as RouteIcon,
    TouchApp as TouchAppIcon,
    PhoneAndroid as PhoneAndroidIcon,
    Error as ErrorIcon,
    Send as SendIcon,
} from '@mui/icons-material';
import { protectedApiCall } from '../../auth';

const UsersManager = () => {
    const [users, setUsers] = useState([]);
    const [schools, setSchools] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);
    const [formOpen, setFormOpen] = useState(false);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [userStats, setUserStats] = useState({});
    const [statsDialogOpen, setStatsDialogOpen] = useState(false);
    const [loadingStats, setLoadingStats] = useState(false);
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        role: 'user',
        schools: [],
    });

    // Fetch users and schools
    const fetchData = async () => {
        try {
            setLoading(true);
            // Get all users (includes schools info)
            const usersResponse = await protectedApiCall('/api/auth/users', 'GET');
            
            // Get all schools for the dropdown
            const schoolsResponse = await protectedApiCall('/api/schools', 'GET');

            console.log('API Response:', {
                users: usersResponse.data,
                schools: schoolsResponse.data
            });

            // Ensure we have valid user data
            const validUsers = usersResponse.data.filter(user => user && typeof user === 'object');
            console.log('Filtered valid users:', validUsers);

            setUsers(validUsers);
            setSchools(schoolsResponse.data);
            setError(null);
        } catch (err) {
            setError('Failed to fetch data: ' + (err.response?.data?.message || err.message));
            console.error('Error fetching data:', err);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const handleCreateUser = async () => {
        try {
            // Register new user
            const response = await protectedApiCall('/api/auth/register', 'POST', {
                ...formData,
                password: 'tempPass123!' // Temporary password, user will need to reset
            });

            // If user should be admin, make them admin
            if (formData.role === 'admin') {
                await protectedApiCall('/api/auth/make-admin', 'POST', {
                    email: formData.email
                });
            }

            // Refresh user list
            await fetchData();
            
            setFormOpen(false);
            setFormData({
                firstName: '',
                lastName: '',
                email: '',
                role: 'user',
                schools: [],
            });
            setError(null);
        } catch (err) {
            setError('Failed to create user: ' + (err.response?.data?.message || err.message));
            console.error('Error creating user:', err);
        }
    };

    const handleUpdateUser = async () => {
        try {
            setLoading(true);
            console.log('Updating user:', {
                userId: selectedUser._id,
                formData,
                currentSchools: selectedUser.schools
            });

            // Update user details
            console.log('Making update API call with:', {
                endpoint: `/api/auth/users/${selectedUser._id}`,
                method: 'PUT',
                data: {
                    firstName: formData.firstName,
                    lastName: formData.lastName,
                    email: formData.email,
                    role: formData.role,
                    schools: formData.schools
                }
            });

            const updateResponse = await protectedApiCall(
                `/api/auth/users/${selectedUser._id}`, 
                'PUT',
                {
                    firstName: formData.firstName,
                    lastName: formData.lastName,
                    email: formData.email,
                    role: formData.role,
                    schools: formData.schools
                }
            );

            console.log('Update response:', updateResponse);

            if (!updateResponse?.data) {
                throw new Error('No response data from update');
            }

            // Update local state first
            setUsers(prevUsers => prevUsers.map(user => 
                user._id === selectedUser._id ? updateResponse.data : user
            ));

            // If role changed to admin, make them admin
            if (selectedUser.role !== formData.role && formData.role === 'admin') {
                await protectedApiCall('/api/auth/make-admin', 'POST', {
                    email: formData.email
                });
            }

            // Close form and reset state
            setFormOpen(false);
            setSelectedUser(null);
            setFormData({
                firstName: '',
                lastName: '',
                email: '',
                role: 'user',
                schools: [],
            });
            setError(null);

            // Show success message
            setSuccessMessage('User updated successfully');
            setTimeout(() => setSuccessMessage(null), 3000);

            // Then refresh the full list
            await fetchData();
            setLoading(false);
            
            setFormOpen(false);
            setSelectedUser(null);
            setFormData({
                firstName: '',
                lastName: '',
                email: '',
                role: 'user',
                schools: [],
            });
            setError(null);
        } catch (err) {
            console.error('Error updating user:', {
                error: err,
                response: err.response,
                data: err.response?.data,
                status: err.response?.status,
                message: err.message
            });
            setError('Failed to update user: ' + (err.response?.data?.message || err.message));
        } finally {
            setLoading(false);
        }
    };

    const handleDeleteUser = async () => {
        try {
            // Delete user
            await protectedApiCall(`/api/auth/users/${selectedUser._id}`, 'DELETE');
            
            setUsers(users.filter(user => user._id !== selectedUser._id));
            setDeleteDialogOpen(false);
            setSelectedUser(null);
            setError(null);
        } catch (err) {
            setError('Failed to delete user: ' + (err.response?.data?.message || err.message));
            console.error('Error deleting user:', err);
        }
    };

    const handleEdit = (user) => {
        setSelectedUser(user);
        setFormData({
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email,
            role: user.role || 'user',
            schools: user.schools?.map(s => s.id) || [],
        });
        setFormOpen(true);
    };

    const handleDelete = (user) => {
        setSelectedUser(user);
        setDeleteDialogOpen(true);
    };

    const filteredUsers = users.filter(user => {
        if (!user) return false;
        
        const searchTermLower = searchTerm.toLowerCase();
        return (
            (user.firstName || '').toLowerCase().includes(searchTermLower) ||
            (user.lastName || '').toLowerCase().includes(searchTermLower) ||
            (user.email || '').toLowerCase().includes(searchTermLower)
        );
    });

    const displayedUsers = filteredUsers
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

    return (
        <Box sx={{ p: 3 }}>
            <Paper elevation={0} sx={{ p: 3, borderRadius: 2, border: '1px solid', borderColor: 'divider' }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
                    <Typography variant="h5" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <PersonIcon sx={{ color: 'primary.main' }} />
                        Teacher Management
                    </Typography>
                    <Button
                        variant="contained"
                        startIcon={<AddIcon />}
                        onClick={() => {
                            setSelectedUser(null);
                            setFormData({
                                firstName: '',
                                lastName: '',
                                email: '',
                                role: 'user',
                                schools: [],
                            });
                            setFormOpen(true);
                        }}
                    >
                        Add User
                    </Button>
                </Box>

                {error && (
                    <Alert severity="error" sx={{ mb: 3 }}>
                        {error}
                    </Alert>
                )}
                {successMessage && (
                    <Alert severity="success" sx={{ mb: 3 }}>
                        {successMessage}
                    </Alert>
                )}

                <TextField
                    fullWidth
                    variant="outlined"
                    placeholder="Search users..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    sx={{ mb: 3 }}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon sx={{ color: 'text.secondary' }} />
                            </InputAdornment>
                        ),
                    }}
                />

                {loading ? (
                    <Box sx={{ display: 'flex', justifyContent: 'center', py: 3 }}>
                        <CircularProgress />
                    </Box>
                ) : (
                    <>
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Name</TableCell>
                                        <TableCell>Email</TableCell>
                                        <TableCell>Role</TableCell>
                                        <TableCell>Schools</TableCell>
                                        <TableCell align="right">Actions</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {displayedUsers.map((user) => (
                                        <TableRow key={user._id}>
                                            <TableCell>
                                                {user.firstName || ''} {user.lastName || ''}
                                            </TableCell>
                                            <TableCell>{user.email || ''}</TableCell>
                                            <TableCell>
                                                <Chip 
                                                    label={user.role || 'user'} 
                                                    color={user.role === 'admin' ? 'primary' : 'default'}
                                                    size="small"
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <Box sx={{ display: 'flex', gap: 0.5, flexWrap: 'wrap' }}>
                                                    {user.schools?.map(school => (
                                                        <Chip
                                                            key={school.id}
                                                            label={school.name}
                                                            size="small"
                                                            icon={<SchoolIcon />}
                                                            variant={school.isActive ? "filled" : "outlined"}
                                                        />
                                                    ))}
                                                </Box>
                                            </TableCell>
                                            <TableCell align="right">
                                                <Tooltip title="View Activity Stats">
                                                    <IconButton
                                                        onClick={async () => {
                                                            setSelectedUser(user);
                                                            setStatsDialogOpen(true);
                                                            setLoadingStats(true);
                                                            try {
                                                                const response = await protectedApiCall(`/api/syllabus/user-activity-stats?userId=${user._id}`, 'GET');

                                                                setUserStats(prev => ({
                                                                    ...prev,
                                                                    [user._id]: response.data
                                                                }));
                                                            } catch (err) {
                                                                console.error('Error fetching user stats:', err);
                                                                setError('Failed to fetch user statistics');
                                                            } finally {
                                                                setLoadingStats(false);
                                                            }
                                                        }}
                                                        size="small"
                                                        sx={{ color: 'info.main' }}
                                                    >
                                                        <AssessmentIcon />
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title="Edit user">
                                                    <IconButton
                                                        onClick={() => handleEdit(user)}
                                                        size="small"
                                                        sx={{ color: 'primary.main' }}
                                                    >
                                                        <EditIcon />
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title="Delete user">
                                                    <IconButton
                                                        onClick={() => handleDelete(user)}
                                                        size="small"
                                                        sx={{ color: 'error.main' }}
                                                    >
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>

                        <TablePagination
                            component="div"
                            count={filteredUsers.length}
                            page={page}
                            onPageChange={(e, newPage) => setPage(newPage)}
                            rowsPerPage={rowsPerPage}
                            onRowsPerPageChange={(e) => {
                                setRowsPerPage(parseInt(e.target.value, 10));
                                setPage(0);
                            }}
                        />
                    </>
                )}
            </Paper>

            {/* Create/Edit Dialog */}
            <Dialog 
                open={formOpen} 
                onClose={() => setFormOpen(false)}
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle>
                    {selectedUser ? 'Edit User' : 'Add New User'}
                </DialogTitle>
                <DialogContent>
                    <Box sx={{ pt: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    label="First Name"
                                    value={formData.firstName}
                                    onChange={(e) => setFormData({ ...formData, firstName: e.target.value })}
                                    required
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    label="Last Name"
                                    value={formData.lastName}
                                    onChange={(e) => setFormData({ ...formData, lastName: e.target.value })}
                                    required
                                />
                            </Grid>
                        </Grid>
                        <TextField
                            fullWidth
                            label="Email"
                            type="email"
                            value={formData.email}
                            onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                            required
                        />
                        <FormControl fullWidth>
                            <InputLabel>Role</InputLabel>
                            <Select
                                value={formData.role}
                                label="Role"
                                onChange={(e) => setFormData({ ...formData, role: e.target.value })}
                            >
                                <MenuItem value="user">User</MenuItem>
                                <MenuItem value="admin">Admin</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl fullWidth>
                            <InputLabel>Schools</InputLabel>
                            <Select
                                multiple
                                value={formData.schools}
                                label="Schools"
                                onChange={(e) => setFormData({ ...formData, schools: e.target.value })}
                                renderValue={(selected) => (
                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                        {selected.map((value) => {
                                            const school = schools.find(s => (s.id || s._id) === value);
                                            return (
                                                <Chip
                                                    key={value}
                                                    label={school?.name || value}
                                                    size="small"
                                                />
                                            );
                                        })}
                                    </Box>
                                )}
                            >
                                {schools.map((school) => {
                                    const schoolId = school.id || school._id;
                                    return (
                                        <MenuItem key={schoolId} value={schoolId}>
                                            {school.name}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setFormOpen(false)}>
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        onClick={selectedUser ? handleUpdateUser : handleCreateUser}
                        disabled={!formData.firstName.trim() || !formData.lastName.trim() || !formData.email.trim()}
                    >
                        {selectedUser ? 'Update' : 'Create'}
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Delete Confirmation Dialog */}
            <Dialog
                open={deleteDialogOpen}
                onClose={() => setDeleteDialogOpen(false)}
            >
                <DialogTitle>Delete User?</DialogTitle>
                <DialogContent>
                    <Typography>
                        Are you sure you want to delete {selectedUser?.firstName} {selectedUser?.lastName}? 
                        This action cannot be undone.
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDeleteDialogOpen(false)}>
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        color="error"
                        onClick={handleDeleteUser}
                    >
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>

            {/* User Stats Dialog */}
            <Dialog
                open={statsDialogOpen}
                onClose={() => {
                    setStatsDialogOpen(false);
                    setSelectedUser(null);
                }}
                maxWidth="md"
                fullWidth
            >
                <DialogTitle>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <AssessmentIcon sx={{ color: 'primary.main' }} />
                        User Activity Statistics
                    </Box>
                </DialogTitle>
                <DialogContent>
                    {loadingStats ? (
                        <Box sx={{ p: 3, textAlign: 'center' }}>
                            <CircularProgress />
                            <Typography sx={{ mt: 2 }}>Loading user statistics...</Typography>
                        </Box>
                    ) : selectedUser && userStats[selectedUser._id] ? (
                        <Grid container spacing={3} sx={{ pt: 2 }}>
                            {/* Login Activity Section */}
                            <Grid item xs={12} md={6}>
                                <Card variant="outlined">
                                    <CardContent>
                                        <Typography variant="h6" sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 2 }}>
                                            <LoginIcon color="primary" />
                                            Login Activity
                                        </Typography>
                                        <List dense>
                                            <ListItem>
                                                <ListItemText 
                                                    primary="Total Logins"
                                                    secondary={userStats[selectedUser._id]?.activity?.web?.logins?.total || 0}
                                                />
                                            </ListItem>
                                            <ListItem>
                                                <ListItemText 
                                                    primary="Last Login"
                                                    secondary={userStats[selectedUser._id]?.activity?.web?.logins?.last_login ? 
                                                        new Date(userStats[selectedUser._id].activity.web.logins.last_login).toLocaleString() :
                                                        'Never'
                                                    }
                                                />
                                            </ListItem>
                                            <Divider sx={{ my: 1 }} />
                                            <ListItem>
                                                <ListItemText 
                                                    primary="Recent Login Activity"
                                                    secondary={
                                                        <List dense>
                                                            {(userStats[selectedUser._id]?.activity?.web?.logins?.recent_logins || []).map((login, index) => (
                                                                <ListItem key={index}>
                                                                    <ListItemText
                                                                        primary={new Date(login.timestamp).toLocaleString()}
                                                                        secondary={`${login.userAgent} - ${login.ip} (${login.success ? 'Success' : 'Failed'})`}
                                                                    />
                                                                </ListItem>
                                                            ))}
                                                        </List>
                                                    }
                                                />
                                            </ListItem>
                                        </List>
                                    </CardContent>
                                </Card>
                            </Grid>

                            {/* Page Visits Section */}
                            <Grid item xs={12} md={6}>
                                <Card variant="outlined">
                                    <CardContent>
                                        <Typography variant="h6" sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 2 }}>
                                            <RouteIcon color="primary" />
                                            Page Visits
                                        </Typography>
                                        <List dense>
                                            <ListItem>
                                                <ListItemText 
                                                    primary="Total Page Visits"
                                                    secondary={userStats[selectedUser._id]?.activity?.page_visits?.total || 0}
                                                />
                                            </ListItem>
                                            <Divider sx={{ my: 1 }} />
                                            <ListItem>
                                                <ListItemText 
                                                    primary="Most Visited Pages"
                                                    secondary={
                                                        <List dense>
                                                            {(userStats[selectedUser._id]?.activity?.page_visits?.most_visited || []).map((page, index) => (
                                                                <ListItem key={index}>
                                                                    <ListItemText
                                                                        primary={page.path}
                                                                        secondary={`${page.visits} visits - Last: ${new Date(page.last_visit).toLocaleDateString()}`}
                                                                    />
                                                                </ListItem>
                                                            ))}
                                                        </List>
                                                    }
                                                />
                                            </ListItem>
                                        </List>
                                    </CardContent>
                                </Card>
                            </Grid>

                            {/* Feature Usage Section */}
                            <Grid item xs={12} md={6}>
                                <Card variant="outlined">
                                    <CardContent>
                                        <Typography variant="h6" sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 2 }}>
                                            <TouchAppIcon color="primary" />
                                            Feature Usage
                                        </Typography>
                                        <List dense>
                                            <ListItem>
                                                <ListItemText 
                                                    primary="Total Feature Interactions"
                                                    secondary={userStats[selectedUser._id]?.activity?.features?.total_interactions || 0}
                                                />
                                            </ListItem>
                                            <Divider sx={{ my: 1 }} />
                                            <ListItem>
                                                <ListItemText 
                                                    primary="Most Used Features"
                                                    secondary={
                                                        <List dense>
                                                            {(userStats[selectedUser._id]?.activity?.features?.most_used || []).map((feature, index) => (
                                                                <ListItem key={index}>
                                                                    <ListItemText
                                                                        primary={feature.name}
                                                                        secondary={`${feature.uses} uses - Last: ${new Date(feature.last_used).toLocaleDateString()}`}
                                                                    />
                                                                </ListItem>
                                                            ))}
                                                        </List>
                                                    }
                                                />
                                            </ListItem>
                                        </List>
                                    </CardContent>
                                </Card>
                            </Grid>

                            {/* Documents Section */}
                            <Grid item xs={12} md={6}>
                                <Card variant="outlined">
                                    <CardContent>
                                        <Typography variant="h6" sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 2 }}>
                                            <DescriptionIcon color="primary" />
                                            Document Activity
                                        </Typography>
                                        <List dense>
                                            <ListItem>
                                                <ListItemText 
                                                    primary="Generated Documents"
                                                    secondary={userStats[selectedUser._id]?.documents?.generated || 0}
                                                />
                                            </ListItem>
                                            <ListItem>
                                                <ListItemText 
                                                    primary="Viewed Documents"
                                                    secondary={userStats[selectedUser._id]?.documents?.viewed || 0}
                                                />
                                            </ListItem>
                                            <ListItem>
                                                <ListItemText 
                                                    primary="Downloaded Documents"
                                                    secondary={userStats[selectedUser._id]?.documents?.downloaded || 0}
                                                />
                                            </ListItem>
                                            <ListItem>
                                                <ListItemText 
                                                    primary="Last Generated"
                                                    secondary={userStats[selectedUser._id]?.documents?.last_generated ? 
                                                        new Date(userStats[selectedUser._id].documents.last_generated).toLocaleString() : 
                                                        'Never'
                                                    }
                                                />
                                            </ListItem>
                                        </List>
                                    </CardContent>
                                </Card>
                            </Grid>

                            {/* Templates Section */}
                            <Grid item xs={12} md={6}>
                                <Card variant="outlined">
                                    <CardContent>
                                        <Typography variant="h6" sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 2 }}>
                                            <SubjectIcon color="primary" />
                                            Template Usage
                                        </Typography>
                                        <List dense>
                                            <ListItem>
                                                <ListItemText 
                                                    primary="Total Templates Used"
                                                    secondary={userStats[selectedUser._id]?.templates?.total_used || 0}
                                                />
                                            </ListItem>
                                            <Divider sx={{ my: 1 }} />
                                            <ListItem>
                                                <ListItemText 
                                                    primary="Most Used Templates"
                                                    secondary={
                                                        <List dense>
                                                            {(userStats[selectedUser._id]?.templates?.most_used || []).map((template, index) => (
                                                                <ListItem key={index}>
                                                                    <ListItemText
                                                                        primary={template.name}
                                                                        secondary={`Used ${template.uses} times - Last: ${new Date(template.last_used).toLocaleDateString()}`}
                                                                    />
                                                                </ListItem>
                                                            ))}
                                                        </List>
                                                    }
                                                />
                                            </ListItem>
                                        </List>
                                    </CardContent>
                                </Card>
                            </Grid>

                            {/* Mobile App Stats Section */}
                            <Grid item xs={12} md={6}>
                                <Card variant="outlined">
                                    <CardContent>
                                        <Typography variant="h6" sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 2 }}>
                                            <PhoneAndroidIcon color="primary" />
                                            Mobile App Activity
                                        </Typography>
                                        <List dense>
                                            <ListItem>
                                                <ListItemText 
                                                    primary="Mobile App Logins"
                                                    secondary={userStats[selectedUser._id]?.activity?.mobile_app?.logins?.total || 0}
                                                />
                                            </ListItem>
                                            <ListItem>
                                                <ListItemText 
                                                    primary="Records Sent"
                                                    secondary={userStats[selectedUser._id]?.activity?.mobile_app?.logins?.records_sent || 0}
                                                />
                                            </ListItem>
                                            <ListItem>
                                                <ListItemText 
                                                    primary="Last Record Sent"
                                                    secondary={userStats[selectedUser._id]?.activity?.mobile_app?.logins?.last_record_sent ? 
                                                        new Date(userStats[selectedUser._id].activity.mobile_app.logins.last_record_sent).toLocaleString() : 
                                                        'Never'
                                                    }
                                                />
                                            </ListItem>
                                            <Divider sx={{ my: 1 }} />
                                            <ListItem>
                                                <ListItemText 
                                                    primary={
                                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                                            <ErrorIcon fontSize="small" color="error" />
                                                            Wrong Name Attempts
                                                        </Box>
                                                    }
                                                    secondary={userStats[selectedUser._id]?.activity?.mobile_app?.logins?.incorrect_name_count || 0}
                                                />
                                            </ListItem>
                                            <ListItem>
                                                <ListItemText 
                                                    primary="Last Wrong Name"
                                                    secondary={userStats[selectedUser._id]?.activity?.mobile_app?.logins?.last_incorrect_name ? 
                                                        new Date(userStats[selectedUser._id].activity.mobile_app.logins.last_incorrect_name).toLocaleString() : 
                                                        'Never'
                                                    }
                                                />
                                            </ListItem>
                                        </List>
                                    </CardContent>
                                </Card>
                            </Grid>

                            {/* Token Usage Section */}
                            <Grid item xs={12} md={6}>
                                <Card variant="outlined">
                                    <CardContent>
                                        <Typography variant="h6" sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 2 }}>
                                            <VisibilityIcon color="primary" />
                                            Token Usage
                                        </Typography>
                                        <List dense>
                                            <ListItem>
                                                <ListItemText 
                                                    primary="Total Tokens Used"
                                                    secondary={userStats[selectedUser._id]?.tokens?.total_tokens || 0}
                                                />
                                            </ListItem>
                                            <ListItem>
                                                <ListItemText 
                                                    primary="Prompt Tokens"
                                                    secondary={userStats[selectedUser._id]?.tokens?.prompt_tokens || 0}
                                                />
                                            </ListItem>
                                            <ListItem>
                                                <ListItemText 
                                                    primary="Completion Tokens"
                                                    secondary={userStats[selectedUser._id]?.tokens?.completion_tokens || 0}
                                                />
                                            </ListItem>
                                            <ListItem>
                                                <ListItemText 
                                                    primary="Average Per Document"
                                                    secondary={userStats[selectedUser._id]?.tokens?.average_per_document || 0}
                                                />
                                            </ListItem>
                                        </List>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                    ) : (
                        <Box sx={{ p: 3, textAlign: 'center' }}>
                            <Typography color="error" sx={{ mb: 2 }}>
                                No statistics available for this user
                            </Typography>
                            <Button 
                                variant="outlined" 
                                onClick={async () => {
                                    setLoadingStats(true);
                                    try {
                                        const response = await protectedApiCall(`/api/syllabus/user-activity-stats?userId=${selectedUser._id}`, 'GET');
                                        setUserStats(prev => ({
                                            ...prev,
                                            [selectedUser._id]: response.data
                                        }));
                                    } catch (err) {
                                        console.error('Error fetching user stats:', err);
                                        setError('Failed to fetch user statistics');
                                    } finally {
                                        setLoadingStats(false);
                                    }
                                }}
                            >
                                Retry Loading Stats
                            </Button>
                        </Box>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        setStatsDialogOpen(false);
                        setSelectedUser(null);
                    }}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default UsersManager;