import React from 'react';
import { 
    DialogTitle,
    DialogContent,
    Typography,
    Stack,
    Button,
    IconButton,
    Box
} from '@mui/material';
import { 
    ContentCopy as ContentCopyIcon,
    Close as CloseIcon
} from '@mui/icons-material';
import FormattedContent from './ResultsSection/FormattedContent';

const ResultsDialog = ({ 
    isOpen, 
    onClose, 
    aiResponse, 
    showToast, 
    isMobile, 
    theme 
}) => {
    return (
        <>
            <DialogTitle
                sx={{
                    borderBottom: 1,
                    borderColor: 'divider',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    p: isMobile ? 1.5 : 2,
                    flexWrap: isMobile ? 'wrap' : 'nowrap'
                }}
            >
                <Typography variant="h6" sx={{ mb: isMobile ? 1 : 0, width: isMobile ? '100%' : 'auto' }}>
                    View Results
                </Typography>
                <Stack 
                    direction="row" 
                    spacing={isMobile ? 1 : 2} 
                    sx={{ 
                        width: isMobile ? '100%' : 'auto',
                        justifyContent: isMobile ? 'space-between' : 'flex-end'
                    }}
                >
                    <Button
                        variant="outlined"
                        onClick={() => {
                            const content = document.querySelector('.formatted-content');
                            if (!content) return;

                            try {
                                const listener = function(e) {
                                    const tempDiv = document.createElement('div');
                                    tempDiv.innerHTML = content.innerHTML;
                                    const baseStyle = 'font-family: Calibri, Arial, sans-serif; font-size: 10pt; line-height: 1.4;';

                                    const headings = tempDiv.querySelectorAll('h1, h2, h3, h4, h5, h6');
                                    headings.forEach(heading => {
                                        heading.style.cssText = `${baseStyle} font-weight: bold; font-size: 11pt; margin: 1em 0 0.5em 0;`;
                                    });

                                    // Style lists and list items
                                    const lists = tempDiv.querySelectorAll('ul');
                                    lists.forEach(list => {
                                        list.style.cssText = 'padding-left: 20px; margin: 8px 0;';
                                    });
                                    
                                    const listItems = tempDiv.querySelectorAll('li');
                                    listItems.forEach(item => {
                                        item.style.cssText = 'margin-bottom: 8px;';
                                    });

                                    const tables = tempDiv.getElementsByTagName('table');
                                    for (let table of tables) {
                                        table.setAttribute('border', '1');
                                        table.setAttribute('cellpadding', '6');
                                        table.setAttribute('cellspacing', '0');
                                        table.style.width = '100%';
                                        table.style.borderCollapse = 'collapse';
                                        table.style.fontSize = '10pt';

                                        const cells = table.getElementsByTagName('td');
                                        for (let cell of cells) {
                                            cell.style.cssText = `${baseStyle} border: 1px solid #ccc; padding: 6px;`;
                                        }

                                        const headers = table.getElementsByTagName('th');
                                        for (let header of headers) {
                                            header.style.cssText = `${baseStyle} border: 1px solid #ccc; padding: 6px; background-color: #f8f9fa; font-weight: bold;`;
                                        }
                                    }

                                    const styledHTML = `
                                        <div style="${baseStyle}">
                                            ${tempDiv.innerHTML}
                                        </div>
                                    `;

                                    e.clipboardData.setData("text/html", styledHTML);
                                    e.clipboardData.setData("text/plain", content.innerText);
                                    e.preventDefault();
                                };

                                document.addEventListener("copy", listener);
                                document.execCommand("copy");
                                document.removeEventListener("copy", listener);
                                showToast('Content copied to clipboard');
                            } catch (err) {
                                console.error('Failed to copy:', err);
                                showToast('Copy failed - try downloading as DOCX');
                            }
                        }}
                        startIcon={<ContentCopyIcon />}
                        size={isMobile ? "small" : "medium"}
                        sx={{ 
                            flex: isMobile ? 1 : 'none',
                            borderRadius: 2
                        }}
                    >
                        {isMobile ? 'Copy' : 'Copy Document'}
                    </Button>
                    {/* Download as DOCX button removed */}
                    <IconButton
                        onClick={onClose}
                        size="small"
                        sx={{ 
                            ml: isMobile ? 0 : 2,
                            bgcolor: theme.palette.grey[100],
                            '&:hover': {
                                bgcolor: theme.palette.grey[200]
                            }
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </Stack>
            </DialogTitle>
            <DialogContent sx={{ p: isMobile ? 2 : 3 }}>
                <Box 
                    className="formatted-content"
                    sx={{
                        fontSize: isMobile ? '0.9rem' : '1rem',
                        '& h1, & h2, & h3, & h4, & h5, & h6': {
                            fontSize: isMobile ? '1.1rem' : '1.25rem'
                        },
                        '& table': {
                            display: isMobile ? 'block' : 'table',
                            overflowX: isMobile ? 'auto' : 'visible',
                            width: '100%'
                        }
                    }}
                >
                    {aiResponse && (
                        <FormattedContent
                            content={typeof aiResponse === 'string' ? aiResponse : aiResponse.content || ''}
                        />
                    )}
                </Box>
            </DialogContent>
        </>
    );
};

export default ResultsDialog;
