import React, { useState } from 'react';
import { Box, Paper, Typography, useTheme } from '@mui/material';
import { PieChart, Pie, Cell, Tooltip, Legend, Sector, ResponsiveContainer } from 'recharts';

const COLORS = [
    '#0088FE', // Bright Blue
    '#00C49F', // Light Green
    '#FFBB28', // Yellow
    '#FF8042', // Orange
    '#FF6384', // Pink
    '#2e73a4', // Light Blue
    '#FFCE56', // Pale Yellow
    '#4BC0C0', // Aqua
    '#9966FF', // Purple
    '#FF9F40', // Coral
];

const renderCustomLabel = ({ name, value, percent }) => {
    return `${(percent * 100).toFixed(0)}%`;
};

const renderActiveShape = (props) => {
    const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value } = props;
    const RADIAN = Math.PI / 180;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? 'start' : 'end';

    return (
        <g>
            <Sector
                cx={cx}
                cy={cy}
                innerRadius={innerRadius}
                outerRadius={outerRadius}
                startAngle={startAngle}
                endAngle={endAngle}
                fill={fill}
            />
            <Sector
                cx={cx}
                cy={cy}
                startAngle={startAngle}
                endAngle={endAngle}
                innerRadius={outerRadius + 6}
                outerRadius={outerRadius + 10}
                fill={fill}
            />
        </g>
    );
};

const BehaviorGraph = ({ data }) => {
    const theme = useTheme();
    const [activeIndex, setActiveIndex] = useState(0);

    const onPieEnter = (_, index) => {
        setActiveIndex(index);
    };

    if (!Array.isArray(data)) {
        return (
            <Box sx={{ 
                display: 'flex', 
                justifyContent: 'center', 
                alignItems: 'center',
                height: '100%',
                color: 'text.secondary'
            }}>
                <Typography>No data available</Typography>
            </Box>
        );
    }

    return (
        <Box sx={{ width: '100%', height: '100%' }}>
            <Typography 
                variant="h6" 
                sx={{ 
                    mb: 1,
                    fontWeight: 500,
                    color: theme.palette.text.primary,
                    textAlign: 'center'
                }}
            >
                Behavior Graph
            </Typography>
            <Typography 
                variant="subtitle1"
                sx={{ 
                    mb: 3,
                    color: theme.palette.text.secondary,
                    textAlign: 'center'
                }}
            >
                Analysis of Student Behavior
            </Typography>
            
            <Paper 
                elevation={0}
                sx={{ 
                    p: 2,
                    height: 'calc(100% - 100px)',
                    bgcolor: theme.palette.background.default,
                    border: `1px solid ${theme.palette.divider}`,
                    borderRadius: 2,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    overflow: 'hidden'
                }}
            >
                <ResponsiveContainer width="100%" height={450}>
                    <PieChart>
                        <Pie
                            activeIndex={activeIndex}
                            activeShape={renderActiveShape}
                            data={data}
                            cx="50%"
                            cy="50%"
                            outerRadius={({ width }) => Math.min(width * 0.35, 150)}
                            fill="#8884d8"
                            dataKey="value"
                            label={renderCustomLabel}
                            onMouseEnter={onPieEnter}
                        >
                            {data.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                            ))}
                        </Pie>
                        <Tooltip 
                            contentStyle={{
                                backgroundColor: theme.palette.background.paper,
                                border: `1px solid ${theme.palette.divider}`,
                                borderRadius: 1
                            }}
                        />
                        <Legend 
                            wrapperStyle={{
                                paddingTop: '20px'
                            }}
                            layout="horizontal"
                            verticalAlign="bottom"
                            align="center"
                        />
                    </PieChart>
                </ResponsiveContainer>
            </Paper>
        </Box>
    );
};

export default BehaviorGraph;