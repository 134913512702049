import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Alert } from 'react-bootstrap';
import '../../css/EditRecordModal.css'; // Reuse the same CSS as EditRecordModal
import { protectedApiCall } from '../../auth';
import axios from 'axios';

const AddRecordModal = ({ show, onHide, selectedClass, onRecordAdded }) => {
    const [inputText, setInputText] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);
    const [unmatchedNames, setUnmatchedNames] = useState([]);
    const BASE_URL = 'https://www.tammoo.com/tammooappserve';
    
    // Reset state when modal is opened
    useEffect(() => {
        if (show) {
            setInputText('');
            setError(null);
            setSuccess(false);
            setIsSubmitting(false);
            setUnmatchedNames([]);
        }
    }, [show]);

    const handleChange = (e) => {
        setInputText(e.target.value);
    };

    // Function to save record locally (for future offline support)
    const saveRecordLocally = async (record) => {
        try {
            const existingRecords = localStorage.getItem('localRecords');
            const records = existingRecords ? JSON.parse(existingRecords) : [];
            records.push({ ...record, timestamp: Date.now() });
            localStorage.setItem('localRecords', JSON.stringify(records));
            console.log('Record saved locally');
        } catch (error) {
            console.error('Error saving record locally:', error);
        }
    };

    const sendRecord = async (text, classId) => {
        try {
            const token = localStorage.getItem('token');
            if (!token) {
                throw new Error('Authentication token not found. Please log in again.');
            }
            
            console.log('Sending record with token:', token);
            const url = `${BASE_URL}/records/${classId}/records`;
            
            // Save locally first as backup (for future offline support)
            await saveRecordLocally({ text, classId });
            
            // Send to server
            const response = await axios.post(
                url,
                { text },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    }
                }
            );
            
            console.log('Server response:', response.data);
            return {
                success: true,
                message: 'Record sent',
                unmatched: []
            };
        } catch (error) {
            console.error('Error sending record:', error);
            
            // Handle case where there are unmatched student names
            if (error.response?.status === 400 && error.response.data.unmatched) {
                setUnmatchedNames(error.response.data.unmatched);
                return {
                    success: true,
                    unmatched: error.response.data.unmatched,
                    message: error.response.data.message
                };
            }
            
            throw error;
        }
    };

    const handleSubmit = async () => {
        if (!inputText.trim()) {
            setError('Please enter an observation');
            return;
        }

        if (!selectedClass || !selectedClass._id) {
            setError('Please select a class first');
            return;
        }

        setIsSubmitting(true);
        setError(null);

        try {
            // Use the sendRecord function similar to the React Native app
            const result = await sendRecord(inputText, selectedClass._id);
            
            console.log('Record added result:', result);
            setSuccess(true);
            
            // Check if there were any unmatched names
            if (result.unmatched && result.unmatched.length > 0) {
                setUnmatchedNames(result.unmatched);
            }
            
            // Notify parent component that a record was added
            if (onRecordAdded) {
                onRecordAdded(result);
            }
            
            // Close the modal after a short delay
            setTimeout(() => {
                onHide();
            }, 1500);
            
        } catch (error) {
            console.error('Error adding record:', error);
            setError(error.response?.data?.message || error.message || 'Failed to add record. Please try again.');
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <Modal 
            show={show} 
            onHide={onHide} 
            centered 
            dialogClassName="custom-modal"
            backdrop="static"
            keyboard={false}
            animation={true}
            size="lg"
            aria-labelledby="add-record-modal"
            enforceFocus={true}
            restoreFocus={true}
            autoFocus={true}
        >
            <Modal.Header closeButton className="custom-modal-header">
                <Modal.Title>Add New Observation</Modal.Title>
            </Modal.Header>
            <Modal.Body className="custom-modal-body">
                {error && (
                    <Alert variant="danger">{error}</Alert>
                )}
                
                {success && (
                    <Alert variant="success">
                        Record added successfully!
                        {unmatchedNames.length > 0 && (
                            <div className="mt-2">
                                <strong>Note:</strong> The following student names were not found in the class: 
                                <span className="font-weight-bold"> {unmatchedNames.join(', ')}</span>. 
                                The record has been sent, but you may want to review it later.
                            </div>
                        )}
                    </Alert>
                )}
                
                <Form>
                    <Form.Group controlId="formObservation">
                        <Form.Label>Enter your observation</Form.Label>
                        <Form.Text className="text-muted">
                            For example: "Jenny has done a great job in English today"
                        </Form.Text>
                        <Form.Control
                            as="textarea"
                            rows={4}
                            value={inputText}
                            onChange={handleChange}
                            placeholder="Enter your observation here..."
                            className="custom-textarea"
                            disabled={isSubmitting || success}
                        />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer className="custom-modal-footer">
                <Button variant="secondary" onClick={onHide} className="custom-cancel-button">
                    Cancel
                </Button>
                <Button 
                    variant="primary" 
                    onClick={handleSubmit} 
                    className="custom-save-button"
                    disabled={isSubmitting || success || !inputText.trim()}
                >
                    {isSubmitting ? 'Submitting...' : 'Add Record'}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default AddRecordModal;