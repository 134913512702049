import { getSignedUrl, cacheSignedUrl } from './urlCache';
import { protectedFetch } from '../auth';

export const getLogoUrl = async (schoolId) => {
    // Try to get from cache first
    const cachedUrl = getSignedUrl(`school-logo-${schoolId}`);
    if (cachedUrl) {
        return cachedUrl;
    }

    // If not in cache, fetch new signed URL
    try {
        const response = await protectedFetch(async () => {
            const response = await fetch(
                `${process.env.REACT_APP_BASE_URL}/api/schools/${schoolId}/logo-url`,
                {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                    }
                }
            );
            if (!response.ok) {
                if (response.status === 401) {
                    throw new Error('Unauthorized');
                }
                throw new Error('Failed to get logo URL');
            }
            return response.json();
        });

        if (response?.logoUrl) {
            // Cache the new URL
            cacheSignedUrl(`school-logo-${schoolId}`, response.logoUrl);
            return response.logoUrl;
        }
    } catch (err) {
        if (err.message === 'Unauthorized') {
            // Token might be expired, let protectedFetch handle it
            throw err;
        }
        console.error('Error getting logo URL:', err);
        return null;
    }
};