import React from 'react';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText
} from '@mui/material';

const StudentSelector = ({ students, selectedStudent, onChange, disabled, error, helperText }) => {
  return (
    <FormControl fullWidth error={error} disabled={disabled}>
      <InputLabel id="student-select-label">Student</InputLabel>
      <Select
        labelId="student-select-label"
        id="student-select"
        value={selectedStudent || ''}
        label="Student"
        onChange={(e) => onChange(e.target.value)}
      >
        <MenuItem value="">
          <em>Select a student</em>
        </MenuItem>
        {students && students.length > 0 ? (
          students.map((student) => (
            <MenuItem key={student._id} value={student._id}>
              {student.firstName} {student.lastName}
            </MenuItem>
          ))
        ) : (
          <MenuItem value="" disabled>
            No students available
          </MenuItem>
        )}
      </Select>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

export default StudentSelector;