import { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { protectedApiCall, protectedFetch } from '../../../auth';


const useTimetables = (navigate) => {
    const [timetables, setTimetables] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    // Get school ID from token
    const [schoolId, setSchoolId] = useState(null);
    const [currentSchool, setCurrentSchool] = useState(null);

    // Extract school info from token
    useEffect(() => {
        try {
            const token = localStorage.getItem('token');
            if (token) {
                const decoded = JSON.parse(atob(token.split('.')[1]));
                console.log('Token decoded:', {
                    currentSchool: decoded.currentSchool,
                    id: decoded.id
                });
                if (decoded.currentSchool?._id) {
                    setSchoolId(decoded.currentSchool._id);
                    setCurrentSchool(decoded.currentSchool);
                }
            }
        } catch (error) {
            console.error('Error getting school from token:', error);
        }
    }, []);

    const fetchTimetables = useCallback(async () => {
        console.log('fetchTimetables called with:', { schoolId });

        if (!schoolId) {
            console.log('No schoolId available, skipping fetch');
            setError('Please select a school first');
            setTimetables([]);
            setLoading(false);
            return;
        }

        try {
            setLoading(true);
            console.log('Making API call to fetch timetables with:', { 
                schoolId,
                token: localStorage.getItem('token')
            });

            const result = await protectedFetch(async () => {
                // Log the request details
                console.log('Timetables request details:', {
                    url: `${process.env.REACT_APP_BASE_URL}/api/timetables/all`,
                    params: { school: schoolId },
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                    }
                });

                const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/timetables/all`, {
                    params: { school: schoolId },
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                    }
                });

                console.log('Timetables API response:', {
                    count: response.data.length,
                    timetables: response.data.map(timetable => ({
                        _id: timetable._id,
                        name: timetable.name,
                        school: timetable.school,
                        blockCount: timetable.blocks?.length
                    }))
                });

                return response.data;
            }, navigate);

            if (result) {
                // Filter timetables to only show ones for current school
                const filteredTimetables = result.filter(timetable => {
                    const timetableSchoolId = timetable.school?._id || timetable.school;
                    const match = timetableSchoolId === schoolId;
                    console.log('Timetable school check:', {
                        name: timetable.name,
                        timetableSchoolId,
                        schoolId,
                        match,
                        schoolObject: timetable.school
                    });
                    return match;
                });

                console.log('Filtered timetables:', {
                    total: result.length,
                    filtered: filteredTimetables.length,
                    schoolId
                });

                setTimetables(filteredTimetables);
                setError(null);
            }
        } catch (err) {
            console.error('Error fetching timetables:', err);
            setError('Failed to load timetables');
            setTimetables([]);
        } finally {
            setLoading(false);
        }
    }, [navigate, schoolId]);

    const createTimetable = async (timetableData) => {
        if (!schoolId) {
            throw new Error('Please select a school first');
        }

        try {
            setLoading(true);
            const result = await protectedFetch(async () => {
                const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/timetables/new`, {
                    ...timetableData,
                    school: schoolId
                });
                return response.data;
            }, navigate);

            if (result) {
                setTimetables(prev => [...prev, result]);
                return result;
            }
        } catch (err) {
            console.error('Error creating timetable:', err);
            throw err;
        } finally {
            setLoading(false);
        }
    };

    const updateTimetable = async (id, timetableData) => {
        if (!schoolId) {
            throw new Error('Please select a school first');
        }

        try {
            setLoading(true);
            const result = await protectedFetch(async () => {
                const response = await axios.put(`${process.env.REACT_APP_BASE_URL}/api/timetables/update/${id}`, {
                    ...timetableData,
                    school: schoolId
                });
                return response.data;
            }, navigate);

            if (result) {
                setTimetables(prev => prev.map(timetable => timetable._id === id ? result : timetable));
                return result;
            }
        } catch (err) {
            console.error('Error updating timetable:', err);
            throw err;
        } finally {
            setLoading(false);
        }
    };

    const deleteTimetable = async (id) => {
        if (!schoolId) {
            throw new Error('Please select a school first');
        }

        try {
            setLoading(true);
            await protectedFetch(async () => {
                await axios.delete(`${process.env.REACT_APP_BASE_URL}/api/timetables/delete/${id}`, {
                    params: { school: schoolId }
                });
            }, navigate);

            setTimetables(prev => prev.filter(timetable => timetable._id !== id));
        } catch (err) {
            console.error('Error deleting timetable:', err);
            throw err;
        } finally {
            setLoading(false);
        }
    };

    // Log mount/update
    useEffect(() => {
        console.log('useTimetables - Mount/Update:', {
            currentSchool,
            schoolId,
            token: localStorage.getItem('token')
        });
    }, [currentSchool, schoolId]);

    // Fetch timetables when dependencies change
    useEffect(() => {
        console.log('Timetables fetch effect triggered');
        fetchTimetables();
    }, [fetchTimetables]);

    return {
        timetables,
        loading,
        error,
        createTimetable,
        updateTimetable,
        deleteTimetable,
        refreshTimetables: fetchTimetables,
        currentSchool,
        schoolId,
    };
};

export default useTimetables;