import React, { useState, useEffect } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    Button,
    Box,
    Alert,
} from '@mui/material';

const TermForm = ({
    open,
    onClose,
    onSubmit,
    initialData = null,
    isEditing = false,
    selectedYear
}) => {
    const [formData, setFormData] = useState({
        termNumber: '',
        startDate: '',
        endDate: '',
        year: selectedYear
    });
    const [error, setError] = useState('');

    useEffect(() => {
        if (initialData) {
            setFormData({
                termNumber: initialData.termNumber,
                startDate: new Date(initialData.startDate).toISOString().split('T')[0],
                endDate: new Date(initialData.endDate).toISOString().split('T')[0],
                year: initialData.year
            });
        } else {
            setFormData(prev => ({
                ...prev,
                year: selectedYear
            }));
        }
    }, [initialData, selectedYear]);

    const handleSubmit = (e) => {
        e.preventDefault();
        
        // Validate dates
        const start = new Date(formData.startDate);
        const end = new Date(formData.endDate);
        
        if (end <= start) {
            setError('End date must be after start date');
            return;
        }

        onSubmit(formData);
    };

    const handleClose = () => {
        setError('');
        onClose();
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            maxWidth="sm"
            fullWidth
            PaperProps={{
                sx: {
                    borderRadius: 2,
                }
            }}
        >
            <form onSubmit={handleSubmit}>
                <DialogTitle sx={{ 
                    bgcolor: 'primary.lighter',
                    color: 'primary.dark',
                    fontWeight: 600
                }}>
                    {isEditing ? 'Edit Term' : 'Add New Term'}
                </DialogTitle>
                <DialogContent>
                    <Box sx={{ mt: 2 }}>
                        {error && (
                            <Alert severity="error" sx={{ mb: 2 }}>
                                {error}
                            </Alert>
                        )}
                        
                        <TextField
                            fullWidth
                            label="Term Number"
                            type="number"
                            value={formData.termNumber}
                            onChange={(e) => setFormData(prev => ({ ...prev, termNumber: e.target.value }))}
                            disabled={isEditing}
                            required
                            sx={{ mb: 2 }}
                        />

                        <TextField
                            fullWidth
                            label="Start Date"
                            type="date"
                            value={formData.startDate}
                            onChange={(e) => setFormData(prev => ({ ...prev, startDate: e.target.value }))}
                            required
                            InputLabelProps={{ shrink: true }}
                            sx={{ mb: 2 }}
                        />

                        <TextField
                            fullWidth
                            label="End Date"
                            type="date"
                            value={formData.endDate}
                            onChange={(e) => setFormData(prev => ({ ...prev, endDate: e.target.value }))}
                            required
                            InputLabelProps={{ shrink: true }}
                            sx={{ mb: 2 }}
                        />

                        <TextField
                            fullWidth
                            label="Year"
                            value={formData.year}
                            onChange={(e) => setFormData(prev => ({ ...prev, year: e.target.value }))}
                            required
                            sx={{ mb: 2 }}
                        />
                    </Box>
                </DialogContent>
                <DialogActions sx={{ px: 3, pb: 2 }}>
                    <Button onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                    >
                        {isEditing ? 'Save Changes' : 'Add Term'}
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};

export default TermForm;