import { createTheme } from '@mui/material/styles';

// Get CSS variables
const getCssVar = (name) => getComputedStyle(document.documentElement).getPropertyValue(name).trim();

// Dynamic theme colors based on CSS variables
const getThemeColors = () => ({
  primary: {
    main: getCssVar('--primary-color'),
    light: getCssVar('--secondary-color'),
    dark: getCssVar('--text-color'),
    contrastText: getCssVar('--background-color'),
  },
  secondary: {
    main: getCssVar('--secondary-color'),
    light: getCssVar('--accent-color'),
    dark: getCssVar('--primary-color'),
    contrastText: getCssVar('--background-color'),
  },
  success: {
    main: getCssVar('--success-color'),
    light: getCssVar('--accent-color'),
    dark: getCssVar('--primary-color'),
    contrastText: getCssVar('--background-color'),
  },
  error: {
    main: getCssVar('--danger-color'),
    light: getCssVar('--danger-color'),
    dark: getCssVar('--danger-color'),
    contrastText: getCssVar('--background-color'),
  },
  background: {
    default: getCssVar('--background-color'),
    paper: getCssVar('--background-color'),
  },
  text: {
    primary: getCssVar('--text-color'),
    secondary: getCssVar('--text-color'),
  },
  divider: getCssVar('--border-color'),
});

export const getThemeConfig = () => ({
  palette: getThemeColors(),
  shape: {
    borderRadius: 8,
  },
  typography: {
    fontFamily: '"Inter", "Helvetica", "Arial", sans-serif',
    h5: {
      fontWeight: 600,
      fontSize: '1.5rem',
      letterSpacing: '-0.02em',
    },
    subtitle1: {
      fontSize: '1rem',
      fontWeight: 500,
    },
    body1: {
      fontSize: '0.875rem',
    },
    body2: {
      fontSize: '0.875rem',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          padding: '8px 16px',
          fontSize: '0.875rem',
          fontWeight: 600,
          borderRadius: 8,
          boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
          '&:hover': {
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.15)',
            transform: 'translateY(-1px)',
          },
        },
        contained: {
          '&:hover': {
            opacity: 0.9,
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          boxShadow: '0 4px 12px rgba(0, 0, 0, 0.08)',
          borderRadius: 12,
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          '&:hover': {
            backgroundColor: getCssVar('--light-background'),
            transform: 'translateY(-1px)',
            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.05)',
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          '&:hover': {
            transform: 'translateY(-1px)',
          },
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: 12,
          padding: '8px',
          boxShadow: '0 8px 24px rgba(0, 0, 0, 0.12)',
        },
      },
    },
  },
});