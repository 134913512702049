import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Box,
    Paper,
    Typography,
    Button,
    Divider,
    IconButton,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Alert,
    Snackbar,
    CircularProgress,
    Tooltip,
    Tab,
    Tabs,
} from '@mui/material';
import {
    Add as AddIcon,
    Archive as ArchiveIcon,
    Unarchive as UnarchiveIcon,
} from '@mui/icons-material';

import ClassList from './components/ClassList';
import ClassForm from './components/ClassForm';
import StudentList from './components/StudentList';
import useClasses from './hooks/useClasses';

const ClassManager = () => {
    const navigate = useNavigate();
    const [selectedClass, setSelectedClass] = useState(null);
    const [formOpen, setFormOpen] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [showArchived, setShowArchived] = useState(false);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
    const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')) || { years: [] });

    // Update user when localStorage changes
    useEffect(() => {
        const handleStorageChange = () => {
            const newUser = JSON.parse(localStorage.getItem('user')) || { years: [] };
            setUser(newUser);
        };

        window.addEventListener('storage', handleStorageChange);
        return () => window.removeEventListener('storage', handleStorageChange);
    }, []);

    const {
        classes,
        deletedClasses,
        loading,
        error,
        createClass,
        updateClass,
        deleteClass,
        restoreClass,
        refreshClasses
    } = useClasses(navigate);

    console.log('ClassManager initialized with:', {
        classCount: classes.length,
        deletedCount: deletedClasses.length,
        loading,
        error
    });

    // Refresh data whenever component mounts or becomes active
    useEffect(() => {
        const rawUser = localStorage.getItem('user');
        console.log('ClassManager mounting, raw user data:', rawUser);
        
        const user = JSON.parse(rawUser || '{}');
        const schoolId = user?.currentSchool?._id || user?.currentSchool;
        
        console.log('ClassManager user data:', {
            userId: user?.id || user?._id,
            email: user?.email,
            schoolId,
            currentSchool: user?.currentSchool
        });
        
        console.log('ClassManager mounted/activated:', { 
            userId: user?.id,
            schoolId,
            schoolObject: user?.currentSchool
        });

        // Track page view
        if (window.dataLayer) {
            window.dataLayer.push({
                event: 'pageview',
                page: '/classes',
                schoolId
            });
        }

        // Force refresh of classes data
        const loadData = async () => {
            try {
                await refreshClasses();
            } catch (error) {
                console.error('Error refreshing classes:', error);
            }
        };
        
        loadData();

        // Cleanup function
        return () => {
            setSelectedClass(null);
            setShowArchived(false);
            setIsEditing(false);
            setFormOpen(false);
        };
    }, [refreshClasses]);

    const handleClassSelect = (classItem) => {
        setSelectedClass(classItem);
    };

    const handleCreateClass = () => {
        setIsEditing(false);
        setFormOpen(true);
    };

    const handleEditClass = (classItem) => {
        setSelectedClass(classItem);
        setIsEditing(true);
        setFormOpen(true);
    };

    const handleFormSubmit = async (classData) => {
        try {
            if (isEditing) {
                await updateClass(classData._id, classData);
                setSnackbar({
                    open: true,
                    message: 'Class updated successfully',
                    severity: 'success'
                });
            } else {
                const newClass = await createClass({
                    ...classData,
                    teacher: user._id
                });
                setSnackbar({
                    open: true,
                    message: 'Class created successfully',
                    severity: 'success'
                });
                setSelectedClass(newClass);
            }
            setFormOpen(false);
        } catch (err) {
            setSnackbar({
                open: true,
                message: `Failed to ${isEditing ? 'update' : 'create'} class`,
                severity: 'error'
            });
        }
    };

    const handleDeleteConfirm = async () => {
        try {
            await deleteClass(selectedClass._id);
            setSnackbar({
                open: true,
                message: 'Class archived successfully',
                severity: 'success'
            });
            setSelectedClass(null);
            setDeleteDialogOpen(false);
        } catch (err) {
            setSnackbar({
                open: true,
                message: 'Failed to archive class',
                severity: 'error'
            });
        }
    };

    const handleRestore = async (classId) => {
        try {
            await restoreClass(classId);
            setSnackbar({
                open: true,
                message: 'Class restored successfully',
                severity: 'success'
            });
        } catch (err) {
            setSnackbar({
                open: true,
                message: 'Failed to restore class',
                severity: 'error'
            });
        }
    };

    const handleLinkClass = async (classId) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/linkClass/link/${classId}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                },
            });

            if (!response.ok) throw new Error('Failed to link class');

            const result = await response.json();
            localStorage.setItem('user', JSON.stringify(result));
            setSnackbar({
                open: true,
                message: 'Class linked successfully',
                severity: 'success'
            });
        } catch (err) {
            setSnackbar({
                open: true,
                message: 'Failed to link class',
                severity: 'error'
            });
        }
    };

    const handleUnlinkClass = async (classId) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/linkClass/unlink/${classId}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                },
            });

            if (!response.ok) throw new Error('Failed to unlink class');

            const result = await response.json();
            localStorage.setItem('user', JSON.stringify(result));
            setSnackbar({
                open: true,
                message: 'Class unlinked successfully',
                severity: 'success'
            });
        } catch (err) {
            setSnackbar({
                open: true,
                message: 'Failed to unlink class',
                severity: 'error'
            });
        }
    };

    if (error) {
        return (
            <Box sx={{ p: 3 }}>
                <Alert severity="error">{error}</Alert>
            </Box>
        );
    }

    return (
        <Box sx={{ p: 3, height: 'calc(100vh - 64px)' }}>
            <Paper 
                elevation={0}
                sx={{ 
                    height: '100%',
                    display: 'flex',
                    borderRadius: 2,
                    overflow: 'hidden',
                    border: '1px solid',
                    borderColor: 'divider',
                }}
            >
                {/* Left Panel */}
                <Box sx={{ 
                    width: 360,
                    borderRight: '1px solid',
                    borderColor: 'divider',
                    display: 'flex',
                    flexDirection: 'column'
                }}>
                    <Box sx={{ 
                        p: 2,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between'
                    }}>
                        <Typography variant="h6">
                            Classes
                        </Typography>
                        <Button
                            variant="contained"
                            startIcon={<AddIcon />}
                            onClick={handleCreateClass}
                            size="small"
                        >
                            New Class
                        </Button>
                    </Box>

                    <Tabs
                        value={showArchived ? 1 : 0}
                        onChange={(_, value) => setShowArchived(value === 1)}
                        sx={{ px: 2, borderBottom: 1, borderColor: 'divider' }}
                    >
                        <Tab 
                            label={`Active (${classes.length})`}
                            id="classes-tab-0"
                        />
                        <Tab 
                            label={`Archived (${deletedClasses.length})`}
                            id="classes-tab-1"
                        />
                    </Tabs>

                    <Box sx={{ flex: 1, overflow: 'auto' }}>
                        {console.log('Rendering class list:', {
                            loading,
                            classCount: classes.length,
                            deletedCount: deletedClasses.length,
                            showArchived
                        })}
                        {loading ? (
                            <Box sx={{ 
                                display: 'flex', 
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: '100%'
                            }}>
                                <CircularProgress />
                            </Box>
                        ) : (
                            <ClassList
                                classes={showArchived ? deletedClasses : classes}
                                selectedClassId={selectedClass?._id}
                                onClassSelect={handleClassSelect}
                                onEditClass={handleEditClass}
                                onLinkClass={handleLinkClass}
                                onUnlinkClass={handleUnlinkClass}
                                userLinkedClasses={user.years || []}
                                key={showArchived ? 'archived' : 'active'}
                            />
                        )}
                    </Box>
                </Box>

                {/* Right Panel */}
                <Box sx={{ flex: 1, p: 3, bgcolor: 'background.default' }}>
                    {selectedClass ? (
                        <Box sx={{ height: '100%' }}>
                            <Box sx={{ 
                                mb: 3,
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center'
                            }}>
                                <Typography variant="h5">
                                    {selectedClass.className}
                                </Typography>
                                <Box>
                                    <Tooltip title={showArchived ? "Restore class" : "Archive class"}>
                                        <IconButton
                                            onClick={() => showArchived 
                                                ? handleRestore(selectedClass._id)
                                                : setDeleteDialogOpen(true)
                                            }
                                            color={showArchived ? "primary" : "error"}
                                        >
                                            {showArchived ? <UnarchiveIcon /> : <ArchiveIcon />}
                                        </IconButton>
                                    </Tooltip>
                                </Box>
                            </Box>
                            <StudentList 
                                students={selectedClass.role}
                                className={selectedClass.className}
                            />
                        </Box>
                    ) : (
                        <Box sx={{ 
                            height: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            color: 'text.secondary'
                        }}>
                            <Typography>
                                Select a class to view details
                            </Typography>
                        </Box>
                    )}
                </Box>
            </Paper>

            {/* Class Form Dialog */}
            <ClassForm
                open={formOpen}
                onClose={() => setFormOpen(false)}
                onSubmit={handleFormSubmit}
                initialData={isEditing ? selectedClass : null}
                isEditing={isEditing}
            />

            {/* Delete Confirmation Dialog */}
            <Dialog
                open={deleteDialogOpen}
                onClose={() => setDeleteDialogOpen(false)}
                PaperProps={{
                    sx: { borderRadius: 2 }
                }}
            >
                <DialogTitle>Archive Class?</DialogTitle>
                <DialogContent>
                    <Typography>
                        Are you sure you want to archive "{selectedClass?.className}"? 
                        You can restore it later from the archived classes tab.
                    </Typography>
                </DialogContent>
                <DialogActions sx={{ p: 2 }}>
                    <Button onClick={() => setDeleteDialogOpen(false)}>
                        Cancel
                    </Button>
                    <Button 
                        onClick={handleDeleteConfirm}
                        variant="contained"
                        color="error"
                    >
                        Archive
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Snackbar for notifications */}
            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={() => setSnackbar(prev => ({ ...prev, open: false }))}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            >
                <Alert 
                    onClose={() => setSnackbar(prev => ({ ...prev, open: false }))}
                    severity={snackbar.severity}
                    elevation={6}
                    variant="filled"
                >
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default ClassManager;