import React from 'react';
import { List, ListItem, ListItemText, ListItemSecondaryAction, IconButton, Tooltip, Typography, Box } from '@mui/material';
import { Link as LinkIcon, LinkOff as LinkOffIcon, Edit as EditIcon } from '@mui/icons-material';

const ClassList = ({ 
    classes, 
    selectedClassId, 
    onClassSelect, 
    onLinkClass, 
    onUnlinkClass,
    onEditClass,
    userLinkedClasses 
}) => {
    console.log('ClassList rendering with:', {
        classCount: classes?.length,
        classes,
        selectedClassId,
        userLinkedClasses
    });
    return (
        <List sx={{ 
            width: '100%',
            bgcolor: 'background.paper',
            borderRadius: 1,
            '& .MuiListItem-root': {
                borderBottom: '1px solid',
                borderColor: 'divider',
                '&:last-child': {
                    borderBottom: 'none'
                }
            }
        }}>
            {classes.length > 0 ? (
                classes.map((classItem) => {
                    const isLinked = userLinkedClasses.includes(classItem._id);
                    const isSelected = selectedClassId === classItem._id;

                    return (
                        <ListItem
                            key={classItem._id}
                            selected={isSelected}
                            onClick={() => onClassSelect(classItem)}
                            sx={{
                                cursor: 'pointer',
                                transition: 'all 0.2s ease',
                                '&:hover': {
                                    bgcolor: 'action.hover',
                                },
                                '&.Mui-selected': {
                                    bgcolor: 'primary.light',
                                    '&:hover': {
                                        bgcolor: 'primary.light',
                                    }
                                }
                            }}
                        >
                            <ListItemText
                                primary={
                                    <Typography variant="subtitle1" component="div" sx={{ fontWeight: isSelected ? 600 : 400 }}>
                                        {classItem.className}
                                    </Typography>
                                }
                                secondary={
                                    <Typography variant="body2" color="text.secondary">
                                        {classItem.role.length} students
                                    </Typography>
                                }
                            />
                            <ListItemSecondaryAction>
                                <Box sx={{ display: 'flex', gap: 1 }}>
                                    <Tooltip title="Edit class">
                                        <IconButton
                                            edge="end"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                onEditClass(classItem);
                                            }}
                                            size="small"
                                            sx={{
                                                color: 'primary.main',
                                                '&:hover': {
                                                    bgcolor: 'primary.lighter',
                                                }
                                            }}
                                        >
                                            <EditIcon />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title={isLinked ? "Class is linked - Click to unlink" : "Class is not linked - Click to link"}>
                                        <IconButton
                                            edge="end"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                isLinked ? onUnlinkClass(classItem._id) : onLinkClass(classItem._id);
                                            }}
                                            size="small"
                                            sx={{
                                                color: isLinked ? 'success.main' : 'text.secondary',
                                                '&:hover': {
                                                    bgcolor: isLinked ? 'success.lighter' : 'action.hover',
                                                }
                                            }}
                                        >
                                            {isLinked ? <LinkIcon /> : <LinkOffIcon />}
                                        </IconButton>
                                    </Tooltip>
                                </Box>
                            </ListItemSecondaryAction>
                        </ListItem>
                    );
                })
            ) : (
                <ListItem>
                    <ListItemText
                        primary={
                            <Box sx={{ 
                                textAlign: 'center', 
                                py: 4,
                                color: 'text.secondary'
                            }}>
                                <Typography variant="body1">
                                    No classes available
                                </Typography>
                                <Typography variant="body2" sx={{ mt: 1 }}>
                                    Create a new class to get started
                                </Typography>
                            </Box>
                        }
                    />
                </ListItem>
            )}
        </List>
    );
};

export default ClassList;