import React, { useState, useEffect } from 'react';
import { Calendar } from 'react-multi-date-picker';
import 'react-multi-date-picker/styles/colors/purple.css';
import '../../css/CalendarCustom.css';

const ThreeMonthCalendar = ({ recordDates, onDateRangeChange, dateRange }) => {
    // Get the selected year from localStorage
    const selectedYear = localStorage.getItem('selectedYear') || new Date().getFullYear().toString();
    const [currentDate, setCurrentDate] = useState([new Date(), new Date()]);
    const [key, setKey] = useState(0); // Add a key to force re-renders

    useEffect(() => {
        if (dateRange) {
            setCurrentDate(dateRange);
        }
    }, [dateRange]);

    const handleChange = (date) => {
        console.log('Calendar raw selection:', date);
        
        if (!Array.isArray(date)) {
            console.log('Not an array selection, ignoring');
            return;
        }
        
        // Convert valid dates to Date objects
        const validDates = date
            .filter(d => d && d.year) // Filter out invalid dates
            .map(d => {
                const year = d.year;
                const month = d.month.number - 1;
                const day = d.day;
                return new Date(year, month, day, 0, 0, 0, 0);
            });
            
        console.log('Valid dates after conversion:', validDates);
        
        // Update current selection
        setCurrentDate(validDates);
        
        // Only process complete selections
        if (validDates.length === 2) {
            // Sort dates and create fresh copies
            const orderedDates = [...validDates].sort((a, b) => a.getTime() - b.getTime());
            const newRange = orderedDates.map(d => new Date(d.getTime()));
            
            console.log('Calling onDateRangeChange with ordered dates:', {
                dates: newRange,
                formatted: newRange.map(d => `${d.getFullYear()}-${String(d.getMonth() + 1).padStart(2, '0')}-${String(d.getDate()).padStart(2, '0')}`)
            });
            
            // Force a re-render before calling the parent
            setKey(k => k + 1);
            
            // Use requestAnimationFrame to ensure the state update has processed
            requestAnimationFrame(() => {
                onDateRangeChange(newRange);
            });
        }
    };

    const mapDays = ({ date }) => {
        const dateHasRecord = hasRecord(date.toDate(), recordDates);
        let props = {};
        if (dateHasRecord) {
            props.className = "has-record";
            props.children = (
                <>
                    <span>{date.day}</span>
                    <div className="record-dot"></div>
                </>
            );
        }
        return props;
    };

    return (
        <div className="three-month-calendar">
            <Calendar
                key={key} // Add key to force re-creation
                numberOfMonths={3}
                value={currentDate}
                onChange={handleChange}
                calendarPosition="right"
                mapDays={mapDays}
                range
                weekStartDayIndex={1}
                displayWeekNumbers
                weekNumber="WK"
                minDate={`${selectedYear}-01-01`}
                maxDate={`${selectedYear}-12-31`}
                onOpen={() => setKey(k => k + 1)} // Force re-render when calendar opens
            />
        </div>
    );
};

const hasRecord = (date, recordDates) => {
    const dateString = date.toISOString().split('T')[0];
    return recordDates.includes(dateString);
};

export default ThreeMonthCalendar;
