import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation, Outlet } from 'react-router-dom';
import {
    Box,
    Tabs,
    Tab,
    Typography,
    Alert,
} from '@mui/material';
import {
    School as SchoolIcon,
    Person as PersonIcon,
    Leaderboard as LeaderboardIcon,
    PhoneAndroid as PhoneAndroidIcon,
} from '@mui/icons-material';

const TabPanel = ({ children, value, index, ...other }) => (
    <div
        role="tabpanel"
        hidden={value !== index}
        id={`admin-tabpanel-${index}`}
        aria-labelledby={`admin-tab-${index}`}
        {...other}
    >
        {value === index && children}
    </div>
);

const AdminLayout = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [currentTab, setCurrentTab] = useState(0);
    const [error, setError] = useState(null);

    useEffect(() => {
        // Set initial tab based on current path
        const path = location.pathname;
        if (path.includes('/admin/users')) {
            setCurrentTab(1);
        } else if (path.includes('/admin/analytics')) {
            setCurrentTab(2);
        } else if (path.includes('/admin/mobile-analytics')) {
            setCurrentTab(3);
        } else {
            setCurrentTab(0);
        }
    }, [location]);

    const handleTabChange = (event, newValue) => {
        setCurrentTab(newValue);
        setError(null);
        // Navigate to appropriate route
        switch (newValue) {
            case 0:
                navigate('/admin/schools');
                break;
            case 1:
                navigate('/admin/users');
                break;
            case 2:
                navigate('/admin/analytics');
                break;
            case 3:
                navigate('/admin/mobile-analytics');
                break;
            default:
                navigate('/admin/schools');
        }
    };

    return (
        <Box>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs 
                    value={currentTab} 
                    onChange={handleTabChange}
                    aria-label="admin sections"
                    sx={{
                        '& .MuiTab-root': {
                            minHeight: '64px',
                        }
                    }}
                >
                    <Tab 
                        icon={<SchoolIcon />} 
                        label="Schools" 
                        iconPosition="start"
                        sx={{ textTransform: 'none' }}
                    />
                    <Tab 
                        icon={<PersonIcon />} 
                        label="Teachers"
                        iconPosition="start"
                        sx={{ textTransform: 'none' }}
                    />
                    <Tab 
                        icon={<LeaderboardIcon />} 
                        label="Analytics"
                        iconPosition="start"
                        sx={{ textTransform: 'none' }}
                    />
                    <Tab 
                        icon={<PhoneAndroidIcon />} 
                        label="Mobile Analytics"
                        iconPosition="start"
                        sx={{ textTransform: 'none' }}
                    />
                </Tabs>
            </Box>

            {error && (
                <Alert severity="error" sx={{ m: 2 }}>
                    {error}
                </Alert>
            )}

            <Box sx={{ p: 2 }}>
                <Outlet />
            </Box>
        </Box>
    );
};

export default AdminLayout;