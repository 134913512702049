import React, { useState, useEffect } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    Button,
    Box,
    Typography,
    IconButton,
    List,
    ListItem,
    ListItemText,
    ListItemSecondaryAction,
    Tooltip,
    Alert,
} from '@mui/material';
import { Delete as DeleteIcon, Add as AddIcon } from '@mui/icons-material';

const ClassForm = ({
    open,
    onClose,
    onSubmit,
    initialData = null,
    isEditing = false
}) => {
    const [formData, setFormData] = useState(() => ({
        className: initialData?.className || '',
        students: initialData?.role || []
    }));
    const [newStudent, setNewStudent] = useState({ firstName: '', lastName: '' });
    const [error, setError] = useState('');

    useEffect(() => {
        if (initialData) {
            setFormData({
                className: initialData.className || '',
                students: initialData.role || []
            });
        }
    }, [initialData]);

    const handleClose = () => {
        setNewStudent({ firstName: '', lastName: '' });
        setError('');
        onClose();
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!formData.className.trim()) {
            setError('Class name is required');
            return;
        }
        if (formData.students.length === 0) {
            setError('At least one student is required');
            return;
        }
        if (formData.students.some(s => !s.firstName.trim() || !s.lastName.trim())) {
            setError('All student names must be filled out');
            return;
        }

        const submissionData = {
            ...initialData,
            className: formData.className,
            role: formData.students.map(student => ({
                firstName: student.firstName,
                lastName: student.lastName
            }))
        };
        onSubmit(submissionData);
    };

    const handleAddStudent = () => {
        if (!newStudent.firstName.trim() || !newStudent.lastName.trim()) {
            setError('Both first and last name are required');
            return;
        }
        setFormData(prev => ({
            ...prev,
            students: [...prev.students, { ...newStudent }]
        }));
        setNewStudent({ firstName: '', lastName: '' });
        setError('');
    };

    const handleRemoveStudent = (index) => {
        setFormData(prev => ({
            ...prev,
            students: prev.students.filter((_, i) => i !== index)
        }));
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter' && newStudent.firstName && newStudent.lastName) {
            e.preventDefault();
            handleAddStudent();
        }
    };

    return (
        <Dialog 
            open={open} 
            onClose={handleClose}
            maxWidth="sm"
            fullWidth
            PaperProps={{
                sx: {
                    borderRadius: 2,
                }
            }}
        >
            <form onSubmit={handleSubmit}>
                <DialogTitle sx={{ 
                    bgcolor: 'primary.lighter',
                    color: 'primary.dark',
                    fontWeight: 600
                }}>
                    {isEditing ? 'Edit Class' : 'Create New Class'}
                </DialogTitle>
                <DialogContent>
                    <Box sx={{ mt: 2 }}>
                        {error && (
                            <Alert severity="error" sx={{ mb: 2 }}>
                                {error}
                            </Alert>
                        )}
                        
                        <TextField
                            fullWidth
                            label="Class Name"
                            value={formData.className}
                            onChange={(e) => setFormData(prev => ({ ...prev, className: e.target.value }))}
                            sx={{ mb: 3 }}
                        />

                        <Typography variant="subtitle1" sx={{ mb: 2 }}>
                            Students
                        </Typography>

                        <Box sx={{ 
                            display: 'flex', 
                            gap: 1, 
                            mb: 2,
                            alignItems: 'flex-start'
                        }}>
                            <TextField
                                label="First Name"
                                value={newStudent.firstName}
                                onChange={(e) => setNewStudent(prev => ({ ...prev, firstName: e.target.value }))}
                                onKeyPress={handleKeyPress}
                                size="small"
                            />
                            <TextField
                                label="Last Name"
                                value={newStudent.lastName}
                                onChange={(e) => setNewStudent(prev => ({ ...prev, lastName: e.target.value }))}
                                onKeyPress={handleKeyPress}
                                size="small"
                            />
                            <Tooltip title="Add student">
                                <IconButton 
                                    onClick={handleAddStudent}
                                    color="primary"
                                    sx={{ mt: 1 }}
                                >
                                    <AddIcon />
                                </IconButton>
                            </Tooltip>
                        </Box>

                        <List sx={{ 
                            maxHeight: 300, 
                            overflow: 'auto',
                            bgcolor: 'background.paper',
                            borderRadius: 1,
                            border: '1px solid',
                            borderColor: 'divider',
                            '& .MuiTextField-root': {
                                '& .MuiOutlinedInput-root': {
                                    '&:hover fieldset': {
                                        borderColor: 'primary.main',
                                    },
                                    '&.Mui-focused fieldset': {
                                        borderColor: 'primary.main',
                                    }
                                }
                            }
                        }}>
                            {formData.students.map((student, index) => (
                                <ListItem
                                    key={index}
                                    divider={index !== formData.students.length - 1}
                                    sx={{ 
                                        py: 1,
                                        px: 2
                                    }}
                                >
                                    <Box sx={{ 
                                        display: 'flex', 
                                        gap: 1,
                                        flex: 1,
                                        mr: 4
                                    }}>
                                        <TextField
                                            size="small"
                                            value={student.firstName}
                                            onChange={(e) => {
                                                const newStudents = [...formData.students];
                                                newStudents[index] = {
                                                    ...student,
                                                    firstName: e.target.value
                                                };
                                                setFormData(prev => ({
                                                    ...prev,
                                                    students: newStudents
                                                }));
                                            }}
                                            placeholder="First Name"
                                            sx={{ 
                                                flex: 1,
                                                '& .MuiOutlinedInput-root': {
                                                    bgcolor: 'background.paper',
                                                    '&:hover': {
                                                        bgcolor: 'primary.lighter',
                                                    },
                                                    '&.Mui-focused': {
                                                        bgcolor: 'primary.lighter',
                                                    }
                                                }
                                            }}
                                        />
                                        <TextField
                                            size="small"
                                            value={student.lastName}
                                            onChange={(e) => {
                                                const newStudents = [...formData.students];
                                                newStudents[index] = {
                                                    ...student,
                                                    lastName: e.target.value
                                                };
                                                setFormData(prev => ({
                                                    ...prev,
                                                    students: newStudents
                                                }));
                                            }}
                                            placeholder="Last Name"
                                            sx={{ 
                                                flex: 1,
                                                '& .MuiOutlinedInput-root': {
                                                    bgcolor: 'background.paper',
                                                    '&:hover': {
                                                        bgcolor: 'primary.lighter',
                                                    },
                                                    '&.Mui-focused': {
                                                        bgcolor: 'primary.lighter',
                                                    }
                                                }
                                            }}
                                        />
                                    </Box>
                                    <ListItemSecondaryAction>
                                        <Tooltip title="Remove student">
                                            <IconButton
                                                edge="end"
                                                onClick={() => handleRemoveStudent(index)}
                                                size="small"
                                                sx={{
                                                    color: 'error.main',
                                                    '&:hover': {
                                                        bgcolor: 'error.lighter'
                                                    }
                                                }}
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </ListItemSecondaryAction>
                                </ListItem>
                            ))}
                            {formData.students.length === 0 && (
                                <ListItem>
                                    <ListItemText
                                        primary={
                                            <Typography 
                                                variant="body2" 
                                                color="text.secondary"
                                                align="center"
                                            >
                                                No students added yet
                                            </Typography>
                                        }
                                    />
                                </ListItem>
                            )}
                        </List>
                    </Box>
                </DialogContent>
                <DialogActions sx={{ px: 3, pb: 2 }}>
                    <Button onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button 
                        type="submit" 
                        variant="contained"
                        color="primary"
                    >
                        {isEditing ? 'Save Changes' : 'Create Class'}
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};

export default ClassForm;