import React, { useState } from 'react';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Typography,
  Chip,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  CircularProgress
} from '@mui/material';
import {
  MoreVert as MoreVertIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  Visibility as ViewIcon,
  Print as PrintIcon,
  Assignment as AssignmentIcon,
  CloudDownload as DownloadIcon
} from '@mui/icons-material';
import { format } from 'date-fns';
import WorksheetDetail from './WorksheetDetail';
import EditWorksheet from './EditWorksheet';
import PrintableWorksheet from './PrintableWorksheet';
import { Dialog, DialogContent } from '@mui/material';

const WorksheetList = ({ worksheets, loading, onDelete, onUpdate, onRefresh }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedWorksheet, setSelectedWorksheet] = useState(null);
  const [detailOpen, setDetailOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [printOpen, setPrintOpen] = useState(false);
  
  // Get school logo from localStorage if available
  const [schoolLogo, setSchoolLogo] = useState(() => {
    return localStorage.getItem('schoolLogo') || null;
  });

  const handleMenuOpen = (event, worksheet) => {
    setAnchorEl(event.currentTarget);
    setSelectedWorksheet(worksheet);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleViewDetail = () => {
    setDetailOpen(true);
    handleMenuClose();
  };

  const handleEditClick = () => {
    setEditOpen(true);
    handleMenuClose();
  };

  const handleDeleteClick = () => {
    if (window.confirm('Are you sure you want to delete this worksheet?')) {
      onDelete(selectedWorksheet._id);
    }
    handleMenuClose();
  };

  const handlePrintWorksheet = () => {
    setPrintOpen(true);
    handleMenuClose();
  };
  
  const handleClosePrintDialog = () => {
    setPrintOpen(false);
  };

  const handleDownloadPDF = () => {
    // Implement PDF download
    console.log('Download PDF:', selectedWorksheet);
    handleMenuClose();
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (!worksheets || worksheets.length === 0) {
    return (
      <Box sx={{ textAlign: 'center', p: 3 }}>
        <AssignmentIcon sx={{ fontSize: 60, color: 'text.secondary', mb: 2 }} />
        <Typography variant="h6" color="text.secondary">
          No worksheets found
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Create your first worksheet to get started
        </Typography>
      </Box>
    );
  }

  return (
    <>
      <TableContainer component={Paper} sx={{ boxShadow: 'none' }}>
        <Table sx={{ minWidth: 650 }} aria-label="worksheets table">
          <TableHead>
            <TableRow>
              <TableCell>Title</TableCell>
              <TableCell>Subject</TableCell>
              <TableCell>Grade</TableCell>
              <TableCell>Questions</TableCell>
              <TableCell>Created</TableCell>
              <TableCell>Status</TableCell>
              <TableCell align="right">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {worksheets.map((worksheet) => (
              <TableRow
                key={worksheet._id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  <Typography variant="body1" fontWeight={500}>
                    {worksheet.title}
                  </Typography>
                </TableCell>
                <TableCell>{worksheet.subject}</TableCell>
                <TableCell>{worksheet.grade}</TableCell>
                <TableCell>{worksheet.questions ? worksheet.questions.length : 0}</TableCell>
                <TableCell>
                  {worksheet.createdAt ? format(new Date(worksheet.createdAt), 'MMM d, yyyy') : 'N/A'}
                </TableCell>
                <TableCell>
                  <Chip 
                    label={worksheet.status || 'Active'} 
                    size="small"
                    color={worksheet.status === 'Draft' ? 'default' : 'success'}
                  />
                </TableCell>
                <TableCell align="right">
                  <Tooltip title="Worksheet options">
                    <IconButton
                      aria-label="worksheet options"
                      onClick={(e) => handleMenuOpen(e, worksheet)}
                    >
                      <MoreVertIcon />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        PaperProps={{
          elevation: 3,
          sx: { minWidth: 200 }
        }}
      >
        <MenuItem onClick={handleViewDetail}>
          <ListItemIcon>
            <ViewIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>View Details</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleEditClick}>
          <ListItemIcon>
            <EditIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Edit</ListItemText>
        </MenuItem>
        <MenuItem onClick={handlePrintWorksheet}>
          <ListItemIcon>
            <PrintIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Print</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleDownloadPDF}>
          <ListItemIcon>
            <DownloadIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Download PDF</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleDeleteClick} sx={{ color: 'error.main' }}>
          <ListItemIcon sx={{ color: 'error.main' }}>
            <DeleteIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Delete</ListItemText>
        </MenuItem>
      </Menu>

      {selectedWorksheet && (
        <>
          <WorksheetDetail
            open={detailOpen}
            onClose={() => setDetailOpen(false)}
            worksheet={selectedWorksheet}
            onRefresh={onRefresh}
          />
          <EditWorksheet
            open={editOpen}
            onClose={() => setEditOpen(false)}
            worksheetId={selectedWorksheet._id}
            onSave={onUpdate}
          />
          <Dialog
            open={printOpen}
            onClose={handleClosePrintDialog}
            maxWidth="md"
            fullWidth
            PaperProps={{
              sx: {
                minHeight: '80vh',
                maxHeight: '90vh',
                overflowY: 'auto'
              }
            }}
          >
            <DialogContent>
              <PrintableWorksheet 
                worksheet={selectedWorksheet} 
                schoolLogo={schoolLogo}
              />
            </DialogContent>
          </Dialog>
        </>
      )}
    </>
  );
};

export default WorksheetList;