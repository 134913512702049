import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import '../../css/ConversationList.css';
import {
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Typography,
  Paper,
  Box,
  Pagination,
  CircularProgress,
  Divider,
  Tooltip,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@mui/material';
import {
  Delete as DeleteIcon,
  History as HistoryIcon,
  Forum as ForumIcon,
  AccessTime as AccessTimeIcon,
} from '@mui/icons-material';

const CHAT_API_URL = process.env.REACT_APP_CHAT_API_URL || process.env.REACT_APP_BASE_URL;

console.log('Chat API URL:', {
    fromEnv: process.env.REACT_APP_CHAT_API_URL,
    fallback: process.env.REACT_APP_BASE_URL,
    using: CHAT_API_URL
});

const ConversationList = forwardRef(function ConversationList({ onSelectConversation, selectedId, userId }, ref) {
  const [conversations, setConversations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [stats, setStats] = useState(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [conversationToDelete, setConversationToDelete] = useState(null);
  const limit = 10;

  const refresh = () => {
    if (userId) {
      console.log('Refreshing conversations and stats');
      fetchConversations();
      fetchStats();
    }
  };

  // Expose refresh method to parent
  useImperativeHandle(ref, () => ({
    refreshConversations: refresh
  }));

  const fetchStats = async () => {
    if (!userId) {
      setStats({
        last_24h: 0,
        last_week: 0,
        total: 0,
        avg_messages_per_conversation: 0
      });
      return;
    }
    try {
      const response = await fetch(`${CHAT_API_URL}/api/conversations/stats?user_id=${userId}`, {
        headers: {
          'Accept': 'application/json'
        }
      });
      if (!response.ok) {
        console.warn('Stats not available');
        setStats({
          last_24h: 0,
          last_week: 0,
          total: 0,
          avg_messages_per_conversation: 0
        });
        return;
      }
      
      const text = await response.text();
      try {
        const data = JSON.parse(text);
        setStats(data);
      } catch (parseError) {
        console.warn('Invalid stats response, using defaults');
        setStats({
          last_24h: 0,
          last_week: 0,
          total: 0,
          avg_messages_per_conversation: 0
        });
      }
    } catch (err) {
      console.warn('Failed to fetch stats, using defaults');
      setStats({
        last_24h: 0,
        last_week: 0,
        total: 0,
        avg_messages_per_conversation: 0
      });
    }
  };

  const fetchConversations = async () => {
    if (!userId) {
      setLoading(false);
      setConversations([]);
      return;
    }
    try {
      setLoading(true);
      const skip = (page - 1) * limit;
      console.log('Fetching conversations:', { skip, limit });
      
      const response = await fetch(`${CHAT_API_URL}/api/conversations?user_id=${userId}&skip=${skip}&limit=${limit}`, {
        headers: {
          'Accept': 'application/json'
        }
      });
      console.log('Response status:', response.status);
      
      const text = await response.text();
      console.log('Response text:', text);
      
      if (!response.ok) {
        throw new Error(`Failed to fetch conversations: ${response.status} ${text}`);
      }
      
      try {
        const data = JSON.parse(text);
        console.log('Parsed data:', data);
        
        if (Array.isArray(data)) {
          // Handle array response
          setConversations(data);
          setTotalPages(Math.ceil(data.length / limit));
        } else if (data.conversations) {
          // Handle object response with conversations property
          setConversations(data.conversations);
          setTotalPages(Math.ceil(data.total / limit));
        } else {
          console.error('Unexpected data format:', data);
          throw new Error('Unexpected response format');
        }
        
        setError(null);
      } catch (parseError) {
        console.error('Invalid JSON response:', text);
        setError('Failed to parse server response');
      }
    } catch (err) {
      console.error('Error fetching conversations:', err);
      setError(err.message);
      setConversations([]);
      setTotalPages(1);
    } finally {
      setLoading(false);
    }
  };

  // Fetch data when component mounts, when page changes, or when userId changes
  useEffect(() => {
    if (userId) {
      console.log('Fetching initial data with userId:', userId);
      fetchConversations();
      fetchStats();
    }
  }, [userId, page]);

  const handleDeleteClick = (id, event) => {
    event.stopPropagation();
    if (!id) {
      console.error('Attempted to delete conversation with undefined id');
      return;
    }
    setConversationToDelete(id);
    setDeleteDialogOpen(true);
  };

  const handleDeleteConfirm = async () => {
    const id = conversationToDelete;
    setDeleteDialogOpen(false);
    setConversationToDelete(null);

    try {
      const response = await fetch(`${CHAT_API_URL}/api/conversations/${id}?user_id=${userId}`, {
        method: 'DELETE',
        headers: {
          'Accept': 'application/json'
        },
      });
      
      if (!response.ok) {
        const text = await response.text();
        try {
          const data = JSON.parse(text);
          throw new Error(data.message || 'Failed to delete conversation');
        } catch (parseError) {
          console.error('Invalid JSON response:', text);
          throw new Error('Failed to delete conversation');
        }
      }
      
      fetchConversations();
      fetchStats();
      if (selectedId === id) {
        onSelectConversation(null);
      }
    } catch (err) {
      console.error('Delete error:', err);
      setError(err.message);
    }
  };

  const handleDeleteCancel = () => {
    setDeleteDialogOpen(false);
    setConversationToDelete(null);
  };

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleDateString('en-US', {
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    });
  };

  if (loading && page === 1) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight={200}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box p={2}>
        <Typography color="error">{error}</Typography>
      </Box>
    );
  }

  const StatItem = ({ label, value, tooltip }) => (
    <Tooltip title={tooltip} arrow placement="top">
      <Box 
        className="stats-box"
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 0.5,
          py: 0.5,
          px: 1,
          borderRadius: '0.375rem',
          minWidth: 0,
          cursor: 'help',
        }}
      >
        <Typography 
          sx={{ 
            fontWeight: 600,
            fontSize: '0.875rem',
            color: '#111827',
            lineHeight: 1,
          }}
        >
          {typeof value === 'number' && !Number.isInteger(value) 
            ? value.toFixed(1) 
            : value}
        </Typography>
        <Typography 
          sx={{ 
            fontSize: '0.75rem',
            color: '#6b7280',
            lineHeight: 1,
          }}
        >
          {label}
        </Typography>
      </Box>
    </Tooltip>
  );

  if (loading && page === 1) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight={200}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box p={2}>
        <Typography color="error">{error}</Typography>
      </Box>
    );
  }

  return (
    <Paper 
      elevation={0} 
      sx={{ 
        height: '100%', 
        display: 'flex', 
        flexDirection: 'column',
        bgcolor: '#ffffff',
        borderRadius: 0,
      }}
    >
      <Box sx={{ 
        p: 2,
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
      }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <HistoryIcon 
            sx={{ 
              color: 'var(--primary-color)',
              fontSize: '1.25rem',
            }}
          />
          <Typography 
            variant="h6" 
            sx={{ 
              color: '#111827',
              fontWeight: 600,
              fontSize: '1.125rem',
            }}
          >
            Chat History
          </Typography>
        </Box>
        
        {stats && (
          <Box 
            sx={{ 
              mt: 1,
              px: 1.5,
              display: 'flex',
              gap: 1,
              flexWrap: 'nowrap',
              overflowX: 'auto',
              pb: 1,
              '&::-webkit-scrollbar': {
                height: '4px',
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: '#e5e7eb',
                borderRadius: '4px',
              },
            }}
          >
            <StatItem 
              label="24h" 
              value={stats.last_24h} 
              tooltip="Conversations in the last 24 hours"
            />
            <StatItem 
              label="Week" 
              value={stats.last_week} 
              tooltip="Conversations in the last 7 days"
            />
            <StatItem 
              label="Total" 
              value={stats.total} 
              tooltip="Total conversations"
            />
            <StatItem 
              label="Avg" 
              value={stats.avg_messages_per_conversation} 
              tooltip="Average messages per conversation"
            />
          </Box>
        )}
      </Box>
      
      <List 
        className="conversation-list"
        sx={{ 
          flex: 1, 
          overflow: 'auto', 
          pt: 0,
          px: 1,
          '& .MuiListItem-root': {
            borderRadius: '0.5rem',
            mb: 0.5,
          }
        }}>
        {conversations.map((conv, index) => {
          if (!conv) return null;
          
          // Handle different ID field names
          const id = conv._id || conv.id;
          if (!id) return null;

          // Get the title from the first message if no title exists
          const truncateText = (text, maxLength) => {
            if (text.length <= maxLength) return text;
            return text.substring(0, maxLength) + '...';
          };

          let fullTitle = conv.title;
          if (!fullTitle && conv.messages && conv.messages.length > 0) {
            const firstMessage = conv.messages[0];
            fullTitle = firstMessage.content;
          }
          if (!fullTitle && conv.dialogue && conv.dialogue.length > 0) {
            const firstMessage = conv.dialogue[0];
            fullTitle = firstMessage.content;
          }
          fullTitle = fullTitle || 'Untitled Conversation';
          const title = truncateText(fullTitle, 30);

          // Get timestamp from various possible fields
          const timestamp = conv.created_at || conv.timestamp || conv.createdAt || new Date().toISOString();

          return (
            <ListItem
              key={id}
              button
              selected={selectedId === id}
              onClick={() => onSelectConversation(id)}
              secondaryAction={
                <IconButton
                  edge="end"
                  aria-label="delete"
                  onClick={(e) => handleDeleteClick(id, e)}
                  size="small"
                  className="delete-button"
                  sx={{ 
                    opacity: 0,
                    transition: 'opacity 0.2s',
                    color: '#ef4444',
                    p: 0.5,
                    '&:hover': {
                      bgcolor: '#fef2f2',
                    }
                  }}
                >
                  <DeleteIcon sx={{ fontSize: '1.125rem' }} />
                </IconButton>
              }
              sx={{
                py: 1,
                px: 1.5,
                pr: 5,
                borderBottom: '1px solid #f3f4f6',
                '&:hover': {
                  bgcolor: 'var(--light-background)',
                  opacity: 0.7,
                  '& .delete-button': {
                    opacity: 1,
                  }
                },
                '&.Mui-selected': {
                  bgcolor: 'var(--light-background)',
                  '&:hover': {
                    bgcolor: 'var(--light-background)',
                  }
                },
                position: 'relative'
              }}
            >
              <ForumIcon 
                sx={{ 
                  mr: 1.5, 
                  fontSize: '1.25rem',
                  color: selectedId === id ? 'var(--primary-color)' : 'var(--text-color)',
                  opacity: selectedId === id ? 1 : 0.8,
                  flexShrink: 0
                }} 
              />
              <Tooltip 
                title={fullTitle} 
                placement="top"
                arrow
                enterDelay={500}
              >
                <ListItemText
                  primary={title}
                  secondary={
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                      <AccessTimeIcon sx={{ fontSize: '0.75rem', opacity: 0.7 }} />
                      <span>{formatDate(timestamp)}</span>
                    </Box>
                  }
                  primaryTypographyProps={{
                    sx: {
                      fontSize: '0.875rem',
                      fontWeight: selectedId === id ? 600 : 500,
                      color: '#111827',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                    }
                  }}
                  secondaryTypographyProps={{
                    sx: {
                      fontSize: '0.75rem',
                      color: '#6b7280',
                      display: 'flex',
                      alignItems: 'center',
                    },
                    component: 'div'
                  }}
                  sx={{
                    margin: 0,
                    minWidth: 0,
                    pr: 4
                  }}
                />
              </Tooltip>
            </ListItem>
          );
        })}
        {conversations.length === 0 && !loading && (
          <Box p={3} textAlign="center">
            <Typography color="textSecondary">
              No conversations yet
            </Typography>
          </Box>
        )}
      </List>
      
      {totalPages > 1 && (
        <Box p={2} display="flex" justifyContent="center" borderTop={1} borderColor="divider">
          <Pagination
            count={totalPages}
            page={page}
            onChange={(_, value) => setPage(value)}
            color="primary"
            size="small"
          />
        </Box>
      )}
      <Dialog
        open={deleteDialogOpen}
        onClose={handleDeleteCancel}
        aria-labelledby="delete-dialog-title"
        aria-describedby="delete-dialog-description"
        PaperProps={{
          sx: {
            borderRadius: '0.75rem',
            maxWidth: '400px',
          }
        }}
      >
        <DialogTitle 
          id="delete-dialog-title"
          sx={{
            pb: 1,
            color: '#111827',
            fontSize: '1.125rem',
            fontWeight: 600,
          }}
        >
          Delete Conversation
        </DialogTitle>
        <DialogContent>
          <DialogContentText 
            id="delete-dialog-description"
            sx={{
              color: '#4b5563',
              fontSize: '0.875rem',
            }}
          >
            Are you sure you want to delete this conversation? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ px: 3, pb: 2 }}>
          <Button 
            onClick={handleDeleteCancel}
            sx={{
              color: '#4b5563',
              '&:hover': {
                bgcolor: '#f3f4f6',
              },
              textTransform: 'none',
              fontWeight: 500,
            }}
          >
            Cancel
          </Button>
          <Button 
            onClick={handleDeleteConfirm}
            variant="contained"
            sx={{
              bgcolor: '#ef4444',
              '&:hover': {
                bgcolor: '#dc2626',
              },
              textTransform: 'none',
              fontWeight: 500,
            }}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
});

export default ConversationList;
