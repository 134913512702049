import { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { protectedApiCall, protectedFetch } from '../../../auth';


const useTerms = (navigate) => {
    const [terms, setTerms] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    // Get school info from token
    const [schoolId, setSchoolId] = useState(null);
    const [currentSchool, setCurrentSchool] = useState(null);

    // Extract school info from token
    useEffect(() => {
        try {
            const token = localStorage.getItem('token');
            if (token) {
                const decoded = JSON.parse(atob(token.split('.')[1]));
                console.log('Token decoded in useTerms:', {
                    currentSchool: decoded.currentSchool,
                    id: decoded.id
                });
                if (decoded.currentSchool?._id) {
                    setSchoolId(decoded.currentSchool._id);
                    setCurrentSchool(decoded.currentSchool);
                }
            }
        } catch (error) {
            console.error('Error getting school from token:', error);
        }
    }, []);
    
    useEffect(() => {
        console.log('useTerms - Mount/Update:', {
            currentSchool,
            schoolId,
            token: localStorage.getItem('token')
        });
    }, [currentSchool, schoolId]);

    const fetchTerms = useCallback(async () => {
        console.log('fetchTerms called with:', { 
            schoolId,
            token: localStorage.getItem('token'),
            currentSchool
        });
        
        if (!schoolId) {
            console.log('No schoolId available, skipping fetch');
            setError('Please select a school first');
            setTerms([]);
            setLoading(false);
            return;
        }

        try {
            console.log('Starting terms fetch process');
            setLoading(true);

            const result = await protectedFetch(async () => {
                // Get token and log request details
                const token = localStorage.getItem('token');
                console.log('Terms request details:', {
                    url: `${process.env.REACT_APP_BASE_URL}/api/terms`,
                    token: token ? token.substring(0, 20) + '...' : 'no token'
                });
                
                const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/terms`, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                
                console.log('Terms response status:', response.status);
                console.log('Terms API response:', {
                    count: response.data.length,
                    terms: response.data.map(term => ({
                        termNumber: term.termNumber,
                        year: term.year,
                        school: term.school,
                        startDate: term.startDate,
                        endDate: term.endDate
                    }))
                });
                return response.data;
            }, navigate);

            if (result) {
                console.log('Setting terms:', {
                    count: result.length,
                    terms: result.map(term => ({
                        termNumber: term.termNumber,
                        year: term.year,
                        school: term.school,
                        startDate: term.startDate,
                        endDate: term.endDate
                    }))
                });
                // For now, show all terms as they're linked to user ID
                const filteredTerms = result;
                console.log('Terms found:', {
                    count: result.length,
                    terms: result.map(term => ({
                        termNumber: term.termNumber,
                        year: term.year,
                        school: term.school,
                        startDate: term.startDate,
                        endDate: term.endDate
                    }))
                });

                console.log('Filtered terms:', {
                    total: result.length,
                    filtered: filteredTerms.length,
                    schoolId
                });

                setTerms(filteredTerms);
                setError(null);
            }
        } catch (err) {
            console.error('Error fetching terms:', err);
            setError('Failed to load terms');
            setTerms([]);
        } finally {
            setLoading(false);
        }
    }, [navigate, schoolId]);

    const createTerm = async (termData) => {
        if (!schoolId) {
            throw new Error('Please select a school first');
        }

        try {
            setLoading(true);
            const result = await protectedFetch(async () => {
                // Use current user ID for school field temporarily
                const userData = JSON.parse(localStorage.getItem('user'));
                const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/terms`, {
                    ...termData,
                    school: userData.id || userData._id
                });
                return response.data;
            }, navigate);

            if (result) {
                setTerms(prev => [...prev, result]);
                return result;
            }
        } catch (err) {
            console.error('Error creating term:', err);
            throw err;
        } finally {
            setLoading(false);
        }
    };

    const updateTerm = async (termNumber, termData) => {
        if (!schoolId) {
            throw new Error('Please select a school first');
        }

        try {
            setLoading(true);
            const result = await protectedFetch(async () => {
                // Use current user ID for school field temporarily
                const userData = JSON.parse(localStorage.getItem('user'));
                const response = await axios.put(`${process.env.REACT_APP_BASE_URL}/api/terms/${termNumber}`, {
                    ...termData,
                    school: userData.id || userData._id
                });
                return response.data;
            }, navigate);

            if (result) {
                setTerms(prev => prev.map(term => term.termNumber === termNumber ? result : term));
                return result;
            }
        } catch (err) {
            console.error('Error updating term:', err);
            throw err;
        } finally {
            setLoading(false);
        }
    };

    const deleteTerm = async (termNumber) => {
        if (!schoolId) {
            throw new Error('Please select a school first');
        }

        try {
            setLoading(true);
            await protectedFetch(async () => {
                // Use current user ID for school field temporarily
                const userData = JSON.parse(localStorage.getItem('user'));
                await axios.delete(`${process.env.REACT_APP_BASE_URL}/api/terms/${termNumber}`, {
                    params: { school: userData.id || userData._id }
                });
            }, navigate);

            setTerms(prev => prev.filter(term => term.termNumber !== termNumber));
        } catch (err) {
            console.error('Error deleting term:', err);
            throw err;
        } finally {
            setLoading(false);
        }
    };

    // Initial data load
    useEffect(() => {
        console.log('Terms initial load triggered with:', {
            schoolId,
            currentSchool,
            token: localStorage.getItem('token')
        });
        let mounted = true;

        const loadData = async () => {
            if (!mounted) {
                console.log('Component not mounted, skipping load');
                return;
            }
            if (!schoolId) {
                console.log('No schoolId available, skipping load');
                return;
            }
            console.log('Starting initial data load');
            await fetchTerms();
        };

        loadData().catch(error => {
            console.error('Error in initial load:', error);
        });

        return () => {
            console.log('Terms cleanup triggered');
            mounted = false;
            setTerms([]);
            setError(null);
            setLoading(false);
        };
    }, [schoolId, currentSchool]); // Depend on both schoolId and currentSchool

    return {
        terms,
        loading,
        error,
        createTerm,
        updateTerm,
        deleteTerm,
        refreshTerms: fetchTerms,
        currentSchool,
        schoolId,
    };
};

export default useTerms;