import React, { useState, useEffect } from 'react';
import {
    Box,
    Paper,
    Typography,
    TextField,
    Button,
    IconButton,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
    Tooltip,
    Alert,
    CircularProgress,
    InputAdornment,
} from '@mui/material';
import {
    Edit as EditIcon,
    Delete as DeleteIcon,
    Search as SearchIcon,
    Add as AddIcon,
    School as SchoolIcon,
    Image as ImageIcon,
    CloudUpload as CloudUploadIcon,
} from '@mui/icons-material';
import { protectedApiCall, protectedFetch } from '../../auth';
import { getLogoUrl } from '../../utils/logoHelper';
import { cacheSchoolLogo, getCachedLogo, clearCachedLogo } from '../../utils/schoolLogoCache';
import { cacheSignedUrl, getSignedUrl, clearSignedUrl } from '../../utils/urlCache';

const SchoolsManager = () => {
    const [schools, setSchools] = useState([]);
    const [logoUrls, setLogoUrls] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [formOpen, setFormOpen] = useState(false);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [selectedSchool, setSelectedSchool] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [formData, setFormData] = useState({
        name: '',
        address: '',
        contactEmail: '',
        contactPhone: '',
    });

    // Fetch schools
    const fetchSchools = async () => {
        try {
            setLoading(true);
            const response = await protectedApiCall('/api/schools', 'GET');
            setSchools(response.data);
            setError(null);
        } catch (err) {
            setError('Failed to fetch schools: ' + (err.response?.data?.message || err.message));
            console.error('Error fetching schools:', err);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchSchools();
    }, []);

    // Load logo URLs when schools change
    useEffect(() => {
        const loadLogoUrls = async () => {
            try {
                const urls = {};
                for (const school of schools) {
                    try {
                        // Try to get from cache first
                        const cached = getCachedLogo(school._id);
                        if (cached) {
                            urls[school._id] = cached;
                            continue;
                        }

                        // If not in cache and school has a logo URL, cache it
                        if (school.logoUrl) {
                            const imageData = await cacheSchoolLogo(school._id, school.logoUrl);
                            if (imageData) {
                                urls[school._id] = imageData;
                            }
                        }
                    } catch (err) {
                        console.warn(`Failed to load logo for school ${school._id}:`, err);
                    }
                }
                setLogoUrls(urls);
            } catch (err) {
                console.error('Failed to load logos:', err);
            }
        };
        
        if (schools.length) {
            loadLogoUrls();
        }
    }, [schools]);

    const handleCreateSchool = async () => {
        try {
            const response = await protectedApiCall('/api/schools', 'POST', formData);
            setSchools([...schools, response.data]);
            setFormOpen(false);
            setFormData({ name: '', address: '', contactEmail: '', contactPhone: '' });
            setError(null);
        } catch (err) {
            setError('Failed to create school: ' + (err.response?.data?.message || err.message));
            console.error('Error creating school:', err);
        }
    };

    const handleUpdateSchool = async () => {
        try {
            const response = await protectedApiCall(
                `/api/schools/${selectedSchool._id}`,
                'PUT',
                formData
            );
            setSchools(schools.map(school => 
                school._id === selectedSchool._id ? response.data : school
            ));
            setFormOpen(false);
            setSelectedSchool(null);
            setFormData({ name: '', address: '', contactEmail: '', contactPhone: '' });
            setError(null);
        } catch (err) {
            setError('Failed to update school: ' + (err.response?.data?.message || err.message));
            console.error('Error updating school:', err);
        }
    };

    const handleLogoUpload = async (schoolId, file) => {
        try {
            // Validate file size (5MB limit)
            if (file.size > 5 * 1024 * 1024) {
                setError('Logo file size must be less than 5MB');
                return;
            }

            // Validate file type
            if (!file.type.startsWith('image/')) {
                setError('Please upload an image file');
                return;
            }

            const formData = new FormData();
            formData.append('logo', file);

            try {
                const response = await protectedFetch(async () => {
                    const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/schools/${schoolId}/logo`, {
                        method: 'POST',
                        headers: {
                            'Authorization': `Bearer ${localStorage.getItem('token')}`
                        },
                        body: formData
                    });

                    if (!response.ok) {
                        const errorData = await response.json();
                        console.error('Server error details:', errorData);
                        throw new Error(
                            errorData.error?.message || // AWS/S3 error message
                            errorData.message || // General API error message
                            errorData.error || // Error string
                            'Failed to upload logo'
                        );
                    }

                    const result = await response.json();
                    console.log('Upload response:', result);
                    return result;
                });

                if (response && response.logoUrl && response.logoKey) {
                    // Cache the signed URL
                    cacheSignedUrl(response.logoKey, response.logoUrl);
                    
                    // Update the schools list with both URL and key
                    setSchools(schools.map(school => 
                        school._id === schoolId 
                            ? { ...school, logoUrl: response.logoUrl, logoKey: response.logoKey }
                            : school
                    ));
                    setError(null);
                }
            } catch (err) {
                console.error('Error uploading logo:', err);
                // If it's an S3/AWS error, show a more user-friendly message
                if (err.message?.includes('ACL')) {
                    setError('Server storage configuration error. Please contact support.');
                } else {
                    setError(err.message || 'Failed to upload logo');
                }
            }
        } catch (err) {
            setError('Error processing file: ' + err.message);
            console.error('Error processing file:', err);
        }
    };

    const handleLogoDelete = async (schoolId) => {
        try {
            // Find the school first
            const school = schools.find(s => s._id === schoolId);
            if (!school?.logoUrl) {
                // If school has no logo, just update the UI
                setSchools(schools.map(s => 
                    s._id === schoolId 
                        ? { ...s, logoUrl: null, logoKey: null }
                        : s
                ));
                return;
            }

            try {
                await protectedFetch(async () => {
                    const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/schools/${schoolId}/logo`, {
                        method: 'DELETE',
                        headers: {
                            'Authorization': `Bearer ${localStorage.getItem('token')}`
                        }
                    });

                    if (!response.ok) {
                        // If 404, treat as already deleted
                        if (response.status === 404) {
                            return null;
                        }
                        const errorData = await response.json();
                        throw new Error(errorData.message || errorData.error || 'Failed to delete logo');
                    }

                    return response;
                });
            } catch (err) {
                // If error is "School has no logo", treat as success
                if (err.message === 'School has no logo') {
                    console.log('Logo already removed from server');
                } else {
                    throw err;
                }
            }
            
            // Clear the cached logo
            clearCachedLogo(schoolId);

            // Remove the logo URL and key from the school
            setSchools(schools.map(s => 
                s._id === schoolId 
                    ? { ...s, logoUrl: null, logoKey: null }
                    : s
            ));
            setError(null);
        } catch (err) {
            console.error('Error deleting logo:', err);
            // Don't show "School has no logo" error to user
            if (err.message !== 'School has no logo') {
                setError(err.message || 'Failed to delete logo');
            }
        }
    };

    const handleDeleteSchool = async () => {
        try {
            await protectedApiCall(`/api/schools/${selectedSchool._id}`, 'DELETE');
            setSchools(schools.filter(school => school._id !== selectedSchool._id));
            setDeleteDialogOpen(false);
            setSelectedSchool(null);
            setError(null);
        } catch (err) {
            setError('Failed to delete school: ' + (err.response?.data?.message || err.message));
            console.error('Error deleting school:', err);
        }
    };

    const handleEdit = (school) => {
        // Make sure we have the latest school data including the logo
        const currentSchool = schools.find(s => s._id === school._id) || school;
        setSelectedSchool(currentSchool);
        setFormData({
            name: currentSchool.name,
            address: currentSchool.address || '',
            contactEmail: currentSchool.contactEmail || '',
            contactPhone: currentSchool.contactPhone || '',
        });
        setFormOpen(true);
    };

    const handleDelete = (school) => {
        setSelectedSchool(school);
        setDeleteDialogOpen(true);
    };

    const filteredSchools = schools.filter(school =>
        school.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        (school.address && school.address.toLowerCase().includes(searchTerm.toLowerCase())) ||
        (school.contactEmail && school.contactEmail.toLowerCase().includes(searchTerm.toLowerCase())) ||
        (school.contactPhone && school.contactPhone.includes(searchTerm))
    );

    const displayedSchools = filteredSchools
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

    return (
        <Box sx={{ p: 3 }}>
            <Paper elevation={0} sx={{ p: 3, borderRadius: 2, border: '1px solid', borderColor: 'divider' }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
                    <Typography variant="h5" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <SchoolIcon sx={{ color: 'primary.main' }} />
                        Schools Management
                    </Typography>
                    <Button
                        variant="contained"
                        startIcon={<AddIcon />}
                        onClick={() => {
                            setSelectedSchool(null);
                            setFormData({ name: '', address: '', contactEmail: '', contactPhone: '' });
                            setFormOpen(true);
                        }}
                    >
                        Add School
                    </Button>
                </Box>

                {error && (
                    <Alert severity="error" sx={{ mb: 3 }}>
                        {error}
                    </Alert>
                )}

                <TextField
                    fullWidth
                    variant="outlined"
                    placeholder="Search schools..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    sx={{ mb: 3 }}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon sx={{ color: 'text.secondary' }} />
                            </InputAdornment>
                        ),
                    }}
                />

                {loading ? (
                    <Box sx={{ display: 'flex', justifyContent: 'center', py: 3 }}>
                        <CircularProgress />
                    </Box>
                ) : (
                    <>
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Name</TableCell>
                                        <TableCell>Address</TableCell>
                                        <TableCell>Contact Email</TableCell>
                                        <TableCell>Contact Phone</TableCell>
                                        <TableCell align="right">Actions</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {displayedSchools.map((school) => (
                                        <TableRow key={school._id}>
                                            <TableCell>
                                                <Box sx={{ 
                                                    display: 'flex', 
                                                    alignItems: 'center', 
                                                    gap: 1
                                                }}>
                                                    {(logoUrls[school._id] || school.logoUrl) ? (
                                                        <>
                                                            <Box
                                                                component="img"
                                                                src={logoUrls[school._id] || school.logoUrl}
                                                                alt={`${school.name} logo`}
                                                                onError={(e) => {
                                                                    console.log('Image load error, attempting to use direct URL');
                                                                    // Clear cache and try direct URL
                                                                    clearCachedLogo(school._id);
                                                                    if (school.logoUrl && e.target.src !== school.logoUrl) {
                                                                        e.target.src = school.logoUrl;
                                                                    } else {
                                                                        e.target.style.display = 'none';
                                                                        e.target.nextSibling.style.display = 'block';
                                                                    }
                                                                }}
                                                                sx={{
                                                                    width: 32,
                                                                    height: 32,
                                                                    objectFit: 'contain',
                                                                    borderRadius: 1
                                                                }}
                                                            />
                                                            <SchoolIcon sx={{ 
                                                                width: 32,
                                                                height: 32,
                                                                color: 'primary.main',
                                                                opacity: 0.7,
                                                                display: 'none'
                                                            }} />
                                                            <Typography>{school.name}</Typography>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <SchoolIcon sx={{ 
                                                                width: 32,
                                                                height: 32,
                                                                color: 'primary.main',
                                                                opacity: 0.7
                                                            }} />
                                                            <Typography>{school.name}</Typography>
                                                        </>
                                                    )}
                                                </Box>
                                            </TableCell>
                                            <TableCell>{school.address}</TableCell>
                                            <TableCell>{school.contactEmail}</TableCell>
                                            <TableCell>{school.contactPhone}</TableCell>
                                            <TableCell align="right">
                                                <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1 }}>
                                                    <Tooltip title="Edit school">
                                                        <IconButton
                                                            size="small"
                                                            onClick={() => handleEdit(school)}
                                                            sx={{ color: 'primary.main' }}
                                                        >
                                                            <EditIcon fontSize="small" />
                                                        </IconButton>
                                                    </Tooltip>
                                                    <Tooltip title="Delete school">
                                                        <IconButton
                                                            size="small"
                                                            onClick={() => handleDelete(school)}
                                                            sx={{ color: 'error.main' }}
                                                        >
                                                            <DeleteIcon fontSize="small" />
                                                        </IconButton>
                                                    </Tooltip>
                                                </Box>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>

                        <TablePagination
                            component="div"
                            count={filteredSchools.length}
                            page={page}
                            onPageChange={(e, newPage) => setPage(newPage)}
                            rowsPerPage={rowsPerPage}
                            onRowsPerPageChange={(e) => {
                                setRowsPerPage(parseInt(e.target.value, 10));
                                setPage(0);
                            }}
                        />
                    </>
                )}
            </Paper>

            {/* Create/Edit Dialog */}
            <Dialog
                open={formOpen}
                onClose={() => setFormOpen(false)}
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle>
                    {selectedSchool ? 'Edit School' : 'Add New School'}
                </DialogTitle>
                <DialogContent>
                    <Box sx={{ pt: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
                        {selectedSchool && (
                            <Box sx={{ 
                                display: 'flex', 
                                alignItems: 'center', 
                                gap: 2,
                                p: 2,
                                bgcolor: 'background.default',
                                borderRadius: 1
                            }}>
                                {(logoUrls[selectedSchool._id] || selectedSchool.logoUrl) ? (
                                    <>
                                        <Box
                                            component="img"
                                            src={logoUrls[selectedSchool._id] || selectedSchool.logoUrl}
                                            alt={`${selectedSchool.name} logo`}
                                            onError={(e) => {
                                                console.log('Image load error, attempting to use direct URL');
                                                // Clear cache and try direct URL
                                                clearCachedLogo(selectedSchool._id);
                                                if (selectedSchool.logoUrl && e.target.src !== selectedSchool.logoUrl) {
                                                    e.target.src = selectedSchool.logoUrl;
                                                } else {
                                                    e.target.style.display = 'none';
                                                    e.target.nextSibling.style.display = 'block';
                                                }
                                            }}
                                            sx={{
                                                width: 48,
                                                height: 48,
                                                objectFit: 'contain',
                                                borderRadius: 1
                                            }}
                                        />
                                        <SchoolIcon sx={{ 
                                            width: 48,
                                            height: 48,
                                            color: 'primary.main',
                                            opacity: 0.7,
                                            display: 'none'
                                        }} />
                                        <Box sx={{ flex: 1 }}>
                                            <Typography variant="subtitle2" color="text.secondary">School Logo</Typography>
                                            <Typography variant="body2" color="text.secondary">Current logo</Typography>
                                        </Box>
                                        <Tooltip title="Delete logo">
                                            <IconButton
                                                size="small"
                                                onClick={() => handleLogoDelete(selectedSchool._id)}
                                                sx={{ color: 'error.main' }}
                                            >
                                                <DeleteIcon fontSize="small" />
                                            </IconButton>
                                        </Tooltip>
                                    </>
                                ) : (
                                    <>
                                        <SchoolIcon sx={{ 
                                            width: 48,
                                            height: 48,
                                            color: 'primary.main',
                                            opacity: 0.7
                                        }} />
                                        <Box sx={{ flex: 1 }}>
                                            <Typography variant="subtitle2" color="text.secondary">School Logo</Typography>
                                            <Typography variant="body2" color="text.secondary">No logo uploaded</Typography>
                                        </Box>
                                        <Tooltip title="Upload logo">
                                            <IconButton
                                                component="label"
                                                size="small"
                                                sx={{ color: 'primary.main' }}
                                            >
                                                <input
                                                    type="file"
                                                    hidden
                                                    accept="image/*"
                                                    onChange={(e) => {
                                                        if (e.target.files?.[0]) {
                                                            handleLogoUpload(selectedSchool._id, e.target.files[0]);
                                                        }
                                                    }}
                                                />
                                                <CloudUploadIcon fontSize="small" />
                                            </IconButton>
                                        </Tooltip>
                                    </>
                                )}
                            </Box>
                        )}
                        <TextField
                            label="School Name"
                            fullWidth
                            value={formData.name}
                            onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                        />
                        <TextField
                            label="Address"
                            fullWidth
                            value={formData.address}
                            onChange={(e) => setFormData({ ...formData, address: e.target.value })}
                        />
                        <TextField
                            label="Contact Email"
                            fullWidth
                            value={formData.contactEmail}
                            onChange={(e) => setFormData({ ...formData, contactEmail: e.target.value })}
                        />
                        <TextField
                            label="Contact Phone"
                            fullWidth
                            value={formData.contactPhone}
                            onChange={(e) => setFormData({ ...formData, contactPhone: e.target.value })}
                        />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setFormOpen(false)}>Cancel</Button>
                    <Button
                        variant="contained"
                        onClick={selectedSchool ? handleUpdateSchool : handleCreateSchool}
                    >
                        {selectedSchool ? 'Update' : 'Create'}
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Delete Confirmation Dialog */}
            <Dialog
                open={deleteDialogOpen}
                onClose={() => setDeleteDialogOpen(false)}
            >
                <DialogTitle>Delete School</DialogTitle>
                <DialogContent>
                    <Typography>
                        Are you sure you want to delete {selectedSchool?.name}? This action cannot be undone.
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDeleteDialogOpen(false)}>Cancel</Button>
                    <Button
                        variant="contained"
                        color="error"
                        onClick={handleDeleteSchool}
                    >
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default SchoolsManager;