import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import '../css/Navbar.css';
import { ThemeContext } from '../context/ThemeContext';
import { useSchool } from '../context';
import { isAuthenticated, isAdmin } from '../auth';
import { getLogoUrl } from '../utils/logoHelper';
import { cacheSchoolLogo, getCachedLogo, clearCachedLogo } from '../utils/schoolLogoCache';
import { Menu, MenuItem, IconButton, Box, Typography, Drawer, useMediaQuery } from '@mui/material';
import { 
    Settings as SettingsIcon, 
    School as SchoolIcon, 
    Palette as PaletteIcon, 
    Logout as LogoutIcon,
    Build as BuildIcon,
    Menu as MenuIcon,
    Close as CloseIcon
} from '@mui/icons-material';

const Navbar = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { theme, setTheme } = useContext(ThemeContext);
    const { currentSchool } = useSchool();
    const [userData, setUserData] = useState(() => {
        if (isAuthenticated()) {
            return JSON.parse(localStorage.getItem('user')) || {};
        }
        return {};
    });
    const [isAdminUser, setIsAdminUser] = useState(false);
    const [adminMenuAnchor, setAdminMenuAnchor] = useState(null);
    const [classesMenuAnchor, setClassesMenuAnchor] = useState(null);
    const [themeMenuAnchor, setThemeMenuAnchor] = useState(null);
    const [toolsMenuAnchor, setToolsMenuAnchor] = useState(null);
    const [logoUrl, setLogoUrl] = useState(null);
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
    const isMobile = useMediaQuery('(max-width:768px)');

    useEffect(() => {
        if (isAuthenticated()) {
            const storedUser = JSON.parse(localStorage.getItem('user'));
            setUserData(storedUser || {});
            setIsAdminUser(isAdmin()); // Check if user is admin
        }
    }, []);

    // Update userData when currentSchool changes
    useEffect(() => {
        if (currentSchool) {
            setUserData(prev => ({
                ...prev,
                currentSchool
            }));

            // Load logo URL
            const loadLogoUrl = async () => {
                try {
                    // Try to get from cache first
                    const cached = getCachedLogo(currentSchool.id);
                    if (cached) {
                        setLogoUrl(cached);
                        return;
                    }

                    // If not in cache and school has a logo URL, cache it
                    if (currentSchool.logoUrl) {
                        const imageData = await cacheSchoolLogo(currentSchool.id, currentSchool.logoUrl);
                        if (imageData) {
                            setLogoUrl(imageData);
                            return;
                        }
                    }

                    // If no logo URL, try to get one
                    const url = await getLogoUrl(currentSchool.id);
                    if (url) {
                        const imageData = await cacheSchoolLogo(currentSchool.id, url);
                        if (imageData) {
                            setLogoUrl(imageData);
                        }
                    }
                } catch (err) {
                    console.warn('Failed to load school logo:', err);
                }
            };
            loadLogoUrl();
        }
    }, [currentSchool]);

    const handleLogout = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('user');
        navigate('/');
        setMobileMenuOpen(false);
    };

    const handleLogin = () => {
        navigate('/login');
        setMobileMenuOpen(false);
    };

    const getNavLinkClass = (path) => {
        return location.pathname === path ? 'navbar-link active' : 'navbar-link';
    };

    const handleThemeMenuOpen = (event) => {
        setThemeMenuAnchor(event.currentTarget);
    };

    const handleThemeMenuClose = () => {
        setThemeMenuAnchor(null);
    };

    const handleThemeChange = (newTheme) => {
        setTheme(newTheme);
        localStorage.setItem('theme-choice', newTheme);
        handleThemeMenuClose();
        setMobileMenuOpen(false);
    };

    const handleAdminMenuOpen = (event) => {
        setAdminMenuAnchor(event.currentTarget);
    };

    const handleAdminMenuClose = () => {
        setAdminMenuAnchor(null);
    };

    const handleAdminMenuClick = (path) => {
        navigate(path);
        handleAdminMenuClose();
        setMobileMenuOpen(false);
    };

    const handleClassesMenuOpen = (event) => {
        setClassesMenuAnchor(event.currentTarget);
    };

    const handleClassesMenuClose = () => {
        setClassesMenuAnchor(null);
    };

    const handleClassesMenuClick = (path) => {
        navigate(path);
        handleClassesMenuClose();
        setMobileMenuOpen(false);
    };

    const handleToolsMenuOpen = (event) => {
        setToolsMenuAnchor(event.currentTarget);
    };

    const handleToolsMenuClose = () => {
        setToolsMenuAnchor(null);
    };

    const handleToolsMenuClick = (path) => {
        navigate(path);
        handleToolsMenuClose();
        setMobileMenuOpen(false);
    };
    
    const toggleMobileMenu = () => {
        setMobileMenuOpen(!mobileMenuOpen);
    };
    
    const closeMobileMenu = () => {
        setMobileMenuOpen(false);
    };

    return (
        <nav className="navbar">
            <div className={`navbar-container ${isMobile ? 'mobile' : ''}`}>
                <div className="navbar-left">
                    <Link to="/" className="navbar-logo">
                        <img src="/bg-guy.png" alt="Logo" className="navbar-logo-image" />
                    </Link>
                    <span className="navbar-title">Tammoo</span>
                </div>

                {!isMobile && (
                    <div className="navbar-center">
                        {isAuthenticated() && (
                            <Box 
                                className="navbar-user" 
                                sx={{ 
                                    display: 'flex', 
                                    alignItems: 'center', 
                                    gap: 1.5,
                                    color: 'var(--background-color)',
                                    fontSize: '1rem',
                                    fontWeight: 500,
                                    width: '100%',
                                    justifyContent: 'center'
                                }}
                            >
                                <Typography 
                                    component="span" 
                                    sx={{ 
                                        color: 'inherit',
                                        opacity: 0.9
                                    }}
                                >
                                    Hi {userData.firstName}
                                </Typography>
                                <Typography 
                                    component="span" 
                                    sx={{ 
                                        color: 'inherit',
                                        opacity: 0.6,
                                        fontSize: '0.9em',
                                        fontStyle: 'italic',
                                        mx: 0.5
                                    }}
                                >
                                    @
                                </Typography>
                                {logoUrl ? (
                                    <Box
                                        component="img"
                                        src={logoUrl}
                                        alt="School logo"
                                        onError={(e) => {
                                            console.log('Image load error, attempting to use direct URL');
                                            // Clear cache and try direct URL
                                            clearCachedLogo(currentSchool.id);
                                            if (currentSchool.logoUrl && e.target.src !== currentSchool.logoUrl) {
                                                e.target.src = currentSchool.logoUrl;
                                            } else {
                                                // If direct URL also fails, show SchoolIcon
                                                e.target.style.display = 'none';
                                                e.target.nextSibling.style.display = 'block';
                                            }
                                        }}
                                        sx={{
                                            width: 20,
                                            height: 20,
                                            objectFit: 'contain',
                                            borderRadius: 0.5,
                                            bgcolor: 'var(--background-color)',
                                            p: 0.25,
                                            mx: 0.5
                                        }}
                                    />
                                ) : null}
                                <SchoolIcon 
                                    sx={{ 
                                        width: 20,
                                        height: 20,
                                        display: logoUrl ? 'none' : 'block',
                                        opacity: 0.8,
                                        mx: 0.5,
                                        color: 'inherit'
                                    }} 
                                />
                                <Typography 
                                    component="span"
                                    sx={{ 
                                        color: 'inherit',
                                        opacity: 0.9
                                    }}
                                >
                                    {userData.currentSchool?.name || ''}
                                </Typography>
                            </Box>
                        )}
                        {!isAuthenticated() && (
                            <span className="navbar-tagline">AI-Powered Speech-to-Insight for Teachers</span>
                        )}
                    </div>
                )}

                <div className="navbar-right">
                    {isMobile ? (
                        <IconButton
                            onClick={toggleMobileMenu}
                            className="mobile-menu-toggle"
                            sx={{
                                color: '#fff',
                                padding: '6px',
                                '&:hover': { 
                                    backgroundColor: 'var(--secondary-color)',
                                    borderRadius: '16px',
                                }
                            }}
                        >
                            <MenuIcon />
                        </IconButton>
                    ) : (
                        <>
                            <IconButton
                                onClick={handleThemeMenuOpen}
                                className={`navbar-link ${themeMenuAnchor ? 'active' : ''}`}
                                sx={{
                                    color: '#fff',
                                    padding: '6px',
                                    '&:hover': { 
                                        backgroundColor: 'var(--secondary-color)',
                                        borderRadius: '16px',
                                    },
                                    '&.active': {
                                        backgroundColor: 'var(--accent-color)',
                                        color: 'var(--primary-color)',
                                        borderRadius: '16px',
                                        fontWeight: 'bold',
                                    }
                                }}
                            >
                                <PaletteIcon />
                            </IconButton>
                            <Menu
                                anchorEl={themeMenuAnchor}
                                open={Boolean(themeMenuAnchor)}
                                onClose={handleThemeMenuClose}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                PaperProps={{
                                    sx: {
                                        backgroundColor: 'var(--primary-color)',
                                        color: '#fff',
                                        marginTop: '8px',
                                        '& .MuiMenuItem-root': {
                                            fontSize: '14px',
                                            color: '#fff',
                                            padding: '10px 15px',
                                            '&:hover': {
                                                backgroundColor: 'var(--secondary-color)',
                                            },
                                            '&.active': {
                                                backgroundColor: 'var(--accent-color)',
                                                color: 'var(--primary-color)',
                                            }
                                        },
                                    }
                                }}
                            >
                                <MenuItem 
                                    onClick={() => handleThemeChange('royal-elegance')}
                                    className={theme === 'royal-elegance' ? 'active' : ''}
                                >
                                    Royal Elegance
                                </MenuItem>
                                <MenuItem 
                                    onClick={() => handleThemeChange('ocean-breeze')}
                                    className={theme === 'ocean-breeze' ? 'active' : ''}
                                >
                                    Ocean Breeze
                                </MenuItem>
                                <MenuItem 
                                    onClick={() => handleThemeChange('sunset-glow')}
                                    className={theme === 'sunset-glow' ? 'active' : ''}
                                >
                                    Sunset Glow
                                </MenuItem>
                                <MenuItem 
                                    onClick={() => handleThemeChange('forest-calm')}
                                    className={theme === 'forest-calm' ? 'active' : ''}
                                >
                                    Forest Calm
                                </MenuItem>
                                <MenuItem 
                                    onClick={() => handleThemeChange('citrus-fresh')}
                                    className={theme === 'citrus-fresh' ? 'active' : ''}
                                >
                                    Citrus Fresh
                                </MenuItem>
                                <MenuItem 
                                    onClick={() => handleThemeChange('cool-mint')}
                                    className={theme === 'cool-mint' ? 'active' : ''}
                                >
                                    Cool Mint
                                </MenuItem>
                                <MenuItem 
                                    onClick={() => handleThemeChange('earthy-tones')}
                                    className={theme === 'earthy-tones' ? 'active' : ''}
                                >
                                    Earthy Tones
                                </MenuItem>
                            </Menu>
                            
                            <ul className="navbar-menu">
                                {isAuthenticated() && (
                                    <>
                                        <li className="navbar-item">
                                            <IconButton
                                                onClick={handleToolsMenuOpen}
                                                className={`navbar-link ${toolsMenuAnchor ? 'active' : ''}`}
                                                sx={{
                                                    color: '#fff',
                                                    padding: '6px',
                                                    '&:hover': { 
                                                        backgroundColor: 'var(--secondary-color)',
                                                        borderRadius: '16px',
                                                    },
                                                    '&.active': {
                                                        backgroundColor: 'var(--accent-color)',
                                                        color: 'var(--primary-color)',
                                                        borderRadius: '16px',
                                                        fontWeight: 'bold',
                                                    }
                                                }}
                                            >
                                                <BuildIcon />
                                            </IconButton>
                                            <Menu
                                                anchorEl={toolsMenuAnchor}
                                                open={Boolean(toolsMenuAnchor)}
                                                onClose={handleToolsMenuClose}
                                                anchorOrigin={{
                                                    vertical: 'bottom',
                                                    horizontal: 'right',
                                                }}
                                                transformOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'right',
                                                }}
                                                PaperProps={{
                                                    sx: {
                                                        backgroundColor: 'var(--primary-color)',
                                                        color: '#fff',
                                                        marginTop: '8px',
                                                        '& .MuiMenuItem-root': {
                                                            fontSize: '14px',
                                                            color: '#fff',
                                                            padding: '10px 15px',
                                                            '&:hover': {
                                                                backgroundColor: 'var(--secondary-color)',
                                                            },
                                                            '&.active': {
                                                                backgroundColor: 'var(--accent-color)',
                                                                color: 'var(--primary-color)',
                                                            }
                                                        },
                                                    }
                                                }}
                                            >
                                                <MenuItem 
                                                    onClick={() => handleToolsMenuClick('/dash')}
                                                    className={location.pathname === '/dash' ? 'active' : ''}
                                                >
                                                    Dashboard
                                                </MenuItem>
                                                <MenuItem 
                                                    onClick={() => handleToolsMenuClick('/generate')}
                                                    className={location.pathname === '/generate' ? 'active' : ''}
                                                >
                                                    Generate
                                                </MenuItem>
                                                <MenuItem 
                                                    onClick={() => handleToolsMenuClick('/chat')}
                                                    className={location.pathname === '/chat' ? 'active' : ''}
                                                >
                                                    Chat
                                                </MenuItem>
                                                <MenuItem 
                                                    onClick={() => handleToolsMenuClick('/reports')}
                                                    className={location.pathname === '/reports' ? 'active' : ''}
                                                >
                                                    Reports
                                                </MenuItem>
                                                <MenuItem 
                                                    onClick={() => handleToolsMenuClick('/text-leveling')}
                                                    className={location.pathname === '/text-leveling' ? 'active' : ''}
                                                >
                                                    Text Leveling
                                                </MenuItem>
                                                <MenuItem
                                                    onClick={() => handleToolsMenuClick('/worksheets')}
                                                    className={location.pathname === '/worksheets' ? 'active' : ''}
                                                >
                                                    Worksheets
                                                </MenuItem>
                                            </Menu>
                                        </li>
                                        <li className="navbar-item">
                                            <IconButton
                                                onClick={handleClassesMenuOpen}
                                                className={`navbar-link ${classesMenuAnchor ? 'active' : ''}`}
                                                sx={{
                                                    color: '#fff',
                                                    padding: '6px',
                                                    '&:hover': { 
                                                        backgroundColor: 'var(--secondary-color)',
                                                        borderRadius: '16px',
                                                    },
                                                    '&.active': {
                                                        backgroundColor: 'var(--accent-color)',
                                                        color: 'var(--primary-color)',
                                                        borderRadius: '16px',
                                                        fontWeight: 'bold',
                                                    }
                                                }}
                                            >
                                                <SchoolIcon />
                                            </IconButton>
                                            <Menu
                                                anchorEl={classesMenuAnchor}
                                                open={Boolean(classesMenuAnchor)}
                                                onClose={handleClassesMenuClose}
                                                anchorOrigin={{
                                                    vertical: 'bottom',
                                                    horizontal: 'right',
                                                }}
                                                transformOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'right',
                                                }}
                                                PaperProps={{
                                                    sx: {
                                                        backgroundColor: 'var(--primary-color)',
                                                        color: '#fff',
                                                        marginTop: '8px',
                                                        '& .MuiMenuItem-root': {
                                                            fontSize: '14px',
                                                            color: '#fff',
                                                            padding: '10px 15px',
                                                            '&:hover': {
                                                                backgroundColor: 'var(--secondary-color)',
                                                            },
                                                        },
                                                    }
                                                }}
                                            >
                                                <MenuItem onClick={() => handleClassesMenuClick('/classes')}>
                                                    Classes
                                                </MenuItem>
                                                <MenuItem onClick={() => handleClassesMenuClick('/terms')}>
                                                    Terms
                                                </MenuItem>
                                            </Menu>
                                        </li>
                                        {isAdminUser && (
                                            <li className="navbar-item">
                                                <IconButton
                                                    onClick={handleAdminMenuOpen}
                                                    className={`navbar-link ${adminMenuAnchor ? 'active' : ''}`}
                                                    sx={{
                                                        color: '#fff',
                                                        padding: '6px',
                                                        '&:hover': { 
                                                            backgroundColor: 'var(--secondary-color)',
                                                            borderRadius: '16px',
                                                        },
                                                        '&.active': {
                                                            backgroundColor: 'var(--accent-color)',
                                                            color: 'var(--primary-color)',
                                                            borderRadius: '16px',
                                                            fontWeight: 'bold',
                                                        }
                                                    }}
                                                >
                                                    <SettingsIcon />
                                                </IconButton>
                                                <Menu
                                                    anchorEl={adminMenuAnchor}
                                                    open={Boolean(adminMenuAnchor)}
                                                    onClose={handleAdminMenuClose}
                                                    anchorOrigin={{
                                                        vertical: 'bottom',
                                                        horizontal: 'right',
                                                    }}
                                                    transformOrigin={{
                                                        vertical: 'top',
                                                        horizontal: 'right',
                                                    }}
                                                    PaperProps={{
                                                        sx: {
                                                            backgroundColor: 'var(--primary-color)',
                                                            color: '#fff',
                                                            marginTop: '8px',
                                                            '& .MuiMenuItem-root': {
                                                                fontSize: '14px',
                                                                color: '#fff',
                                                                padding: '10px 15px',
                                                                '&:hover': {
                                                                    backgroundColor: 'var(--secondary-color)',
                                                                },
                                                            },
                                                        }
                                                    }}
                                                >
                                                    <MenuItem onClick={() => handleAdminMenuClick('/admin/schools')}>
                                                        Schools Management
                                                    </MenuItem>
                                                    <MenuItem onClick={() => handleAdminMenuClick('/admin/users')}>
                                                        Teacher Management
                                                    </MenuItem>
                                                    <MenuItem onClick={() => handleAdminMenuClick('/newsletter')}>
                                                        Newsletter Editor
                                                    </MenuItem>
                                                    <MenuItem onClick={() => handleAdminMenuClick('/messaging')}>
                                                        Messaging
                                                    </MenuItem>
                                                </Menu>
                                            </li>
                                        )}
                                        <li className="navbar-item">
                                            <IconButton
                                                onClick={handleLogout}
                                                className="navbar-link"
                                                sx={{
                                                    color: '#fff',
                                                    padding: '6px',
                                                    '&:hover': { 
                                                        backgroundColor: 'var(--secondary-color)',
                                                        borderRadius: '16px',
                                                    }
                                                }}
                                                title="Logout"
                                            >
                                                <LogoutIcon />
                                            </IconButton>
                                        </li>
                                    </>
                                )}
                                {!isAuthenticated() && (
                                    <>
                                        <li className="navbar-item">
                                            <Link to="/" className={getNavLinkClass('/')}>Home</Link>
                                        </li>
                                        <li className="navbar-item">
                                            <button onClick={handleLogin} className="navbar-button navbar-link">
                                                Login
                                            </button>
                                        </li>
                                    </>
                                )}
                            </ul>
                        </>
                    )}
                </div>
            </div>
            
            {/* Mobile Menu Drawer */}
            <Drawer
                anchor="right"
                open={isMobile && mobileMenuOpen}
                onClose={closeMobileMenu}
                PaperProps={{
                    sx: {
                        width: '80%',
                        maxWidth: '300px',
                        backgroundColor: 'var(--primary-color)',
                        color: '#fff',
                        padding: '20px 0',
                    }
                }}
            >
                <Box sx={{ 
                    display: 'flex', 
                    justifyContent: 'space-between', 
                    alignItems: 'center',
                    padding: '0 20px 20px',
                    borderBottom: '1px solid var(--secondary-color)'
                }}>
                    <Typography variant="h6" sx={{ color: 'var(--accent-color)' }}>
                        Menu
                    </Typography>
                    <IconButton onClick={closeMobileMenu} sx={{ color: 'var(--accent-color)' }}>
                        <CloseIcon />
                    </IconButton>
                </Box>
                
                {isAuthenticated() && (
                    <Box sx={{ padding: '20px', borderBottom: '1px solid var(--secondary-color)' }}>
                        <Typography sx={{ color: 'var(--accent-color)', fontWeight: 'bold', mb: 1 }}>
                            Hi {userData.firstName}
                        </Typography>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            {logoUrl ? (
                                <Box
                                    component="img"
                                    src={logoUrl}
                                    alt="School logo"
                                    sx={{
                                        width: 24,
                                        height: 24,
                                        objectFit: 'contain',
                                        borderRadius: 0.5,
                                        bgcolor: 'var(--background-color)',
                                        p: 0.25
                                    }}
                                />
                            ) : (
                                <SchoolIcon sx={{ color: 'var(--accent-color)' }} />
                            )}
                            <Typography sx={{ color: 'var(--accent-color)', fontSize: '0.9rem' }}>
                                {userData.currentSchool?.name || ''}
                            </Typography>
                        </Box>
                    </Box>
                )}
                
                {isAuthenticated() && (
                    <Box component="ul" sx={{ 
                        listStyle: 'none', 
                        padding: '10px 0',
                        margin: 0,
                        '& li': {
                            padding: '10px 20px',
                            '&:hover': {
                                backgroundColor: 'var(--secondary-color)'
                            }
                        }
                    }}>
                        <Typography sx={{ 
                            color: 'var(--accent-color)', 
                            fontWeight: 'bold', 
                            padding: '5px 20px',
                            fontSize: '0.8rem',
                            opacity: 0.8
                        }}>
                            TOOLS
                        </Typography>
                        <li onClick={() => handleToolsMenuClick('/dash')}>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                                <BuildIcon sx={{ color: 'var(--accent-color)' }} />
                                <Typography>Dashboard</Typography>
                            </Box>
                        </li>
                        <li onClick={() => handleToolsMenuClick('/generate')}>
                            <Typography>Generate</Typography>
                        </li>
                        <li onClick={() => handleToolsMenuClick('/chat')}>
                            <Typography>Chat</Typography>
                        </li>
                        <li onClick={() => handleToolsMenuClick('/reports')}>
                            <Typography>Reports</Typography>
                        </li>
                        <li onClick={() => handleToolsMenuClick('/text-leveling')}>
                            <Typography>Text Leveling</Typography>
                        </li>
                        <li onClick={() => handleToolsMenuClick('/worksheets')}>
                            <Typography>Worksheets</Typography>
                        </li>
                        <Typography sx={{ 
                            color: 'var(--accent-color)', 
                            fontWeight: 'bold', 
                            padding: '15px 20px 5px',
                            fontSize: '0.8rem',
                            opacity: 0.8,
                            marginTop: '10px',
                            borderTop: '1px solid var(--secondary-color)'
                        }}>
                            CLASSES
                        </Typography>
                        <li onClick={() => handleClassesMenuClick('/classes')}>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                                <SchoolIcon sx={{ color: 'var(--accent-color)' }} />
                                <Typography>Classes</Typography>
                            </Box>
                        </li>
                        <li onClick={() => handleClassesMenuClick('/terms')}>
                            <Typography>Terms</Typography>
                        </li>
                        
                        {isAdminUser && (
                            <>
                                <Typography sx={{ 
                                    color: 'var(--accent-color)', 
                                    fontWeight: 'bold', 
                                    padding: '15px 20px 5px',
                                    fontSize: '0.8rem',
                                    opacity: 0.8,
                                    marginTop: '10px',
                                    borderTop: '1px solid var(--secondary-color)'
                                }}>
                                    ADMIN
                                </Typography>
                                <li onClick={() => handleAdminMenuClick('/admin/schools')}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                                        <SettingsIcon sx={{ color: 'var(--accent-color)' }} />
                                        <Typography>Schools Management</Typography>
                                    </Box>
                                </li>
                                <li onClick={() => handleAdminMenuClick('/admin/users')}>
                                    <Typography>Teacher Management</Typography>
                                </li>
                                <li onClick={() => handleAdminMenuClick('/newsletter')}>
                                    <Typography>Newsletter Editor</Typography>
                                </li>
                                <li onClick={() => handleAdminMenuClick('/messaging')}>
                                    <Typography>Messaging</Typography>
                                </li>
                            </>
                        )}
                        
                        <Typography sx={{ 
                            color: 'var(--accent-color)', 
                            fontWeight: 'bold', 
                            padding: '15px 20px 5px',
                            fontSize: '0.8rem',
                            opacity: 0.8,
                            marginTop: '10px',
                            borderTop: '1px solid var(--secondary-color)'
                        }}>
                            THEME
                        </Typography>
                        <li onClick={() => handleThemeChange('royal-elegance')} 
                            className={theme === 'royal-elegance' ? 'active' : ''}>
                            <Box sx={{ 
                                display: 'flex', 
                                alignItems: 'center', 
                                gap: 2,
                                color: theme === 'royal-elegance' ? 'var(--primary-color)' : 'inherit',
                                backgroundColor: theme === 'royal-elegance' ? 'var(--accent-color)' : 'transparent',
                                padding: '5px 10px',
                                borderRadius: '4px'
                            }}>
                                <PaletteIcon sx={{ 
                                    color: theme === 'royal-elegance' ? 'var(--primary-color)' : 'var(--accent-color)' 
                                }} />
                                <Typography>Royal Elegance</Typography>
                            </Box>
                        </li>
                        <li onClick={() => handleThemeChange('ocean-breeze')}
                            className={theme === 'ocean-breeze' ? 'active' : ''}>
                            <Typography sx={{ 
                                color: theme === 'ocean-breeze' ? 'var(--primary-color)' : 'inherit',
                                backgroundColor: theme === 'ocean-breeze' ? 'var(--accent-color)' : 'transparent',
                                padding: '5px 10px',
                                borderRadius: '4px'
                            }}>Ocean Breeze</Typography>
                        </li>
                        <li onClick={() => handleThemeChange('sunset-glow')}
                            className={theme === 'sunset-glow' ? 'active' : ''}>
                            <Typography sx={{ 
                                color: theme === 'sunset-glow' ? 'var(--primary-color)' : 'inherit',
                                backgroundColor: theme === 'sunset-glow' ? 'var(--accent-color)' : 'transparent',
                                padding: '5px 10px',
                                borderRadius: '4px'
                            }}>Sunset Glow</Typography>
                        </li>
                        <li onClick={() => handleThemeChange('forest-calm')}
                            className={theme === 'forest-calm' ? 'active' : ''}>
                            <Typography sx={{ 
                                color: theme === 'forest-calm' ? 'var(--primary-color)' : 'inherit',
                                backgroundColor: theme === 'forest-calm' ? 'var(--accent-color)' : 'transparent',
                                padding: '5px 10px',
                                borderRadius: '4px'
                            }}>Forest Calm</Typography>
                        </li>
                        <li onClick={() => handleThemeChange('citrus-fresh')}
                            className={theme === 'citrus-fresh' ? 'active' : ''}>
                            <Typography sx={{ 
                                color: theme === 'citrus-fresh' ? 'var(--primary-color)' : 'inherit',
                                backgroundColor: theme === 'citrus-fresh' ? 'var(--accent-color)' : 'transparent',
                                padding: '5px 10px',
                                borderRadius: '4px'
                            }}>Citrus Fresh</Typography>
                        </li>
                        <li onClick={() => handleThemeChange('cool-mint')}
                            className={theme === 'cool-mint' ? 'active' : ''}>
                            <Typography sx={{ 
                                color: theme === 'cool-mint' ? 'var(--primary-color)' : 'inherit',
                                backgroundColor: theme === 'cool-mint' ? 'var(--accent-color)' : 'transparent',
                                padding: '5px 10px',
                                borderRadius: '4px'
                            }}>Cool Mint</Typography>
                        </li>
                        <li onClick={() => handleThemeChange('earthy-tones')}
                            className={theme === 'earthy-tones' ? 'active' : ''}>
                            <Typography sx={{ 
                                color: theme === 'earthy-tones' ? 'var(--primary-color)' : 'inherit',
                                backgroundColor: theme === 'earthy-tones' ? 'var(--accent-color)' : 'transparent',
                                padding: '5px 10px',
                                borderRadius: '4px'
                            }}>Earthy Tones</Typography>
                        </li>
                    </Box>
                )}
                
                {!isAuthenticated() && (
                    <Box component="ul" sx={{ 
                        listStyle: 'none', 
                        padding: '10px 0',
                        margin: 0,
                        '& li': {
                            padding: '10px 20px',
                            '&:hover': {
                                backgroundColor: 'var(--secondary-color)'
                            }
                        }
                    }}>
                        <li>
                            <Link to="/" style={{ 
                                color: 'white', 
                                textDecoration: 'none',
                                display: 'block'
                            }}>
                                Home
                            </Link>
                        </li>
                        <li>
                            <button 
                                onClick={handleLogin} 
                                style={{
                                    background: 'none',
                                    border: 'none',
                                    color: 'white',
                                    padding: 0,
                                    fontSize: '1rem',
                                    cursor: 'pointer',
                                    textAlign: 'left',
                                    width: '100%'
                                }}
                            >
                                Login
                            </button>
                        </li>
                        <Typography sx={{ 
                            color: 'var(--accent-color)', 
                            fontWeight: 'bold', 
                            padding: '15px 20px 5px',
                            fontSize: '0.8rem',
                            opacity: 0.8,
                            marginTop: '10px',
                            borderTop: '1px solid var(--secondary-color)'
                        }}>
                            THEME
                        </Typography>
                        <li onClick={() => handleThemeChange('royal-elegance')}>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                                <PaletteIcon sx={{ color: 'var(--accent-color)' }} />
                                <Typography>Royal Elegance</Typography>
                            </Box>
                        </li>
                        <li onClick={() => handleThemeChange('ocean-breeze')}>
                            <Typography>Ocean Breeze</Typography>
                        </li>
                        <li onClick={() => handleThemeChange('sunset-glow')}>
                            <Typography>Sunset Glow</Typography>
                        </li>
                        <li onClick={() => handleThemeChange('forest-calm')}>
                            <Typography>Forest Calm</Typography>
                        </li>
                        <li onClick={() => handleThemeChange('citrus-fresh')}>
                            <Typography>Citrus Fresh</Typography>
                        </li>
                        <li onClick={() => handleThemeChange('cool-mint')}>
                            <Typography>Cool Mint</Typography>
                        </li>
                        <li onClick={() => handleThemeChange('earthy-tones')}>
                            <Typography>Earthy Tones</Typography>
                        </li>
                    </Box>
                )}
                
                {isAuthenticated() && (
                    <Box sx={{ 
                        padding: '20px', 
                        borderTop: '1px solid var(--secondary-color)',
                        display: 'flex',
                        justifyContent: 'center'
                    }}>
                        <button 
                            onClick={handleLogout} 
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '10px',
                                background: 'var(--secondary-color)',
                                border: 'none',
                                color: 'white',
                                padding: '10px 20px',
                                borderRadius: '4px',
                                cursor: 'pointer',
                                fontSize: '1rem'
                            }}
                        >
                            <LogoutIcon />
                            Logout
                        </button>
                    </Box>
                )}
            </Drawer>
        </nav>
    );
};

export default Navbar;
