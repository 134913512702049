import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Box,
    Paper,
    Typography,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    CircularProgress,
    Alert,
} from '@mui/material';
import { Add as AddIcon } from '@mui/icons-material';

import TimetableList from './components/TimetableList';
import TimetableForm from './components/TimetableForm';
import useTimetables from './hooks/useTimetables';

const TimetableManager = () => {
    const navigate = useNavigate();
    const [formOpen, setFormOpen] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [selectedTimetable, setSelectedTimetable] = useState(null);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [expandedId, setExpandedId] = useState(null);

    const {
        timetables,
        loading,
        error,
        createTimetable,
        updateTimetable,
        deleteTimetable,
        currentSchool,
    } = useTimetables(navigate);

    const handleCreateTimetable = () => {
        setIsEditing(false);
        setSelectedTimetable(null);
        setFormOpen(true);
    };

    const handleEditTimetable = (timetable) => {
        setSelectedTimetable(timetable);
        setIsEditing(true);
        setFormOpen(true);
    };

    const handleDeleteClick = (id) => {
        const timetable = timetables.find(t => t._id === id);
        setSelectedTimetable(timetable);
        setDeleteDialogOpen(true);
    };

    const handleDeleteConfirm = async () => {
        try {
            await deleteTimetable(selectedTimetable._id);
            setDeleteDialogOpen(false);
            setSelectedTimetable(null);
        } catch (err) {
            console.error('Failed to delete timetable:', err);
        }
    };

    const handleFormSubmit = async (formData) => {
        try {
            if (isEditing) {
                await updateTimetable(selectedTimetable._id, formData);
            } else {
                await createTimetable(formData);
            }
            setFormOpen(false);
        } catch (err) {
            console.error('Failed to save timetable:', err);
        }
    };

    const handleToggleExpand = (id) => {
        setExpandedId(expandedId === id ? null : id);
    };

    if (error) {
        return (
            <Alert severity="error">{error}</Alert>
        );
    }

    return (
        <Paper
            elevation={0}
            sx={{
                p: 3,
                borderRadius: 2,
                border: '1px solid',
                borderColor: 'divider',
            }}
        >
            <Box sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                mb: 3
            }}>
                <Typography variant="h5" sx={{ fontWeight: 600 }}>
                    Timetables - {currentSchool?.name || 'Loading...'}
                </Typography>
                <Button
                    variant="contained"
                    startIcon={<AddIcon />}
                    onClick={handleCreateTimetable}
                >
                    Add Timetable
                </Button>
            </Box>

            {loading ? (
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    py: 4
                }}>
                    <CircularProgress />
                </Box>
            ) : (
                <TimetableList
                    timetables={timetables}
                    onEdit={handleEditTimetable}
                    onDelete={handleDeleteClick}
                    expandedId={expandedId}
                    onToggleExpand={handleToggleExpand}
                />
            )}

            <TimetableForm
                open={formOpen}
                onClose={() => setFormOpen(false)}
                onSubmit={handleFormSubmit}
                initialData={isEditing ? selectedTimetable : null}
                isEditing={isEditing}
            />

            <Dialog
                open={deleteDialogOpen}
                onClose={() => setDeleteDialogOpen(false)}
                PaperProps={{
                    sx: { borderRadius: 2 }
                }}
            >
                <DialogTitle>Delete Timetable?</DialogTitle>
                <DialogContent>
                    <Typography>
                        Are you sure you want to delete "{selectedTimetable?.name}"? 
                        This action cannot be undone.
                    </Typography>
                </DialogContent>
                <DialogActions sx={{ p: 2 }}>
                    <Button onClick={() => setDeleteDialogOpen(false)}>
                        Cancel
                    </Button>
                    <Button
                        onClick={handleDeleteConfirm}
                        variant="contained"
                        color="error"
                    >
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </Paper>
    );
};

export default TimetableManager;