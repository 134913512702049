import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Button,
  Paper,
  Grid,
  Divider,
  CircularProgress,
  Alert,
  IconButton,
  Card,
  CardContent,
  CardMedia,
  CardActions,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  useTheme
} from '@mui/material';
import {
  PhotoCamera as PhotoCameraIcon,
  Upload as UploadIcon,
  Delete as DeleteIcon,
  Visibility as VisibilityIcon,
  Check as CheckIcon,
  Close as CloseIcon
} from '@mui/icons-material';
import { protectedApiCall } from '../../../auth';
import ClassSelector from './ClassSelector';
import StudentSelector from './StudentSelector';

const WorksheetSubmissions = ({ worksheet }) => {
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const [classes, setClasses] = useState([]);
  const [students, setStudents] = useState([]);
  const [selectedClass, setSelectedClass] = useState('');
  const [selectedStudent, setSelectedStudent] = useState('');
  const [submissions, setSubmissions] = useState([]);
  const [uploadOpen, setUploadOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState('');
  const [uploadLoading, setUploadLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [viewSubmissionOpen, setViewSubmissionOpen] = useState(false);
  const [selectedSubmission, setSelectedSubmission] = useState(null);
  const [worksheetQuestions, setWorksheetQuestions] = useState([]);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [submissionToDelete, setSubmissionToDelete] = useState(null);

  // Load classes when component mounts
  useEffect(() => {
    loadClasses();
  }, []);

  // Load students when a class is selected
  useEffect(() => {
    if (selectedClass) {
      loadStudents(selectedClass);
    } else {
      setStudents([]);
    }
  }, [selectedClass]);

  // Load submissions when worksheet, class, or student changes
  useEffect(() => {
    if (worksheet && worksheet._id) {
      loadSubmissions();
      loadWorksheetQuestions();
    }
  }, [worksheet, selectedClass, selectedStudent]);
  
  // Load worksheet questions
  const loadWorksheetQuestions = async () => {
    if (!worksheet || !worksheet._id) return;
    
    try {
      const response = await protectedApiCall(`/api/worksheets/${worksheet._id}`, 'GET');
      if (response.data && response.data.questions) {
        setWorksheetQuestions(response.data.questions);
      }
    } catch (error) {
      console.error('Error loading worksheet questions:', error);
    }
  };

  const loadClasses = async () => {
    setLoading(true);
    try {
      const response = await protectedApiCall('/api/classes', 'GET');
      setClasses(response.data || []);
    } catch (error) {
      console.error('Error loading classes:', error);
      setError('Failed to load classes. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const loadStudents = async (classId) => {
    setLoading(true);
    try {
      const response = await protectedApiCall(`/api/classes/${classId}/students`, 'GET');
      setStudents(response.data || []);
    } catch (error) {
      console.error('Error loading students:', error);
      setError('Failed to load students. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const loadSubmissions = async () => {
    setLoading(true);
    try {
      let url = `/api/worksheets/${worksheet._id}/submissions`;
      if (selectedClass) {
        url += `?classId=${selectedClass}`;
      }
      if (selectedStudent) {
        url += `${selectedClass ? '&' : '?'}studentId=${selectedStudent}`;
      }
      
      const response = await protectedApiCall(url, 'GET');
      
      // Process the submissions data
      const processedSubmissions = (response.data || []).map(submission => {
        // Get student name - first check if it's already provided by the backend
        let studentName = submission.studentName || 'Unknown Student';
        
        // If studentName is not provided but we have student object or ID
        if (studentName === 'Unknown Student') {
          if (submission.student) {
            // If student is an object with firstName and lastName
            if (typeof submission.student === 'object' && submission.student.firstName) {
              studentName = `${submission.student.firstName} ${submission.student.lastName}`;
            } else if (submission.studentId) {
              // Try to find student by ID
              const studentObj = students.find(s => s._id === submission.studentId);
              if (studentObj) {
                studentName = `${studentObj.firstName} ${studentObj.lastName}`;
              }
            }
          } else if (submission.studentId) {
            // Try to find student by ID
            const studentObj = students.find(s => s._id === submission.studentId);
            if (studentObj) {
              studentName = `${studentObj.firstName} ${studentObj.lastName}`;
            }
          }
        }
        
        // Get class name - first check if it's already provided by the backend
        let className = submission.className || 'Unknown Class';
        
        // If className is not provided but we have class object or ID
        if (className === 'Unknown Class') {
          if (submission.class) {
            // If class is an object with className
            if (typeof submission.class === 'object' && submission.class.className) {
              className = submission.class.className;
            } else if (submission.classId) {
              // Try to find class by ID
              const classObj = classes.find(c => c._id === submission.classId);
              if (classObj) {
                className = classObj.className;
              }
            }
          } else if (submission.classId) {
            // Try to find class by ID
            const classObj = classes.find(c => c._id === submission.classId);
            if (classObj) {
              className = classObj.className;
            }
          }
        }
        
        // Ensure createdAt is valid - use submittedAt if available
        let createdAt = submission.submittedAt || submission.createdAt;
        if (!createdAt || new Date(createdAt).toString() === 'Invalid Date') {
          createdAt = new Date().toISOString();
        }
        
        // Format the data for display
        return {
          ...submission,
          studentName,
          className,
          createdAt,
          // Score is already a percentage in the backend
          score: submission.score !== undefined ? submission.score : undefined
        };
      });
      
      setSubmissions(processedSubmissions);
    } catch (error) {
      console.error('Error loading submissions:', error);
      setError('Failed to load submissions. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleClassChange = (classId) => {
    setSelectedClass(classId);
    setSelectedStudent(''); // Reset student when class changes
  };

  const handleStudentChange = (studentId) => {
    setSelectedStudent(studentId);
  };

  const handleOpenUpload = () => {
    setUploadOpen(true);
    setSelectedFile(null);
    setPreviewUrl('');
    setError('');
  };

  const handleCloseUpload = () => {
    setUploadOpen(false);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
      setPreviewUrl(URL.createObjectURL(file));
    }
  };

  const handleUploadSubmission = async () => {
    if (!selectedFile) {
      setError('Please select a file to upload');
      return;
    }

    if (!selectedClass) {
      setError('Please select a class');
      return;
    }

    if (!selectedStudent) {
      setError('Please select a student');
      return;
    }

    setUploadLoading(true);
    setError('');

    try {
      // Get student and class information for display
      const selectedStudentObj = students.find(s => s._id === selectedStudent);
      const selectedClassObj = classes.find(c => c._id === selectedClass);
      
      const studentName = selectedStudentObj ? 
        `${selectedStudentObj.firstName} ${selectedStudentObj.lastName}` : 'Unknown Student';
      const className = selectedClassObj ? selectedClassObj.className : 'Unknown Class';

      const formData = new FormData();
      formData.append('image', selectedFile);
      formData.append('classId', selectedClass);
      formData.append('studentId', selectedStudent);

      // Upload the submission
      const response = await protectedApiCall(
        `/api/worksheets/${worksheet._id}/submissions`, 
        'POST', 
        formData, 
        true
      );
      
      // If successful, process the submission for grading
      if (response.data && response.data._id) {
        // Add the student and class info to the response data for display
        const submissionWithDetails = {
          ...response.data,
          studentName,
          className,
          createdAt: response.data.createdAt || new Date().toISOString()
        };
        
        // Update the submissions list with the new submission
        setSubmissions(prev => [submissionWithDetails, ...prev]);
        
        setSuccess('Submission uploaded successfully! Processing for grading...');
        
        try {
          // Process the submission for grading - send empty object to ensure proper JSON formatting
          await protectedApiCall(
            `/api/worksheets/submissions/${response.data._id}/process`, 
            'POST',
            {}
          );
          
          // Update the submission status in the list
          setSubmissions(prev => 
            prev.map(sub => 
              sub._id === response.data._id ? 
                {
                  ...sub,
                  status: 'graded'
                } : 
                sub
            )
          );
          
          setSuccess('Submission uploaded and graded successfully!');
        } catch (processingError) {
          console.error('Error processing submission:', processingError);
          setSuccess('Submission uploaded, but automatic grading failed. You can try processing it again later.');
        }
      } else {
        setSuccess('Submission uploaded successfully!');
      }
      
      setUploadOpen(false);
      // We've already updated the submissions list, so we don't need to reload
      // loadSubmissions(); 
    } catch (error) {
      console.error('Error uploading submission:', error);
      setError('Failed to upload submission. Please try again.');
    } finally {
      setUploadLoading(false);
    }
  };

  const handleViewSubmission = async (submission) => {
    // Get student and class information for display if not already present
    let enhancedSubmission = { ...submission };
    
    // Use studentName if already provided
    if (!enhancedSubmission.studentName) {
      if (submission.student) {
        // If we have student object but no formatted name
        if (typeof submission.student === 'object') {
          enhancedSubmission.studentName = `${submission.student.firstName} ${submission.student.lastName}`;
        } else if (submission.studentId) {
          // Try to find student by ID
          const studentObj = students.find(s => s._id === submission.studentId);
          if (studentObj) {
            enhancedSubmission.studentName = `${studentObj.firstName} ${studentObj.lastName}`;
          }
        }
      } else if (submission.studentId) {
        // Try to find student by ID
        const studentObj = students.find(s => s._id === submission.studentId);
        if (studentObj) {
          enhancedSubmission.studentName = `${studentObj.firstName} ${studentObj.lastName}`;
        }
      }
    }
    
    // Use className if already provided
    if (!enhancedSubmission.className) {
      if (submission.class) {
        // If we have class object but no formatted name
        if (typeof submission.class === 'object') {
          enhancedSubmission.className = submission.class.className;
        } else if (submission.classId) {
          // Try to find class by ID
          const classObj = classes.find(c => c._id === submission.classId);
          if (classObj) {
            enhancedSubmission.className = classObj.className;
          }
        }
      } else if (submission.classId) {
        // Try to find class by ID
        const classObj = classes.find(c => c._id === submission.classId);
        if (classObj) {
          enhancedSubmission.className = classObj.className;
        }
      }
    }
    
    // Use submittedAt if available, otherwise use createdAt
    enhancedSubmission.createdAt = submission.submittedAt || submission.createdAt;
    
    // Format date if it's invalid
    if (!enhancedSubmission.createdAt || new Date(enhancedSubmission.createdAt).toString() === 'Invalid Date') {
      enhancedSubmission.createdAt = new Date().toISOString();
    }
    
    setSelectedSubmission(enhancedSubmission);
    setViewSubmissionOpen(true);
    
    // If the submission has an ID but no detailed results or answers yet, fetch them
    if (submission._id && (!submission.results && !submission.answers || !submission.feedback)) {
      try {
        const response = await protectedApiCall(`/api/worksheets/submissions/${submission._id}`, 'GET');
        
        if (response.data) {
          // Update the selected submission with the detailed data
          const updatedSubmission = {
            ...submission,
            ...response.data,
            // Ensure we keep the formatted display values
            studentName: submission.studentName || 'Unknown Student',
            className: submission.className || 'Unknown Class',
            createdAt: submission.createdAt || new Date().toISOString(),
            // Score is already a percentage in the backend
            score: response.data.score !== undefined ? response.data.score : submission.score
          };
          
          setSelectedSubmission(updatedSubmission);
        }
      } catch (error) {
        console.error('Error fetching submission details:', error);
      }
    }
  };
  
  // Function to process a submission for grading
  const handleProcessSubmission = async (submissionId) => {
    if (!submissionId) return;
    
    setLoading(true);
    try {
      // Send an empty object as the body to ensure proper JSON formatting
      await protectedApiCall(`/api/worksheets/submissions/${submissionId}/process`, 'POST', {});
      setSuccess('Submission processed successfully!');
      
      // Fetch the updated submission
      const response = await protectedApiCall(`/api/worksheets/submissions/${submissionId}`, 'GET');
      if (response.data) {
        // Update the selected submission with the graded data
        setSelectedSubmission(prev => ({
          ...prev,
          ...response.data,
          status: 'graded',
          // Ensure we keep the formatted display values
          studentName: prev.studentName,
          className: prev.className,
          score: response.data.score !== undefined && response.data.totalPoints ? 
            Math.round((response.data.score / response.data.totalPoints) * 100) : prev.score
        }));
        
        // Also update the submission in the list
        setSubmissions(prev => 
          prev.map(sub => 
            sub._id === submissionId ? 
              {
                ...sub,
                ...response.data,
                status: 'graded',
                score: response.data.score !== undefined && response.data.totalPoints ? 
                  Math.round((response.data.score / response.data.totalPoints) * 100) : sub.score
              } : 
              sub
          )
        );
      }
    } catch (error) {
      console.error('Error processing submission:', error);
      setError('Failed to process submission. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleCloseViewSubmission = () => {
    setViewSubmissionOpen(false);
    setSelectedSubmission(null);
  };

  const handleDeleteSubmissionConfirm = (submission) => {
    setSubmissionToDelete(submission);
    setDeleteConfirmOpen(true);
  };

  const handleDeleteCancel = () => {
    setSubmissionToDelete(null);
    setDeleteConfirmOpen(false);
  };

  const handleDeleteConfirm = async () => {
    if (!submissionToDelete || !submissionToDelete._id) {
      return;
    }

    setLoading(true);
    setDeleteConfirmOpen(false);
    
    try {
      // Use the correct API endpoint for deleting submissions
      await protectedApiCall(`/api/worksheets/submissions/${submissionToDelete._id}`, 'DELETE');
      setSuccess('Submission deleted successfully!');
      loadSubmissions(); // Reload submissions after deletion
    } catch (error) {
      console.error('Error deleting submission:', error);
      setError('Failed to delete submission. Please try again.');
    } finally {
      setLoading(false);
      setSubmissionToDelete(null);
    }
  };

  return (
    <Box>
      {error && (
        <Alert severity="error" sx={{ mb: 2 }} onClose={() => setError('')}>
          {error}
        </Alert>
      )}
      
      {success && (
        <Alert severity="success" sx={{ mb: 2 }} onClose={() => setSuccess('')}>
          {success}
        </Alert>
      )}

      {/* Prominent Upload Section */}
      <Paper sx={{ p: 3, mb: 3, bgcolor: theme.palette.primary.lighter, borderRadius: 2 }}>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} md={7}>
            <Typography variant="h6" gutterBottom color="primary.dark">
              Upload Student Worksheet Submissions
            </Typography>
            <Typography variant="body1" paragraph>
              Take a photo of completed worksheets and upload them here for automatic grading.
              Select a class and student, then upload the image of their completed worksheet.
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <ClassSelector 
                  classes={classes}
                  selectedClass={selectedClass}
                  onChange={handleClassChange}
                  disabled={loading}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <StudentSelector 
                  students={students}
                  selectedStudent={selectedStudent}
                  onChange={handleStudentChange}
                  disabled={loading || !selectedClass}
                  helperText={!selectedClass ? "Select a class first" : ""}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={5} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Box sx={{ textAlign: 'center' }}>
              <Button
                variant="contained"
                color="primary"
                size="large"
                startIcon={<UploadIcon />}
                onClick={handleOpenUpload}
                sx={{ py: 1.5, px: 3, fontSize: '1rem' }}
                disabled={!selectedClass || !selectedStudent}
              >
                Upload Submission
              </Button>
              {(!selectedClass || !selectedStudent) && (
                <Typography variant="caption" display="block" sx={{ mt: 1, color: 'text.secondary' }}>
                  Please select a class and student first
                </Typography>
              )}
            </Box>
          </Grid>
        </Grid>
      </Paper>

      {/* Filter and Submissions List */}
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Typography variant="h6">
          Submissions History {submissions.length > 0 ? `(${submissions.length})` : ''}
        </Typography>
      </Box>

      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', my: 4 }}>
          <CircularProgress />
        </Box>
      ) : submissions.length > 0 ? (
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
          <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
            {/* Table header */}
            <Box sx={{ 
              display: 'flex', 
              p: 1.5, 
              borderBottom: '1px solid', 
              borderColor: 'divider',
              bgcolor: 'background.default',
              fontWeight: 'bold'
            }}>
              <Box sx={{ flex: 2 }}>Student</Box>
              <Box sx={{ flex: 2 }}>Class</Box>
              <Box sx={{ flex: 1, display: { xs: 'none', sm: 'block' } }}>Date</Box>
              <Box sx={{ flex: 1 }}>Status</Box>
              <Box sx={{ flex: 1 }}>Score</Box>
              <Box sx={{ flex: 1, textAlign: 'right' }}>Actions</Box>
            </Box>
            
            {/* Table rows */}
            {submissions.map((submission) => (
              <Box 
                key={submission._id} 
                sx={{ 
                  display: 'flex', 
                  p: 1,
                  alignItems: 'center',
                  borderBottom: '1px solid',
                  borderColor: 'divider',
                  '&:hover': { bgcolor: 'action.hover' }
                }}
              >
                <Box sx={{ flex: 2, fontWeight: 'medium' }}>{submission.studentName}</Box>
                <Box sx={{ flex: 2 }}>{submission.className}</Box>
                <Box sx={{ flex: 1, display: { xs: 'none', sm: 'block' } }}>
                  {new Date(submission.submittedAt || submission.createdAt).toLocaleDateString()}
                </Box>
                <Box sx={{ flex: 1 }}>
                  {submission.status === 'graded' || submission.processed || (submission.answers && submission.answers.length > 0) ? (
                    <Box sx={{ display: 'flex', alignItems: 'center', color: 'success.main' }}>
                      <CheckIcon fontSize="small" sx={{ mr: 0.5 }} />
                      <Typography variant="body2">Graded</Typography>
                    </Box>
                  ) : (
                    <Typography variant="body2" color="text.secondary">Pending</Typography>
                  )}
                </Box>
                <Box sx={{ flex: 1, fontWeight: 'bold' }}>
                  {submission.score !== undefined ? (
                    <Typography 
                      variant="body2" 
                      fontWeight="bold" 
                      color={
                        submission.score >= 80 ? 'success.main' : 
                        submission.score >= 60 ? 'warning.main' : 'error.main'
                      }
                    >
                      {submission.score}%
                    </Typography>
                  ) : '—'}
                </Box>
                <Box sx={{ flex: 1, textAlign: 'right' }}>
                  <IconButton 
                    size="small" 
                    color="primary"
                    onClick={() => handleViewSubmission(submission)}
                    title="View"
                  >
                    <VisibilityIcon fontSize="small" />
                  </IconButton>
                  <IconButton 
                    size="small" 
                    color="error" 
                    onClick={() => handleDeleteSubmissionConfirm(submission)}
                    title="Delete"
                    sx={{ ml: 1 }}
                  >
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                </Box>
              </Box>
            ))}
          </Box>
        </Paper>
      ) : (
        <Box sx={{ textAlign: 'center', py: 4 }}>
          <PhotoCameraIcon sx={{ fontSize: 60, color: 'text.secondary', mb: 2 }} />
          <Typography variant="h6" color="text.secondary" gutterBottom>
            No Submissions Yet
          </Typography>
          <Typography variant="body1" color="text.secondary" sx={{ maxWidth: 500, mx: 'auto', mb: 3 }}>
            When students complete this worksheet, you can upload their work to automatically grade their answers.
          </Typography>
          <Button
            variant="outlined"
            startIcon={<UploadIcon />}
            onClick={handleOpenUpload}
          >
            Upload Submission
          </Button>
        </Box>
      )}

      {/* Upload Dialog */}
      <Dialog open={uploadOpen} onClose={handleCloseUpload} maxWidth="md" fullWidth>
        <DialogTitle sx={{ bgcolor: theme.palette.primary.lighter, pb: 2 }}>
          <Typography variant="h6" component="div">
            Upload Worksheet Submission
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Upload a photo of the completed worksheet
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Box sx={{ py: 3 }}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <Paper 
                  variant="outlined" 
                  sx={{ 
                    p: 3, 
                    height: '100%', 
                    display: 'flex', 
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    bgcolor: theme.palette.background.default,
                    border: '2px dashed',
                    borderColor: 'divider',
                    borderRadius: 2
                  }}
                >
                  <input
                    accept="image/*"
                    style={{ display: 'none' }}
                    id="upload-submission-button"
                    type="file"
                    onChange={handleFileChange}
                    disabled={uploadLoading}
                  />
                  <label htmlFor="upload-submission-button" style={{ width: '100%', textAlign: 'center' }}>
                    {!previewUrl ? (
                      <>
                        <PhotoCameraIcon sx={{ fontSize: 60, color: 'text.secondary', mb: 2 }} />
                        <Typography variant="h6" color="text.secondary" gutterBottom>
                          Take or Select a Photo
                        </Typography>
                        <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
                          Click here to take a photo or select an image from your device
                        </Typography>
                        <Button
                          variant="contained"
                          component="span"
                          startIcon={<PhotoCameraIcon />}
                          disabled={uploadLoading}
                        >
                          Select Image
                        </Button>
                      </>
                    ) : (
                      <Box
                        component="img"
                        src={previewUrl}
                        alt="Submission Preview"
                        sx={{
                          width: '100%',
                          maxHeight: '300px',
                          objectFit: 'contain',
                          cursor: 'pointer'
                        }}
                      />
                    )}
                  </label>
                  
                  {previewUrl && (
                    <Button 
                      variant="outlined" 
                      sx={{ mt: 2 }}
                      startIcon={<PhotoCameraIcon />}
                      component="span"
                    >
                      Change Image
                    </Button>
                  )}
                </Paper>
              </Grid>
              
              <Grid item xs={12} md={6}>
                <Paper variant="outlined" sx={{ p: 3, height: '100%', borderRadius: 2 }}>
                  <Typography variant="subtitle1" gutterBottom fontWeight="medium">
                    Submission Details
                  </Typography>
                  <Divider sx={{ mb: 2 }} />
                  
                  <Box sx={{ mb: 3 }}>
                    <Typography variant="body2" color="text.secondary" gutterBottom>
                      Class
                    </Typography>
                    <ClassSelector 
                      classes={classes}
                      selectedClass={selectedClass}
                      onChange={handleClassChange}
                      disabled={uploadLoading}
                      error={!selectedClass && error.includes('class')}
                    />
                  </Box>
                  
                  <Box sx={{ mb: 3 }}>
                    <Typography variant="body2" color="text.secondary" gutterBottom>
                      Student
                    </Typography>
                    <StudentSelector 
                      students={students}
                      selectedStudent={selectedStudent}
                      onChange={handleStudentChange}
                      disabled={uploadLoading || !selectedClass}
                      error={!selectedStudent && error.includes('student')}
                      helperText={!selectedClass ? "Select a class first" : ""}
                    />
                  </Box>
                  
                  <Box sx={{ mb: 2 }}>
                    <Typography variant="body2" color="text.secondary" gutterBottom>
                      Worksheet
                    </Typography>
                    <Typography variant="body1" fontWeight="medium">
                      {worksheet.title}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {worksheet.subject} - {worksheet.grade}
                    </Typography>
                  </Box>
                  
                  {error && (
                    <Alert severity="error" sx={{ mt: 2 }}>
                      {error}
                    </Alert>
                  )}
                </Paper>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions sx={{ px: 3, py: 2 }}>
          <Button onClick={handleCloseUpload} disabled={uploadLoading}>
            Cancel
          </Button>
          <Button
            onClick={handleUploadSubmission}
            variant="contained"
            color="primary"
            disabled={uploadLoading || !selectedFile || !selectedClass || !selectedStudent}
            startIcon={uploadLoading ? <CircularProgress size={20} /> : <UploadIcon />}
            sx={{ px: 3 }}
          >
            {uploadLoading ? 'Uploading...' : 'Upload Submission'}
          </Button>
        </DialogActions>
      </Dialog>

      {/* View Submission Dialog */}
      <Dialog open={viewSubmissionOpen} onClose={handleCloseViewSubmission} maxWidth="md" fullWidth>
        {selectedSubmission && (
          <>
            <DialogTitle>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Box>
                  <Typography variant="h6">
                    Submission by {selectedSubmission.studentName}
                  </Typography>
                  <Typography variant="caption" color="text.secondary">
                    {selectedSubmission.className} • {new Date(selectedSubmission.submittedAt || selectedSubmission.createdAt).toLocaleString()} • 
                    <Typography component="span" variant="caption" color={
                      selectedSubmission.status === 'graded' || 
                      selectedSubmission.processed || 
                      (selectedSubmission.answers && selectedSubmission.answers.length > 0) ? 
                        'success.main' : 'text.secondary'
                    }>
                      {selectedSubmission.status === 'graded' || selectedSubmission.processed || (selectedSubmission.answers && selectedSubmission.answers.length > 0) ? 'Graded' : 'Pending'}
                    </Typography>
                    {selectedSubmission.score !== undefined && (
                      <Typography component="span" variant="caption" fontWeight="bold" color={
                        selectedSubmission.score >= 80 ? 'success.main' : 
                        selectedSubmission.score >= 60 ? 'warning.main' : 'error.main'
                      } sx={{ ml: 1 }}>
                        Score: {selectedSubmission.score}%
                      </Typography>
                    )}
                  </Typography>
                </Box>
                <IconButton onClick={handleCloseViewSubmission}>
                  <CloseIcon />
                </IconButton>
              </Box>
            </DialogTitle>
            <DialogContent sx={{ py: 1 }}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, gap: 2, mb: 2 }}>
                    <Box sx={{ flex: '0 0 auto', maxWidth: { xs: '100%', sm: '40%' } }}>
                      <Box
                        component="img"
                        src={selectedSubmission.imageUrl || '/placeholder-image.jpg'}
                        alt={`Submission by ${selectedSubmission.studentName}`}
                        sx={{
                          width: '100%',
                          border: '1px solid',
                          borderColor: 'divider',
                          borderRadius: 1
                        }}
                      />
                    </Box>
                    
                    <Box sx={{ flex: '1 1 auto' }}>
                      {selectedSubmission.status === 'graded' && selectedSubmission.feedback && (
                        <Box sx={{ mb: 2 }}>
                          <Typography variant="subtitle2" sx={{ fontSize: '0.85rem', mb: 0.5 }}>
                            Overall Feedback
                          </Typography>
                          <Box sx={{ 
                            fontSize: '0.75rem',
                            fontStyle: 'italic',
                            color: 'text.secondary',
                            pl: 1,
                            py: 0.5,
                            borderLeft: '2px solid',
                            borderColor: 'primary.light'
                          }}>
                            {selectedSubmission.feedback}
                          </Box>
                        </Box>
                      )}
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12}>


                  
                  {(selectedSubmission.status === 'graded' || selectedSubmission.processed || (selectedSubmission.answers && selectedSubmission.answers.length > 0)) && (
                    <Box sx={{ mt: 1.5 }}>
                      <Typography variant="subtitle2" sx={{ fontSize: '0.85rem', mb: 0.5 }}>
                        Grading Results
                      </Typography>
                      <Paper sx={{ p: 0.75 }}>
                        {/* Use results if available, otherwise use answers */}
                        {(selectedSubmission.results || []).length > 0 ? (
                          // Display results format - ultra compact table-like
                          selectedSubmission.results.map((result, index) => (
                            <Box key={index} sx={{ 
                              py: 0.25, 
                              borderBottom: index < selectedSubmission.results.length - 1 ? "1px solid" : "none", 
                              borderColor: 'divider'
                            }}>
                              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                {/* Status and Question Number */}
                                <Typography 
                                  variant="caption" 
                                  sx={{ 
                                    fontWeight: 'bold',
                                    color: result.correct ? 'success.main' : 'error.main',
                                    minWidth: '30px'
                                  }}
                                >
                                  {result.correct ? '✓' : '✗'} Q{index + 1}
                                </Typography>
                                
                                {/* Student Answer */}
                                <Box sx={{ flex: 1 }}>
                                  <Typography variant="caption" sx={{ fontWeight: 'medium' }}>
                                    {/* Display the question if available */}
                                    {worksheetQuestions[index] && (
                                      <Typography component="span" variant="caption" color="text.secondary" sx={{ display: 'block', mb: 0.5 }}>
                                        <strong>{worksheetQuestions[index].questionText}</strong>
                                      </Typography>
                                    )}
                                    {result.studentAnswer || "No answer"}
                                  </Typography>
                                  
                                  {/* Feedback as a subtitle */}
                                  {result.feedback && (
                                    <Typography 
                                      variant="caption" 
                                      sx={{ 
                                        display: 'block',
                                        fontStyle: 'italic', 
                                        color: 'text.secondary',
                                        fontSize: '0.7rem'
                                      }}
                                    >
                                      {result.feedback}
                                    </Typography>
                                  )}
                                </Box>
                                
                                {/* Points */}
                                <Typography 
                                  variant="caption" 
                                  sx={{ 
                                    fontWeight: 'bold',
                                    color: result.correct ? 'success.main' : 'error.main',
                                    minWidth: '24px',
                                    textAlign: 'right'
                                  }}
                                >
                                  {result.correct ? result.pointsPossible : 0}/{result.pointsPossible}
                                </Typography>
                              </Box>
                            </Box>
                          ))
                        ) : (
                          // Display answers format - ultra compact table-like
                          (selectedSubmission.answers || []).map((answer, index) => (
                            <Box key={index} sx={{ 
                              py: 0.25, 
                              borderBottom: index < selectedSubmission.answers.length - 1 ? "1px solid" : "none", 
                              borderColor: 'divider'
                            }}>
                              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                {/* Status and Question Number */}
                                <Typography 
                                  variant="caption" 
                                  sx={{ 
                                    fontWeight: 'bold',
                                    color: answer.isCorrect ? 'success.main' : 'error.main',
                                    minWidth: '30px'
                                  }}
                                >
                                  {answer.isCorrect ? '✓' : '✗'} Q{index + 1}
                                </Typography>
                                
                                {/* Student Answer */}
                                <Box sx={{ flex: 1 }}>
                                  <Typography variant="caption" sx={{ fontWeight: 'medium' }}>
                                    {/* Display the question if available */}
                                    {worksheetQuestions[index] && (
                                      <Typography component="span" variant="caption" color="text.secondary" sx={{ display: 'block', mb: 0.5 }}>
                                        <strong>{worksheetQuestions[index].questionText}</strong>
                                      </Typography>
                                    )}
                                    {answer.studentAnswer || "No answer"}
                                    {answer.correctAnswer && answer.studentAnswer !== answer.correctAnswer && (
                                      <Typography component="span" variant="caption" color="success.main" sx={{ ml: 0.5 }}>
                                        → {answer.correctAnswer}
                                      </Typography>
                                    )}
                                  </Typography>
                                  
                                  {/* Feedback as a subtitle */}
                                  {answer.feedback && (
                                    <Typography 
                                      variant="caption" 
                                      sx={{ 
                                        display: 'block',
                                        fontStyle: 'italic', 
                                        color: 'text.secondary',
                                        fontSize: '0.7rem'
                                      }}
                                    >
                                      {answer.feedback}
                                    </Typography>
                                  )}
                                </Box>
                                
                                {/* Points */}
                                <Typography 
                                  variant="caption" 
                                  sx={{ 
                                    fontWeight: 'bold',
                                    color: answer.isCorrect ? 'success.main' : 'error.main',
                                    minWidth: '24px',
                                    textAlign: 'right'
                                  }}
                                >
                                  {answer.isCorrect ? (answer.pointsPossible || 1) : 0}/{answer.pointsPossible || 1}
                                </Typography>
                              </Box>
                            </Box>
                          ))
                        )}
                      </Paper>
                    </Box>
                  )}

                  {selectedSubmission.status !== 'graded' && !selectedSubmission.processed && !(selectedSubmission.answers && selectedSubmission.answers.length > 0) && (
                    <Box sx={{ mt: 3 }}>
                      <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        onClick={() => handleProcessSubmission(selectedSubmission._id)}
                        disabled={loading}
                        startIcon={loading ? <CircularProgress size={20} /> : null}
                      >
                        {loading ? "Processing..." : "Process Submission"}
                      </Button>
                      <Typography variant="caption" color="text.secondary" sx={{ display: "block", mt: 1, textAlign: "center" }}>
                        This will use AI to grade the submission automatically
                      </Typography>
                    </Box>
                  )}
                </Grid>
              </Grid>
            </DialogContent>
          </>
        )}
      </Dialog>

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={deleteConfirmOpen}
        onClose={handleDeleteCancel}
        aria-labelledby="delete-dialog-title"
        aria-describedby="delete-dialog-description"
      >
        <DialogTitle id="delete-dialog-title">
          Confirm Deletion
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            Are you sure you want to delete this submission? This action cannot be undone.
          </Typography>
          {submissionToDelete && (
            <Box sx={{ mt: 2 }}>
              <Typography variant="body2" color="text.secondary">
                Student: {submissionToDelete.studentName}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Class: {submissionToDelete.className}
              </Typography>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteCancel} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteConfirm} color="error" variant="contained">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default WorksheetSubmissions;