import React from 'react';
import {
    List,
    ListItem,
    ListItemText,
    ListItemSecondaryAction,
    IconButton,
    Typography,
    Box,
    Tooltip,
    Chip,
    Collapse,
} from '@mui/material';
import {
    Edit as EditIcon,
    Delete as DeleteIcon,
    Schedule as ScheduleIcon,
    ExpandMore as ExpandMoreIcon,
    ExpandLess as ExpandLessIcon,
} from '@mui/icons-material';

const TimetableList = ({
    timetables,
    onEdit,
    onDelete,
    expandedId,
    onToggleExpand
}) => {
    const formatTime = (time) => {
        return new Date(`2000-01-01T${time}`).toLocaleTimeString([], { 
            hour: '2-digit', 
            minute: '2-digit',
            hour12: true 
        });
    };

    return (
        <List sx={{
            width: '100%',
            bgcolor: 'background.paper',
            borderRadius: 1,
            border: '1px solid',
            borderColor: 'divider',
            '& .MuiListItem-root': {
                borderBottom: '1px solid',
                borderColor: 'divider',
                '&:last-child': {
                    borderBottom: 'none'
                }
            }
        }}>
            {timetables.length > 0 ? (
                timetables.map((timetable) => (
                    <React.Fragment key={timetable._id}>
                        <ListItem
                            sx={{
                                py: 1.5,
                                '&:hover': {
                                    bgcolor: 'action.hover',
                                },
                            }}
                        >
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                                <ScheduleIcon sx={{ color: 'primary.main' }} />
                                <ListItemText
                                    primary={
                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                            <Typography variant="subtitle1" component="div">
                                                {timetable.name}
                                            </Typography>
                                            <Chip 
                                                label={`${timetable.blocks.length} periods`}
                                                size="small"
                                                sx={{ 
                                                    bgcolor: 'primary.lighter',
                                                    color: 'primary.main',
                                                    fontWeight: 500
                                                }}
                                            />
                                        </Box>
                                    }
                                    secondary={
                                        <Typography variant="body2" color="text.secondary">
                                            {formatTime(timetable.blocks[0].startTime)} - {formatTime(timetable.blocks[timetable.blocks.length - 1].endTime)}
                                        </Typography>
                                    }
                                />
                            </Box>
                            <ListItemSecondaryAction>
                                <Box sx={{ display: 'flex', gap: 1 }}>
                                    <Tooltip title="Edit timetable">
                                        <IconButton
                                            edge="end"
                                            onClick={() => onEdit(timetable)}
                                            size="small"
                                            sx={{
                                                color: 'primary.main',
                                                '&:hover': {
                                                    bgcolor: 'primary.lighter',
                                                }
                                            }}
                                        >
                                            <EditIcon />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Delete timetable">
                                        <IconButton
                                            edge="end"
                                            onClick={() => onDelete(timetable._id)}
                                            size="small"
                                            sx={{
                                                color: 'error.main',
                                                '&:hover': {
                                                    bgcolor: 'error.lighter',
                                                }
                                            }}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title={expandedId === timetable._id ? "Show less" : "Show more"}>
                                        <IconButton
                                            edge="end"
                                            onClick={() => onToggleExpand(timetable._id)}
                                            size="small"
                                            sx={{
                                                color: 'text.secondary',
                                                transform: expandedId === timetable._id ? 'rotate(180deg)' : 'none',
                                                transition: 'transform 0.2s',
                                            }}
                                        >
                                            <ExpandMoreIcon />
                                        </IconButton>
                                    </Tooltip>
                                </Box>
                            </ListItemSecondaryAction>
                        </ListItem>
                        <Collapse in={expandedId === timetable._id}>
                            <Box sx={{ 
                                p: 2, 
                                bgcolor: 'action.hover',
                                borderBottom: '1px solid',
                                borderColor: 'divider'
                            }}>
                                <Typography variant="subtitle2" sx={{ mb: 1, color: 'text.secondary' }}>
                                    Time Blocks
                                </Typography>
                                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                                    {timetable.blocks.map((block, index) => (
                                        <Box
                                            key={index}
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                gap: 2,
                                                p: 1,
                                                borderRadius: 1,
                                                bgcolor: 'background.paper',
                                                border: '1px solid',
                                                borderColor: 'divider'
                                            }}
                                        >
                                            <Typography 
                                                variant="body2"
                                                sx={{ 
                                                    fontWeight: 600,
                                                    width: 80
                                                }}
                                            >
                                                {block.period}
                                            </Typography>
                                            <Typography variant="body2" color="text.secondary">
                                                {formatTime(block.startTime)} - {formatTime(block.endTime)}
                                            </Typography>
                                        </Box>
                                    ))}
                                </Box>
                            </Box>
                        </Collapse>
                    </React.Fragment>
                ))
            ) : (
                <ListItem>
                    <ListItemText
                        primary={
                            <Box sx={{
                                textAlign: 'center',
                                py: 4,
                                color: 'text.secondary'
                            }}>
                                <Typography variant="body1">
                                    No timetables available
                                </Typography>
                                <Typography variant="body2" sx={{ mt: 1 }}>
                                    Create a new timetable to get started
                                </Typography>
                            </Box>
                        }
                    />
                </ListItem>
            )}
        </List>
    );
};

export default TimetableList;