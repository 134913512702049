import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate, Link } from 'react-router-dom';
import { setToken } from '../../auth';
import { userStorage } from '../../utils/userStorage';
import { useSchool } from '../../context';
import SchoolSelector from '../School/SchoolSelector';
import { CircularProgress } from '@mui/material';
import '../../css/Login.css';
import mascotImage from '../../assets/bg-guy.png';

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [loadingMessage, setLoadingMessage] = useState('');
    const [selectedSchool, setSelectedSchool] = useState(null);
    const [availableSchools, setAvailableSchools] = useState([]);
    const [showSchoolSelect, setShowSchoolSelect] = useState(false);
    const navigate = useNavigate();
    const { loadSchoolData, switchSchool } = useSchool();

    const handleLogin = async (e) => {
        e.preventDefault();

        if (!email || !password) {
            alert('Please fill in all fields.');
            return;
        }

        setLoading(true);
        setLoadingMessage('Authenticating...');

        try {
            // First, authenticate the user
            const response = await axios.post(
                `${process.env.REACT_APP_BASE_URL}/api/auth/login`,
                { email, password }
            );

            const { token, refreshToken, user } = response.data;

            // Store auth data
            const userData = {
                ...user,
                id: user._id || user.id, // Ensure we have id field
                currentSchool: user.currentSchool
            };
            console.log('Storing user data:', userData);
            
            localStorage.setItem('token', token);
            localStorage.setItem('refreshToken', refreshToken);
            localStorage.setItem('user', JSON.stringify(userData));

            // Fetch available schools
            try {
                setLoadingMessage('Loading schools...');
                const schoolsResponse = await axios.get(
                    `${process.env.REACT_APP_BASE_URL}/api/user/schools`,
                    {
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    }
                );

                const { schools } = schoolsResponse.data;
                if (schools && schools.length > 0) {
                    setAvailableSchools(schools);
                    if (schools.length === 1) {
                        // Auto-select if only one school
                        setLoadingMessage('Setting up your account...');
                        await handleSchoolSelection(schools[0], { token, refreshToken, user });
                    } else {
                        // Show selector for multiple schools
                        setLoading(false);
                        setLoadingMessage('');
                        setShowSchoolSelect(true);
                    }
                } else {
                    setLoading(false);
                    setLoadingMessage('');
                    alert('No schools assigned to your account. Please contact your administrator.');
                }
            } catch (error) {
                console.error('Error fetching schools:', error);
                alert('Failed to fetch schools. Please try again.');
            }
        } catch (error) {
            console.error('Login error:', error);
            const errorMessage = error.response?.data?.message || 'Login failed. Please check your credentials.';
            alert(errorMessage);
        } finally {
            setLoading(false);
        }
    };

    const handleSchoolSelection = async (school, auth) => {
        try {
            setLoading(true);
            setLoadingMessage('Setting up your school...');
            
            // Switch to selected school and get new token
            const result = await switchSchool(school.id);
            const { token: newToken, refreshToken: newRefreshToken } = result;

            setLoadingMessage('Preparing your dashboard...');

            // Update user data with new token info
            const userData = JSON.parse(localStorage.getItem('user'));
            userData.currentSchool = school;  // Store the full school object
            
            // Set new tokens and updated user data
            setToken(newToken || auth.token, newRefreshToken || auth.refreshToken, userData);
            navigate('/dash');
        } catch (error) {
            console.error('Error selecting school:', error);
            alert('Failed to select school. Please try again.');
            setLoading(false);
            setLoadingMessage('');
        }
    };

    return (
        <div className="login-page">
            <div className="login-container">
                <img src={mascotImage} alt="Mascot" className="mascot-login" />
                <h2 className="login-title">Tammoo Manager: Login</h2>
                <form onSubmit={handleLogin} className="login-form">
                    <input
                        type="email"
                        placeholder="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                        className="login-input"
                        disabled={loading}
                    />
                    <input
                        type="password"
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                        className="login-input"
                        disabled={loading}
                    />
                    <button type="submit" className="login-button" disabled={loading}>
                        {loading ? (
                            <div className="button-content">
                                <CircularProgress size={20} sx={{ color: 'white', mr: 1 }} />
                                <span>{loadingMessage || 'Loading...'}</span>
                            </div>
                        ) : 'Login'}
                    </button>
                </form>
                <Link to="/forgot-password" className="login-link">Forgot Password?</Link>
            </div>

            {/* School Selector Dialog */}
            <SchoolSelector
                open={showSchoolSelect}
                schools={availableSchools}
                onSelect={(school) => handleSchoolSelection(school, {
                    token: localStorage.getItem('token'),
                    refreshToken: localStorage.getItem('refreshToken'),
                    user: JSON.parse(localStorage.getItem('user'))
                })}
                onClose={() => setShowSchoolSelect(false)}
                loading={loading}
                title="Select Your School"
            />
        </div>
    );
};

export default Login;