import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import { ThemeProvider as MuiThemeProvider, createTheme } from '@mui/material/styles';
import { getThemeConfig } from './theme/muiTheme';
import Login from './components/Login/Login';
import Register from './components/Login/Register';
import Dash from './components/Dash/Dash';
import ForgotPassword from './components/Login/ForgotPassword';
import ResetPassword from './components/Login/ResetPassword';
import ClassManager from './components/Classes/ClassManager';
import Reports from './components/Reports/Reports';
import ProtectedRoute from './components/ProtectedRoute';
import AdminProtectedRoute from './routes/AdminProtectedRoutes';
import Navbar from './components/Navbar';
import LandingPage from './components/Landing/LandingPage';
import './App.css';
import TermManager from './components/Terms/TermManager';
import { ThemeProvider, SchoolProvider } from './context';
import ContactForm from './components/Login/ContactForm';
import TagManager from 'react-gtm-module';
import Feedback from './components/feedback/Feedback';
import { isAuthenticated } from "./auth";
import { usePageTracking } from './hooks/usePageTracking';
import NewsletterEditor from './components/newsletter/NewsLetterEditor';
import SyllabusView from "./components/Syllabus/SyllabusView";
import Generate from "./components/Syllabus/Generate";
import Messaging from "./components/Messaging/Messaging";
import Chat from "./components/Chat/Chat";
import AdminLayout from "./components/Admin/AdminLayout";
import SchoolsManager from "./components/Admin/SchoolsManager";
import UsersManager from "./components/Admin/UsersManager";
import AnalyticsDashboard from "./components/Admin/Analytics/AnalyticsDashboard";
import MobileAnalyticsDashboard from "./components/Admin/Analytics/MobileAnalyticsDashboard";
import TextLeveling from "./components/TextLeveling/TextLeveling";
import WorksheetManager from "./components/Worksheets/WorksheetManager";

const AppContent = () => {
    const location = useLocation();
    usePageTracking(); // Add page tracking

    React.useEffect(() => {
        TagManager.dataLayer({
            dataLayer: {
                event: 'pageview',
                page: location.pathname,
            },
        });
    }, [location]);


    return (
        <>
            <Navbar />
            <div className="content">
                <Routes>
                    <Route path="/" element={<LandingPage />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/register" element={<Register />} />
                    <Route path="/forgot-password" element={<ForgotPassword />} />
                    <Route path="/reset-password/:token" element={<ResetPassword />} />
                    <Route path="/dash" element={<ProtectedRoute element={<Dash />} />} />
                    <Route path="/classes" element={<ProtectedRoute element={<ClassManager />} />} />
                    <Route path="/reports" element={<ProtectedRoute element={<Reports />} />} />
                    <Route path="/terms" element={<ProtectedRoute element={<TermManager />} />} />
                    <Route path="/contact" element={<ContactForm />} />
                    <Route path="/syllabus" element={<ProtectedRoute element={<SyllabusView />} />} />
                    <Route path="/generate" element={<ProtectedRoute element={<Generate />} />} />
                    <Route path="/chat" element={<ProtectedRoute element={<Chat />} />} />
                    <Route path="/text-leveling" element={<ProtectedRoute element={<TextLeveling />} />} />
                    <Route path="/worksheets" element={<ProtectedRoute element={<WorksheetManager />} />} />

                    {/* Admin Protected Routes */}
                    <Route path="/admin" element={<AdminProtectedRoute element={<AdminLayout />} />}>
                        <Route path="schools" element={<SchoolsManager />} />
                        <Route path="users" element={<UsersManager />} />
                        <Route path="analytics" element={<AnalyticsDashboard />} />
                        <Route path="mobile-analytics" element={<MobileAnalyticsDashboard />} />
                    </Route>
                    <Route path="/newsletter" element={<AdminProtectedRoute element={<NewsletterEditor />} />} />
                    <Route path="/messaging" element={<AdminProtectedRoute element={<Messaging />} />} />
                </Routes>
            </div>
            {isAuthenticated() && <Feedback />}
        </>
    );
};

const App = () => {
    const [muiTheme, setMuiTheme] = useState(() => createTheme(getThemeConfig()));

    // Update MUI theme when CSS variables change
    useEffect(() => {
        const observer = new MutationObserver(() => {
            setMuiTheme(createTheme(getThemeConfig()));
        });

        observer.observe(document.documentElement, {
            attributes: true,
            attributeFilter: ['style']
        });

        return () => observer.disconnect();
    }, []);

    React.useEffect(() => {
        TagManager.initialize({ gtmId: 'G-00N0B37RSG' });
    }, []);

    return (
        <ThemeProvider>
            <MuiThemeProvider theme={muiTheme}>
                <SchoolProvider>
                    <React.StrictMode>
                        <Router>
                            <AppContent />
                        </Router>
                    </React.StrictMode>
                </SchoolProvider>
            </MuiThemeProvider>
        </ThemeProvider>
    );
};

export default App;
