import React, { useState, useRef, useEffect } from 'react';
import { 
    Container, 
    Paper, 
    Snackbar, 
    Alert, 
    Box, 
    Dialog,
    Button,
    IconButton,
    Stack,
    Typography,
    useTheme,
    useMediaQuery,
    Drawer,
    Fab
} from '@mui/material';
import { 
    Close as CloseIcon,
    Menu as MenuIcon,
    History as HistoryIcon
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { protectedFetch, protectedApiCall } from '../../../auth';
import { trackUserAction } from '../../../hooks/usePageTracking';
import { getTemplateConfig } from '../TemplateSelector';
import { templates } from '../TemplateSelector';
import ResultsDialog from './DialogContent';
// Removed docx imports
import SearchSection from './SearchSection';
import PreviousCreations from './PreviousCreations';

const loadingMessages = [
    "Sending to the Syllabus Library",
    "Hmm... pondering your Question",
    "Stitching together a great query",
    "Asking the machine to do some thinking",
    "gosh, that takes an age....",
    "How has your day been going",
    "About that weather, hey!",
    "Have you got plans this weekend",
    "... Ok, Great, we're Done!",
    "Potentially ??",
    "How did I get this so wrong?",
    "I really have to evaluate things better",
    "Next time I will be more thorough, promise",
    "... I am going to have to talk to the engineers",
    "this is crazy, I am so sorry",
    "I'm not sure what's actually going on back there"
];

const Generate = () => {
    console.log('Generate component mounting');
    const navigate = useNavigate();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const [query, setQuery] = useState('');
    const [selectedSubject, setSelectedSubject] = useState(null);
    const [selectedStage, setSelectedStage] = useState(null);
    const [selectedTemplate, setSelectedTemplate] = useState('');
    const [options, setOptions] = useState({
        includeOutcomesInPrompt: true,
        includeOutcomesInResponse: true,
        includeBiblicalOutcomesInPrompt: true,
        includeBiblicalOutcomesInResponse: true
    });
    const [loading, setLoading] = useState(false);
    const [aiResponse, setAIResponse] = useState('');
    const [aiResponses, setAiResponses] = useState([]);
    const [processingResponses, setProcessingResponses] = useState([]);
    const [toast, setToast] = useState({ visible: false, message: '' });
    const [loadingMessage, setLoadingMessage] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const textareaRef = useRef(null);

    const loadSavedSearches = async () => {
        console.log('Loading saved searches...');
        try {
            const response = await protectedFetch(async () => {
                const token = localStorage.getItem('token');
                console.log('Making request to load saved searches');
                const res = await fetch(`${process.env.REACT_APP_BASE_URL}/api/syllabus/saved-searches`, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                });

                if (!res.ok) {
                    throw new Error(`Failed to load saved searches: ${res.status} ${res.statusText}`);
                }

                return res.json();
            });

            console.log('Saved searches loaded:', response);
            // Format the responses
            const formattedResponses = response.map(search => ({
                id: search._id || search.id,
                title: search.query, // Use query as title
                content: typeof search.aiResponse === 'string' ? search.aiResponse : JSON.stringify(search.aiResponse, null, 2),
                query: search.query,
                subject: search.subject,
                stage: search.stage,
                timestamp: search.createdAt || new Date().toISOString()
            }));

            setAiResponses(formattedResponses);
        } catch (err) {
            console.error('Error loading saved searches:', err);
            showToast('Failed to load saved searches');
        }
    };

    // Load saved searches on mount
    useEffect(() => {
        loadSavedSearches();
    }, []);

    // Track subject selection
    useEffect(() => {
        if (selectedSubject) {
            trackUserAction(
                '/syllabus/generate',
                'Generate Document',
                'syllabus',
                'select_subject',
                {
                    subject: selectedSubject,
                    stage: selectedStage || null
                }
            ).catch(err => console.error('Error tracking subject selection:', err));
        }
    }, [selectedSubject, selectedStage]);

    useEffect(() => {
        const config = getTemplateConfig(selectedTemplate);
        if (config) {
            setOptions(config);
            // Track template selection
            trackUserAction(
                '/syllabus/generate',
                'Generate Document',
                'syllabus',
                'select_template',
                {
                    template: selectedTemplate,
                    templateConfig: config
                }
            ).catch(err => console.error('Error tracking template selection:', err));
        }
    }, [selectedTemplate]);

    useEffect(() => {
        let messageIndex = 0;
        let interval;

        if (loading) {
            setLoadingMessage(loadingMessages[0]);
            interval = setInterval(() => {
                messageIndex = (messageIndex + 1) % loadingMessages.length;
                setLoadingMessage(loadingMessages[messageIndex]);
            }, 2400);
        }

        return () => clearInterval(interval);
    }, [loading]);

    const showToast = (message) => {
        setToast({ visible: true, message });
        setTimeout(() => {
            setToast({ visible: false, message: '' });
        }, 4000);
    };

    const getTemplate = (templateId) => {
        return templates.find(t => t.id === templateId);
    };

    const handleUsePrompt = (promptText, subject, stage) => {
        console.log('Using previous prompt:', { promptText, subject, stage });
        
        // Ask for confirmation before replacing the current prompt
        if (query.trim() && !window.confirm('This will replace your current prompt. Continue?')) {
            return; // User canceled
        }
        
        setQuery(promptText);
        
        // If subject is provided, set it
        if (subject) {
            setSelectedSubject(subject);
        }
        
        // If stage is provided, set it
        if (stage) {
            setSelectedStage(stage);
        }
        
        // Show a toast notification
        showToast('Previous prompt loaded');
        
        // If on mobile, close the drawer
        if (isMobile) {
            setDrawerOpen(false);
        }
        
        // Track this action
        trackUserAction(
            '/syllabus/generate',
            'Generate Document',
            'syllabus',
            'use_previous_prompt',
            {
                subject: subject || selectedSubject,
                stage: stage || selectedStage
            }
        ).catch(err => console.error('Error tracking use previous prompt:', err));
    };

    const handleSearch = async () => {
        console.log('Starting search with:', { query, selectedSubject, selectedStage, selectedTemplate });
        if (!query.trim() || !selectedSubject || !selectedStage || !selectedTemplate) {
            showToast('Please select a Subject, Stage and Template type before searching');
            return;
        }

        setLoading(true);
        
        // Track document generation attempt
        await trackUserAction(
            '/syllabus/generate',
            'Generate Document',
            'syllabus',
            'generate_start',
            {
                subject: selectedSubject,
                stage: selectedStage,
                template: selectedTemplate,
                queryLength: query.length
            }
        );
        const processingId = Date.now().toString(); // Only used for UI tracking
        
        const processingItem = {
            id: processingId, // Temporary ID just for UI state
            title: 'Working...',
            content: '',
            query,
            subject: selectedSubject,
            stage: selectedStage,
            templateType: selectedTemplate,
            timestamp: new Date().toISOString()
        };
        console.log('Adding processing item:', processingItem);
        setProcessingResponses(prev => [processingItem, ...prev]);
        showToast('Search submitted - processing will take a few moments');

        try {
            const fullTemplate = getTemplate(selectedTemplate);
            if (!fullTemplate) {
                throw new Error('Selected template not found');
            }

            console.log('Sending request with:', { query, selectedSubject, selectedStage, template: fullTemplate, options });
            const response = await protectedApiCall('/api/syllabus/vector-search', 'POST', {
                query,
                subject: selectedSubject,
                stage: selectedStage,
                template: fullTemplate,  // Send the entire template object
                options
            });
            console.log('Received response:', response);

            // Use the query as the title instead of extracting from response
            const title = query;

            console.log('Processing completed response:', {
                title,
                aiResponse: response.data.aiResponse,
                formattedPrompt: response.data.formattedPrompt,
                outcomes: response.data.outcomes
            });

            // Remove from processing list
            setProcessingResponses(prev => prev.filter(item => item.id !== processingId));

            // Get the saved response from the backend
            await loadSavedSearches();
            setAIResponse(response.data.aiResponse);
            showToast('Content generated successfully');

            // Track successful generation
            await trackUserAction(
                '/syllabus/generate',
                'Generate Document',
                'syllabus',
                'generate_success',
                {
                    subject: selectedSubject,
                    stage: selectedStage,
                    template: selectedTemplate,
                    responseLength: response.data.aiResponse.length,
                    processingTime: Date.now() - processingId
                }
            );
        } catch (err) {
            setProcessingResponses(prev => prev.filter(r => r.id !== processingId));
            showToast(err.message || 'Failed to generate content');

            // Track generation failure
            await trackUserAction(
                '/syllabus/generate',
                'Generate Document',
                'syllabus',
                'generate_error',
                {
                    subject: selectedSubject,
                    stage: selectedStage,
                    template: selectedTemplate,
                    error: err.message || 'Unknown error'
                }
            );
        } finally {
            setLoading(false);
        }
    };

    // Removed downloadAsDocx function

    // Handler for toggling the drawer
    const toggleDrawer = () => {
        setDrawerOpen(!drawerOpen);
    };

    // Previous creations component - reused in both desktop and mobile views
    const previousCreationsComponent = (
        <PreviousCreations 
            aiResponses={aiResponses}
            processingResponses={processingResponses}
            loading={loading}
            loadingMessage={loadingMessage}
            onDelete={async (id, title) => {
                console.log('Deleting creation:', id, title);
                if (window.confirm(`Are you sure you want to delete "${title}"?`)) {
                    try {
                        await protectedFetch(async () => {
                            const token = localStorage.getItem('token');
                            const res = await fetch(`${process.env.REACT_APP_BASE_URL}/api/syllabus/saved-searches/${id}`, {
                                method: 'DELETE',
                                headers: {
                                    'Authorization': `Bearer ${token}`
                                }
                            });

                            if (!res.ok) {
                                throw new Error(`Failed to delete creation: ${res.status} ${res.statusText}`);
                            }
                        });

                        console.log('Creation deleted successfully');
                        await loadSavedSearches(); // Reload the list after deletion
                        showToast('Creation deleted successfully');
                    } catch (err) {
                        console.error('Error deleting creation:', err);
                        showToast('Failed to delete creation');
                    }
                }
            }}
            onView={(response) => {
                setAIResponse(response);
                if (isMobile) {
                    setDrawerOpen(false); // Close drawer on mobile when viewing a response
                }
            }}
            onModalOpen={() => setIsModalOpen(true)}
            onUsePrompt={handleUsePrompt}
            isMobile={isMobile}
        />
    );

    return (
        <Container maxWidth="xl" sx={{ py: 4, position: 'relative' }}>
            <Snackbar
                open={toast.visible}
                autoHideDuration={4000}
                onClose={() => setToast({ visible: false, message: '' })}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                sx={{ 
                    marginTop: '64px' // Add margin to position below NavBar
                }}
            >
                <Alert severity="success" variant="filled" onClose={() => setToast({ visible: false, message: '' })}>
                    {toast.message}
                </Alert>
            </Snackbar>

            {/* Desktop Layout */}
            {!isMobile && (
                <Box sx={{ 
                    display: 'flex', 
                    gap: 3, 
                    mb: 3,
                    height: 'calc(100vh - 200px)', // Fixed height for desktop
                    flexWrap: 'nowrap'
                }}>
                    <Paper
                        elevation={2}
                        sx={{
                            p: 3,
                            borderRadius: 2,
                            bgcolor: theme.palette.background.paper,
                            display: 'flex',
                            flexDirection: 'column',
                            height: '100%',
                            width: 'fit-content',
                            overflow: 'hidden'
                        }}
                    >
                        {previousCreationsComponent}
                    </Paper>

                    <Paper 
                        elevation={2} 
                        sx={{ 
                            p: 3, 
                            borderRadius: 2,
                            flex: 1,
                            bgcolor: theme.palette.background.paper,
                            minWidth: 0,
                            display: 'flex',
                            flexDirection: 'column',
                            height: 'calc(100vh - 200px)', // Fixed height
                            overflow: 'auto', // Enable scrolling
                            '&::-webkit-scrollbar': {
                                width: '8px',
                                height: '8px'
                            },
                            '&::-webkit-scrollbar-thumb': {
                                backgroundColor: theme.palette.divider,
                                borderRadius: '4px'
                            },
                            '&::-webkit-scrollbar-track': {
                                backgroundColor: 'transparent'
                            }
                        }}
                    >
                        <SearchSection 
                            query={query}
                            selectedSubject={selectedSubject}
                            selectedStage={selectedStage}
                            selectedTemplate={selectedTemplate}
                            textareaRef={textareaRef}
                            onQueryChange={setQuery}
                            onSubjectChange={setSelectedSubject}
                            onStageChange={setSelectedStage}
                            onTemplateChange={setSelectedTemplate}
                            options={options}
                            onOptionsChange={setOptions}
                            loading={loading}
                            onSubmit={handleSearch}
                            isMobile={isMobile}
                        />
                    </Paper>
                </Box>
            )}

            {/* Mobile Layout */}
            {isMobile && (
                <>
                    <Paper 
                        elevation={2} 
                        sx={{ 
                            p: 2, 
                            borderRadius: 2,
                            bgcolor: theme.palette.background.paper,
                            minWidth: 0,
                            display: 'flex',
                            flexDirection: 'column',
                            height: 'auto',
                            minHeight: 'calc(100vh - 180px)', // Adjusted for mobile
                            overflow: 'auto',
                            mb: 8, // Add margin at bottom for FAB
                            '&::-webkit-scrollbar': {
                                width: '8px',
                                height: '8px'
                            },
                            '&::-webkit-scrollbar-thumb': {
                                backgroundColor: theme.palette.divider,
                                borderRadius: '4px'
                            },
                            '&::-webkit-scrollbar-track': {
                                backgroundColor: 'transparent'
                            }
                        }}
                    >
                        <SearchSection 
                            query={query}
                            selectedSubject={selectedSubject}
                            selectedStage={selectedStage}
                            selectedTemplate={selectedTemplate}
                            textareaRef={textareaRef}
                            onQueryChange={setQuery}
                            onSubjectChange={setSelectedSubject}
                            onStageChange={setSelectedStage}
                            onTemplateChange={setSelectedTemplate}
                            options={options}
                            onOptionsChange={setOptions}
                            loading={loading}
                            onSubmit={handleSearch}
                            isMobile={isMobile}
                        />
                    </Paper>

                    {/* Floating Action Button for mobile to open previous creations */}
                    <Fab 
                        color="primary" 
                        aria-label="previous creations"
                        onClick={toggleDrawer}
                        sx={{
                            position: 'fixed',
                            bottom: 16,
                            right: 16,
                            zIndex: 1000
                        }}
                    >
                        <HistoryIcon />
                    </Fab>

                    {/* Drawer for Previous Creations on mobile */}
                    <Drawer
                        anchor="right"
                        open={drawerOpen}
                        onClose={toggleDrawer}
                        sx={{
                            '& .MuiDrawer-paper': {
                                width: '85%',
                                maxWidth: 350,
                                boxSizing: 'border-box',
                                p: 2
                            },
                        }}
                    >
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                            <Typography variant="h6">Previous Creations</Typography>
                            <IconButton onClick={toggleDrawer}>
                                <CloseIcon />
                            </IconButton>
                        </Box>
                        {previousCreationsComponent}
                    </Drawer>
                </>
            )}

            {/* Results Dialog - Same for both mobile and desktop */}
            <Dialog
                open={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                maxWidth="lg"
                fullWidth
                sx={{
                    '& .MuiDialog-paper': {
                        borderRadius: 2,
                        minHeight: isMobile ? '100vh' : '90vh',
                        margin: isMobile ? 0 : undefined,
                        maxHeight: isMobile ? '100vh' : undefined,
                        width: isMobile ? '100%' : undefined
                    }
                }}
            >
                <ResultsDialog 
                    isOpen={isModalOpen}
                    onClose={() => setIsModalOpen(false)}
                    aiResponse={aiResponse}
                    showToast={showToast}
                    isMobile={isMobile}
                    theme={theme}
                />
            </Dialog>
        </Container>
    );
};

export default Generate;