import React, { createContext, useState, useContext, useCallback, useEffect } from 'react';
import axios, { isCancel } from 'axios';

const SchoolContext = createContext(null);

export const useSchool = () => {
    const context = useContext(SchoolContext);
    if (!context) {
        throw new Error('useSchool must be used within a SchoolProvider');
    }
    return context;
};

export const SchoolProvider = ({ children }) => {
    const [schools, setSchools] = useState([]);
    const [currentSchool, setCurrentSchool] = useState(() => {
        try {
            const user = JSON.parse(localStorage.getItem('user') || '{}');
            console.log('SchoolContext - Initial user data:', user);
            
            if (!user?.currentSchool?.id && !user?.currentSchool?._id) {
                console.log('SchoolContext - No currentSchool.id or _id found');
                return null;
            }

            // Try to get school details from user.schools array
            if (user.schools?.length > 0) {
                console.log('SchoolContext - Looking for school in schools array:', user.schools);
                const school = user.schools.find(s => 
                    (s._id && s._id === user.currentSchool._id) || 
                    (s.id && s.id === user.currentSchool.id)
                );
                if (school) {
                    console.log('SchoolContext - Found school in array:', school);
                    return school;
                }
            }

            // If no details found, return basic structure from token
            console.log('SchoolContext - Using school from token:', user.currentSchool);
            return user.currentSchool;
        } catch (e) {
            console.error('Error parsing user data:', e);
            return null;
        }
    });
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    // Load initial school data
    useEffect(() => {
        let mounted = true;
        const controller = new AbortController();

        const loadInitialSchoolData = async () => {
            try {
                const token = localStorage.getItem('token');
                if (!token) {
                    console.log('No token found, skipping school data load');
                    return;
                }

                const user = JSON.parse(localStorage.getItem('user') || '{}');
                console.log('Loading schools for user:', user);

                const response = await axios.get(
                    `${process.env.REACT_APP_BASE_URL}/api/user/schools`,
                    {
                        headers: {
                            'Authorization': `Bearer ${token}`
                        },
                        signal: controller.signal,
                        timeout: 5000 // 5 second timeout
                    }
                );

                if (!mounted) {
                    console.log('Component unmounted, skipping state update');
                    return;
                }

                const { schools } = response.data;
                console.log('Received schools:', schools);

                if (schools?.length > 0) {
                    setSchools(schools);
                    
                    // Update current school with full details if needed
                    if (currentSchool?.id || currentSchool?._id) {
                        console.log('Looking for current school match:', currentSchool);
                        const school = schools.find(s => 
                            (s._id && (s._id === currentSchool._id || s._id === currentSchool.id)) ||
                            (s.id && (s.id === currentSchool.id || s.id === currentSchool._id))
                        );
                        if (school) {
                            console.log('Found matching school:', school);
                            setCurrentSchool(school);
                        } else {
                            console.log('No matching school found');
                        }
                    }
                } else {
                    console.log('No schools received from API');
                }
            } catch (error) {
                if (isCancel(error)) {
                    console.log('Request cancelled:', error.message);
                } else {
                    console.error('Error loading initial school data:', error);
                    if (error.response) {
                        console.error('Error response:', error.response.data);
                        console.error('Error status:', error.response.status);
                    }
                }
            }
        };

        loadInitialSchoolData();

        return () => {
            mounted = false;
            controller.abort();
        };
    }, []);

    // Update school when localStorage changes
    useEffect(() => {
        const handleStorageChange = () => {
            try {
                const user = JSON.parse(localStorage.getItem('user') || '{}');
                if (!user?.currentSchool) {
                    setCurrentSchool(null);
                    return;
                }

                // Find school details in current schools array
                const school = schools.find(s => s._id === user.currentSchool._id || s.id === user.currentSchool._id);
                if (school && JSON.stringify(school) !== JSON.stringify(currentSchool)) {
                    console.log('School changed:', {
                        from: currentSchool,
                        to: school
                    });
                    setCurrentSchool(school);
                }
            } catch (e) {
                console.error('Error handling storage change:', e);
            }
        };

        window.addEventListener('storage', handleStorageChange);
        return () => window.removeEventListener('storage', handleStorageChange);
    }, [currentSchool, schools]);

    const loadSchoolData = useCallback(async (user) => {
        if (!user?.schools?.length) {
            setSchools([]);
            setCurrentSchool(null);
            return;
        }

        setSchools(user.schools);
        
        // Set current school from user data or first available school
        if (user.currentSchool) {
            setCurrentSchool(user.schools.find(s => s.id === user.currentSchool));
        } else if (user.schools.length === 1) {
            setCurrentSchool(user.schools[0]);
        }
    }, []);

    const switchSchool = async (schoolId) => {
        try {
            setLoading(true);
            setError(null);

            const token = localStorage.getItem('token');
            const response = await axios.put(
                `${process.env.REACT_APP_BASE_URL}/api/user/current-school/${schoolId}`,
                {},
                {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                }
            );

            const newCurrentSchool = schools.find(s => 
                (s.id && s.id.toString() === schoolId) || 
                (s._id && s._id.toString() === schoolId)
            );
            setCurrentSchool(newCurrentSchool);

            // Update user data in localStorage with full school object
            const userData = JSON.parse(localStorage.getItem('user'));
            userData.currentSchool = newCurrentSchool;
            localStorage.setItem('user', JSON.stringify(userData));

            return response.data;
        } catch (err) {
            setError(err.response?.data?.message || 'Failed to switch school');
            throw err;
        } finally {
            setLoading(false);
        }
    };

    // Helper to get the current school ID as a string
    const schoolId = typeof currentSchool?._id === 'string' ? currentSchool._id : 
                    typeof currentSchool?.id === 'string' ? currentSchool.id :
                    currentSchool?._id?.toString() || currentSchool?.id?.toString();

    const value = {
        schools,
        currentSchool,
        loading,
        error,
        loadSchoolData,
        switchSchool,
        schoolId
    };

    return (
        <SchoolContext.Provider value={value}>
            {children}
        </SchoolContext.Provider>
    );
};