import React from 'react';
import {
    List,
    ListItem,
    ListItemText,
    ListItemSecondaryAction,
    IconButton,
    Typography,
    Box,
    Tooltip,
} from '@mui/material';
import {
    Edit as EditIcon,
    Delete as DeleteIcon,
    Event as EventIcon,
} from '@mui/icons-material';

const TermList = ({
    terms,
    onEdit,
    onDelete
}) => {
    return (
        <List sx={{
            width: '100%',
            bgcolor: 'background.paper',
            borderRadius: 1,
            border: '1px solid',
            borderColor: 'divider',
            '& .MuiListItem-root': {
                borderBottom: '1px solid',
                borderColor: 'divider',
                '&:last-child': {
                    borderBottom: 'none'
                }
            }
        }}>
            {terms.length > 0 ? (
                terms.map((term) => {
                    const startDate = new Date(term.startDate).toLocaleDateString();
                    const endDate = new Date(term.endDate).toLocaleDateString();

                    return (
                        <ListItem
                            key={term.termNumber}
                            sx={{
                                py: 1.5,
                                '&:hover': {
                                    bgcolor: 'action.hover',
                                },
                            }}
                        >
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                                <EventIcon sx={{ color: 'primary.main' }} />
                                <ListItemText
                                    primary={
                                        <Typography variant="subtitle1" component="div">
                                            Term {term.termNumber}
                                        </Typography>
                                    }
                                    secondary={
                                        <Typography variant="body2" color="text.secondary">
                                            {startDate} - {endDate}
                                        </Typography>
                                    }
                                />
                            </Box>
                            <ListItemSecondaryAction>
                                <Box sx={{ display: 'flex', gap: 1 }}>
                                    <Tooltip title="Edit term">
                                        <IconButton
                                            edge="end"
                                            onClick={() => onEdit(term)}
                                            size="small"
                                            sx={{
                                                color: 'primary.main',
                                                '&:hover': {
                                                    bgcolor: 'primary.lighter',
                                                }
                                            }}
                                        >
                                            <EditIcon />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Delete term">
                                        <IconButton
                                            edge="end"
                                            onClick={() => onDelete(term.termNumber)}
                                            size="small"
                                            sx={{
                                                color: 'error.main',
                                                '&:hover': {
                                                    bgcolor: 'error.lighter',
                                                }
                                            }}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </Tooltip>
                                </Box>
                            </ListItemSecondaryAction>
                        </ListItem>
                    );
                })
            ) : (
                <ListItem>
                    <ListItemText
                        primary={
                            <Box sx={{
                                textAlign: 'center',
                                py: 4,
                                color: 'text.secondary'
                            }}>
                                <Typography variant="body1">
                                    No terms available
                                </Typography>
                                <Typography variant="body2" sx={{ mt: 1 }}>
                                    Create a new term to get started
                                </Typography>
                            </Box>
                        }
                    />
                </ListItem>
            )}
        </List>
    );
};

export default TermList;