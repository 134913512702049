import React, { useState, useRef, useEffect } from 'react';
import { jwtDecode } from 'jwt-decode';
import {
  Box,
  Paper,
  TextField,
  IconButton,
  Typography,
  CircularProgress,
  Tooltip,
  Card,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Fade,
  useMediaQuery,
  Drawer,
  AppBar,
  Toolbar,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import ConversationList from './ConversationList';
import { 
  Send as SendIcon, 
  MenuBook as MenuBookIcon, 
  Add as AddIcon,
  History as HistoryIcon,
  Forum as ForumIcon,
  Close as CloseIcon
} from '@mui/icons-material';
import ReactMarkdown from 'react-markdown';
import '../../css/Chat.css';

const CHAT_API_URL = process.env.REACT_APP_CHAT_API_URL;

// ... rest of the code ...
const SourceTooltip = ({ sources }) => {
  if (!sources?.length) {
    return (
      <Box className="source-tooltip">
        <Typography variant="overline" className="source-header warning">
          Note:
        </Typography>
        <Typography variant="body2">
          Using general teaching knowledge (no direct curriculum matches)
        </Typography>
      </Box>
    );
  }

  return (
    <Box className="source-tooltip">
      <Typography variant="overline" className="source-header">
        Sources:
      </Typography>
      {sources.map((source, index) => {
        const parts = source.split(' > ');
        return (
          <Card key={index} className="source-item">
            <Typography variant="subtitle2" className="source-main">
              {parts[0]} > {parts[1]}
            </Typography>
            <Typography variant="caption" className="source-path">
              {parts.length > 2 ? (
                <>
                  {parts.slice(2, -2).join(' > ')}
                  {parts.length > 3 && ' > '}
                  <span className="source-path-end">
                    {parts[parts.length - 1] === 'Content' 
                      ? parts[parts.length - 2]
                      : parts[parts.length - 1]}
                  </span>
                  {parts[parts.length - 1] !== 'Content' && parts.length > 3 && ' >'}
                </>
              ) : null}
            </Typography>
          </Card>
        );
      })}
    </Box>
  );
};

const Message = ({ role, content, metadata }) => {
  const isBot = role === 'assistant';

  return (
    <Box className={`message-container ${isBot ? 'bot' : 'user'}`}>
      {isBot && (
        <Tooltip
          title={<SourceTooltip sources={metadata?.sources} />}
          placement="left"
          arrow
          classes={{ tooltip: 'custom-tooltip' }}
        >
          <MenuBookIcon
            className={`source-icon ${metadata?.sources?.length ? 'curriculum' : 'general'}`}
          />
        </Tooltip>
      )}
      <div className={`message ${isBot ? 'bot' : 'user'}`}>
        <ReactMarkdown className="markdown-content">{content}</ReactMarkdown>
      </div>
    </Box>
  );
};

const Chat = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [userId, setUserId] = useState(null);

  useEffect(() => {
    try {
      // First try to get user from localStorage
      const userStr = localStorage.getItem('user');
      if (userStr) {
        const user = JSON.parse(userStr);
        const id = user._id || user.id;
        if (id) {
          console.log('Using user ID from localStorage:', id);
          setUserId(id);
          return;
        }
      }

      // Fallback to token if user object doesn't have ID
      const token = localStorage.getItem('token');
      if (token) {
        const decodedToken = jwtDecode(token);
        console.log('Decoded token:', decodedToken);
        const id = decodedToken?.sub || decodedToken?.id || decodedToken?._id || decodedToken?.userId;
        if (id) {
          console.log('Using user ID from token:', id);
          setUserId(id);
        } else {
          console.error('No user ID found in token or localStorage');
        }
      } else {
        console.error('No token or user found');
      }
    } catch (error) {
      console.error('Error getting user ID:', error);
    }
  }, []);
  // Ref for the ConversationList component to call its methods
  const conversationListRef = useRef();
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [conversationId, setConversationId] = useState(null);
  const [error, setError] = useState(null);
  const [pendingConversationId, setPendingConversationId] = useState(null);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const loadConversation = async (id) => {
    console.log('Loading conversation:', id);
    try {
      setIsLoading(true);
      setError(null);
      const response = await fetch(`${CHAT_API_URL}/api/conversations/${id}?user_id=${userId}`, {
        headers: {
          'Accept': 'application/json'
        }
      });
      
      if (!response.ok) {
        throw new Error('Failed to load conversation');
      }
      
      const text = await response.text();
      try {
        const data = JSON.parse(text);
        if (data.dialogue) {
          setMessages(data.dialogue);
          setConversationId(id);
          setError(null);
        } else {
          throw new Error('Invalid conversation format');
        }
      } catch (parseError) {
        console.error('Invalid JSON response:', text);
        throw new Error('Failed to parse server response');
      }
    } catch (err) {
      console.error('Error:', err);
      setMessages([]);
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    console.log('Conversation ID or userId changed:', { conversationId, userId });
    if (conversationId && userId) {
      loadConversation(conversationId);
    } else {
      setMessages([]);
    }
  }, [conversationId, userId]);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!input.trim() || isLoading || !userId) return;

    const userMessage = input.trim();
    setInput('');
    setIsLoading(true);

    try {
      const response = await fetch(`${CHAT_API_URL}/api/query`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          query: userMessage,
          conversation_id: conversationId,
          user_id: userId
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to send message');
      }

      const text = await response.text();
      try {
        const data = JSON.parse(text);
        if (data.dialogue) {
          setConversationId(data.conversation_id);
          setMessages(data.dialogue);
          setError(null);
          
          // Update conversation list after first response
          if (conversationListRef.current?.refreshConversations) {
            conversationListRef.current.refreshConversations();
          }
        } else {
          throw new Error('Invalid response format');
        }
      } catch (parseError) {
        console.error('Invalid JSON response:', text);
        throw new Error('Failed to parse server response');
      }
    } catch (error) {
      console.error('Error:', error);
      setMessages(messages => [
        ...(messages || []),
        { role: 'assistant', content: 'Sorry, I encountered an error processing your request.' },
      ]);
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleConversationSelect = (id) => {
    console.log('Selected conversation:', id);
    if (messages.length > 0 && id !== conversationId) {
      setPendingConversationId(id);
      setShowConfirmDialog(true);
    } else {
      setConversationId(id);
      setError(null);
    }
    
    // Close drawer on mobile when a conversation is selected
    if (isMobile) {
      setDrawerOpen(false);
    }
  };

  const handleConfirmSwitch = () => {
    setConversationId(pendingConversationId);
    setShowConfirmDialog(false);
    setPendingConversationId(null);
    setInput('');
    
    // Close drawer on mobile
    if (isMobile) {
      setDrawerOpen(false);
    }
  };

  const handleCancelSwitch = () => {
    setShowConfirmDialog(false);
    setPendingConversationId(null);
  };

  return (
    <Box className="chat-container">
      {/* Mobile drawer for conversation list */}
      {isMobile && (
        <>
          <Drawer
            anchor="left"
            open={drawerOpen}
            onClose={() => setDrawerOpen(false)}
            sx={{
              '& .MuiDrawer-paper': {
                width: '85%',
                maxWidth: '320px',
                boxSizing: 'border-box',
              },
            }}
          >
            <Box sx={{ height: '100%', overflow: 'hidden' }}>
              <ConversationList
                ref={conversationListRef}
                onSelectConversation={handleConversationSelect}
                selectedId={conversationId}
                userId={userId}
              />
            </Box>
          </Drawer>
          
          {/* Mobile header */}
          <AppBar 
            position="fixed" 
            color="default" 
            elevation={0}
            sx={{ 
              top: '64px', 
              bgcolor: 'var(--background-color)',
              borderBottom: '1px solid var(--border-color)',
              zIndex: 10
            }}
          >
            <Toolbar sx={{ justifyContent: 'space-between', minHeight: '56px' }}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box 
                  sx={{ 
                    display: 'flex', 
                    alignItems: 'center', 
                    border: '1px solid var(--border-color)',
                    borderRadius: '4px',
                    padding: '4px 8px',
                    cursor: 'pointer',
                    '&:hover': {
                      backgroundColor: 'var(--light-background)',
                    }
                  }}
                  onClick={() => setDrawerOpen(true)}
                >
                  <HistoryIcon 
                    sx={{
                      color: 'var(--primary-color)',
                      fontSize: '1.25rem',
                      mr: 0.5,
                    }}
                  />
                  <Typography 
                    sx={{ 
                      fontSize: '0.75rem',
                      fontWeight: 500,
                      color: 'var(--primary-color)',
                    }}
                  >
                    History
                  </Typography>
                </Box>
                <Typography variant="h6" sx={{ ml: 1.5, fontSize: '1rem' }}>
                  Syllabus Chat
                </Typography>
              </Box>
              <Button
                startIcon={<AddIcon />}
                onClick={() => {
                  setMessages([]);
                  setConversationId(null);
                  setError(null);
                  setInput('');
                  if (conversationListRef.current?.refreshConversations) {
                    conversationListRef.current.refreshConversations();
                  }
                }}
                sx={{
                  textTransform: 'none',
                  color: 'var(--text-color)',
                  bgcolor: 'var(--light-background)',
                  '&:hover': {
                    bgcolor: 'var(--border-color)',
                  },
                  fontWeight: 500,
                  px: 1.5,
                  py: 0.5,
                  fontSize: '0.875rem',
                }}
              >
                New Chat
              </Button>
            </Toolbar>
          </AppBar>
        </>
      )}
      
      {/* Desktop layout */}
      <Grid container spacing={0} sx={{ height: '100%' }}>
        {/* Conversation list - only visible on desktop */}
        {!isMobile && (
          <Grid item sx={{ width: '340px' }}>
            <ConversationList
              ref={conversationListRef}
              onSelectConversation={handleConversationSelect}
              selectedId={conversationId}
              userId={userId}
            />
          </Grid>
        )}
        
        {/* Chat area */}
        <Grid 
          item 
          xs 
          sx={{ 
            height: '100%',
            width: isMobile ? '100%' : 'calc(100% - 340px)',
          }}
        >
          <Paper 
            className="chat-paper"
            sx={{
              pt: isMobile ? '56px' : 0, // Add padding for mobile header
            }}
          >
            {/* Desktop header - only visible on desktop */}
            {!isMobile && (
              <Box className="chat-header">
                <Typography variant="h6">
                  Syllabus Chat
                </Typography>
                <Button
                  startIcon={<AddIcon />}
                  onClick={() => {
                    setMessages([]);
                    setConversationId(null);
                    setError(null);
                    setInput('');
                    if (conversationListRef.current?.refreshConversations) {
                      conversationListRef.current.refreshConversations();
                    }
                  }}
                  sx={{
                    textTransform: 'none',
                    color: 'var(--text-color)',
                    bgcolor: 'var(--light-background)',
                    '&:hover': {
                      bgcolor: 'var(--border-color)',
                    },
                    fontWeight: 500,
                    px: 2,
                  }}
                >
                  New Chat
                </Button>
              </Box>
            )}

            <Box sx={{ 
              height: '100%', 
              display: 'flex', 
              flexDirection: 'column', 
              position: 'relative',
            }}>
              {error ? (
                <Box p={3} textAlign="center">
                  <Typography color="error">{error}</Typography>
                </Box>
              ) : (
                <Box 
                  className="messages-container"
                  sx={{
                    paddingTop: isMobile ? '1rem' : 'calc(1rem + 64px)', // Adjust padding for mobile
                  }}
                >
                  {messages.map((msg, index) => (
                    <Message key={index} {...msg} />
                  ))}
                  <div ref={messagesEndRef} />
                </Box>
              )}

              <div 
                className="input-container"
                style={{
                  left: isMobile ? '50%' : 'calc(340px + (100% - 340px) / 2)',
                  width: isMobile ? 'calc(100% - 2rem)' : 'calc(100% - 340px - 4rem)',
                }}
              >
                <form onSubmit={handleSubmit}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    placeholder="Type your question here..."
                    value={input}
                    onChange={(e) => setInput(e.target.value)}
                    disabled={isLoading}
                    size="small"
                  />
                  <IconButton
                    type="submit"
                    color="primary"
                    disabled={isLoading || !input.trim()}
                    className="send-button"
                  >
                    {isLoading ? (
                      <CircularProgress 
                        size={24} 
                        sx={{ 
                          color: 'var(--primary-color)',
                          opacity: 0.8
                        }} 
                      />
                    ) : (
                      <SendIcon />
                    )}
                  </IconButton>
                </form>
              </div>
            </Box>
          </Paper>
        </Grid>
      </Grid>

      <Dialog
        open={showConfirmDialog}
        onClose={handleCancelSwitch}
        aria-labelledby="switch-dialog-title"
        aria-describedby="switch-dialog-description"
      >
        <DialogTitle id="switch-dialog-title">
          Switch Conversation?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="switch-dialog-description">
            You have an active conversation. Would you like to switch to the selected conversation? 
            Your current conversation will be saved.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelSwitch} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmSwitch} color="primary" variant="contained">
            Switch
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Chat;
