import React from 'react';
import { 
    Box, 
    Typography, 
    Card, 
    CardContent, 
    CardActions,
    Button,
    IconButton,
    CircularProgress,
    Tooltip,
    useTheme 
} from '@mui/material';
import {
    Visibility as VisibilityIcon,
    Delete as DeleteIcon,
    Refresh as RefreshIcon
} from '@mui/icons-material';

const PreviousCreations = ({ 
    aiResponses, 
    processingResponses, 
    loading,
    loadingMessage,
    onDelete,
    onView,
    onModalOpen,
    onUsePrompt,
    isMobile
}) => {
    console.log('PreviousCreations render:', {
        aiResponses,
        processingResponses,
        loading,
        loadingMessage
    });

    // Combine processing and completed responses for display
    const allResponses = [
        ...processingResponses,
        ...aiResponses
    ];
    console.log('Combined responses:', allResponses);
    const theme = useTheme();

    return (
        <Box sx={{ 
            width: isMobile ? '100%' : 300, 
            minWidth: isMobile ? '100%' : 300,
            maxWidth: isMobile ? '100%' : 300,
            alignSelf: 'flex-start',
            position: 'sticky',
            top: 0,
            pt: 2,
            height: isMobile ? 'calc(100vh - 80px)' : 'calc(100vh - 32px)', // Account for top padding
            display: 'flex',
            flexDirection: 'column',
            bgcolor: theme.palette.background.default,
            overflow: 'hidden' // Prevent double scrollbars
        }}>
            <Typography 
                variant="h6" 
                sx={{ 
                    px: 2,
                    mb: 2,
                    color: theme.palette.text.primary,
                    fontWeight: theme.typography.fontWeightMedium,
                    flexShrink: 0
                }}
            >
                Previous Creations
            </Typography>

            <Box 
                sx={{ 
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                    overflowY: 'scroll',
                    px: 2,
                    width: '100%',
                    height: '100%',
                    pb: 2, // Add bottom padding for last item
                    '&::-webkit-scrollbar': {
                        width: 8,
                    },
                    '&::-webkit-scrollbar-track': {
                        backgroundColor: 'rgba(0,0,0,0.05)',
                        borderRadius: 4,
                    },
                    '&::-webkit-scrollbar-thumb': {
                        backgroundColor: 'rgba(0,0,0,0.2)',
                        borderRadius: 4,
                        '&:hover': {
                            backgroundColor: 'rgba(0,0,0,0.3)',
                        },
                    },
                }}
            >
                {/* All responses - both processing and completed */}
                {allResponses.map(response => {
                    const isProcessing = processingResponses.some(p => p.id === response.id);
                    
                    return isProcessing ? (
                        <Card
                            key={response.id}
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                position: 'relative',
                                bgcolor: 'rgba(25, 118, 210, 0.05)',
                                borderRadius: 2,
                                height: 75, // Match completed card height
                                flexShrink: 0,
                                flexGrow: 0,
                            }}
                        >
                            <CardContent sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                gap: 1,
                                height: '100%',
                                p: '12px !important'
                            }}>
                                <CircularProgress size={24} />
                                <Typography variant="caption" color="text.secondary">
                                    {loadingMessage}
                                </Typography>
                            </CardContent>
                        </Card>
                    ) : (
                    <Card
                        key={response.id}
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            position: 'relative',
                            borderRadius: 2,
                            width: '100%',
                            maxWidth: '100%',
                            height: 75,
                            flexShrink: 0,
                            flexGrow: 0,
                            '&:hover': {
                                bgcolor: theme.palette.action.hover
                            }
                        }}
                    >
                        <CardContent sx={{ 
                            px: 2,
                            py: 1.5,
                            pb: '12px !important',
                            height: '100%',
                            display: 'flex',
                            gap: 1
                        }}>
                            <Box sx={{ flex: 1, minWidth: 0 }}>
                                <Tooltip title={response.query} placement="top">
                                    <Typography
                                        variant="subtitle2"
                                        sx={{ 
                                            color: theme.palette.text.primary,
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            whiteSpace: 'nowrap',
                                            fontSize: '0.875rem',
                                            mb: 0.5
                                        }}
                                    >
                                        {response.title || response.query}
                                    </Typography>
                                </Tooltip>
                                <Typography
                                    variant="caption"
                                    color="text.secondary"
                                    sx={{
                                        display: 'block',
                                        fontSize: '0.7rem'
                                    }}
                                >
                                    Created: {new Date(response.createdAt || response.timestamp).toLocaleDateString()}
                                </Typography>
                            </Box>
                            <Box sx={{ 
                                display: 'flex',
                                gap: 0.5,
                                alignItems: 'center'
                            }}>
                                <Tooltip title="View" placement="top">
                                    <IconButton
                                        size="small"
                                        onClick={() => {
                                            console.log('Viewing response:', response);
                                            onView({
                                                ...response,
                                                content: response.aiResponse || response.response || response.content || '',
                                                title: response.title || response.query
                                            });
                                            onModalOpen();
                                        }}
                                        sx={{ 
                                            width: 24,
                                            height: 24,
                                            color: theme.palette.primary.main,
                                            bgcolor: theme.palette.background.paper,
                                            border: 1,
                                            borderColor: theme.palette.primary.main,
                                            '&:hover': {
                                                bgcolor: theme.palette.primary.light + '20'
                                            }
                                        }}
                                    >
                                        <VisibilityIcon sx={{ fontSize: 16 }} />
                                    </IconButton>
                                </Tooltip>
                                {onUsePrompt && (
                                    <Tooltip title="Use this prompt" placement="top">
                                        <IconButton
                                            size="small"
                                            onClick={() => {
                                                console.log('Using prompt:', response.query);
                                                onUsePrompt(response.query, response.subject, response.stage);
                                            }}
                                            sx={{ 
                                                width: 24,
                                                height: 24,
                                                color: theme.palette.success.main,
                                                bgcolor: theme.palette.background.paper,
                                                border: 1,
                                                borderColor: theme.palette.success.main,
                                                '&:hover': {
                                                    bgcolor: theme.palette.success.light + '20'
                                                }
                                            }}
                                        >
                                            <RefreshIcon sx={{ fontSize: 16 }} />
                                        </IconButton>
                                    </Tooltip>
                                )}
                                <Tooltip title="Delete" placement="top">
                                    <IconButton
                                        size="small"
                                        onClick={() => onDelete(response.id, response.title)}
                                        sx={{
                                            width: 24,
                                            height: 24,
                                            bgcolor: 'error.light',
                                            color: 'error.contrastText',
                                            '&:hover': {
                                                bgcolor: 'error.main',
                                            }
                                        }}
                                    >
                                        <DeleteIcon sx={{ fontSize: 16 }} />
                                    </IconButton>
                                </Tooltip>
                            </Box>
                        </CardContent>
                    </Card>
                    );
                })}

                {allResponses.length === 0 && !loading && (
                    <Typography 
                        variant="body2" 
                        sx={{ 
                            textAlign: 'center',
                            color: theme.palette.text.secondary,
                            py: 4
                        }}
                    >
                        No previous creations yet
                    </Typography>
                )}
            </Box>
        </Box>
    );
};

export default PreviousCreations;