import { useState, useEffect, useCallback } from 'react';
import { protectedApiCall, protectedFetch } from '../../../auth';

const useClasses = (navigate) => {
    const [classes, setClasses] = useState([]);
    const [deletedClasses, setDeletedClasses] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const token = localStorage.getItem('token');
    const [user, setUser] = useState(() => {
        try {
            const rawUser = localStorage.getItem('user');
            console.log('Initial user data from localStorage:', rawUser);
            
            const parsedUser = JSON.parse(rawUser || '{}');
            
            // Ensure we have a consistent id field
            const userData = {
                ...parsedUser,
                id: parsedUser?.id || parsedUser?._id
            };

            console.log('Parsed user data:', {
                id: userData.id,
                email: userData.email,
                currentSchool: userData.currentSchool
            });
            
            return userData;
        } catch (e) {
            console.error('Error parsing user data:', e);
            return {};
        }
    });

    // Extract school ID as string
    const schoolId = user?.currentSchool?._id || user?.currentSchool?.id;
    
    // Update user when localStorage changes
    useEffect(() => {
        const handleStorageChange = () => {
            try {
                const newUser = JSON.parse(localStorage.getItem('user') || '{}');
                console.log('User storage changed:', {
                    oldSchool: user?.currentSchool,
                    newSchool: newUser?.currentSchool
                });
                setUser(newUser);
            } catch (e) {
                console.error('Error handling storage change:', e);
            }
        };

        window.addEventListener('storage', handleStorageChange);
        return () => window.removeEventListener('storage', handleStorageChange);
    }, [user]);

    console.log('Current user and school:', {
        userId: user?.id,
        schoolId,
        schoolObject: user?.currentSchool
    });

    const fetchClasses = useCallback(async () => {
        try {
            setLoading(true);
            const result = await protectedFetch(async () => {
                const userId = user?.id;
                console.log('Fetching classes with:', { 
                    schoolId,
                    token: token ? 'present' : 'missing',
                    userId,
                    user
                });

                const url = new URL(`${process.env.REACT_APP_BASE_URL}/api/classes`);
                url.searchParams.append('schoolId', schoolId);

                console.log('Making request to:', url.toString());

                const response = await fetch(url, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }

                const data = await response.json();
                console.log('API Response:', {
                    status: response.status,
                    data
                });

                return data;
            }, navigate);

            if (result) {
                console.log('Classes fetched:', {
                    classes: result,
                    schoolId,
                    firstClassSchool: result[0]?.school,
                    firstClassSchoolId: typeof result[0]?.school?._id === 'string' ? result[0].school._id :
                                      typeof result[0]?.school?.id === 'string' ? result[0].school.id :
                                      result[0]?.school?._id?.toString() || result[0]?.school?.id?.toString() || result[0]?.school?.toString()
                });

                // Filter classes by school, comparing IDs
                const schoolClasses = result.filter(c => {
                    const classSchoolId = c.school?._id || c.school?.id || c.school;
                    const match = classSchoolId === schoolId;
                    console.log('Class school check:', {
                        classId: c._id,
                        className: c.className,
                        classSchoolId,
                        schoolId,
                        match,
                        schoolObject: c.school,
                        schoolIdType: typeof schoolId,
                        classSchoolIdType: typeof classSchoolId,
                        schoolIdValue: schoolId,
                        classSchoolIdValue: classSchoolId
                    });
                    return match;
                });

                console.log('Filtered classes for school:', { 
                    schoolId, 
                    totalClasses: result.length,
                    filteredCount: schoolClasses.length,
                    filteredClasses: schoolClasses
                });
                setClasses(schoolClasses);
            } else {
                setClasses([]);
                console.warn('No classes returned from API');
            }
            setError(null);
        } catch (err) {
            console.error('Error fetching classes:', err);
            setError('Failed to load classes');
            setClasses([]);
        } finally {
            setLoading(false);
        }
    }, [token, navigate, schoolId, user]);

    const fetchDeletedClasses = useCallback(async () => {
        try {
            const result = await protectedFetch(async () => {
                console.log('Fetching deleted classes with:', { schoolId });

                const url = new URL(`${process.env.REACT_APP_BASE_URL}/api/classes/deleted`);
                url.searchParams.append('schoolId', schoolId);

                const response = await fetch(url, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                });
                return response.json();
            }, navigate);

            // If we get an array, use it; if we get an error message or null, use empty array
            if (Array.isArray(result)) {
                console.log('Deleted classes fetched:', {
                    classes: result,
                    schoolId,
                    firstClassSchool: result[0]?.school
                });

                // Filter deleted classes by school, comparing IDs
                const schoolDeletedClasses = result.filter(c => {
                    const classSchoolId = c.school?._id || c.school?.id || c.school;
                    const match = classSchoolId === schoolId;
                    console.log('Deleted class school check:', {
                        classId: c._id,
                        className: c.className,
                        classSchoolId,
                        schoolId,
                        match,
                        schoolObject: c.school,
                        schoolIdType: typeof schoolId,
                        classSchoolIdType: typeof classSchoolId,
                        schoolIdValue: schoolId,
                        classSchoolIdValue: classSchoolId
                    });
                    return match;
                });

                console.log('Filtered deleted classes for school:', { 
                    schoolId, 
                    totalClasses: result.length,
                    filteredCount: schoolDeletedClasses.length,
                    filteredClasses: schoolDeletedClasses
                });
                setDeletedClasses(schoolDeletedClasses);
            } else {
                setDeletedClasses([]);
            }
        } catch (err) {
            console.error('Error fetching deleted classes:', err);
            setDeletedClasses([]);
        }
    }, [token, navigate, schoolId]);

    const createClass = async (classData) => {
        try {
            setLoading(true);
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/classes`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({
                    ...classData,
                    school: schoolId
                }),
            });

            if (!response.ok) throw new Error('Failed to create class');

            const newClass = await response.json();
            setClasses(prev => [...prev, newClass]);
            return newClass;
        } catch (err) {
            console.error('Error creating class:', err);
            throw err;
        } finally {
            setLoading(false);
        }
    };

    const updateClass = async (classId, classData) => {
        try {
            setLoading(true);
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/classes/${classId}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({
                    ...classData,
                    school: schoolId
                }),
            });

            if (!response.ok) throw new Error('Failed to update class');

            const updatedClass = await response.json();
            setClasses(prev => prev.map(c => c._id === classId ? updatedClass : c));
            return updatedClass;
        } catch (err) {
            console.error('Error updating class:', err);
            throw err;
        } finally {
            setLoading(false);
        }
    };

    const deleteClass = async (classId) => {
        try {
            setLoading(true);
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/classes/delete/${classId}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ school: schoolId }),
            });

            if (!response.ok) throw new Error('Failed to delete class');

            const deletedClass = classes.find(c => c._id === classId);
            setClasses(prev => prev.filter(c => c._id !== classId));
            setDeletedClasses(prev => [...prev, { ...deletedClass, isDeleted: true }]);
        } catch (err) {
            console.error('Error deleting class:', err);
            throw err;
        } finally {
            setLoading(false);
        }
    };

    const restoreClass = async (classId) => {
        try {
            setLoading(true);
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/classes/restore/${classId}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ school: schoolId }),
            });

            if (!response.ok) throw new Error('Failed to restore class');

            const restoredClass = deletedClasses.find(c => c._id === classId);
            setDeletedClasses(prev => prev.filter(c => c._id !== classId));
            setClasses(prev => [...prev, { ...restoredClass, isDeleted: false }]);
        } catch (err) {
            console.error('Error restoring class:', err);
            throw err;
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        let mounted = true;
        
        console.log('School changed, refreshing classes:', schoolId);
        
        const loadData = async () => {
            if (!mounted) return;
            
            setLoading(true);
            try {
                await Promise.all([
                    fetchClasses(),
                    fetchDeletedClasses()
                ]);
            } catch (error) {
                console.error('Error loading classes data:', error);
            } finally {
                if (mounted) {
                    setLoading(false);
                }
            }
        };

        loadData();

        // Cleanup function
        return () => {
            mounted = false;
            setClasses([]);
            setDeletedClasses([]);
            setError(null);
        };
    }, [fetchClasses, fetchDeletedClasses, schoolId]);

    // Expose fetchClasses as refreshClasses for external use
    const refreshClasses = useCallback(async () => {
        console.log('Manual refresh triggered');
        await fetchClasses();
    }, [fetchClasses]);

    return {
        classes,
        deletedClasses,
        loading,
        error,
        createClass,
        updateClass,
        deleteClass,
        restoreClass,
        refreshClasses
    };
};

export default useClasses;
