import React from 'react';
import {
    Paper,
    Typography,
    List,
    ListItem,
    ListItemText,
    ListItemAvatar,
    Avatar,
    Box,
    Divider,
} from '@mui/material';
import { Person as PersonIcon } from '@mui/icons-material';

const StudentList = ({ students, className }) => {
    return (
        <Paper 
            elevation={0}
            sx={{ 
                border: '1px solid',
                borderColor: 'divider',
                borderRadius: 2,
                height: '100%',
                display: 'flex',
                flexDirection: 'column'
            }}
        >
            <Box sx={{ 
                p: 2, 
                borderBottom: '1px solid',
                borderColor: 'divider',
                bgcolor: 'background.neutral'
            }}>
                <Typography variant="h6" sx={{ color: 'text.primary' }}>
                    {className} - Student List
                </Typography>
                <Typography variant="body2" color="text.secondary" sx={{ mt: 0.5 }}>
                    {students.length} {students.length === 1 ? 'student' : 'students'} enrolled
                </Typography>
            </Box>

            <List sx={{ 
                flex: 1, 
                overflow: 'auto',
                p: 0
            }}>
                {students.map((student, index) => (
                    <React.Fragment key={index}>
                        <ListItem>
                            <ListItemAvatar>
                                <Avatar sx={{ bgcolor: 'primary.lighter' }}>
                                    <PersonIcon sx={{ color: 'primary.main' }} />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={
                                    <Typography variant="subtitle2">
                                        {student.firstName} {student.lastName}
                                    </Typography>
                                }
                            />
                        </ListItem>
                        {index < students.length - 1 && (
                            <Divider variant="inset" component="li" />
                        )}
                    </React.Fragment>
                ))}
                {students.length === 0 && (
                    <Box sx={{ 
                        p: 4, 
                        textAlign: 'center',
                        color: 'text.secondary'
                    }}>
                        <Typography variant="body1">
                            No students in this class
                        </Typography>
                        <Typography variant="body2" sx={{ mt: 1 }}>
                            Edit the class to add students
                        </Typography>
                    </Box>
                )}
            </List>
        </Paper>
    );
};

export default StudentList;