const CACHE_PREFIX = 'school_logo_';
const MAX_CACHE_SIZE = 5 * 1024 * 1024; // 5MB limit for all cached logos combined

const getTotalCacheSize = () => {
    let total = 0;
    for (let i = 0; i < localStorage.length; i++) {
        const key = localStorage.key(i);
        if (key.startsWith(CACHE_PREFIX)) {
            total += localStorage.getItem(key).length;
        }
    }
    return total;
};

const makeRoomInCache = (neededSize) => {
    const keys = Object.keys(localStorage)
        .filter(key => key.startsWith(CACHE_PREFIX))
        .sort((a, b) => {
            const aTime = localStorage.getItem(a + '_time') || 0;
            const bTime = localStorage.getItem(b + '_time') || 0;
            return aTime - bTime;
        });

    for (const key of keys) {
        if (getTotalCacheSize() + neededSize <= MAX_CACHE_SIZE) {
            break;
        }
        localStorage.removeItem(key);
        localStorage.removeItem(key + '_time');
    }
};

export const cacheSchoolLogo = async (schoolId, url) => {
    try {
        // Fetch the image without credentials since signed URLs don't need them
        const response = await fetch(url);
        if (!response.ok) {
            throw new Error(`Failed to fetch image: ${response.status}`);
        }
        
        const blob = await response.blob();
        
        // Check file size before processing
        if (blob.size > 5 * 1024 * 1024) { // 5MB per image limit
            throw new Error('Image is too large (max 5MB)');
        }
        
        // Convert to base64
        const base64 = await new Promise((resolve) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.readAsDataURL(blob);
        });

        // Make room in cache if needed
        makeRoomInCache(base64.length);
        
        // Store in localStorage with timestamp
        localStorage.setItem(CACHE_PREFIX + schoolId, base64);
        localStorage.setItem(CACHE_PREFIX + schoolId + '_time', Date.now());
        
        console.log('Cached logo for school:', schoolId);
        return base64;
    } catch (error) {
        console.warn('Failed to cache school logo:', error);
        return null;
    }
};

export const getCachedLogo = (schoolId) => {
    try {
        const cached = localStorage.getItem(CACHE_PREFIX + schoolId);
        if (cached) {
            // Update access time
            localStorage.setItem(CACHE_PREFIX + schoolId + '_time', Date.now());
            console.log('Cache hit for school logo:', schoolId);
        }
        return cached;
    } catch (error) {
        console.warn('Failed to get cached logo:', error);
        return null;
    }
};

export const clearCachedLogo = (schoolId) => {
    try {
        localStorage.removeItem(CACHE_PREFIX + schoolId);
        localStorage.removeItem(CACHE_PREFIX + schoolId + '_time');
        console.log('Cleared cached logo for school:', schoolId);
    } catch (error) {
        console.warn('Failed to clear cached logo:', error);
    }
};

export const clearAllCachedLogos = () => {
    try {
        const keys = Object.keys(localStorage);
        keys.forEach(key => {
            if (key.startsWith(CACHE_PREFIX)) {
                localStorage.removeItem(key);
            }
        });
        console.log('Cleared all cached logos');
    } catch (error) {
        console.warn('Failed to clear all cached logos:', error);
    }
};