import React, { useState, useEffect } from 'react';
import {
  Box,
  Paper,
  Typography,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Divider,
  Stepper,
  Step,
  StepLabel,
  CircularProgress,
  Alert,
  Card,
  CardContent,
  useTheme
} from '@mui/material';
import { 
  AutoAwesome as TemplateIcon,
  School as CurriculumIcon,
  Create as PromptIcon,
  Preview as PreviewIcon,
  Save as SaveIcon
} from '@mui/icons-material';
import { protectedApiCall } from '../../auth';

// Default template options (will be replaced with data from the backend)
const DEFAULT_TEMPLATES = [
  { 
    _id: '65f1a2b3c4d5e6f7a8b9c0d1', // Using MongoDB ObjectId format
    name: 'Multiple Choice Quiz', 
    description: 'A quiz with multiple choice questions, ideal for quick assessments and objective testing.'
  },
  { 
    _id: '65f1a2b3c4d5e6f7a8b9c0d2',
    name: 'Short Answer Worksheet', 
    description: 'Questions requiring brief written responses, good for testing recall and basic understanding.'
  },
  { 
    _id: '65f1a2b3c4d5e6f7a8b9c0d3',
    name: 'Mixed Format Assessment', 
    description: 'A combination of multiple choice, short answer, and long answer questions for comprehensive assessment.'
  },
  { 
    _id: '65f1a2b3c4d5e6f7a8b9c0d4',
    name: 'Concept Application', 
    description: 'Questions that require students to apply concepts to real-world scenarios.'
  },
  { 
    _id: '65f1a2b3c4d5e6f7a8b9c0d5',
    name: 'Critical Thinking', 
    description: 'Questions designed to develop analytical and evaluative skills.'
  }
];

// Subject options (same as in CreateWorksheet)
const SUBJECTS = [
  'Mathematics',
  'English',
  'Science',
  'History',
  'Geography',
  'Art',
  'Music',
  'Physical Education',
  'Religious Studies',
  'Technology',
  'Languages',
  'Other'
];

// Grade options (same as in CreateWorksheet)
const GRADES = [
  'Kindergarten',
  'Year 1',
  'Year 2',
  'Year 3',
  'Year 4',
  'Year 5',
  'Year 6',
  'Year 7',
  'Year 8',
  'Year 9',
  'Year 10',
  'Year 11',
  'Year 12'
];

const TemplateGenerator = ({ onWorksheetCreated }) => {
  const theme = useTheme();
  const [activeStep, setActiveStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [loadingTemplates, setLoadingTemplates] = useState(false);
  const [error, setError] = useState(null);
  const [generatedWorksheet, setGeneratedWorksheet] = useState(null);
  const [templates, setTemplates] = useState(DEFAULT_TEMPLATES);
  
  // Form state
  const [templateId, setTemplateId] = useState('');
  const [subject, setSubject] = useState('');
  const [grade, setGrade] = useState('');
  const [prompt, setPrompt] = useState('');
  const [numberOfQuestions, setNumberOfQuestions] = useState(5);
  
  // Fetch templates from the backend when component mounts
  useEffect(() => {
    fetchTemplates();
  }, []);
  
  const fetchTemplates = async () => {
    setLoadingTemplates(true);
    setError(null);
    
    try {
      const response = await protectedApiCall('/api/templates', 'GET');
      if (response.data && Array.isArray(response.data)) {
        setTemplates(response.data);
      }
    } catch (err) {
      console.error('Error fetching templates:', err);
      // If we can't fetch templates, we'll use the default ones
    } finally {
      setLoadingTemplates(false);
    }
  };
  
  const steps = [
    'Select Template',
    'Curriculum Alignment',
    'Customize Prompt',
    'Review & Save'
  ];
  
  const handleNext = () => {
    if (validateCurrentStep()) {
      setActiveStep((prevStep) => prevStep + 1);
    }
  };
  
  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };
  
  const validateCurrentStep = () => {
    setError(null);
    
    switch (activeStep) {
      case 0:
        if (!templateId) {
          setError('Please select a template');
          return false;
        }
        break;
      case 1:
        if (!subject) {
          setError('Please select a subject');
          return false;
        }
        if (!grade) {
          setError('Please select a grade level');
          return false;
        }
        break;
      case 2:
        if (!prompt.trim()) {
          setError('Please enter a prompt for the AI');
          return false;
        }
        break;
      default:
        break;
    }
    
    return true;
  };
  
  const handleGenerate = async () => {
    if (!validateCurrentStep()) return;
    
    setLoading(true);
    setError(null);
    
    try {
      console.log('Sending template generation request with:');
      console.log('- templateId:', templateId);
      console.log('- subject:', subject);
      console.log('- grade:', grade);
      console.log('- userPrompt:', prompt);
      console.log('- numberOfQuestions:', numberOfQuestions);
      
      const requestData = {
        templateId, // This is now the MongoDB ObjectId
        subject,
        grade,
        userPrompt: prompt, // Renamed to match backend expectation
        numberOfQuestions: parseInt(numberOfQuestions) // Ensure it's a number
      };
      
      console.log('Full request data:', requestData);
      
      const response = await protectedApiCall('/api/worksheets/generate-from-template', 'POST', requestData);
      
      console.log('Received worksheet data:', response.data);
      console.log('Response status:', response.status);
      console.log('Full response object:', response);
      console.log('Response data type:', typeof response.data);
      
      // Validate the response data
      if (!response.data) {
        console.error('No response data received');
        throw new Error('No data received from server');
      }
      
      // Check for different possible response structures
      let worksheetData;
      
      // Case 1: response.data is the worksheet object directly
      if (typeof response.data === 'object' && !Array.isArray(response.data)) {
        console.log('Case 1: response.data is an object');
        worksheetData = response.data;
      } 
      // Case 2: response.data contains a worksheet property
      else if (typeof response.data === 'object' && response.data.worksheet) {
        console.log('Case 2: response.data contains a worksheet property');
        worksheetData = response.data.worksheet;
      }
      // Case 3: response.data is a string (possibly JSON)
      else if (typeof response.data === 'string') {
        console.log('Case 3: response.data is a string, attempting to parse as JSON');
        try {
          worksheetData = JSON.parse(response.data);
        } catch (e) {
          console.error('Failed to parse response.data as JSON:', e);
          throw new Error('Invalid response format from server');
        }
      }
      // Case 4: response.data is something else
      else {
        console.error('Unexpected response data format:', response.data);
        throw new Error('Unexpected response format from server');
      }
      
      console.log('Extracted worksheet data:', worksheetData);
      
      // Check if the worksheet data is empty
      if (!worksheetData || Object.keys(worksheetData).length === 0) {
        console.error('Empty worksheet data');
        throw new Error('The backend processed your request but returned an empty worksheet. Please try again with a different prompt.');
      }
      
      // Log specific properties to help diagnose issues
      console.log('Worksheet data properties:', Object.keys(worksheetData));
      
      // Look for questions in different possible locations
      let questions = null;
      
      if (Array.isArray(worksheetData.questions)) {
        console.log('Found questions in worksheetData.questions');
        questions = worksheetData.questions;
      } else if (worksheetData.data && Array.isArray(worksheetData.data.questions)) {
        console.log('Found questions in worksheetData.data.questions');
        questions = worksheetData.data.questions;
      } else if (worksheetData.worksheet && Array.isArray(worksheetData.worksheet.questions)) {
        console.log('Found questions in worksheetData.worksheet.questions');
        questions = worksheetData.worksheet.questions;
      } else if (worksheetData.result && Array.isArray(worksheetData.result.questions)) {
        console.log('Found questions in worksheetData.result.questions');
        questions = worksheetData.result.questions;
      } else if (worksheetData.content && Array.isArray(worksheetData.content.questions)) {
        console.log('Found questions in worksheetData.content.questions');
        questions = worksheetData.content.questions;
      }
      
      // Special case: If questions is still null but worksheetData itself is an array
      if (!questions && Array.isArray(worksheetData)) {
        console.log('worksheetData itself is an array, checking if it contains questions');
        
        // Check if the array items look like questions
        if (worksheetData.length > 0 && 
            (worksheetData[0].questionText || 
             worksheetData[0].question || 
             worksheetData[0].text)) {
          console.log('worksheetData array contains question-like objects');
          questions = worksheetData.map(item => {
            // Normalize question format
            return {
              questionText: item.questionText || item.question || item.text || 'No question text',
              questionType: item.questionType || item.type || 'multiple-choice',
              options: item.options || [],
              correctAnswer: item.correctAnswer || item.answer || '',
              points: item.points || 1
            };
          });
        }
      }
      
      console.log('Extracted questions:', questions);
      
      // Special case: If we have a single question with multiple questions embedded in the text
      if (!questions && worksheetData.questions && worksheetData.questions.length === 1 && 
          worksheetData.questions[0].questionText && 
          worksheetData.questions[0].questionText.includes('QUESTION:')) {
        
        console.log('Detected multiple questions embedded in a single question text');
        
        // Extract the original question text
        const fullText = worksheetData.questions[0].questionText;
        console.log('Full text of embedded questions:', fullText);
        
        // Try to parse multiple questions from the text
        try {
          // Split by question numbers (1., 2., etc.)
          const questionRegex = /(\d+\.\s+(?:QUESTION:\s+)?)(.*?)(?=\d+\.\s+(?:QUESTION:\s+)?|$)/gs;
          const matches = [...fullText.matchAll(questionRegex)];
          
          console.log('Regex matches:', matches);
          
          if (matches && matches.length > 0) {
            const parsedQuestions = matches.map(match => {
              const questionText = match[2].trim();
              
              // Try to extract options and answer
              let options = [];
              let correctAnswer = '';
              
              // Look for options (A., B., etc.)
              const optionRegex = /([A-D])\.\s+(.*?)(?=[A-D]\.|ANSWER:|$)/gs;
              const optionMatches = [...questionText.matchAll(optionRegex)];
              
              if (optionMatches && optionMatches.length > 0) {
                options = optionMatches.map(m => `${m[1]}. ${m[2].trim()}`);
              }
              
              // Look for the answer
              const answerMatch = questionText.match(/ANSWER:\s+([A-D]\..*?)(?=\s*$)/);
              if (answerMatch) {
                correctAnswer = answerMatch[1].trim();
              }
              
              // Extract the actual question text without options and answer
              let cleanQuestionText = questionText;
              if (cleanQuestionText.includes('TYPE:')) {
                cleanQuestionText = cleanQuestionText.split('TYPE:')[0].trim();
              }
              
              return {
                questionText: cleanQuestionText,
                questionType: 'multiple-choice',
                options: options,
                correctAnswer: correctAnswer,
                points: 1
              };
            });
            
            console.log('Parsed questions from text:', parsedQuestions);
            questions = parsedQuestions;
          }
        } catch (e) {
          console.error('Error parsing embedded questions:', e);
        }
      }
      
      // If we found questions, add them to the worksheet data
      if (questions && questions.length > 0) {
        worksheetData.questions = questions;
      } else {
        console.error('No questions found in the response data');
        throw new Error('The worksheet was generated but no questions were found. Please try again with a more specific prompt.');
      }
      
      // Add default title and description if missing
      if (!worksheetData.title) {
        worksheetData.title = `${subject} Worksheet for ${grade}`;
      }
      
      if (!worksheetData.description) {
        worksheetData.description = `Generated worksheet based on prompt: "${prompt.substring(0, 50)}${prompt.length > 50 ? '...' : ''}"`;
      }
      
      console.log('Processed worksheet data:', worksheetData);
      setGeneratedWorksheet(worksheetData);
      handleNext();
    } catch (err) {
      console.error('Error generating worksheet:', err);
      
      // Extract the error message from the response if available
      let errorMessage = err.response?.data?.error || 
                        err.response?.data?.message || 
                        err.message || 
                        'Failed to generate worksheet. Please try again.';
      
      // Add more context to the error message based on the type of error
      if (errorMessage.includes('empty worksheet') || errorMessage.includes('no questions were created')) {
        errorMessage = `${errorMessage} Try being more specific about the types of questions you want.`;
      } else if (err.response?.status === 500) {
        errorMessage = 'The server encountered an error while processing your request. Please try again later.';
      } else if (err.response?.status === 400) {
        errorMessage = 'There was an issue with your request. Please check your inputs and try again.';
      } else if (err.response?.status === 401 || err.response?.status === 403) {
        errorMessage = 'You are not authorized to perform this action. Please log in again.';
      }
      
      setError(errorMessage);
      
      // If there are specific required fields mentioned in the error, show them
      if (err.response?.data?.required) {
        const missingFields = err.response.data.required.join(', ');
        setError(`${errorMessage} Missing fields: ${missingFields}`);
      }
    } finally {
      setLoading(false);
    }
  };
  
  const handleAcceptWorksheet = () => {
    if (!generatedWorksheet) return;
    
    // The worksheet is already saved in the database by the backend
    console.log('Accepting worksheet:', generatedWorksheet);
    
    // Notify parent component that a worksheet was created
    if (onWorksheetCreated) {
      onWorksheetCreated(generatedWorksheet);
    }
    
    // Reset the form
    setTemplateId('');
    setSubject('');
    setGrade('');
    setPrompt('');
    setNumberOfQuestions(5);
    setGeneratedWorksheet(null);
    setActiveStep(0);
  };
  
  const handleRejectWorksheet = async () => {
    if (!generatedWorksheet || !generatedWorksheet._id) return;
    
    setLoading(true);
    setError(null);
    
    try {
      // Delete the worksheet from the database
      await protectedApiCall(`/api/worksheets/${generatedWorksheet._id}`, 'DELETE');
      console.log('Worksheet rejected and deleted from database');
      
      // Reset the form and go back to the prompt step
      setActiveStep(2); // Go back to the prompt step
      setGeneratedWorksheet(null);
    } catch (err) {
      console.error('Error deleting worksheet:', err);
      setError('Failed to delete the worksheet, but you can still try again with a different prompt.');
    } finally {
      setLoading(false);
    }
  };
  
  const renderStepContent = () => {
    switch (activeStep) {
      case 0:
        return (
          <Box>
            <Typography variant="h6" gutterBottom>
              Select a Worksheet Template
            </Typography>
            <Typography variant="body2" color="text.secondary" paragraph>
              Choose a template that best fits your teaching needs. Each template is designed for different types of assessment.
            </Typography>
            
            {loadingTemplates ? (
              <Box sx={{ display: 'flex', justifyContent: 'center', my: 4 }}>
                <CircularProgress />
              </Box>
            ) : (
              <Grid container spacing={2} sx={{ mt: 2 }}>
                {templates.map((template) => (
                  <Grid item xs={12} sm={6} md={4} key={template._id}>
                    <Card 
                      sx={{ 
                        height: '100%',
                        cursor: 'pointer',
                        transition: 'all 0.2s',
                        border: templateId === template._id ? `2px solid ${theme.palette.primary.main}` : '1px solid transparent',
                        '&:hover': {
                          boxShadow: 3,
                          borderColor: theme.palette.primary.light
                        }
                      }}
                      onClick={() => setTemplateId(template._id)}
                    >
                    <CardContent>
                      <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                        <TemplateIcon color="primary" sx={{ mr: 1 }} />
                        <Typography variant="subtitle1" fontWeight="medium">
                          {template.name}
                        </Typography>
                      </Box>
                      <Typography variant="body2" color="text.secondary">
                        {template.description}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
            )}
          </Box>
        );
        
      case 1:
        return (
          <Box>
            <Typography variant="h6" gutterBottom>
              Curriculum Alignment
            </Typography>
            <Typography variant="body2" color="text.secondary" paragraph>
              Specify the subject and grade level to ensure the generated worksheet aligns with the appropriate curriculum standards.
            </Typography>
            
            <Grid container spacing={3} sx={{ mt: 1 }}>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth required>
                  <InputLabel>Subject</InputLabel>
                  <Select
                    value={subject}
                    onChange={(e) => setSubject(e.target.value)}
                    label="Subject"
                  >
                    {SUBJECTS.map(subj => (
                      <MenuItem key={subj} value={subj}>{subj}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth required>
                  <InputLabel>Grade Level</InputLabel>
                  <Select
                    value={grade}
                    onChange={(e) => setGrade(e.target.value)}
                    label="Grade Level"
                  >
                    {GRADES.map(g => (
                      <MenuItem key={g} value={g}>{g}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Box>
        );
        
      case 2:
        return (
          <Box>
            <Typography variant="h6" gutterBottom>
              Customize Your Prompt
            </Typography>
            <Typography variant="body2" color="text.secondary" paragraph>
              Provide specific instructions for the AI to generate questions. Be as detailed as possible about the topics, concepts, and skills you want to assess.
            </Typography>
            
            <TextField
              fullWidth
              multiline
              rows={6}
              label="Prompt for AI"
              value={prompt}
              onChange={(e) => setPrompt(e.target.value)}
              placeholder="Example: Generate questions about photosynthesis focusing on the chemical processes and energy transformation. Include questions that test both factual recall and conceptual understanding."
              required
              sx={{ mt: 2, mb: 3 }}
            />
            
            <Box sx={{ mb: 3, p: 2, bgcolor: 'background.default', borderRadius: 2 }}>
              <Typography variant="subtitle2" gutterBottom color="primary">
                Number of Questions to Generate
              </Typography>
              <Grid container spacing={3} alignItems="center">
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    type="number"
                    label="Number of Questions"
                    value={numberOfQuestions}
                    onChange={(e) => {
                      const value = parseInt(e.target.value) || 5;
                      const clampedValue = Math.max(1, Math.min(20, value));
                      console.log('Setting numberOfQuestions to:', clampedValue);
                      setNumberOfQuestions(clampedValue);
                    }}
                    InputProps={{ 
                      inputProps: { min: 1, max: 20 },
                      startAdornment: <Typography variant="body2" sx={{ mr: 1 }}>#</Typography>
                    }}
                    variant="outlined"
                    color="primary"
                    focused
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body2" color="text.secondary">
                    Choose between 1-20 questions. The AI will generate exactly this many questions based on your prompt.
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Box>
        );
        
      case 3:
        return (
          <Box>
            <Typography variant="h6" gutterBottom>
              Review Generated Worksheet
            </Typography>
            
            {generatedWorksheet ? (
              <Box>
                <Paper sx={{ p: 3, mb: 3, border: '1px solid', borderColor: 'divider' }}>
                  <Typography variant="h5" gutterBottom>
                    {generatedWorksheet.title}
                  </Typography>
                  
                  <Typography variant="body2" color="text.secondary" paragraph>
                    {generatedWorksheet.description}
                  </Typography>
                  
                  <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
                    <Typography variant="body2">
                      <strong>Subject:</strong> {generatedWorksheet.subject}
                    </Typography>
                    <Typography variant="body2">
                      <strong>Grade:</strong> {generatedWorksheet.grade}
                    </Typography>
                  </Box>
                  
                  <Divider sx={{ my: 2 }} />
                  
                  <Typography variant="subtitle1" gutterBottom>
                    Questions ({generatedWorksheet.questions?.length || 0})
                  </Typography>
                  
                  {generatedWorksheet.questions && generatedWorksheet.questions.length > 0 ? (
                    generatedWorksheet.questions.map((question, index) => (
                    <Box key={index} sx={{ mb: 3, pl: 2, borderLeft: '3px solid', borderColor: 'primary.main' }}>
                      <Typography variant="body1" gutterBottom>
                        <strong>{index + 1}.</strong> {question.questionText || 'No question text provided'}
                      </Typography>
                      
                      {question.questionType === 'multiple-choice' && (
                        <Box sx={{ ml: 4, mt: 1 }}>
                          {Array.isArray(question.options) && question.options.length > 0 ? (
                            question.options.map((option, optIndex) => (
                              <Typography key={optIndex} variant="body2" sx={{ mb: 0.5 }}>
                                {String.fromCharCode(65 + optIndex)}. {option}
                              </Typography>
                            ))
                          ) : (
                            <Typography variant="body2" color="text.secondary">
                              No options provided for this multiple-choice question
                            </Typography>
                          )}
                          <Typography variant="body2" sx={{ mt: 1, color: 'success.main' }}>
                            Correct Answer: {question.correctAnswer || 'Not specified'}
                          </Typography>
                        </Box>
                      )}
                      
                      {(question.questionType === 'short-answer' || question.questionType === 'long-answer') && (
                        <Box sx={{ ml: 4, mt: 1 }}>
                          <Typography variant="body2" sx={{ fontStyle: 'italic' }}>
                            {question.questionType === 'short-answer' ? 'Short answer question' : 'Long answer question'}
                          </Typography>
                          <Typography variant="body2" sx={{ mt: 1, color: 'success.main' }}>
                            Answer Guidelines: {question.answerGuidelines || 'Not provided'}
                          </Typography>
                        </Box>
                      )}
                      
                      {(!question.questionType || (question.questionType !== 'multiple-choice' && 
                                                  question.questionType !== 'short-answer' && 
                                                  question.questionType !== 'long-answer')) && (
                        <Box sx={{ ml: 4, mt: 1 }}>
                          <Typography variant="body2" sx={{ fontStyle: 'italic' }}>
                            Question type: {question.questionType || 'Not specified'}
                          </Typography>
                        </Box>
                      )}
                    </Box>
                  ))
                  ) : (
                    <Box sx={{ textAlign: 'center', py: 3, bgcolor: 'background.default', borderRadius: 1 }}>
                      <Typography variant="body1" color="text.secondary" gutterBottom>
                        No questions were generated. The backend processed your request but didn't return any questions.
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        Possible reasons:
                      </Typography>
                      <ul style={{ textAlign: 'left', display: 'inline-block', marginTop: '8px' }}>
                        <li>The AI model couldn't generate appropriate questions for your prompt</li>
                        <li>There was an issue with the template processing</li>
                        <li>The response format from the backend was unexpected</li>
                      </ul>
                      <Typography variant="body2" color="primary" sx={{ mt: 2, fontWeight: 'medium' }}>
                        Try adjusting your prompt to be more specific about the types of questions you want.
                      </Typography>
                    </Box>
                  )}
                </Paper>
                
                {generatedWorksheet.questions && generatedWorksheet.questions.length > 0 ? (
                  <Box sx={{ display: 'flex', gap: 2 }}>
                    <Button
                      variant="contained"
                      color="primary"
                      startIcon={<SaveIcon />}
                      onClick={handleAcceptWorksheet}
                      disabled={loading}
                      fullWidth
                    >
                      Accept & View My Worksheets
                    </Button>
                    <Button
                      variant="outlined"
                      color="error"
                      onClick={handleRejectWorksheet}
                      disabled={loading}
                      fullWidth
                    >
                      {loading ? 'Rejecting...' : 'Reject & Try Again'}
                    </Button>
                  </Box>
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      setActiveStep(2); // Go back to the prompt step
                      setError('Please try again with a more specific prompt');
                    }}
                    fullWidth
                  >
                    Try Again with Different Prompt
                  </Button>
                )}
              </Box>
            ) : (
              <Box sx={{ textAlign: 'center', py: 4 }}>
                <Typography variant="body1" color="text.secondary">
                  Click "Generate" to create your worksheet based on the template and prompt.
                </Typography>
              </Box>
            )}
          </Box>
        );
        
      default:
        return null;
    }
  };
  
  // Get the selected template name
  const getSelectedTemplateName = () => {
    if (!templateId) return '';
    const template = templates.find(t => t._id === templateId);
    return template ? template.name : '';
  };
  
  // Render the context header (template, subject, grade)
  const renderContextHeader = () => {
    // Only show if we've completed at least the first step
    if (activeStep < 1) return null;
    
    return (
      <Paper sx={{ p: 2, mb: 3, bgcolor: 'primary.light', color: 'primary.contrastText' }}>
        <Grid container spacing={2} alignItems="center">
          {templateId && (
            <Grid item xs={12} sm={4}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <TemplateIcon sx={{ mr: 1 }} />
                <Typography variant="body2" fontWeight="medium">
                  Template: {getSelectedTemplateName()}
                </Typography>
              </Box>
            </Grid>
          )}
          
          {subject && activeStep >= 2 && (
            <Grid item xs={6} sm={4}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <CurriculumIcon sx={{ mr: 1 }} />
                <Typography variant="body2" fontWeight="medium">
                  Subject: {subject}
                </Typography>
              </Box>
            </Grid>
          )}
          
          {grade && activeStep >= 2 && (
            <Grid item xs={6} sm={4}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="body2" fontWeight="medium">
                  Grade: {grade}
                </Typography>
              </Box>
            </Grid>
          )}
        </Grid>
      </Paper>
    );
  };
  
  return (
    <Box>
      <Stepper activeStep={activeStep} alternativeLabel sx={{ mb: 4 }}>
        {steps.map((label, index) => (
          <Step key={label}>
            <StepLabel 
              StepIconProps={{
                icon: index === 0 ? <TemplateIcon /> : 
                      index === 1 ? <CurriculumIcon /> : 
                      index === 2 ? <PromptIcon /> : 
                      <PreviewIcon />
              }}
            >
              {label}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
      
      {renderContextHeader()}
      
      {error && (
        <Alert severity="error" sx={{ mb: 3 }}>
          {error}
        </Alert>
      )}
      
      <Box sx={{ mb: 4 }}>
        {renderStepContent()}
      </Box>
      
      <Divider sx={{ mb: 3 }} />
      
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Button
          disabled={activeStep === 0 || loading}
          onClick={handleBack}
        >
          Back
        </Button>
        
        <Box>
          {activeStep === 2 ? (
            <Button
              variant="contained"
              color="primary"
              onClick={handleGenerate}
              disabled={loading}
              startIcon={loading && <CircularProgress size={20} color="inherit" />}
            >
              {loading ? 'Generating...' : 'Generate'}
            </Button>
          ) : activeStep < steps.length - 1 ? (
            <Button
              variant="contained"
              onClick={handleNext}
              disabled={loading}
            >
              Next
            </Button>
          ) : null}
        </Box>
      </Box>
    </Box>
  );
};

export default TemplateGenerator;