import React, { useState, useEffect, useCallback, useMemo } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { userStorage } from '../../utils/userStorage';
import ClassSelector from '../Classes/ClassSelector';
import DataTable from './DataTable';
import ThreeMonthCalendar from './ThreeMonthCalendar';
import AddRecordModal from './AddRecordModal';
import '../../css/Home.css';
import Papa from 'papaparse';
import { DNA } from 'react-loader-spinner';
import {isTokenExpired, protectedApiCall, protectedFetch, refreshAccessToken} from "../../auth";
import debounce from 'lodash/debounce';

const Dash = () => {
    const getStorageItem = (key, defaultValue) => {
        try {
            console.log(`Getting ${key} from localStorage`);
            const item = localStorage.getItem(key);
            console.log(`Raw value for ${key}:`, item);
            
            if (item === null || item === 'null' || item === 'undefined') {
                console.warn(`No valid data found for ${key}, using default:`, defaultValue);
                return defaultValue;
            }
            
            const parsed = JSON.parse(item);
            console.log(`Parsed value for ${key}:`, parsed);
            
            if (parsed === null || typeof parsed === 'undefined') {
                console.warn(`Invalid data format for ${key}, using default:`, defaultValue);
                localStorage.removeItem(key);
                return defaultValue;
            }
            
            // For selectedClass, validate required fields
            if (key === 'selectedClass' && parsed) {
                const requiredFields = ['_id', 'className', 'teacher'];
                const missingFields = requiredFields.filter(field => !parsed[field]);
                if (missingFields.length > 0) {
                    console.warn(`Selected class missing required fields: ${missingFields.join(', ')}`);
                    localStorage.removeItem(key);
                    return defaultValue;
                }
            }
            
            return parsed;
        } catch (error) {
            console.error(`Error reading ${key} from localStorage:`, error);
            localStorage.removeItem(key); // Clean up invalid data
            return defaultValue;
        }
    };
    const [terms, setTerms] = useState([]);
    const [classes, setClasses] = useState([]);
    const [selectedClass, setSelectedClass] = useState(() =>
        getStorageItem('selectedClass', null)
    );

    // Removed validateDateRange as it was causing issues with date selection
    const [data, setData] = useState([]);
    const [role, setRole] = useState([]);
    const [currentDate, setCurrentDate] = useState(() => {
        const savedDate = localStorage.getItem('currentDate');
        try {
            return savedDate ? new Date(savedDate) : new Date();
        } catch (e) {
            console.error('Error parsing currentDate from localStorage:', e);
            return new Date();
        }
    });
    const [dateRange, setDateRange] = useState(() => {
        try {
            const defaultRange = [new Date(), new Date()];
            const storedRange = getStorageItem('dateRange', defaultRange);
            
            // Validate stored dates
            const validDates = storedRange.map(d => {
                const date = new Date(d);
                return isNaN(date.getTime()) ? new Date() : date;
            });
            
            console.log('Initial dateRange:', {
                dates: validDates,
                formatted: validDates.map(d => d.toISOString().split('T')[0])
            });
            
            return validDates;
        } catch (error) {
            console.error('Error initializing date range:', error);
            const today = new Date();
            return [today, today];
        }
    });
    const [tableState, setTableState] = useState({ columns: [], rows: [] });
    const [selectedName, setSelectedName] = useState(() => {
        const storedName = userStorage.getItem('selectedName');
        const storedStudent = userStorage.getItem('selectedStudent');
        const savedClass = userStorage.getItem('selectedClass');
        
        console.log('=== Initializing Dashboard Student Selection ===');
        console.log('Stored values:', {
            storedName,
            storedStudent,
            savedClass: savedClass?.className
        });

        // If we have both a class and a stored student ID, try to find the name
        if (savedClass?.role && storedStudent && storedStudent !== 'class') {
            const student = savedClass.role.find(s => s._id === storedStudent);
            if (student) {
                console.log('Found student name from stored ID:', student.firstName);
                return student.firstName;
            }
        }

        // If we have both a class and a stored name, verify it exists
        if (savedClass?.role && storedName && storedName !== 'class') {
            const studentExists = savedClass.role.some(s => s.firstName === storedName);
            if (studentExists) {
                console.log('Using stored student name:', storedName);
                return storedName;
            }
        }

        console.log('Using default class view');
        return 'class';
    });
    const [showCalendar, setShowCalendar] = useState(false);
    const [recordDates, setRecordDates] = useState([]);
    const [recordCount, setRecordCount] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [totalRecords, setTotalRecords] = useState(0);
    const [filteredData, setFilteredData] = useState([]);
    const [selectedTerms, setSelectedTerms] = useState(() =>
        getStorageItem('selectedTerms', [])
    );
    const [dateRangeKey, setDateRangeKey] = useState(0);
    const navigate = useNavigate();
    const displayName = useMemo(() => selectedName || 'class', [selectedName]);
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    const [showEditModal, setShowEditModal] = useState();
    const [showAddModal, setShowAddModal] = useState(false);
    const [selectedClassId, setSelectedClassId] = useState(() => {
        const savedClassId = localStorage.getItem('selectedClassId');
        return savedClassId ? JSON.parse(savedClassId) : null;
    });
    const [selectedRecord, setSelectedRecord] = useState(null);
    const [renderKey, setRenderKey] = useState(0);
    const [selectedYear, setSelectedYear] = useState(() => {
        const currentYear = new Date().getFullYear().toString();
        const savedYear = localStorage.getItem('selectedYear');
        
        // If no saved year or saved year is not valid, use current year
        if (!savedYear || isNaN(parseInt(savedYear))) {
            localStorage.setItem('selectedYear', currentYear);
            return currentYear;
        }
        
        return savedYear;
    });

    const setStorageItem = (key, value) => {
        try {
            localStorage.setItem(key, JSON.stringify(value));
        } catch (error) {
            console.error(`Error saving ${key} to localStorage:`, error);
        }
    };

    const handleYearChange = (event = {}) => {
        const newYear = event.target ? event.target.value : selectedYear; // Use selectedYear as a fallback
        console.log('Year changed to:', newYear);
        
        // Validate the year
        if (!newYear || isNaN(parseInt(newYear))) {
            console.error('Invalid year selected:', newYear);
            return;
        }
        
        // Update the year state and storage
        setSelectedYear(newYear);
        localStorage.setItem('selectedYear', newYear);
        
        // Log for debugging
        console.log('Year updated in localStorage:', localStorage.getItem('selectedYear'));
        
        // Clear selected terms
        setSelectedTerms([]);
        
        // Update the date range to the first day of the new year
        const startOfYear = new Date(parseInt(newYear), 0, 1);
        const endOfYear = new Date(parseInt(newYear), 11, 31);
        const newRange = [startOfYear, endOfYear];
        
        console.log('Updating date range for new year:', newRange);
        setDateRange(newRange);
        setCurrentDate(startOfYear);
        localStorage.setItem('dateRange', JSON.stringify(newRange));
        
        // Reset to page 1 and fetch new data
        setCurrentPage(1);
        fetchData();
    };


    useEffect(() => {
        console.log('selectedYear changed to:', selectedYear);
        console.log('Current dateRange before fetch:', {
            dates: dateRange,
            isoStrings: dateRange.map(d => d.toISOString())
        });
        Promise.all([
            fetchClasses(),
            fetchTerms()
        ]).then(() => {
            // Only fetch data if we have valid dates in the current year
            if (dateRange[0].getFullYear().toString() === selectedYear &&
                dateRange[1].getFullYear().toString() === selectedYear) {
                fetchData();
            }
        });
    }, [selectedYear]);

    useEffect(() => {
        if (window.dataLayer) {
            window.dataLayer.push({
                event: 'pageview',
                page: '/dash'
            });
        }
    }, []);


    const filteredTerms = terms.filter(term => term.year === selectedYear);


    useEffect(() => {
        const savedClass = localStorage.getItem('selectedClass');

        if (savedClass) {
            try {
                const parsedClass = JSON.parse(savedClass);
                if (parsedClass && parsedClass._id) {
                    console.log('Loading saved class:', parsedClass.className);
                    // Set the class directly since we have the full object
                    setSelectedClass(parsedClass);
                    setSelectedClassId(parsedClass._id);
                    setRole(parsedClass.role || []);
                } else {
                    console.warn('Invalid saved class data, clearing...');
                    localStorage.removeItem('selectedClass');
                }
            } catch (e) {
                console.error('Error parsing saved class from localStorage', e);
                localStorage.removeItem('selectedClass');
            }
        }
    }, []);

    const handleEdit = (classId, record) => {
        setSelectedRecord(record);  // Store the selected record
        setShowEditModal(true);  // Open the modal
    };

    const handleSaveRecord = async (updatedRecord) => {
        const date = new Date(updatedRecord.date);
        const year = date.getFullYear().toString();
        const day = date.toISOString().split('T')[0];

        try {
            const result = await protectedFetch(async () => {
                const response = await fetch(`${BASE_URL}/api/records/${selectedClass._id}/${year}/${day}/${updatedRecord._id}`, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${localStorage.getItem('token')}`,
                    },
                    body: JSON.stringify(updatedRecord),
                });
                return response.json();
            }, navigate);

            if (result) {
                setData((prevData) => {
                    const newData = prevData.map((r) =>
                        r._id === result._id ? result : r
                    );
                    return [...newData];
                });
                setRenderKey((prevKey) => prevKey + 1);
                await fetchData();
            }
        } catch (error) {
            console.error('Error updating record:', error);
        }
    };
    
    const handleRecordAdded = async (newRecord) => {
        console.log('New record added:', newRecord);
        // Refresh the data to include the new record
        await fetchData();
    };

    const handleDelete = async (classId, record) => {
        // Extract the date portion (YYYY-MM-DD) and the year from the ISO string
        const date = new Date(record.date);
        const year = date.getFullYear().toString(); // Extract the year (e.g., 2024)
        const day = date.toISOString().split('T')[0]; // Extract the date part (e.g., "2024-08-09")

        // Log what you're about to delete
        console.log('GONNA DELETE', selectedClass._id, year, day, record._id);

        if (window.confirm("Are you sure you want to delete this item?")) {
            try {
                const response = await fetch(`${BASE_URL}/api/records/${selectedClass._id}/${year}/${day}/${record._id}`, {
                    method: 'DELETE',
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`,
                    },
                });

                if (response.ok) {
                    // Update state to remove the deleted record
                    setData((prevData) => prevData.filter(r => r._id !== record._id));
                } else {
                    console.error('Failed to delete record');
                }
            } catch (error) {
                console.error('Error deleting record:', error);
            }
        }
    };


    useEffect(() => {
        const initializeData = async () => {
            console.log('Initializing dashboard data...');
            
            try {
                // First fetch available classes
                await fetchClasses();
                
                // Then try to restore saved class
                const savedClass = getStorageItem('selectedClass', null);
                if (savedClass && savedClass._id) {
                    console.log('Found saved class, verifying...', savedClass);
                    
                    // Verify the class exists in the user's available classes
                    const classExists = classes.some(c => c._id === savedClass._id);
                    if (classExists) {
                        console.log('Saved class is valid, restoring selection');
                        setSelectedClass(savedClass);
                        setRole(savedClass.role || []);
                        setSelectedClassId(savedClass._id);
                    } else {
                        console.warn('Saved class not found in available classes, clearing selection');
                        localStorage.removeItem('selectedClass');
                    }
                }

                // Initialize date range
                const defaultRange = [
                    new Date(selectedYear, 0, 1),  // Start of year
                    new Date(selectedYear, 11, 31)  // End of year
                ];
                const savedRange = getStorageItem('dateRange', defaultRange);
                
                // Validate and adjust dates
                const adjustedRange = savedRange.map(date => {
                    const d = new Date(date);
                    if (isNaN(d.getTime())) {
                        console.warn('Invalid date found, using default');
                        return new Date();
                    }
                    // Ensure date is in selected year
                    if (d.getFullYear().toString() !== selectedYear) {
                        return new Date(selectedYear, d.getMonth(), d.getDate());
                    }
                    return d;
                });

                console.log('Using date range:', {
                    dates: adjustedRange,
                    formatted: adjustedRange.map(d => d.toISOString().split('T')[0])
                });

                setDateRange(adjustedRange);
                setCurrentDate(adjustedRange[0]);

            } catch (error) {
                console.error('Error initializing saved data:', error);
                // Set safe defaults
                const defaultRange = [
                    new Date(selectedYear, 0, 1),
                    new Date(selectedYear, 11, 31)
                ];
                setDateRange(defaultRange);
                setCurrentDate(defaultRange[0]);
            }

            // Fetch initial data
            try {
                console.log('Fetching initial data...');
                await Promise.all([
                    fetchTerms(),
                    fetchClasses()
                ]);
            } catch (error) {
                console.error('Error fetching initial data:', error);
            }
        };

        initializeData();
    }, []);

    const fetchClasses = async () => {
        try {
            const result = await protectedFetch(async () => {
                try {
                    // Get current user to get school ID
                    const user = JSON.parse(localStorage.getItem('user'));
                    const schoolId = user?.currentSchool?.id || user?.currentSchool?._id || user?.currentSchool;

                    console.log('Fetching classes with:', {
                        userId: user?.id,
                        schoolId,
                        schoolObject: user?.currentSchool,
                        token: localStorage.getItem('token')?.substring(0, 20) + '...'
                    });

                    if (!schoolId || typeof schoolId !== 'string') {
                        console.error('No school selected');
                        return [];
                    }

                    const token = localStorage.getItem('token');
                    const requestConfig = {
                        headers: { 
                            'Authorization': `Bearer ${token}`,
                            'Content-Type': 'application/json'
                        },
                        params: { 
                            schoolId,
                            userId: user?.id
                        }
                    };

                    console.log('Making request with config:', {
                        url: `${BASE_URL}/api/classes`,
                        config: {
                            ...requestConfig,
                            headers: {
                                ...requestConfig.headers,
                                'Authorization': 'Bearer ' + token?.substring(0, 20) + '...'
                            }
                        }
                    });

                    const response = await axios.get(`${BASE_URL}/api/classes`, requestConfig);

                    console.log('Classes API response:', {
                        status: response.status,
                        data: response.data,
                        headers: response.headers
                    });

                    return response.data;
                } catch (error) {
                    if (error.response?.status === 404) {
                        console.warn('No classes found (404 response):', {
                            status: error.response.status,
                            data: error.response.data,
                            headers: error.response.headers
                        });
                        return [];
                    }
                    console.error('Error fetching classes:', {
                        error,
                        response: error.response?.data,
                        status: error.response?.status,
                        headers: error.response?.headers
                    });
                    throw error;
                }
            }, navigate);
            
            if (result && Array.isArray(result)) {
                setClasses(result);
                if (result.length === 0) {
                    localStorage.removeItem('selectedClass');
                    setSelectedClass(null);
                }
            } else {
                setClasses([]);
                localStorage.removeItem('selectedClass');
                setSelectedClass(null);
            }
        } catch (error) {
            console.error('Error fetching classes:', error);
            setClasses([]);
            localStorage.removeItem('selectedClass');
            setSelectedClass(null);
        }
    };

    const fetchTerms = async () => {
        try {
            const result = await protectedFetch(async () => {
                const response = await axios.get(`${BASE_URL}/api/terms`, {
                    params: { 
                        year: selectedYear,
                        schoolId: JSON.parse(localStorage.getItem('user'))?.currentSchool
                    },
                    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
                });
                return response.data;
            }, navigate);
            
            if (result) {
                const yearTerms = result.filter(term => term.year === selectedYear);
                setTerms(yearTerms);
                
                // If no terms found for the year, set default date range
                if (yearTerms.length === 0) {
                    const startOfYear = new Date(parseInt(selectedYear), 0, 1);
                    const endOfYear = new Date(parseInt(selectedYear), 11, 31);
                    setDateRange([startOfYear, endOfYear]);
                }
            } else {
                setTerms([]);
                console.warn('No terms returned from API');
            }
        } catch (error) {
            console.error('Error fetching terms:', error);
            setTerms([]);
            
            // Set default date range on error
            const startOfYear = new Date(parseInt(selectedYear), 0, 1);
            const endOfYear = new Date(parseInt(selectedYear), 11, 31);
            setDateRange([startOfYear, endOfYear]);
        }
    };




    const fetchData = useCallback(async () => {
        if (!selectedClass) return;

        setIsLoading(true);
        console.log('fetchData executing with dateRange:', {
            dates: dateRange,
            isoStrings: dateRange.map(d => d.toISOString())
        });
        
        // Ensure dates are ordered and format them in YYYY-MM-DD format
        const formatDate = (date) => {
            return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;
        };
        
        // Sort dates to ensure correct order
        const [startDate, endDate] = [...dateRange].sort((a, b) => a.getTime() - b.getTime());
        const start = formatDate(startDate);
        const end = formatDate(endDate);
        
        console.log('Using ordered date range:', {
            start,
            end,
            startObj: startDate,
            endObj: endDate
        });
        
        console.log('Making API call with normalized dates:', { start, end });
        try {
            const result = await protectedFetch(async () => {
                const response = await axios.get(
                    `${BASE_URL}/api/classes/${selectedClass._id}/range/${start}/${end}`,
                    {
                        params: {
                            year: selectedYear,
                            page: currentPage,
                            limit: 100,
                            studentName: selectedName !== 'class' ? selectedName : '',
                            schoolId: JSON.parse(localStorage.getItem('user'))?.currentSchool
                        },
                        headers: { 
                            Authorization: `Bearer ${localStorage.getItem('token')}`
                        }
                    }
                );
                return response.data;
            }, navigate);
            console.log('Got stuff, and now ... ')
            if (result) {
                const { totalRecords, currentPage, totalPages, records } = result;
                setData(records);
                setTotalRecords(totalRecords);
                setCurrentPage(currentPage);
                setTotalPages(totalPages);
                setRole(selectedClass.role || []);
                fetchRecordDates(selectedClass._id, selectedName);
            }
            console.log('... Done ');
        } catch (error) {
            console.error('Error fetching data:', error);
            setData([]);
        } finally {
            setIsLoading(false);
        }
    }, [selectedClass, currentPage, selectedName, selectedYear, dateRange]);


    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const fetchRecordDates = useCallback(async (classId, studentName = null) => {
        try {
            const result = await protectedFetch(async () => {
                const response = await axios.get(`${BASE_URL}/api/classes/${classId}/record-dates`, {
                    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
                    params: { 
                        studentName: studentName === 'class' ? null : studentName,
                        schoolId: JSON.parse(localStorage.getItem('user'))?.currentSchool
                    }
                });
                return response.data;
            }, navigate);

            if (result) {
                setRecordDates(result.map(date => date.split('T')[0]));
            }
        } catch (error) {
            console.error('Error fetching record dates:', error);
            setRecordDates([]);
        }
    }, []);

    useEffect(() => {
        console.log('SETUP!!!!')
        setRecordCount(data.length);
    }, [data]);

    useEffect(() => {
        localStorage.setItem('selectedClass', JSON.stringify(selectedClass));
    }, [selectedClass, dateRange]);

    useEffect(() => {
        localStorage.setItem('currentDate', JSON.stringify(currentDate));
    }, [currentDate]);

    useEffect(() => {
        if (!dateRange || dateRange.length !== 2) {
            console.log('Invalid dateRange, skipping localStorage update');
            return;
        }

        console.log('dateRange changed, updating localStorage:', {
            dates: dateRange,
            formatted: dateRange.map(d => 
                `${d.getFullYear()}-${String(d.getMonth() + 1).padStart(2, '0')}-${String(d.getDate()).padStart(2, '0')}`
            )
        });
        localStorage.setItem('dateRange', JSON.stringify(dateRange));
    }, [dateRange]);

    useEffect(() => {
        localStorage.setItem('selectedName', selectedName);
    }, [selectedName]);

    const navigatePrevious = () => {
        setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
    };

    const navigateNext = () => {
        setCurrentPage(prevPage => Math.min(prevPage + 1, totalPages));
    };

    // Remove debounced fetch as it's causing issues with state updates
    const debouncedFetchData = useCallback(() => {
        fetchData();
    }, [fetchData]);

    const handleDateRangeChange = (range) => {
        console.log('handleDateRangeChange received range:', {
            dates: range,
            isoStrings: range.map(d => d.toISOString())
        });
        
        if (!Array.isArray(range) || range.length !== 2) {
            console.log('Invalid range received:', range);
            return;
        }

        // Create completely new Date objects, ensure they're at start of day, and properly ordered
        const dates = range.map(d => new Date(
            d.getFullYear(),
            d.getMonth(),
            d.getDate(),
            0, 0, 0, 0
        ));
        
        // Sort dates to ensure start date is always before end date
        const newRange = dates.sort((a, b) => a.getTime() - b.getTime());
        
        console.log('Created new date range:', {
            dates: newRange,
            formatted: newRange.map(d => 
                `${d.getFullYear()}-${String(d.getMonth() + 1).padStart(2, '0')}-${String(d.getDate()).padStart(2, '0')}`
            )
        });
        
        // Update the year if it's different from the selected dates
        const selectedYear = newRange[0].getFullYear().toString();
        if (selectedYear !== localStorage.getItem('selectedYear')) {
            console.log('Updating year to match selected dates:', selectedYear);
            setSelectedYear(selectedYear);
            localStorage.setItem('selectedYear', selectedYear);
            // Clear selected terms when year changes
            setSelectedTerms([]);
        }
        
        // Force React to recognize this as a new state update
        const finalRange = newRange.map(d => new Date(d.getTime()));
        console.log('Setting final date range:', {
            dates: finalRange,
            formatted: finalRange.map(d => 
                `${d.getFullYear()}-${String(d.getMonth() + 1).padStart(2, '0')}-${String(d.getDate()).padStart(2, '0')}`
            )
        });
        
        // Update all related state
        setDateRange(finalRange);
        setCurrentDate(new Date(finalRange[0].getTime()));
        setCurrentPage(1);
        setDateRangeKey(prev => prev + 1); // Force calendar to update
        
        // Update state first
        setDateRange(finalRange);
        setCurrentDate(new Date(finalRange[0].getTime()));
        setCurrentPage(1);
        setDateRangeKey(k => k + 1);
        setIsLoading(true);

        // Fetch data directly with the new range instead of using state
        const formatDate = (date) => {
            return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;
        };

        const [start, end] = finalRange.map(formatDate);
        console.log('Making immediate API call with dates:', { start, end });

        protectedFetch(async () => {
            const response = await axios.get(
                `${BASE_URL}/api/classes/${selectedClass._id}/range/${start}/${end}`,
                {
                    params: {
                        year: selectedYear,
                        page: currentPage,
                        limit: 100,
                        studentName: selectedName !== 'class' ? selectedName : ''
                    },
                    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
                }
            );
            return response.data;
        }, navigate).then(result => {
            if (result) {
                const { totalRecords, currentPage, totalPages, records } = result;
                setData(records);
                setTotalRecords(totalRecords);
                setCurrentPage(currentPage);
                setTotalPages(totalPages);
                setRole(selectedClass.role || []);
                fetchRecordDates(selectedClass._id, selectedName);
            }
            setIsLoading(false);
        }).catch(error => {
            console.error('Error fetching data:', error);
            setData([]);
            setIsLoading(false);
        });
    };


    const handleTermChange = (event) => {
        const selectedTermNumbers = Array.from(event.target.selectedOptions, option =>
            parseInt(option.value, 10)
        );
        setSelectedTerms(selectedTermNumbers);

        const selectedTermObjects = terms.filter(term =>
            selectedTermNumbers.includes(term.termNumber)
        );

        if (selectedTermObjects.length > 0) {
            const startDates = selectedTermObjects.map(term => new Date(term.startDate));
            const endDates = selectedTermObjects.map(term => new Date(term.endDate));
            const overallStartDate = new Date(Math.min(...startDates));
            const overallEndDate = new Date(Math.max(...endDates));

            const newRange = [new Date(overallStartDate), new Date(overallEndDate)];
            setDateRange(newRange);
            setCurrentDate(newRange[0]);
            setCurrentPage(1);
            localStorage.setItem('dateRange', JSON.stringify(newRange));

            // Call fetchData directly
            fetchData();

            localStorage.setItem('selectedTerms', JSON.stringify(selectedTermNumbers));
            localStorage.setItem('startDate', overallStartDate.toISOString());
            localStorage.setItem('endDate', overallEndDate.toISOString());
        }
    };


    const handleClassSelect = (selectedClass) => {
        console.log('handleClassSelect called with:', selectedClass);
        setSelectedClass(selectedClass);
        
        if (selectedClass) {
            try {
                const classToStore = {
                    _id: selectedClass._id,
                    className: selectedClass.className,
                    teacher: selectedClass.teacher,
                    role: selectedClass.role || []
                };
                localStorage.setItem('selectedClass', JSON.stringify(classToStore));
                console.log('Saved class to localStorage:', classToStore);
            } catch (error) {
                console.error('Error saving class to localStorage:', error);
                localStorage.removeItem('selectedClass');
            }
        } else {
            console.log('Clearing selected class from localStorage');
            localStorage.removeItem('selectedClass');
        }
        
        setSelectedClassId(selectedClass ? selectedClass._id : null);
    };

    const handleStateChange = useCallback((newState) => {
        setTableState(newState);
    }, []);

    const handleExportCSV = async () => {
        if (!selectedClass || dateRange.length !== 2) {
            return;
        }

        const [startDate, endDate] = dateRange;
        const start = startDate.toISOString().split('T')[0];
        const end = endDate.toISOString().split('T')[0];
        const exportEndpoint = `${BASE_URL}/api/classes/${selectedClass._id}/range/export/${start}/${end}?studentName=${selectedName !== 'class' ? selectedName : ''}&schoolId=${JSON.parse(localStorage.getItem('user'))?.currentSchool}`;

        try {
            const result = await protectedFetch(async () => {
                const response = await axios.get(exportEndpoint, {
                    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
                });
                return response.data;
            }, navigate);

            if (result) {
                const { records } = result;
                const csvData = records.map(record => {
                    const rowData = {};
                    tableState.columns.forEach(col => {
                        if (record[col.key] !== undefined) {
                            rowData[col.label] = col.key === 'date' ? formatDate(record[col.key]) : record[col.key];
                        } else {
                            rowData[col.label] = '';
                        }
                    });
                    return rowData;
                });

                const csv = Papa.unparse(csvData);
                const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
                const url = URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.setAttribute('href', url);
                link.setAttribute('download', 'data.csv');
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        } catch (error) {
            console.error('Error exporting CSV:', error);
        }
    };

    // Effect to handle student selection persistence
    useEffect(() => {
        // Skip if we don't have a class loaded yet
        if (!selectedClass || !selectedClass.role) return;

        console.log('=== Checking Student Selection ===');
        
        // Get stored values using user-specific storage
        const storedStudent = userStorage.getItem('selectedStudent');
        const storedName = userStorage.getItem('selectedName');
        
        console.log('Current state:', {
            selectedName,
            selectedClass: selectedClass.className,
            storedName,
            storedStudent,
            availableStudents: selectedClass.role.map(s => ({ id: s._id, name: s.firstName }))
        });

        // If we have a valid current selection, keep it
        if (selectedName && selectedName !== 'class') {
            const studentExists = selectedClass.role.some(s => s.firstName === selectedName);
            if (studentExists) {
                console.log('Current student selection is valid:', selectedName);
                // Ensure storage is in sync
                const student = selectedClass.role.find(s => s.firstName === selectedName);
                if (student) {
                    userStorage.setItem('selectedName', selectedName);
                    userStorage.setItem('selectedStudent', student._id);
                }
                return;
            }
        }

        // First try by ID (from Reports)
        if (storedStudent && storedStudent !== 'class') {
            const student = selectedClass.role.find(s => s._id === storedStudent);
            if (student) {
                console.log('Restoring student from Reports by ID:', student.firstName);
                setSelectedName(student.firstName);
                userStorage.setItem('selectedName', student.firstName);
                return;
            }
        }

        // Then try by name (from Dashboard)
        if (storedName && storedName !== 'class') {
            const studentExists = selectedClass.role.some(s => s.firstName === storedName);
            if (studentExists) {
                console.log('Restoring student from Dashboard by name:', storedName);
                const student = selectedClass.role.find(s => s.firstName === storedName);
                if (student) {
                    setSelectedName(storedName);
                    userStorage.setItem('selectedStudent', student._id);
                }
                return;
            }
        }

        // Only clear if we had a selection that's no longer valid
        if (selectedName && selectedName !== 'class') {
            console.log('Previous selection is no longer valid:', selectedName);
            setSelectedName('class');
            userStorage.setItem('selectedName', 'class');
            userStorage.setItem('selectedStudent', 'class');
        }
    }, [selectedClass, selectedClass?.role]);

    const handleNameClick = useCallback((name) => {
        // Determine the new name value
        const newName = selectedName === name ? 'class' : name;
        console.log('Student selection changed:', { from: selectedName, to: newName, clickedName: name });
        
        try {
            // Update both dashboard and reports storage
            if (newName && newName !== 'class') {
                console.log('Saving student selection:', newName);
                // For dashboard, store the name
                userStorage.setItem('selectedName', newName);
                
                // For reports, find the student ID
                const selectedClass = classes.find(c => c._id === selectedClassId);
                if (selectedClass && selectedClass.role) {
                    const student = selectedClass.role.find(s => s.firstName === newName);
                    if (student) {
                        console.log('Found student ID for reports:', student._id);
                        userStorage.setItem('selectedStudent', student._id);
                    }
                }
            } else {
                console.log('Clearing student selection');
                userStorage.setItem('selectedName', 'class');
                userStorage.setItem('selectedStudent', 'class');
            }
            
            // Verify the storage was updated
            console.log('Stored values:', {
                selectedName: userStorage.getItem('selectedName'),
                selectedStudent: userStorage.getItem('selectedStudent')
            });
        } catch (error) {
            console.error('Error updating storage:', error);
        }
        
        setSelectedName(newName);
    }, [selectedName, classes, selectedClassId]);

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleString('en-US', {
            hour: 'numeric',
            minute: 'numeric',
            hour12: true,
            weekday: 'short',
            day: 'numeric',
            month: 'short'
        });
    };

    useEffect(() => {
        if (data.length > 0) {
            setFilteredData(data);
        }
    }, [data]);

    // Effect to handle data filtering based on student selection
    useEffect(() => {
        console.log('Filtering data for student:', selectedName);
        
        if (selectedName === 'class') {
            console.log('Showing all data (class view)');
            setFilteredData(data);
            return;
        }

        try {
            const filtered = data.filter(record => record.name && record.name.split(' ')[0] === selectedName);
            console.log(`Found ${filtered.length} records for student:`, selectedName);
            setFilteredData(filtered.length > 0 ? filtered : data);
        } catch (error) {
            console.error('Error filtering data:', error);
            setFilteredData(data);
        }
    }, [data, selectedName]);

    return (
        <div className="home-container">
            <div className="class-and-navigation">
                <div className="class-selector">
                    <label>Select A Class:</label>
                    <ClassSelector
                        classes={classes}
                        onSelect={handleClassSelect}
                        selectedClass={selectedClass}
                    />
                </div>
                <div className="year-selector">
                    <label>Select Year: </label>
                    <select
                        value={selectedYear}
                        onChange={handleYearChange}
                        style={{ padding: '4px 8px', borderRadius: '4px' }}
                    >
                        {(() => {
                            const currentYear = new Date().getFullYear();
                            const years = [];
                            // Include previous year, current year, and next 3 years
                            for (let year = currentYear - 1; year <= currentYear + 3; year++) {
                                years.push(
                                    <option key={year} value={year.toString()}>
                                        {year}
                                    </option>
                                );
                            }
                            return years;
                        })()}
                    </select>
                </div>
            </div>

            {!selectedClass ? (
                <div className="no-data-message">
                    Please select a class to view the dashboard.
                </div>
            ) : (
                <div className="main-content">
                    <div className="left-column">
                        <div className="role-selector">
                            {role && role.length > 0 ? (
                                role.map((person, index) => (
                                    <button
                                        key={index}
                                        onClick={() => handleNameClick(person.firstName)}
                                        className={selectedName === person.firstName ? 'selected' : 'unselected'}
                                    >
                                        {person.firstName} {person.lastName}
                                    </button>
                                ))
                            ) : (
                                <div className="no-data-message">
                                    No students found in this class.
                                </div>
                            )}
                        </div>
                        <div className="term-box">
                            <label htmlFor="term-select" className="term-select-text">Select Terms:</label>
                            <div className="term-sub_text">(Multiple Select)</div>
                            <select
                                className="select-button-home"
                                id="term-select"
                                multiple
                                onChange={handleTermChange}
                                value={selectedTerms}
                            >
                                {terms.map(term => (
                                    <option key={term.termNumber} value={term.termNumber}>
                                        Term {term.termNumber}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>

                    <div className="right-column">
                        <div className="navigation-controls">
                            <span className="date-container">
                                {Array.isArray(currentDate)
                                    ? `${currentDate[0].toDateString()} - ${currentDate[1].toDateString()}`
                                    : currentDate.toDateString()}
                            </span>
                            <button onClick={() => setShowCalendar(!showCalendar)}>
                                {showCalendar ? 'Hide Calendar' : 'Show Calendar'}
                            </button>
                        </div>

                        {showCalendar && (
                            <div className="calendar-container">
                                <ThreeMonthCalendar
                                    recordDates={recordDates}
                                    onDateRangeChange={handleDateRangeChange}
                                    dateRange={dateRange}
                                />
                            </div>
                        )}

                        <div className="table-container">
                            <div className="record-count">
                                Records for {displayName}: {`${(currentPage - 1) * 100 + 1} to ${Math.min(currentPage * 100, totalRecords)} of ${totalRecords}`}
                            </div>
                            {isLoading ? (
                                <div className="loader-container">
                                    <DNA
                                        visible={true}
                                        height="160"
                                        width="160"
                                        ariaLabel="dna-loading"
                                        wrapperStyle={{}}
                                        wrapperClass="dna-wrapper"
                                    />
                                </div>
                            ) : (
                                <DataTable
                                    data={filteredData}
                                    onStateChange={handleStateChange}
                                    onEdit={(record) => handleEdit(selectedClassId, record)}
                                    onDelete={(record) => handleDelete(selectedClassId, record)}
                                    setData={setData}
                                    showEditModal={showEditModal}
                                    setShowEditModal={setShowEditModal}
                                    selectedRecord={selectedRecord}
                                    setSelectedRecord={setSelectedRecord}
                                    record={selectedRecord}
                                    onSave={handleSaveRecord}
                                    onAddRecord={() => setShowAddModal(true)}
                                />
                            )}
                            <div className="pagination-controls">
                                <button onClick={navigatePrevious} disabled={currentPage === 1}>Previous</button>
                                <button onClick={navigateNext} disabled={currentPage === totalPages}>Next</button>
                            </div>
                            <div className="action-buttons">
                                <button onClick={handleExportCSV} className="export-button">Export CSV</button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            
            <AddRecordModal
                show={showAddModal}
                onHide={() => setShowAddModal(false)}
                selectedClass={selectedClass}
                onRecordAdded={handleRecordAdded}
            />
        </div>
    );
};

export default Dash;
