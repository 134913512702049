import React, { useRef, useEffect, useState } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { protectedApiCall } from '../../auth';
import { debounce } from "chart.js/helpers";
import newsletterTemplate from '../../templates/newsletterTemplate';
import announcementTemplate from '../../templates/announcementTemplate';
import newsletterTwoTemplate from '../../templates/tammooNewsletterTemplate';
import invitationTemplate from '../../templates/invitationTemplate';
import {
    Box,
    Button,
    Chip,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    FormControl,
    FormControlLabel,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Paper,
    Radio,
    RadioGroup,
    Select,
    Stack,
    TextField,
    Typography,
    useTheme
} from '@mui/material';
import { Add as AddIcon, Close as CloseIcon, Refresh as RefreshIcon } from '@mui/icons-material';

const templateOptions = {
    'Newsletter': newsletterTemplate,
    'Newsletter2': newsletterTwoTemplate,
    'Announcement': announcementTemplate,
    'Invitation': invitationTemplate,
};

const NewsletterEditor = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    const editorRef = useRef(null);
    const [content, setContent] = useState(newsletterTemplate);
    const [version, setVersion] = useState('');
    const [versions, setVersions] = useState([]);
    const [selectedVersion, setSelectedVersion] = useState('');
    const [showPreview, setShowPreview] = useState(false);
    const [selectedTags, setSelectedTags] = useState([]);
    const [customTag, setCustomTag] = useState('');
    const [selectedTemplate, setSelectedTemplate] = useState('Newsletter');
    const [testMode, setTestMode] = useState('test');
    const [directEmail, setDirectEmail] = useState('');

    const predefinedTags = ['Newsletter', 'Update', 'General', 'Voting'];

    useEffect(() => {
        fetchVersions();
    }, []);

    // Update content when template changes
    useEffect(() => {
        if (window.confirm('Switching templates will overwrite your current changes. Do you want to proceed?')) {
            if (editorRef.current) {
                editorRef.current.setContent(templateOptions[selectedTemplate]);
            }
            setContent(templateOptions[selectedTemplate]);
        }
    }, [selectedTemplate]);

    // Fetch newsletter versions
    const fetchVersions = async () => {
        try {
            const response = await protectedApiCall('/api/newsletters/versions', 'GET', null, false, navigate);
            
            if (response && response.data) {
                setVersions(response.data);
            }
        } catch (error) {
            console.error('Error fetching newsletter versions:', error);
        }
    };

    const saveAndSendNewsletter = async () => {
        try {
            const savedNewsletter = await saveNewsletter();
            if (savedNewsletter) {
                const editedContent = editorRef.current.getContent();
                const fullContent = templateOptions[selectedTemplate].replace(
                    /<body>[\s\S]*<\/body>/,
                    `<body>${editedContent}</body>`
                );
                
                const payload = {
                    content: fullContent,
                    tags: selectedTags,
                    testMode,
                };
                
                const response = await protectedApiCall('/api/newsletters/send-newsletter', 'POST', payload, false, navigate);

                if (response && response.data) {
                    alert('Newsletter saved and sent successfully!');
                }
            }
        } catch (error) {
            console.error('Error sending newsletter:', error);
            alert('Failed to send newsletter.');
        }
    };

    const debouncedSetContent = useRef(debounce((newContent) => {
        setContent(newContent);
    }, 300)).current;
    
    const saveNewsletter = async () => {
        if (!version && !selectedVersion) {
            alert('Please enter a version name.');
            return null;
        }

        try {
            const editedContent = editorRef.current.getContent();
            const fullContent = templateOptions[selectedTemplate].replace(
                /<body>[\s\S]*<\/body>/,
                `<body>${editedContent}</body>`
            );

            const payload = {
                content: fullContent,
                version: version || selectedVersion,
                tags: selectedTags
            };
            
            const response = await protectedApiCall('/api/newsletters/save', 'POST', payload, false, navigate);

            if (response && response.data) {
                alert('Newsletter saved successfully!');
                await fetchVersions();
                return response.data;
            }
        } catch (error) {
            console.error('Error saving newsletter:', error);
            alert('Failed to save newsletter.');
        }
        return null;
    };

    const loadVersion = async (version) => {
        try {
            const response = await protectedApiCall(`/api/newsletters/${version}`, 'GET', null, false, navigate);

            if (response && response.data && response.data.content) {
                setContent(response.data.content);
                setSelectedVersion(version);
                setSelectedTags(response.data.tags || []);
                if (editorRef.current) {
                    editorRef.current.setContent(response.data.content);
                }
            }
        } catch (error) {
            console.error('Error loading newsletter version:', error);
        }
    };

    const resetToDefaultTemplate = () => {
        if (window.confirm('This will reset the content to the default template. Do you want to proceed?')) {
            setContent(templateOptions[selectedTemplate]);
            if (editorRef.current) {
                editorRef.current.setContent(templateOptions[selectedTemplate]);
            }
            setSelectedTags([]);
        }
    };

    const sendNewsletter = async () => {
        try {
            // Validate email address for direct mode
            if (testMode === 'direct' && !directEmail) {
                alert('Please enter an email address for direct mode');
                return;
            }

            const editedContent = editorRef.current.getContent();
            const fullContent = templateOptions[selectedTemplate].replace(
                /<body>[\s\S]*<\/body>/,
                `<body>${editedContent}</body>`
            );

            const payload = {
                content: fullContent,
                tags: selectedTags,
                mode: testMode,
                emailAddress: testMode === 'direct' ? directEmail : null
            };

            const response = await protectedApiCall('/api/newsletters/send-newsletter', 'POST', payload, false, navigate);

            if (response && response.data) {
                alert('Newsletter sent successfully!');
            }
        } catch (error) {
            console.error('Error sending newsletter:', error);
            alert('Failed to send newsletter.');
        }
    };

    const togglePreview = () => {
        setShowPreview(!showPreview);
    };

    const getFullContent = () => {
        const editedContent = editorRef.current.getContent();
        return templateOptions[selectedTemplate].replace(
            /<body>[\s\S]*<\/body>/,
            `<body>${editedContent}</body>`
        );
    };

    const handleTagSelection = (tag) => {
        if (selectedTags.includes(tag)) {
            setSelectedTags(selectedTags.filter(t => t !== tag));
        } else {
            setSelectedTags([...selectedTags, tag]);
        }
    };

    const handleCustomTagInput = (e) => {
        setCustomTag(e.target.value);
    };

    const addCustomTag = () => {
        if (customTag && !selectedTags.includes(customTag)) {
            setSelectedTags([...selectedTags, customTag]);
            setCustomTag('');
        }
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            addCustomTag();
        }
    };

    return (
        <Box sx={{ 
            display: 'flex', 
            flexDirection: 'column', 
            minHeight: '100vh', 
            width: '100%',
            bgcolor: 'background.default'
        }}>
            <Paper 
                elevation={2} 
                sx={{ 
                    p: 2, 
                    mb: 2,
                    borderRadius: 0
                }}
            >
                <Grid container spacing={2}>
                    {/* Version Control */}
                    <Grid item xs={12} md={4}>
                        <Paper 
                            elevation={1} 
                            sx={{ 
                                p: 2, 
                                display: 'flex', 
                                flexDirection: 'column', 
                                gap: 1 
                            }}
                        >
                            <Typography variant="subtitle2" gutterBottom>
                                Version Control
                            </Typography>
                            <Stack direction="row" spacing={1} alignItems="center">
                                <TextField
                                    size="small"
                                    placeholder="Version"
                                    value={version}
                                    onChange={(e) => setVersion(e.target.value)}
                                    sx={{ flex: 1 }}
                                />
                                <FormControl size="small" sx={{ minWidth: 150, flex: 2 }}>
                                    <InputLabel id="version-select-label">Select Version</InputLabel>
                                    <Select
                                        labelId="version-select-label"
                                        value={selectedVersion}
                                        label="Select Version"
                                        onChange={(e) => loadVersion(e.target.value)}
                                    >
                                        <MenuItem value="">
                                            <em>None</em>
                                        </MenuItem>
                                        {versions.map((v) => (
                                            <MenuItem key={v.version} value={v.version}>
                                                {v.version} - {new Date(v.date).toLocaleDateString()}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                <IconButton 
                                    color="primary" 
                                    onClick={resetToDefaultTemplate}
                                    title="New Template"
                                >
                                    <RefreshIcon />
                                </IconButton>
                            </Stack>
                        </Paper>
                    </Grid>

                    {/* Template Selection */}
                    <Grid item xs={12} md={4}>
                        <Paper 
                            elevation={1} 
                            sx={{ 
                                p: 2, 
                                display: 'flex', 
                                flexDirection: 'column', 
                                gap: 1,
                                height: '100%'
                            }}
                        >
                            <Typography variant="subtitle2" gutterBottom>
                                Template
                            </Typography>
                            <FormControl fullWidth size="small">
                                <InputLabel id="template-select-label">Template</InputLabel>
                                <Select
                                    labelId="template-select-label"
                                    value={selectedTemplate}
                                    label="Template"
                                    onChange={(e) => setSelectedTemplate(e.target.value)}
                                >
                                    {Object.keys(templateOptions).map(template => (
                                        <MenuItem key={template} value={template}>{template}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Paper>
                    </Grid>

                    {/* Distribution Controls */}
                    <Grid item xs={12} md={4}>
                        <Paper 
                            elevation={1} 
                            sx={{ 
                                p: 2, 
                                display: 'flex', 
                                flexDirection: 'column', 
                                gap: 1,
                                height: '100%'
                            }}
                        >
                            <Typography variant="subtitle2" gutterBottom>
                                Distribution Mode
                            </Typography>
                            <FormControl component="fieldset">
                                <RadioGroup
                                    row
                                    value={testMode}
                                    onChange={(e) => setTestMode(e.target.value)}
                                >
                                    <FormControlLabel value="test" control={<Radio size="small" />} label="Test" />
                                    <FormControlLabel value="production" control={<Radio size="small" />} label="Production" />
                                    <FormControlLabel value="direct" control={<Radio size="small" />} label="Direct" />
                                </RadioGroup>
                            </FormControl>
                            {testMode === 'direct' && (
                                <TextField
                                    size="small"
                                    placeholder="Email address"
                                    value={directEmail}
                                    onChange={(e) => setDirectEmail(e.target.value)}
                                    type="email"
                                />
                            )}
                        </Paper>
                    </Grid>

                    {/* Tags */}
                    <Grid item xs={12}>
                        <Paper 
                            elevation={1} 
                            sx={{ 
                                p: 2, 
                                display: 'flex', 
                                flexDirection: 'column', 
                                gap: 1 
                            }}
                        >
                            <Typography variant="subtitle2" gutterBottom>
                                Tags
                            </Typography>
                            <Stack direction="row" spacing={1} alignItems="center" flexWrap="wrap">
                                {predefinedTags.map(tag => (
                                    <Chip
                                        key={tag}
                                        label={tag}
                                        onClick={() => handleTagSelection(tag)}
                                        color={selectedTags.includes(tag) ? "primary" : "default"}
                                        variant={selectedTags.includes(tag) ? "filled" : "outlined"}
                                        sx={{ m: 0.5 }}
                                    />
                                ))}
                                <TextField
                                    size="small"
                                    placeholder="Custom tag"
                                    value={customTag}
                                    onChange={handleCustomTagInput}
                                    onKeyPress={handleKeyPress}
                                    sx={{ width: 150 }}
                                />
                                <IconButton 
                                    color="primary" 
                                    onClick={addCustomTag}
                                    disabled={!customTag}
                                >
                                    <AddIcon />
                                </IconButton>
                            </Stack>
                            {selectedTags.length > 0 && (
                                <Box sx={{ mt: 1 }}>
                                    <Typography variant="caption" color="text.secondary">
                                        Selected Tags:
                                    </Typography>
                                    <Stack direction="row" spacing={0.5} flexWrap="wrap">
                                        {selectedTags.map(tag => (
                                            <Chip
                                                key={tag}
                                                label={tag}
                                                onDelete={() => handleTagSelection(tag)}
                                                size="small"
                                                color="primary"
                                                sx={{ m: 0.5 }}
                                            />
                                        ))}
                                    </Stack>
                                </Box>
                            )}
                        </Paper>
                    </Grid>
                </Grid>
            </Paper>

            {/* Editor */}
            <Container 
                maxWidth="lg" 
                sx={{ 
                    flex: 1, 
                    display: 'flex', 
                    flexDirection: 'column',
                    mb: 2
                }}
            >
                <Box 
                    sx={{ 
                        flex: 1, 
                        display: 'flex', 
                        flexDirection: 'column',
                        border: 1,
                        borderColor: 'divider',
                        borderRadius: 1,
                        overflow: 'hidden',
                        '& .tox-tinymce': {
                            border: 'none !important',
                            flex: '1 !important',
                            minHeight: 'calc(100vh - 300px) !important'
                        }
                    }}
                >
                    <Editor
                        apiKey="2a21s1kjn13r7oo10wd0s74qjc98em3ec9ioth1ymk5bpkov"
                        onInit={(evt, editor) => (editorRef.current = editor)}
                        initialValue={content}
                        init={{
                            height: "100%",
                            menubar: false,
                            plugins: [
                                'anchor', 'autolink', 'charmap', 'codesample', 'emoticons', 'image', 'link', 'lists',
                                'media', 'searchreplace', 'table', 'visualblocks', 'wordcount',
                            ],
                            automatic_uploads: false,
                            images_dataimg_filter: function() {
                                return false;
                            },
                            toolbar: `
                                undo redo | blocks fontfamily fontsize | 
                                bold italic underline strikethrough forecolor backcolor | 
                                alignleft aligncenter alignright alignjustify | 
                                bullist numlist outdent indent | 
                                image media link table | 
                                removeformat code fullscreen help
                            `
                        }}
                        onEditorChange={(newContent) => debouncedSetContent(newContent)}
                    />
                </Box>
            </Container>

            {/* Footer Actions */}
            <Paper 
                elevation={3} 
                sx={{ 
                    p: 2, 
                    display: 'flex', 
                    justifyContent: 'flex-end', 
                    gap: 2,
                    borderRadius: 0,
                    bgcolor: 'var(--background-color)',
                    borderTop: '1px solid var(--border-color)'
                }}
            >
                <Button 
                    variant="contained" 
                    onClick={saveNewsletter}
                    sx={{
                        bgcolor: 'var(--primary-color)',
                        color: 'var(--background-color)',
                        '&:hover': {
                            bgcolor: 'var(--text-color)',
                        }
                    }}
                >
                    Save
                </Button>
                <Button 
                    variant="contained" 
                    onClick={sendNewsletter}
                    sx={{
                        bgcolor: 'var(--primary-color)',
                        color: 'var(--background-color)',
                        '&:hover': {
                            bgcolor: 'var(--text-color)',
                        }
                    }}
                >
                    Send
                </Button>
                <Button 
                    variant="contained" 
                    onClick={saveAndSendNewsletter}
                    sx={{
                        bgcolor: 'var(--primary-color)',
                        color: 'var(--background-color)',
                        '&:hover': {
                            bgcolor: 'var(--text-color)',
                        }
                    }}
                >
                    Save & Send
                </Button>
                <Button 
                    variant="outlined" 
                    onClick={togglePreview}
                    sx={{
                        borderColor: 'var(--primary-color)',
                        color: 'var(--primary-color)',
                        '&:hover': {
                            borderColor: 'var(--text-color)',
                            bgcolor: 'transparent',
                        }
                    }}
                >
                    Preview
                </Button>
            </Paper>

            {/* Preview Dialog */}
            <Dialog
                open={showPreview}
                onClose={togglePreview}
                maxWidth="lg"
                fullWidth
                PaperProps={{
                    sx: {
                        maxHeight: '90vh',
                        height: '90vh'
                    }
                }}
            >
                <DialogContent 
                    sx={{ 
                        p: 0, 
                        position: 'relative',
                        height: '100%',
                        overflow: 'auto'
                    }}
                >
                    <Box 
                        sx={{ 
                            position: 'absolute', 
                            top: 8, 
                            right: 8, 
                            zIndex: 1 
                        }}
                    >
                        <IconButton 
                            onClick={togglePreview} 
                            sx={{ 
                                bgcolor: 'var(--background-color)',
                                color: 'var(--primary-color)',
                                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                                '&:hover': {
                                    bgcolor: 'var(--background-color)',
                                    color: 'var(--text-color)',
                                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.15)'
                                }
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Box>
                    <Box 
                        sx={{ 
                            height: '100%', 
                            overflow: 'auto' 
                        }}
                        dangerouslySetInnerHTML={{ __html: showPreview ? getFullContent() : '' }} 
                    />
                </DialogContent>
                <DialogActions>
                    <Button 
                        onClick={togglePreview} 
                        variant="contained"
                        sx={{
                            bgcolor: 'var(--primary-color)',
                            color: 'var(--background-color)',
                            '&:hover': {
                                bgcolor: 'var(--text-color)',
                            }
                        }}
                    >
                        Close Preview
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default NewsletterEditor;
