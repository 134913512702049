import React from 'react';
import { 
    Box,
    FormControl, 
    InputLabel, 
    Select, 
    MenuItem,
    FormGroup,
    FormControlLabel,
    Checkbox,
    Typography,
    Button,
    useTheme
} from '@mui/material';

export const templates = [
    {
        id: 'lesson-plan',
        name: 'Lesson Plan',
        outcomeConfig: {
            includeOutcomesInPrompt: true,
            includeOutcomesInResponse: true,
            includeBiblicalOutcomesInPrompt: true,
            includeBiblicalOutcomesInResponse: true
        },
        template: `# {title}

## Learning Intentions
{learning_intentions}

## Overview
{overview}

## Key Activities
{activities}

## Resources
{resources}

## Assessment
{assessment}

## Differentiation
{differentiation}
`
    },
    {
        id: 'email',
        name: 'Email',
        outcomeConfig: {
            includeOutcomesInPrompt: false,
            includeOutcomesInResponse: false,
            includeBiblicalOutcomesInPrompt: false,
            includeBiblicalOutcomesInResponse: false
        },
        template: `# {subject}

Dear Parents/Guardians,

Kind regards,
{teacher_name}`
    },
    {
        id: 'research-template',
        name: 'Research Project',
        outcomeConfig: {
            includeOutcomesInPrompt: true,
            includeOutcomesInResponse: true,
            includeBiblicalOutcomesInPrompt: false,
            includeBiblicalOutcomesInResponse: false
        },
        template: `# {topic}

## Research Question
{question}

## Methodology
{methodology}

## Data Collection
{data_collection}

## Analysis
{analysis}

## Conclusions
{conclusions}`
    },
    {
        id: 'unit-overview',
        name: 'Unit Overview',
        outcomeConfig: {
            includeOutcomesInPrompt: true,
            includeOutcomesInResponse: true,
            includeBiblicalOutcomesInPrompt: true,
            includeBiblicalOutcomesInResponse: true
        },
        template: `# {unit_title}

## Duration
{duration}

## Assessment Tasks
{assessment_tasks}

## Weekly Breakdown
{weekly_breakdown}`
    },
    {
        id: 'page-of-research',
        name: 'Page Of Research',
        outcomeConfig: {
            includeOutcomesInPrompt: true,
            includeOutcomesInResponse: true,
            includeBiblicalOutcomesInPrompt: false,
            includeBiblicalOutcomesInResponse: false
        },
        template: `# {research}

## Title
{title}

## Body
{body}

## References
{references}

## Quick Summary
{quick_summary}`
    },
    {
        id: 'blank_template',
        name: 'Blank Template',
        outcomeConfig: {
            includeOutcomesInPrompt: false,
            includeOutcomesInResponse: false,
            includeBiblicalOutcomesInPrompt: false,
            includeBiblicalOutcomesInResponse: false
        },
        template: `# {blank}`
    }
];

export const getTemplateConfig = (templateId) => {
    const template = templates.find(t => t.id === templateId);
    return template ? template.outcomeConfig : null;
};

const TemplateSelector = ({ selectedTemplate, onTemplateChange, options, onOptionsChange }) => {
    const theme = useTheme();

    const handleOptionChange = (option) => {
        onOptionsChange({
            ...options,
            [option]: !options[option]
        });
    };

    return (
        <Box>
            {/* Template Selection */}
            <Box sx={{ mb: 1 }}>
                <Typography 
                    variant="subtitle1" 
                    sx={{ 
                        mb: 1,
                        color: theme.palette.text.primary,
                        fontWeight: 600
                    }}
                >
                    Select a Template
                </Typography>
                <Box
                    sx={{
                        p: 1.5,
                        bgcolor: theme.palette.background.default,
                        borderRadius: 2
                    }}
                >
                    <Box 
                        sx={{ 
                            display: 'flex', 
                            flexWrap: 'wrap', 
                            gap: 1
                        }}
                    >
                        {templates.map(template => (
                            <Button
                                key={template.id}
                                variant={selectedTemplate === template.id ? "contained" : "outlined"}
                                onClick={() => onTemplateChange(template.id)}
                                size="small"
                                sx={{
                                    borderRadius: 2,
                                    textTransform: 'none',
                                    fontWeight: 500,
                                    boxShadow: 'none',
                                    py: 0.5,
                                    '&:hover': {
                                        boxShadow: 'none'
                                    }
                                }}
                            >
                                {template.name}
                            </Button>
                        ))}
                    </Box>
                </Box>
            </Box>
            
            {/* Outcomes Options - Compact Layout */}
            <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap', mt: 1 }}>
                {/* Syllabus Outcomes */}
                <Box 
                    sx={{ 
                        flex: '1 1 auto',
                        minWidth: '150px',
                        p: 1,
                        borderRadius: 1,
                        bgcolor: theme.palette.background.default,
                        border: theme => `1px solid ${theme.palette.divider}`
                    }}
                >
                    <Typography 
                        variant="caption" 
                        color="text.secondary" 
                        sx={{ fontWeight: 500 }}
                    >
                        Syllabus Outcomes
                    </Typography>
                    <FormGroup sx={{ mt: 0.5 }}>
                        <FormControlLabel
                            sx={{ my: -0.5 }}
                            control={
                                <Checkbox
                                    size="small"
                                    checked={options.includeOutcomesInPrompt}
                                    onChange={() => {
                                        if (options.includeOutcomesInPrompt) {
                                            onOptionsChange({
                                                ...options,
                                                includeOutcomesInPrompt: false,
                                                includeOutcomesInResponse: false
                                            });
                                        } else {
                                            handleOptionChange('includeOutcomesInPrompt');
                                        }
                                    }}
                                />
                            }
                            label={<Typography variant="caption">Use in content</Typography>}
                        />
                        <FormControlLabel
                            sx={{ my: -0.5 }}
                            disabled={!options.includeOutcomesInPrompt}
                            control={
                                <Checkbox
                                    size="small"
                                    checked={options.includeOutcomesInResponse}
                                    disabled={!options.includeOutcomesInPrompt}
                                    onChange={() => handleOptionChange('includeOutcomesInResponse')}
                                />
                            }
                            label={<Typography variant="caption">Show in result</Typography>}
                        />
                    </FormGroup>
                </Box>

                {/* Biblical Outcomes */}
                <Box 
                    sx={{ 
                        flex: '1 1 auto',
                        minWidth: '150px',
                        p: 1,
                        borderRadius: 1,
                        bgcolor: theme.palette.background.default,
                        border: theme => `1px solid ${theme.palette.divider}`
                    }}
                >
                    <Typography 
                        variant="caption" 
                        color="text.secondary" 
                        sx={{ fontWeight: 500 }}
                    >
                        School Outcomes
                    </Typography>
                    <FormGroup sx={{ mt: 0.5 }}>
                        <FormControlLabel
                            sx={{ my: -0.5 }}
                            control={
                                <Checkbox
                                    size="small"
                                    checked={options.includeBiblicalOutcomesInPrompt}
                                    onChange={() => {
                                        if (options.includeBiblicalOutcomesInPrompt) {
                                            onOptionsChange({
                                                ...options,
                                                includeBiblicalOutcomesInPrompt: false,
                                                includeBiblicalOutcomesInResponse: false
                                            });
                                        } else {
                                            handleOptionChange('includeBiblicalOutcomesInPrompt');
                                        }
                                    }}
                                />
                            }
                            label={<Typography variant="caption">Use in content</Typography>}
                        />
                        <FormControlLabel
                            sx={{ my: -0.5 }}
                            disabled={!options.includeBiblicalOutcomesInPrompt}
                            control={
                                <Checkbox
                                    size="small"
                                    checked={options.includeBiblicalOutcomesInResponse}
                                    disabled={!options.includeBiblicalOutcomesInPrompt}
                                    onChange={() => handleOptionChange('includeBiblicalOutcomesInResponse')}
                                />
                            }
                            label={<Typography variant="caption">Show in result</Typography>}
                        />
                    </FormGroup>
                </Box>
            </Box>
        </Box>
    );
};

export default TemplateSelector;
