import React, { useState, useEffect } from 'react';
import {
    Box,
    Paper,
    Typography,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Grid,
    Card,
    CardContent,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    CircularProgress,
    Alert,
    Tabs,
    Tab,
    Divider,
} from '@mui/material';
import {
    Timeline as TimelineIcon,
    Group as GroupIcon,
    PhoneAndroid as PhoneAndroidIcon,
    Route as RouteIcon,
    Leaderboard as LeaderboardIcon,
    Refresh as RefreshIcon,
    Error as ErrorIcon,
    Send as SendIcon,
} from '@mui/icons-material';
import { Line, Bar, Pie } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    ArcElement,
    Title,
    Tooltip,
    Legend
} from 'chart.js';
import { protectedApiCall } from '../../../auth';

// Register ChartJS components
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    ArcElement,
    Title,
    Tooltip,
    Legend
);

const MobileAnalyticsDashboard = () => {
    const [timeframe, setTimeframe] = useState('7d');
    const [activeTab, setActiveTab] = useState(0);
    const [usageStats, setUsageStats] = useState(null);
    const [timeline, setTimeline] = useState([]);
    const [mobileStats, setMobileStats] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    // Fetch stats and timeline data
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const [usageStatsResponse, timelineResponse, mobileStatsResponse] = await Promise.all([
                    protectedApiCall(`/api/analytics/usage-stats?timeframe=${timeframe}`, 'GET'),
                    protectedApiCall(`/api/analytics/usage-timeline?timeframe=${timeframe}&interval=1d`, 'GET'),
                    protectedApiCall(`/api/analytics/mobile-stats?timeframe=${timeframe}`, 'GET')
                ]);

                console.log('Usage Stats Response:', usageStatsResponse.data);
                console.log('Timeline Response:', timelineResponse.data);
                console.log('Mobile Stats Response:', mobileStatsResponse.data);
                
                setUsageStats(usageStatsResponse.data);
                setTimeline(timelineResponse.data);
                setMobileStats(mobileStatsResponse.data);
                setError(null);
            } catch (err) {
                console.error('Error fetching analytics:', err);
                setError('Failed to fetch analytics data');
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [timeframe]);

    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);
    };

    // Prepare timeline chart data
    const timelineData = {
        labels: timeline.map(t => new Date(t.start).toLocaleDateString()),
        datasets: [
            {
                label: 'Web Logins',
                data: timeline.map(t => t.web_logins),
                borderColor: 'rgb(75, 192, 192)',
                tension: 0.1
            },
            {
                label: 'Mobile Logins',
                data: timeline.map(t => t.app_logins),
                borderColor: 'rgb(255, 99, 132)',
                tension: 0.1
            },
            {
                label: 'Records Sent',
                data: timeline.map(t => t.records_sent || 0),
                borderColor: 'rgb(53, 162, 235)',
                tension: 0.1
            },
            {
                label: 'Token Refreshes',
                data: timeline.map(t => t.token_refreshes || 0),
                borderColor: 'rgb(255, 159, 64)',
                tension: 0.1
            }
        ]
    };

    const timelineOptions = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'Usage Timeline'
            }
        },
        scales: {
            y: {
                beginAtZero: true
            }
        }
    };

    // Platform distribution chart data
    const platformData = {
        labels: mobileStats?.platform_distribution ? Object.keys(mobileStats.platform_distribution) : [],
        datasets: [
            {
                label: 'Platform Distribution',
                data: mobileStats?.platform_distribution ? Object.values(mobileStats.platform_distribution) : [],
                backgroundColor: [
                    'rgba(255, 99, 132, 0.6)',
                    'rgba(54, 162, 235, 0.6)',
                    'rgba(255, 206, 86, 0.6)',
                    'rgba(75, 192, 192, 0.6)',
                    'rgba(153, 102, 255, 0.6)',
                ],
                borderColor: [
                    'rgba(255, 99, 132, 1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(75, 192, 192, 1)',
                    'rgba(153, 102, 255, 1)',
                ],
                borderWidth: 1,
            },
        ],
    };

    // Activity metrics chart data
    const activityData = {
        labels: ['Logins', 'Records Sent', 'Token Refreshes', 'Incorrect Names'],
        datasets: [
            {
                label: 'Activity Metrics',
                data: mobileStats ? [
                    mobileStats.total_logins || 0,
                    mobileStats.total_records_sent || 0,
                    mobileStats.total_token_refreshes || 0,
                    mobileStats.total_incorrect_names || 0
                ] : [],
                backgroundColor: [
                    'rgba(75, 192, 192, 0.6)',
                    'rgba(54, 162, 235, 0.6)',
                    'rgba(255, 206, 86, 0.6)',
                    'rgba(255, 99, 132, 0.6)',
                ],
                borderColor: [
                    'rgba(75, 192, 192, 1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(255, 99, 132, 1)',
                ],
                borderWidth: 1,
            },
        ],
    };

    if (loading) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '400px' }}>
                <CircularProgress />
            </Box>
        );
    }

    if (error) {
        return (
            <Alert severity="error" sx={{ m: 2 }}>
                {error}
            </Alert>
        );
    }
    
    // Debug output
    console.log('Render - Usage Stats:', usageStats);
    console.log('Render - Mobile Stats:', mobileStats);

    return (
        <Box sx={{ p: 3 }}>
            <Box sx={{ mb: 3, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant="h5" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <LeaderboardIcon color="primary" />
                    Enhanced Analytics Dashboard
                </Typography>
                <FormControl sx={{ minWidth: 120 }}>
                    <InputLabel>Timeframe</InputLabel>
                    <Select
                        value={timeframe}
                        label="Timeframe"
                        onChange={(e) => setTimeframe(e.target.value)}
                    >
                        <MenuItem value="24h">Last 24 Hours</MenuItem>
                        <MenuItem value="7d">Last 7 Days</MenuItem>
                        <MenuItem value="30d">Last 30 Days</MenuItem>
                        <MenuItem value="90d">Last 90 Days</MenuItem>
                    </Select>
                </FormControl>
            </Box>

            <Tabs value={activeTab} onChange={handleTabChange} sx={{ mb: 3 }}>
                <Tab label="Overview" icon={<LeaderboardIcon />} iconPosition="start" />
                <Tab label="Mobile Analytics" icon={<PhoneAndroidIcon />} iconPosition="start" />
                <Tab label="Timeline" icon={<TimelineIcon />} iconPosition="start" />
            </Tabs>

            {activeTab === 0 && (
                <>
                    {/* Summary Cards */}
                    <Grid container spacing={3} sx={{ mb: 3 }}>
                        <Grid item xs={12} md={3}>
                            <Card>
                                <CardContent>
                                    <Typography variant="h6" sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 2 }}>
                                        <GroupIcon color="primary" />
                                        Mobile Users
                                    </Typography>
                                    <Typography variant="h4">{mobileStats?.total_mobile_users || 0}</Typography>
                                    <Box sx={{ mt: 2 }}>
                                        <Typography variant="body2" color="text.secondary">
                                            Active Users: {mobileStats?.active_mobile_users || 0}
                                        </Typography>
                                        <Typography variant="body2" color="text.secondary">
                                            Avg Logins: {mobileStats?.avg_logins_per_user?.toFixed(1) || 0}
                                        </Typography>
                                    </Box>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Card>
                                <CardContent>
                                    <Typography variant="h6" sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 2 }}>
                                        <SendIcon color="primary" />
                                        Records Sent
                                    </Typography>
                                    <Typography variant="h4">{mobileStats?.total_records_sent || 0}</Typography>
                                    <Box sx={{ mt: 2 }}>
                                        <Typography variant="body2" color="text.secondary">
                                            From Mobile: {mobileStats?.total_records_sent || 0}
                                        </Typography>
                                        <Typography variant="body2" color="text.secondary">
                                            Avg Per User: {mobileStats?.avg_records_sent_per_user?.toFixed(1) || 0}
                                        </Typography>
                                    </Box>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Card>
                                <CardContent>
                                    <Typography variant="h6" sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 2 }}>
                                        <RefreshIcon color="primary" />
                                        Token Refreshes
                                    </Typography>
                                    <Typography variant="h4">{mobileStats?.total_token_refreshes || 0}</Typography>
                                    <Box sx={{ mt: 2 }}>
                                        <Typography variant="body2" color="text.secondary">
                                            Avg Per User: {mobileStats?.avg_token_refreshes_per_user?.toFixed(1) || 0}
                                        </Typography>
                                    </Box>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Card>
                                <CardContent>
                                    <Typography variant="h6" sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 2 }}>
                                        <ErrorIcon color="primary" />
                                        Incorrect Names
                                    </Typography>
                                    <Typography variant="h4">{mobileStats?.total_incorrect_names || 0}</Typography>
                                    <Box sx={{ mt: 2 }}>
                                        <Typography variant="body2" color="text.secondary">
                                            Per User: {(mobileStats?.total_incorrect_names / (mobileStats?.total_mobile_users || 1)).toFixed(1) || 0}
                                        </Typography>
                                    </Box>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>

                    {/* Leaderboards */}
                    <Grid container spacing={3}>
                        {/* Records Sent Leaderboard */}
                        <Grid item xs={12} md={6}>
                            <Paper sx={{ p: 3 }}>
                                <Typography variant="h6" sx={{ mb: 2, display: 'flex', alignItems: 'center', gap: 1 }}>
                                    <SendIcon color="primary" />
                                    Top Users by Records Sent
                                </Typography>
                                <TableContainer>
                                    <Table size="small">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Rank</TableCell>
                                                <TableCell>User</TableCell>
                                                <TableCell align="right">Records Sent</TableCell>
                                                <TableCell align="right">Platform</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {usageStats?.top_users?.by_records_sent?.map((user, index) => (
                                                <TableRow key={user.id}>
                                                    <TableCell>{index + 1}</TableCell>
                                                    <TableCell>{user.name}</TableCell>
                                                    <TableCell align="right">{user.count}</TableCell>
                                                    <TableCell align="right">{user.platform}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Paper>
                        </Grid>

                        {/* Incorrect Names Leaderboard */}
                        <Grid item xs={12} md={6}>
                            <Paper sx={{ p: 3 }}>
                                <Typography variant="h6" sx={{ mb: 2, display: 'flex', alignItems: 'center', gap: 1 }}>
                                    <ErrorIcon color="primary" />
                                    Users with Incorrect Name Attempts
                                </Typography>
                                <TableContainer>
                                    <Table size="small">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Rank</TableCell>
                                                <TableCell>User</TableCell>
                                                <TableCell align="right">Incorrect Attempts</TableCell>
                                                <TableCell align="right">Platform</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {usageStats?.top_users?.by_incorrect_names?.map((user, index) => (
                                                <TableRow key={user.id}>
                                                    <TableCell>{index + 1}</TableCell>
                                                    <TableCell>{user.name}</TableCell>
                                                    <TableCell align="right">{user.count}</TableCell>
                                                    <TableCell align="right">{user.platform}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Paper>
                        </Grid>
                    </Grid>
                </>
            )}

            {activeTab === 1 && (
                <>
                    <Grid container spacing={3}>
                        {/* Mobile Overview */}
                        <Grid item xs={12}>
                            <Paper sx={{ p: 3, mb: 3 }}>
                                <Typography variant="h6" sx={{ mb: 2 }}>Mobile App Overview</Typography>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} md={6}>
                                        <Card>
                                            <CardContent>
                                                <Typography variant="h6">Platform Distribution</Typography>
                                                <Box sx={{ height: 300 }}>
                                                    <Pie data={platformData} />
                                                </Box>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Card>
                                            <CardContent>
                                                <Typography variant="h6">Activity Metrics</Typography>
                                                <Box sx={{ height: 300 }}>
                                                    <Bar 
                                                        data={activityData}
                                                        options={{
                                                            responsive: true,
                                                            scales: {
                                                                y: {
                                                                    beginAtZero: true
                                                                }
                                                            }
                                                        }}
                                                    />
                                                </Box>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>

                        {/* Mobile User Metrics */}
                        <Grid item xs={12} md={6}>
                            <Paper sx={{ p: 3 }}>
                                <Typography variant="h6" sx={{ mb: 2 }}>User Metrics</Typography>
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <Card>
                                            <CardContent>
                                                <Typography variant="subtitle1">Total Mobile Users</Typography>
                                                <Typography variant="h4">{mobileStats?.total_mobile_users || 0}</Typography>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Card>
                                            <CardContent>
                                                <Typography variant="subtitle1">Active Mobile Users</Typography>
                                                <Typography variant="h4">{mobileStats?.active_mobile_users || 0}</Typography>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Card>
                                            <CardContent>
                                                <Typography variant="subtitle1">Avg. Logins Per User</Typography>
                                                <Typography variant="h4">{mobileStats?.avg_logins_per_user?.toFixed(1) || 0}</Typography>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Card>
                                            <CardContent>
                                                <Typography variant="subtitle1">Avg. Records Per User</Typography>
                                                <Typography variant="h4">{mobileStats?.avg_records_sent_per_user?.toFixed(1) || 0}</Typography>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>

                        {/* Most Active Mobile Users */}
                        <Grid item xs={12} md={6}>
                            <Paper sx={{ p: 3 }}>
                                <Typography variant="h6" sx={{ mb: 2 }}>Mobile Usage Summary</Typography>
                                <TableContainer>
                                    <Table size="small">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Metric</TableCell>
                                                <TableCell align="right">Value</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell>Total Logins</TableCell>
                                                <TableCell align="right">{mobileStats?.total_logins || 0}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>Total Records Sent</TableCell>
                                                <TableCell align="right">{mobileStats?.total_records_sent || 0}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>Total Token Refreshes</TableCell>
                                                <TableCell align="right">{mobileStats?.total_token_refreshes || 0}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>Total Incorrect Names</TableCell>
                                                <TableCell align="right">{mobileStats?.total_incorrect_names || 0}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>Avg. Token Refreshes Per User</TableCell>
                                                <TableCell align="right">{mobileStats?.avg_token_refreshes_per_user?.toFixed(1) || 0}</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Paper>
                        </Grid>
                    </Grid>
                </>
            )}

            {activeTab === 2 && (
                <>
                    {/* Timeline Chart */}
                    <Paper sx={{ p: 3, mb: 3 }}>
                        <Typography variant="h6" sx={{ mb: 2, display: 'flex', alignItems: 'center', gap: 1 }}>
                            <TimelineIcon color="primary" />
                            Enhanced Usage Timeline
                        </Typography>
                        <Box sx={{ height: 400 }}>
                            <Line data={timelineData} options={timelineOptions} />
                        </Box>
                    </Paper>
                </>
            )}
        </Box>
    );
};

export default MobileAnalyticsDashboard;