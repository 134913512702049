import React, { useState, useEffect } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    Button,
    Box,
    IconButton,
    Typography,
    Alert,
    Divider,
    Paper,
    Tooltip,
} from '@mui/material';
import {
    Add as AddIcon,
    Delete as DeleteIcon,
    DragIndicator as DragIcon,
} from '@mui/icons-material';

const TimetableForm = ({
    open,
    onClose,
    onSubmit,
    initialData = null,
    isEditing = false
}) => {
    const [formData, setFormData] = useState({
        name: '',
        blocks: [{ period: '', startTime: '', endTime: '' }]
    });
    const [error, setError] = useState('');

    useEffect(() => {
        if (initialData) {
            setFormData({
                name: initialData.name,
                blocks: initialData.blocks.map(block => ({
                    period: block.period,
                    startTime: block.startTime,
                    endTime: block.endTime
                }))
            });
        } else {
            setFormData({
                name: '',
                blocks: [{ period: '', startTime: '', endTime: '' }]
            });
        }
    }, [initialData]);

    const handleSubmit = (e) => {
        e.preventDefault();
        
        // Validate name
        if (!formData.name.trim()) {
            setError('Timetable name is required');
            return;
        }

        // Validate blocks
        if (formData.blocks.length === 0) {
            setError('At least one time block is required');
            return;
        }

        // Validate each block
        for (const block of formData.blocks) {
            if (!block.period.trim() || !block.startTime || !block.endTime) {
                setError('All block fields are required');
                return;
            }

            // Compare times
            const start = new Date(`2000-01-01T${block.startTime}`);
            const end = new Date(`2000-01-01T${block.endTime}`);
            if (end <= start) {
                setError('End time must be after start time in each block');
                return;
            }
        }

        // Validate overlapping times
        const sortedBlocks = [...formData.blocks].sort((a, b) => {
            return new Date(`2000-01-01T${a.startTime}`) - new Date(`2000-01-01T${b.startTime}`);
        });

        for (let i = 0; i < sortedBlocks.length - 1; i++) {
            const currentEnd = new Date(`2000-01-01T${sortedBlocks[i].endTime}`);
            const nextStart = new Date(`2000-01-01T${sortedBlocks[i + 1].startTime}`);
            if (currentEnd > nextStart) {
                setError('Time blocks cannot overlap');
                return;
            }
        }

        onSubmit(formData);
    };

    const handleAddBlock = () => {
        setFormData(prev => ({
            ...prev,
            blocks: [...prev.blocks, { period: '', startTime: '', endTime: '' }]
        }));
    };

    const handleRemoveBlock = (index) => {
        setFormData(prev => ({
            ...prev,
            blocks: prev.blocks.filter((_, i) => i !== index)
        }));
    };

    const handleClose = () => {
        setError('');
        onClose();
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            maxWidth="sm"
            fullWidth
            PaperProps={{
                sx: {
                    borderRadius: 2,
                }
            }}
        >
            <form onSubmit={handleSubmit}>
                <DialogTitle sx={{ 
                    bgcolor: 'primary.lighter',
                    color: 'primary.dark',
                    fontWeight: 600
                }}>
                    {isEditing ? 'Edit Timetable' : 'Create New Timetable'}
                </DialogTitle>
                <DialogContent>
                    <Box sx={{ mt: 2 }}>
                        {error && (
                            <Alert severity="error" sx={{ mb: 2 }}>
                                {error}
                            </Alert>
                        )}
                        
                        <TextField
                            fullWidth
                            label="Timetable Name"
                            value={formData.name}
                            onChange={(e) => setFormData(prev => ({ ...prev, name: e.target.value }))}
                            required
                            sx={{ mb: 3 }}
                        />

                        <Box sx={{ mb: 2 }}>
                            <Typography variant="subtitle1" sx={{ mb: 1 }}>
                                Time Blocks
                            </Typography>
                            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                                {formData.blocks.map((block, index) => (
                                    <Paper
                                        key={index}
                                        variant="outlined"
                                        sx={{
                                            p: 2,
                                            position: 'relative',
                                            '&:hover': {
                                                bgcolor: 'action.hover'
                                            }
                                        }}
                                    >
                                        <Box sx={{ 
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: 2
                                        }}>
                                            <DragIcon sx={{ color: 'text.secondary' }} />
                                            <TextField
                                                label="Period"
                                                value={block.period}
                                                onChange={(e) => {
                                                    const newBlocks = [...formData.blocks];
                                                    newBlocks[index].period = e.target.value;
                                                    setFormData(prev => ({ ...prev, blocks: newBlocks }));
                                                }}
                                                required
                                                size="small"
                                                sx={{ width: 120 }}
                                            />
                                            <TextField
                                                label="Start Time"
                                                type="time"
                                                value={block.startTime}
                                                onChange={(e) => {
                                                    const newBlocks = [...formData.blocks];
                                                    newBlocks[index].startTime = e.target.value;
                                                    setFormData(prev => ({ ...prev, blocks: newBlocks }));
                                                }}
                                                required
                                                size="small"
                                                InputLabelProps={{ shrink: true }}
                                            />
                                            <TextField
                                                label="End Time"
                                                type="time"
                                                value={block.endTime}
                                                onChange={(e) => {
                                                    const newBlocks = [...formData.blocks];
                                                    newBlocks[index].endTime = e.target.value;
                                                    setFormData(prev => ({ ...prev, blocks: newBlocks }));
                                                }}
                                                required
                                                size="small"
                                                InputLabelProps={{ shrink: true }}
                                            />
                                            {formData.blocks.length > 1 && (
                                                <Tooltip title="Remove block">
                                                    <IconButton
                                                        onClick={() => handleRemoveBlock(index)}
                                                        size="small"
                                                        sx={{
                                                            color: 'error.main',
                                                            '&:hover': {
                                                                bgcolor: 'error.lighter'
                                                            }
                                                        }}
                                                    >
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            )}
                                        </Box>
                                    </Paper>
                                ))}
                            </Box>
                            <Button
                                startIcon={<AddIcon />}
                                onClick={handleAddBlock}
                                sx={{ mt: 2 }}
                            >
                                Add Time Block
                            </Button>
                        </Box>
                    </Box>
                </DialogContent>
                <DialogActions sx={{ px: 3, pb: 2 }}>
                    <Button onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                    >
                        {isEditing ? 'Save Changes' : 'Create Timetable'}
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};

export default TimetableForm;