import React, { useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import {
    Box,
    TextField,
    Button,
    ButtonGroup,
    Typography,
    useTheme,
    Paper
} from '@mui/material';

const SummaryReport = ({ data, generateReportWithType, loading }) => {
    const theme = useTheme();
    const [selectedOption, setSelectedOption] = useState(() => {
        return localStorage.getItem('selectedReportOption') || 'general';
    });
    const [customQuery, setCustomQuery] = useState(() => {
        return localStorage.getItem('customReportQuery') || '';
    });
    const [isCustomQuery, setIsCustomQuery] = useState(() => {
        return localStorage.getItem('isCustomQuery') === 'true';
    });

    // Load stored preferences
    useEffect(() => {
        const storedOption = localStorage.getItem('selectedReportOption');
        const isCustom = localStorage.getItem('isCustomQuery') === 'true';
        const customText = localStorage.getItem('customReportQuery');
        
        if (storedOption) {
            setSelectedOption(storedOption);
        }
        if (isCustom && customText) {
            setIsCustomQuery(true);
            setCustomQuery(customText);
        }
    }, []);

    const options = [
        { label: 'General', value: 'general' },
        { label: 'Behavior', value: 'behavior' },
        { label: 'Progress', value: 'progress' }
    ];

    const handleOptionChange = (value) => {
        setSelectedOption(value);
        setIsCustomQuery(false);
        localStorage.setItem('selectedReportOption', value);
        localStorage.setItem('isCustomQuery', 'false');
        generateReportWithType(value);
    };

    const handleCustomQueryChange = (e) => {
        const query = e.target.value;
        setCustomQuery(query);
        localStorage.setItem('customReportQuery', query);
    };

    const handleCustomQuerySubmit = () => {
        if (customQuery.trim()) {
            setIsCustomQuery(true);
            localStorage.setItem('isCustomQuery', 'true');
            generateReportWithType(customQuery);
        }
    };

    const copyToClipboard = () => {
        if (!data?.summary) return;
        
        const textToCopy = data.summary;
        if (navigator.clipboard) {
            navigator.clipboard.writeText(textToCopy).then(() => {
                alert('Summary copied to clipboard!');
            }).catch((err) => {
                console.error('Failed to copy: ', err);
            });
        } else {
            const textArea = document.createElement('textarea');
            textArea.value = textToCopy;
            document.body.appendChild(textArea);
            textArea.focus();
            textArea.select();
            try {
                document.execCommand('copy');
                alert('Summary copied to clipboard!');
            } catch (err) {
                console.error('Fallback: Oops, unable to copy', err);
            }
            document.body.removeChild(textArea);
        }
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                <Box sx={{ 
                    display: 'flex', 
                    flexDirection: { xs: 'column', sm: 'row' }, 
                    gap: 1 
                }}>
                    <TextField
                        size="small"
                        fullWidth
                        value={customQuery}
                        onChange={handleCustomQueryChange}
                        placeholder="Enter your custom query..."
                        disabled={loading}
                    />
                    <Button
                        variant={isCustomQuery ? "contained" : "outlined"}
                        onClick={handleCustomQuerySubmit}
                        disabled={loading}
                        sx={{ 
                            whiteSpace: 'nowrap',
                            minWidth: { xs: '100%', sm: 'auto' }
                        }}
                    >
                        Use Custom Query
                    </Button>
                </Box>

                <Box sx={{ 
                    overflowX: 'auto',
                    pb: 1
                }}>
                    <ButtonGroup 
                        variant="outlined" 
                        disabled={loading}
                        sx={{
                            display: 'flex',
                            flexWrap: { xs: 'wrap', sm: 'nowrap' },
                            '& > button': {
                                flex: { xs: '1 1 auto', sm: 'none' }
                            }
                        }}
                    >
                        {options.map(option => (
                            <Button
                                key={option.value}
                                onClick={() => handleOptionChange(option.value)}
                                variant={!isCustomQuery && selectedOption === option.value ? "contained" : "outlined"}
                            >
                                {option.label}
                            </Button>
                        ))}
                    </ButtonGroup>
                </Box>
            </Box>

            {data?.summary ? (
                <Box sx={{ mt: 2 }}>
                    <Paper 
                        elevation={0}
                        sx={{ 
                            p: { xs: 2, sm: 3 },
                            bgcolor: 'background.default',
                            border: `1px solid ${theme.palette.divider}`,
                            borderRadius: 2,
                            overflowX: 'auto'
                        }}
                    >
                        <ReactMarkdown>{data.summary}</ReactMarkdown>
                    </Paper>
                    <Button
                        variant="contained"
                        onClick={copyToClipboard}
                        sx={{ mt: 2, width: { xs: '100%', sm: 'auto' } }}
                    >
                        Copy Summary to Clipboard
                    </Button>
                </Box>
            ) : !loading && (
                <Box sx={{ 
                    display: 'flex', 
                    justifyContent: 'center', 
                    alignItems: 'center',
                    height: '100%',
                    minHeight: { xs: 150, sm: 200 },
                    color: 'text.secondary',
                    textAlign: 'center',
                    px: 2
                }}>
                    <Typography>
                        Select an option and generate a report to view summary
                    </Typography>
                </Box>
            )}
        </Box>
    );
};

export default SummaryReport;