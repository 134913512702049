import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../../css/ContactForm.css';

const ContactForm = () => {
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        school: '',
        message: '',
        platform: 'Android' // Ensure default state is set correctly
    });

    useEffect(() => {
        if (window.dataLayer) {
            window.dataLayer.push({
                event: 'pageview',
                page: '/contact'
            });
        }
    }, []);

    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
        if (window.dataLayer) {
            window.dataLayer.push({
                event: 'formInteraction',
                formField: name,
                action: 'input'
            });
        }
    };

    const handlePlatformChange = (e) => {
        const { value } = e.target;
        setFormData({ ...formData, platform: value });
        if (window.dataLayer) {
            window.dataLayer.push({
                event: 'formInteraction',
                formField: 'platform',
                action: 'toggle',
                value: value
            });
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);

        try {
            await axios.post(`${process.env.REACT_APP_BASE_URL}/api/messages/contact`, formData);
            setIsSubmitted(true);

            if (window.dataLayer) {
                window.dataLayer.push({
                    event: 'formSubmission',
                    formName: 'contactForm',
                    status: 'success'
                });
            }
        } catch (error) {
            if (window.dataLayer) {
                window.dataLayer.push({
                    event: 'formSubmission',
                    formName: 'contactForm',
                    status: 'error',
                    errorMessage: error.message
                });
            }
            console.error('Error submitting contact form:', error);
        } finally {
            setIsSubmitting(false);
        }
    };

    if (isSubmitted) {
        return <div className="contact-form-success">Thank you for your message. We will get back to you soon.</div>;
    }

    return (
        <div className="contact-page">
            <div className="intro-text">
                <h1>Interested in Tammoo?</h1>
                <p>
                    Elevate your teaching journey with Tammoo's personalized insights.

                    Imagine having a friendly companion that helps you remember the precious moments and important details throughout the school year. That's Tammoo - your digital memory assistant.

                    From simplifying your reporting process to uncovering valuable patterns in your classroom, Tammoo is here to support your teaching goals.

                    Curious to see how Tammoo can transform your teaching experience? We'd love to show you! Fill out the form below, and let's arrange a friendly demo to explore how Tammoo can work for you.
                </p>
            </div>
            <form className="contact-form" onSubmit={handleSubmit}>
                <h2>Contact Us</h2>
                <input type="text" name="firstName" placeholder="First Name" value={formData.firstName} onChange={handleChange} required />
                <input type="text" name="lastName" placeholder="Last Name" value={formData.lastName} onChange={handleChange} required />
                <input type="email" name="email" placeholder="Email" value={formData.email} onChange={handleChange} required />
                <input type="text" name="school" placeholder="School you work at" value={formData.school} onChange={handleChange} />
                <textarea name="message" placeholder="What interests you about Tammoo?" value={formData.message} onChange={handleChange} required></textarea>

                {/* Platform Toggle */}
                <div className="platform-toggle">
                    <input
                        type="radio"
                        id="android"
                        name="platform"
                        value="Android"
                        checked={formData.platform === 'Android'}
                        onChange={handlePlatformChange}
                    />
                    <label htmlFor="android">Android</label>
                    <input
                        type="radio"
                        id="iphone"
                        name="platform"
                        value="iPhone"
                        checked={formData.platform === 'iPhone'}
                        onChange={handlePlatformChange}
                    />
                    <label htmlFor="iphone">iPhone</label>
                </div>

                <button type="submit" disabled={isSubmitting}>{isSubmitting ? 'Submitting...' : 'Submit'}</button>
            </form>
        </div>
    );
};

export default ContactForm;
