// Utility functions for user-specific localStorage handling

const getUserKey = (key) => {
    try {
        const user = JSON.parse(localStorage.getItem('user'));
        console.log('Getting key for user:', { user, key });
        
        // Check for both _id and id since MongoDB uses _id
        const userId = user ? (user._id || user.id) : undefined;
        if (!userId) {
            console.warn('No user ID found in stored user data');
            return key;
        }
        
        const userKey = `${userId}_${key}`;
        console.log('Generated user-specific key:', userKey);
        return userKey;
    } catch (error) {
        console.error('Error generating user key:', error);
        return key;
    }
};

export const setUserItem = (key, value) => {
    const userKey = getUserKey(key);
    console.log(`Storing ${key} for current user:`, { userKey, value });
    localStorage.setItem(userKey, JSON.stringify(value));
};

export const getUserItem = (key, defaultValue = null) => {
    const userKey = getUserKey(key);
    try {
        const item = localStorage.getItem(userKey);
        console.log(`Reading ${key} for current user:`, { userKey, storedValue: item });
        
        if (item === null || item === 'null' || item === 'undefined') {
            return defaultValue;
        }
        
        return JSON.parse(item);
    } catch (error) {
        console.error(`Error reading ${key} from localStorage:`, error);
        return defaultValue;
    }
};

export const removeUserItem = (key) => {
    const userKey = getUserKey(key);
    console.log(`Removing ${key} for current user:`, { userKey });
    localStorage.removeItem(userKey);
};

// Keys that should not be user-specific
const GLOBAL_KEYS = ['user', 'token', 'refreshToken'];

// Wrapper for localStorage that handles user-specific keys
export const userStorage = {
    setItem: (key, value) => {
        if (GLOBAL_KEYS.includes(key)) {
            localStorage.setItem(key, value);
        } else {
            setUserItem(key, value);
        }
    },
    
    getItem: (key, defaultValue = null) => {
        if (GLOBAL_KEYS.includes(key)) {
            return localStorage.getItem(key);
        }
        return getUserItem(key, defaultValue);
    },
    
    removeItem: (key) => {
        if (GLOBAL_KEYS.includes(key)) {
            localStorage.removeItem(key);
        } else {
            removeUserItem(key);
        }
    }
};