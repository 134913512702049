import React, { useState, useEffect } from 'react';
import {
  Container,
  Paper,
  Typography,
  Box,
  Tabs,
  Tab,
  Button,
  useTheme,
  useMediaQuery,
  Snackbar,
  Alert
} from '@mui/material';
import { Add as AddIcon } from '@mui/icons-material';
import { protectedApiCall } from '../../auth';
import CreateWorksheet from './CreateWorksheet';
import WorksheetList from './WorksheetList';
import TemplateGenerator from './TemplateGenerator';

const WorksheetManager = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [activeTab, setActiveTab] = useState(0);
  const [worksheets, setWorksheets] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [toast, setToast] = useState({ visible: false, message: '', severity: 'success' });

  // Load worksheets on component mount
  useEffect(() => {
    loadWorksheets();
  }, []);

  const loadWorksheets = async () => {
    setLoading(true);
    try {
      const response = await protectedApiCall('/api/worksheets', 'GET');
      setWorksheets(response.data);
    } catch (error) {
      console.error('Error loading worksheets:', error);
      showToast('Failed to load worksheets', 'error');
    } finally {
      setLoading(false);
    }
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const showToast = (message, severity = 'success') => {
    setToast({ visible: true, message, severity });
  };

  const handleCreateWorksheet = async (worksheetData) => {
    try {
      await protectedApiCall('/api/worksheets', 'POST', worksheetData);
      showToast('Worksheet created successfully');
      setShowCreateForm(false);
      loadWorksheets();
    } catch (error) {
      console.error('Error creating worksheet:', error);
      showToast('Failed to create worksheet', 'error');
    }
  };

  const handleDeleteWorksheet = async (worksheetId) => {
    try {
      const response = await protectedApiCall(`/api/worksheets/${worksheetId}`, 'DELETE');
      console.log('Delete response:', response);
      showToast('Worksheet deleted successfully');
      loadWorksheets();
    } catch (error) {
      console.error('Error deleting worksheet:', error);
      showToast('Failed to delete worksheet', 'error');
    }
  };

  const handleUpdateWorksheet = async (worksheetId, worksheetData) => {
    try {
      await protectedApiCall(`/api/worksheets/${worksheetId}`, 'PUT', worksheetData);
      showToast('Worksheet updated successfully');
      loadWorksheets();
      return true;
    } catch (error) {
      console.error('Error updating worksheet:', error);
      showToast('Failed to update worksheet', 'error');
      return false;
    }
  };

  return (
    <Container maxWidth="xl" sx={{ py: 4 }}>
      <Snackbar
        open={toast.visible}
        autoHideDuration={4000}
        onClose={() => setToast({ ...toast, visible: false })}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert severity={toast.severity} onClose={() => setToast({ ...toast, visible: false })}>
          {toast.message}
        </Alert>
      </Snackbar>

      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
        <Typography variant="h4" component="h1">
          Worksheet Manager
        </Typography>
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={() => setShowCreateForm(true)}
        >
          Create Worksheet
        </Button>
      </Box>

      <Paper sx={{ width: '100%', mb: 2, borderRadius: 2 }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={activeTab} onChange={handleTabChange} aria-label="worksheet tabs">
            <Tab label="My Worksheets" />
            {/*<Tab label="Submissions" />*/}
            <Tab label="Templates" />
          </Tabs>
        </Box>

        {activeTab === 0 && (
          <Box sx={{ p: 3 }}>
            <WorksheetList 
              worksheets={worksheets} 
              loading={loading} 
              onDelete={handleDeleteWorksheet}
              onUpdate={handleUpdateWorksheet}
              onRefresh={loadWorksheets}
            />
          </Box>
        )}
        
        {/*{activeTab === 1 && (*/}
        {/*  <Box sx={{ p: 3 }}>*/}
        {/*    <Typography variant="body1">*/}
        {/*      Student submissions will appear here once worksheets have been completed and scanned.*/}
        {/*    </Typography>*/}
        {/*  </Box>*/}
        {/*)}*/}
        
        {activeTab === 1 && (
          <Box sx={{ p: 3 }}>
            <Typography variant="h6" gutterBottom>
              Generate Worksheets from Templates
            </Typography>
            <Typography variant="body2" color="text.secondary" sx={{ mb: 3 }}>
              Use AI-powered templates to quickly generate curriculum-aligned worksheets. Select a template, specify the subject and grade, then provide a prompt to generate questions.
            </Typography>
            
            <Paper sx={{ p: 3, borderRadius: 2 }}>
              <TemplateGenerator 
                onWorksheetCreated={(worksheet) => {
                  showToast('Worksheet created successfully from template');
                  setActiveTab(0); // Switch to My Worksheets tab
                  loadWorksheets(); // Refresh the worksheet list
                }}
              />
            </Paper>
          </Box>
        )}
      </Paper>

      {showCreateForm && (
        <CreateWorksheet
          open={showCreateForm}
          onClose={() => setShowCreateForm(false)}
          onSave={handleCreateWorksheet}
        />
      )}
    </Container>
  );
};

export default WorksheetManager;