const CACHE_PREFIX = 'signed_url_';
const EXPIRY_PREFIX = 'signed_url_expiry_';

// Cache duration slightly less than server's signed URL duration to ensure freshness
const CACHE_DURATION = 23 * 60 * 60 * 1000; // 23 hours in milliseconds

export const cacheSignedUrl = (key, url) => {
    try {
        localStorage.setItem(CACHE_PREFIX + key, url);
        localStorage.setItem(EXPIRY_PREFIX + key, Date.now() + CACHE_DURATION);
    } catch (error) {
        console.warn('Failed to cache signed URL:', error);
    }
};

export const getSignedUrl = (key) => {
    try {
        const url = localStorage.getItem(CACHE_PREFIX + key);
        const expiry = parseInt(localStorage.getItem(EXPIRY_PREFIX + key));
        
        if (!url || !expiry || Date.now() > expiry) {
            // URL doesn't exist or has expired
            localStorage.removeItem(CACHE_PREFIX + key);
            localStorage.removeItem(EXPIRY_PREFIX + key);
            return null;
        }
        
        return url;
    } catch (error) {
        console.warn('Failed to retrieve signed URL:', error);
        return null;
    }
};

export const clearSignedUrl = (key) => {
    try {
        localStorage.removeItem(CACHE_PREFIX + key);
        localStorage.removeItem(EXPIRY_PREFIX + key);
    } catch (error) {
        console.warn('Failed to clear signed URL:', error);
    }
};

export const clearAllSignedUrls = () => {
    try {
        const keys = Object.keys(localStorage);
        keys.forEach(key => {
            if (key.startsWith(CACHE_PREFIX) || key.startsWith(EXPIRY_PREFIX)) {
                localStorage.removeItem(key);
            }
        });
    } catch (error) {
        console.warn('Failed to clear all signed URLs:', error);
    }
};