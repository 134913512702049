import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Box,
    Paper,
    Typography,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    CircularProgress,
    Alert,
    Divider,
} from '@mui/material';
import { Add as AddIcon } from '@mui/icons-material';

import TermList from './components/TermList';
import TermForm from './components/TermForm';
import YearSelector from './components/YearSelector';
import TimetableManager from './TimetableManager';
import useTerms from './hooks/useTerms';

const TermManager = () => {
    const navigate = useNavigate();
    const [formOpen, setFormOpen] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [selectedTerm, setSelectedTerm] = useState(null);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [selectedYear, setSelectedYear] = useState(() => {
        return localStorage.getItem('selectedYear') || new Date().getFullYear().toString();
    });

    const {
        terms,
        loading,
        error,
        createTerm,
        updateTerm,
        deleteTerm,
        currentSchool,
        refreshTerms,
    } = useTerms(navigate);

    // Track page view and setup on mount
    useEffect(() => {
        console.log('TermManager mounted with:', {
            currentSchool,
            token: localStorage.getItem('token'),
            termsCount: terms.length
        });

        // Track page view
        if (window.dataLayer) {
            window.dataLayer.push({
                event: 'pageview',
                page: '/terms'
            });
        }

        // Force initial load
        if (currentSchool) {
            console.log('Triggering initial terms load');
            refreshTerms();
        }
    }, []);

    // Refresh data when school changes
    useEffect(() => {
        if (currentSchool) {
            console.log('School changed, refreshing terms:', {
                schoolId: currentSchool._id,
                schoolName: currentSchool.name
            });
            refreshTerms();
        }
    }, [currentSchool, refreshTerms]);

    useEffect(() => {
        localStorage.setItem('selectedYear', selectedYear);
    }, [selectedYear]);

    const handleCreateTerm = () => {
        setIsEditing(false);
        setSelectedTerm(null);
        setFormOpen(true);
    };

    const handleEditTerm = (term) => {
        setSelectedTerm(term);
        setIsEditing(true);
        setFormOpen(true);
    };

    const handleDeleteClick = (termNumber) => {
        const term = terms.find(t => t.termNumber === termNumber);
        setSelectedTerm(term);
        setDeleteDialogOpen(true);
    };

    const handleDeleteConfirm = async () => {
        try {
            await deleteTerm(selectedTerm.termNumber);
            setDeleteDialogOpen(false);
            setSelectedTerm(null);
        } catch (err) {
            console.error('Failed to delete term:', err);
        }
    };

    const handleFormSubmit = async (formData) => {
        try {
            if (isEditing) {
                await updateTerm(formData.termNumber, formData);
            } else {
                await createTerm(formData);
            }
            setFormOpen(false);
        } catch (err) {
            console.error('Failed to save term:', err);
        }
    };

    const filteredTerms = terms.filter(term => term.year === selectedYear);

    if (error) {
        return (
            <Box sx={{ p: 3 }}>
                <Alert severity="error">{error}</Alert>
            </Box>
        );
    }

    return (
        <Box sx={{ p: { xs: 2, sm: 3, md: 4 } }}>
            <Paper
                elevation={0}
                sx={{
                    p: { xs: 2, sm: 3, md: 4 },
                    borderRadius: 3,
                    border: '1px solid',
                    borderColor: 'divider',
                    transition: 'all 0.2s ease-in-out',
                    '&:hover': {
                        boxShadow: '0 8px 24px rgba(45, 90, 39, 0.12)',
                    },
                }}
            >
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    mb: 3
                }}>
                    <Typography 
                        variant="h5" 
                        sx={{ 
                            fontWeight: 700,
                            color: 'primary.main',
                            fontSize: { xs: '1.25rem', sm: '1.5rem' }
                        }}
                    >
                        School Terms - {currentSchool?.name || 'Loading...'}
                    </Typography>
                    <Button
                        variant="contained"
                        startIcon={<AddIcon />}
                        onClick={handleCreateTerm}
                    >
                        Add Term
                    </Button>
                </Box>

                <YearSelector
                    selectedYear={selectedYear}
                    onYearChange={setSelectedYear}
                />

                {loading ? (
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        py: 4
                    }}>
                        <CircularProgress />
                    </Box>
                ) : (
                    <TermList
                        terms={filteredTerms}
                        onEdit={handleEditTerm}
                        onDelete={handleDeleteClick}
                    />
                )}
            </Paper>

            <Box sx={{ mt: 3 }}>
                <TimetableManager />
            </Box>

            <TermForm
                open={formOpen}
                onClose={() => setFormOpen(false)}
                onSubmit={handleFormSubmit}
                initialData={isEditing ? selectedTerm : null}
                isEditing={isEditing}
                selectedYear={selectedYear}
            />

            <Dialog
                open={deleteDialogOpen}
                onClose={() => setDeleteDialogOpen(false)}
                PaperProps={{
                    sx: { borderRadius: 2 }
                }}
            >
                <DialogTitle>Delete Term?</DialogTitle>
                <DialogContent>
                    <Typography>
                        Are you sure you want to delete Term {selectedTerm?.termNumber}? 
                        This action cannot be undone.
                    </Typography>
                </DialogContent>
                <DialogActions sx={{ p: 2 }}>
                    <Button onClick={() => setDeleteDialogOpen(false)}>
                        Cancel
                    </Button>
                    <Button
                        onClick={handleDeleteConfirm}
                        variant="contained"
                        color="error"
                    >
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default TermManager;