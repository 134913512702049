import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { protectedApiCall } from '../auth';

// Map frontend routes to page names and sections
const pageNameMap = {
    '/': { name: 'Home', section: 'main' },
    '/admin/users': { name: 'User Management', section: 'admin' },
    '/admin/schools': { name: 'School Management', section: 'admin' },
    '/syllabus/generate': { name: 'Generate Document', section: 'syllabus' },
    '/syllabus/view': { name: 'View Document', section: 'syllabus' },
    '/syllabus/templates': { name: 'Templates', section: 'syllabus' },
    '/classes': { name: 'Class Management', section: 'classes' },
    '/classes/create': { name: 'Create Class', section: 'classes' },
    '/terms': { name: 'Term Management', section: 'terms' },
    '/terms/timetable': { name: 'Timetable Management', section: 'terms' },
    '/reports': { name: 'Reports', section: 'reports' },
    '/reports/behavior': { name: 'Behavior Reports', section: 'reports' },
    '/reports/summary': { name: 'Summary Reports', section: 'reports' },
    '/chat': { name: 'Chat', section: 'communication' },
    '/messaging': { name: 'Messaging', section: 'communication' },
    '/newsletter': { name: 'Newsletter', section: 'communication' },
    '/feedback': { name: 'Feedback', section: 'support' },
    '/profile': { name: 'User Profile', section: 'account' },
};

// Only track these frontend routes
const isTrackablePath = (pathname) => {
    // Only track paths that are in our pageNameMap
    return pageNameMap.hasOwnProperty(pathname);
};

export const usePageTracking = () => {
    const location = useLocation();

    useEffect(() => {
        // Only track if it's a known frontend route
        if (!isTrackablePath(location.pathname)) {
            return;
        }

        const trackPage = async () => {
            try {
                const pageInfo = pageNameMap[location.pathname];
                
                await protectedApiCall('/api/analytics/track-page', 'POST', {
                    path: location.pathname,
                    pageName: pageInfo.name,
                    section: pageInfo.section,
                    action: 'view',
                    type: 'page_view'
                });
            } catch (error) {
                console.error('Error tracking page visit:', error);
            }
        };

        trackPage();
    }, [location.pathname]);
};

// Helper function to track specific actions
export const trackUserAction = async (path, pageName, section, action, metadata = {}) => {
    try {
        await protectedApiCall('/api/analytics/track-page', 'POST', {
            path,
            pageName,
            section,
            action,
            type: 'user_action',
            metadata
        });
    } catch (error) {
        console.error('Error tracking user action:', error);
    }
};