import React, { useState, useEffect, useCallback } from 'react';
import { userStorage } from '../../utils/userStorage';
import { isTokenExpired, refreshAccessToken } from '../../auth';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import {
    Box,
    Container,
    Paper,
    Typography,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Button,
    Grid,
    CircularProgress,
    useTheme,
    TextField
} from '@mui/material';
import {
    TextSnippet as TextIcon,
    PieChart as PieChartIcon,
    Timeline as TimelineIcon
} from '@mui/icons-material';
import BehaviorGraph from './BehaviorGraph';
import BehaviorTimeGraph from './BehaviorTimeGraph';
import SummaryReport from './SummaryReport';
import { protectedApiCall, protectedFetch } from '../../auth';

const Reports = () => {
    const navigate = useNavigate();
    const theme = useTheme();

    // Utility function to determine if a key should use user-specific storage
    const isUserPreference = (key) => {
        const userPrefs = [
            'selectedClass', 'selectedStudent', 'selectedName',
            'selectedReportClass', 'reportType', 'reportData'
        ];
        return userPrefs.includes(key);
    };

    const getStorageItem = (key, defaultValue) => {
        try {
            if (isUserPreference(key)) {
                const value = userStorage.getItem(key);
                console.log(`Getting user preference ${key}:`, value);
                return value === null ? defaultValue : value;
            } else {
                const item = localStorage.getItem(key);
                console.log(`Getting global setting ${key}:`, item);
                return item === null ? defaultValue : JSON.parse(item);
            }
        } catch (error) {
            console.error(`Error reading ${key} from storage:`, error);
            if (isUserPreference(key)) {
                userStorage.removeItem(key);
            } else {
                localStorage.removeItem(key);
            }
            return defaultValue;
        }
    };

    const [classes, setClasses] = useState([]);
    const [selectedClass, setSelectedClass] = useState(() => {
        const dashSelectedClass = getStorageItem('selectedClass', null);
        const storedReportClass = localStorage.getItem('selectedReportClass');
        console.log('Initializing selectedClass with stored values:', {
            dashboardClass: dashSelectedClass?.className,
            reportClass: storedReportClass
        });
        return dashSelectedClass?._id || storedReportClass || '';
    });
    const [students, setStudents] = useState([]);
    const [selectedStudent, setSelectedStudent] = useState(() => {
        const storedStudent = userStorage.getItem('selectedStudent');
        console.log('Initializing Reports with stored student:', storedStudent);
        return storedStudent && storedStudent !== 'class' ? storedStudent : '';
    });
    const [reportData, setReportData] = useState(null);
    const [reportType, setReportType] = useState('summary');
    const [startDate, setStartDate] = useState(() => {
        const range = getStorageItem('dateRange', [new Date(), new Date()]);
        return range[0] ? new Date(range[0]).toISOString().split('T')[0] : '';
    });
    const [endDate, setEndDate] = useState(() => {
        const range = getStorageItem('dateRange', [new Date(), new Date()]);
        return range[1] ? new Date(range[1]).toISOString().split('T')[0] : '';
    });
    const [loading, setLoading] = useState(false);
    const [loadingStudents, setLoadingStudents] = useState(false);
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    const [terms, setTerms] = useState([]);
    const [selectedTerms, setSelectedTerms] = useState([]);
    const [selectedYear, setSelectedYear] = useState('2024');
    const [dateRange, setDateRange] = useState(() => {
        const defaultRange = [new Date(), new Date()];
        return getStorageItem('dateRange', defaultRange);
    });
    const [currentDate, setCurrentDate] = useState(() => {
        const savedDate = localStorage.getItem('currentDate');
        try {
            return savedDate ? new Date(savedDate) : new Date();
        } catch (e) {
            console.error('Error parsing currentDate from localStorage:', e);
            return new Date();
        }
    });

    useEffect(() => {
        if (window.dataLayer) {
            window.dataLayer.push({
                event: 'pageview',
                page: '/reports'
            });
        }
    }, []);

    // Fetch classes when component mounts
    useEffect(() => {
        const fetchClasses = async () => {
            try {
                const result = await protectedFetch(async () => {
                    try {
                        const response = await axios.get(`${BASE_URL}/api/classes`, {
                            headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
                        });
                        return response.data;
                    } catch (error) {
                        if (error.response?.status === 404) {
                            console.warn('No classes found (404 response)');
                            return [];
                        }
                        throw error;
                    }
                }, navigate);

                if (result && Array.isArray(result)) {
                    setClasses(result);
                    if (result.length === 0) {
                        setSelectedClass('');
                        localStorage.removeItem('selectedReportClass');
                        localStorage.removeItem('selectedStudent');
                    }
                } else {
                    console.warn('Invalid classes data format, setting empty array');
                    setClasses([]);
                    setSelectedClass('');
                    localStorage.removeItem('selectedReportClass');
                    localStorage.removeItem('selectedStudent');
                }
            } catch (error) {
                console.error('Error fetching classes:', error);
                setClasses([]);
                setSelectedClass('');
                localStorage.removeItem('selectedReportClass');
                localStorage.removeItem('selectedStudent');
            }
        };

        fetchClasses();
    }, [BASE_URL, navigate]);

    useEffect(() => {
        const fetchTerms = async () => {
            try {
                const result = await protectedFetch(async () => {
                    const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/terms`, {
                        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
                        params: { year: selectedYear }
                    });
                    return response.data;
                }, navigate);

                if (result) {
                    const yearTerms = result.filter(term => term.year === selectedYear);
                    setTerms(yearTerms);

                    if (yearTerms.length > 0) {
                        const startDates = yearTerms.map(term => new Date(term.startDate));
                        const endDates = yearTerms.map(term => new Date(term.endDate));
                        const overallStartDate = new Date(Math.min(...startDates));
                        const overallEndDate = new Date(Math.max(...endDates));

                        setDateRange([overallStartDate, overallEndDate]);
                        setCurrentDate(overallStartDate);
                    } else {
                        const yearStart = new Date(selectedYear, 0, 1);
                        const yearEnd = new Date(selectedYear, 11, 31);
                        setDateRange([yearStart, yearEnd]);
                        setCurrentDate(yearStart);
                    }
                }
            } catch (error) {
                console.error('Error fetching terms:', error);
            }
        };

        fetchTerms();
    }, [selectedYear, BASE_URL]);

    // Effect to handle class changes
    useEffect(() => {
        if (!selectedClass) return;
        
        console.log('Class changed in Reports:', {
            newClass: selectedClass,
            currentStudent: selectedStudent
        });
        
        // Load students for the new class
        fetchStudents(selectedClass);
        
        // Student selection will be validated after students load
    }, [selectedClass]);

    // Effect to handle student selection when students list changes
    useEffect(() => {
        console.log('=== Student List Changed ===');
        console.log('Current state:', {
            students: students.map(s => ({ id: s._id, name: s.firstName })),
            selectedStudent,
            selectedClass,
            localStorage: {
                selectedStudent: localStorage.getItem('selectedStudent'),
                selectedName: localStorage.getItem('selectedName')
            }
        });

        // Don't process if we don't have students yet
        if (!students.length) {
            console.log('No students loaded yet, skipping selection check');
            return;
        }

        // If we already have a valid selection, keep it
        if (selectedStudent && students.some(s => s._id === selectedStudent)) {
            const student = students.find(s => s._id === selectedStudent);
            console.log('Current selection is valid:', {
                id: selectedStudent,
                name: student?.firstName
            });
            return;
        }

        // Get stored selections
        const reportsStudent = localStorage.getItem('selectedStudent');
        const dashboardStudent = localStorage.getItem('selectedName');
        
        console.log('Checking stored selections:', {
            reportsStudent,
            dashboardStudent
        });

        // Try to find student by ID from reports storage
        if (reportsStudent && reportsStudent !== 'class') {
            const studentById = students.find(s => s._id === reportsStudent);
            if (studentById) {
                console.log('Found student by ID:', {
                    id: studentById._id,
                    name: studentById.firstName
                });
                setSelectedStudent(reportsStudent);
                return;
            }
        }

        // Try to find student by name from dashboard storage
        if (dashboardStudent && dashboardStudent !== 'class') {
            const studentByName = students.find(s => s.firstName === dashboardStudent);
            if (studentByName) {
                console.log('Found student by name:', {
                    id: studentByName._id,
                    name: studentByName.firstName
                });
                setSelectedStudent(studentByName._id);
                return;
            }
        }

        // Only clear if we had a selection that's no longer valid
        if (selectedStudent) {
            console.log('Previous selection is no longer valid:', selectedStudent);
            setSelectedStudent('');
        } else {
            console.log('No previous selection to restore');
        }
    }, [students, selectedClass]);

    // Effect to sync with storage when component mounts or students change
    useEffect(() => {
        if (!students.length) return;

        const storedStudent = localStorage.getItem('selectedStudent');
        const storedName = localStorage.getItem('selectedName');
        
        console.log('Checking stored student values:', {
            storedStudent,
            storedName,
            currentSelection: selectedStudent,
            availableStudents: students.map(s => ({ id: s._id, name: s.firstName }))
        });

        // If we have a stored student and no current selection, try to restore it
        if (storedStudent && storedStudent !== 'class' && !selectedStudent) {
            const student = students.find(s => s._id === storedStudent);
            if (student) {
                console.log('Restoring stored student:', student);
                setSelectedStudent(storedStudent);
            }
        }
        // If we have a current selection, make sure storage is in sync
        else if (selectedStudent) {
            const student = students.find(s => s._id === selectedStudent);
            if (student && (storedStudent !== selectedStudent || storedName !== student.firstName)) {
                console.log('Updating storage to match current selection:', student);
                localStorage.setItem('selectedStudent', selectedStudent);
                localStorage.setItem('selectedName', student.firstName);
            }
        }
    }, [students, selectedStudent]);

    const fetchStudents = useCallback(async (classId) => {
        if (!classId) {
            console.log('No class ID provided, clearing students list');
            setStudents([]);
            setLoadingStudents(false);
            return;
        }

        // Prevent duplicate fetches
        setLoadingStudents(true);

        try {
            console.log('Fetching students for class:', classId);
            const result = await protectedFetch(async () => {
                try {
                    const response = await axios.get(`${BASE_URL}/api/classes/${classId}/students`, {
                        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
                    });
                    return response.data;
                } catch (error) {
                    if (error.response?.status === 404) {
                        console.warn('No students found (404 response)');
                        return [];
                    }
                    throw error;
                }
            }, navigate);

            if (result && Array.isArray(result)) {
                // Check if we already have these students
                const currentStudents = JSON.stringify(students.map(s => s._id).sort());
                const newStudents = JSON.stringify(result.map(s => s._id).sort());
                
                if (currentStudents !== newStudents) {
                    console.log('Updating students list:', result.map(s => ({ id: s._id, name: s.firstName })));
                    setStudents(result);
                } else {
                    console.log('Students list unchanged');
                }
            } else {
                console.warn('Invalid students data format, setting empty array');
                setStudents([]);
            }
        } catch (error) {
            console.error('Error fetching students:', error);
            setStudents([]);
        } finally {
            setLoadingStudents(false);
        }
    }, [students, navigate, BASE_URL]);

    const generateReport = async (reportOption = 'general') => {
        setReportData(null);
        setLoading(true);

        if (!selectedClass) {
            alert('Please select a class first');
            setLoading(false);
            return;
        }

        if (!selectedStudent) {
            alert('Please select a student first');
            setLoading(false);
            return;
        }

        if (!startDate || !endDate) {
            alert('Please select a date range');
            setLoading(false);
            return;
        }

        try {
            let endpoint;
            if (reportType === 'behavior-time-graph') {
                endpoint = `${BASE_URL}/api/classes/${selectedClass}/records/times/${startDate}/${endDate}`;
            } else if (reportType === 'behavior-graph') {
                endpoint = `${BASE_URL}/api/classes/behavior/report/${selectedClass}/behavior-graph`;
            } else {
                endpoint = reportType === 'summary'
                    ? `${BASE_URL}/api/classes/${selectedClass}/reports/summary`
                    : `${BASE_URL}/api/classes/${selectedClass}/reports/${reportType}`;
            }

            const result = await protectedFetch(async () => {
                const response = await axios({
                    method: reportType === 'summary' ? 'POST' : 'GET',
                    url: endpoint,
                    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
                    data: reportType === 'summary' ? {
                        studentId: selectedStudent,
                        studentName: students.find(s => s._id === selectedStudent)?.firstName,
                        startDate: startDate,
                        endDate: endDate,
                        reportOption: reportOption,
                        isCustomQuery: !['general', 'behavior', 'progress'].includes(reportOption),
                        customQuery: !['general', 'behavior', 'progress'].includes(reportOption) ? reportOption : null
                    } : {},
                    params: reportType !== 'summary' ? {
                        studentId: selectedStudent,
                        studentName: students.find(s => s._id === selectedStudent)?.firstName,
                        startDate: startDate,
                        endDate: endDate,
                    } : {}
                });
                return response.data;
            }, navigate);

            if (result) {
                setReportData(result);
                localStorage.setItem('reportData', JSON.stringify(result));
            }
        } catch (error) {
            console.error('Error generating report:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleGenerateReport = () => {
        if (window.dataLayer) {
            window.dataLayer.push({
                event: 'generateReport',
                reportType: reportType,
                selectedClass: selectedClass,
                selectedStudent: selectedStudent,
                startDate: startDate,
                endDate: endDate
            });
        }
        if (reportType === 'summary') {
            const selectedOption = localStorage.getItem('selectedReportOption') || 'general';
            generateReport(selectedOption);
        } else {
            generateReport();
        }
    };

    return (
        <Container maxWidth="lg" sx={{ py: 3 }}>
            <Grid container spacing={3}>
                {/* Controls Section */}
                <Grid item xs={12} md={4} lg={3}>
                    <Paper
                        elevation={0}
                        sx={{
                            p: 3,
                            bgcolor: 'background.default',
                            border: `1px solid ${theme.palette.divider}`,
                            borderRadius: 2,
                            height: '100%'
                        }}
                    >
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                            <FormControl size="small">
                                <InputLabel>Class</InputLabel>
                                <Select
                                    value={selectedClass}
                                    onChange={(e) => {
                                        const classId = e.target.value;
                                        setSelectedClass(classId);
                                        localStorage.setItem('selectedReportClass', classId);
                                    }}
                                    label="Class"
                                >
                                    <MenuItem value="" disabled>Select a class</MenuItem>
                                    {classes.map((cls) => (
                                        <MenuItem key={cls._id} value={cls._id}>
                                            {cls.className}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                            <Typography
                                variant="caption"
                                sx={{
                                    mb: 0.5,
                                    display: 'block',
                                    color: 'text.secondary',
                                    fontWeight: 500
                                }}
                            >
                                Select Student
                            </Typography>
                            <Box sx={{ 
                                maxHeight: {xs: 200, sm: 250, md: 300}, 
                                overflowY: 'auto',
                                border: `1px solid ${theme.palette.divider}`,
                                borderRadius: 1,
                                bgcolor: 'background.paper',
                                '& > div': {
                                    fontSize: '0.875rem',
                                }
                            }}>
                                {loadingStudents ? (
                                    <Box sx={{ 
                                        display: 'flex', 
                                        justifyContent: 'center', 
                                        alignItems: 'center',
                                        p: 1
                                    }}>
                                        <CircularProgress size={20} />
                                    </Box>
                                ) : students.length > 0 ? (
                                    students.map((student) => (
                                        <Box
                                            key={student._id}
                                            onClick={() => {
                                                setSelectedStudent(student._id);
                                                localStorage.setItem('selectedStudent', student._id);
                                                localStorage.setItem('selectedName', student.firstName);
                                            }}
                                            sx={{
                                                py: {xs: 1, sm: 0.75},
                                                px: 1.5,
                                                cursor: 'pointer',
                                                bgcolor: selectedStudent === student._id ? 'primary.main' : 'transparent',
                                                color: selectedStudent === student._id ? 'primary.contrastText' : 'text.primary',
                                                '&:hover': {
                                                    bgcolor: selectedStudent === student._id 
                                                        ? 'primary.dark'
                                                        : 'action.hover'
                                                },
                                                transition: 'background-color 0.2s',
                                                borderBottom: `1px solid ${theme.palette.divider}`,
                                                '&:last-child': {
                                                    borderBottom: 'none'
                                                }
                                            }}
                                        >
                                            {student.firstName}
                                        </Box>
                                    ))
                                ) : !selectedClass ? (
                                    <Box sx={{ py: 1, px: 1.5, color: 'text.secondary', textAlign: 'center' }}>
                                        Select a class to view students
                                    </Box>
                                ) : (
                                    <Box sx={{ py: 1, px: 1.5, color: 'text.secondary', textAlign: 'center' }}>
                                        No students found in this class
                                    </Box>
                                )}
                            </Box>

                            <FormControl size="small">
                                <InputLabel>Report Type</InputLabel>
                                <Select
                                    value={reportType}
                                    onChange={(e) => setReportType(e.target.value)}
                                    label="Report Type"
                                >
                                    <MenuItem value="summary">
                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                            <TextIcon sx={{ fontSize: 20 }} />
                                            <span>Written Summary</span>
                                        </Box>
                                    </MenuItem>
                                    <MenuItem value="behavior-graph">
                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                            <PieChartIcon sx={{ fontSize: 20 }} />
                                            <span>Overview Graph</span>
                                        </Box>
                                    </MenuItem>
                                    <MenuItem value="behavior-time-graph">
                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                            <TimelineIcon sx={{ fontSize: 20 }} />
                                            <span>Report Times Graph</span>
                                        </Box>
                                    </MenuItem>
                                </Select>
                            </FormControl>

                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6} md={12}>
                                    <TextField
                                        type="date"
                                        label="Start Date"
                                        value={startDate}
                                        onChange={(e) => setStartDate(e.target.value)}
                                        size="small"
                                        fullWidth
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={12}>
                                    <TextField
                                        type="date"
                                        label="End Date"
                                        value={endDate}
                                        onChange={(e) => setEndDate(e.target.value)}
                                        size="small"
                                        fullWidth
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>
                            </Grid>

                            <Button
                                variant="contained"
                                onClick={handleGenerateReport}
                                disabled={!selectedClass || loading}
                                sx={{ mt: 1 }}
                                fullWidth
                            >
                                {loading ? (
                                    <CircularProgress size={24} color="inherit" />
                                ) : (
                                    'Generate Report'
                                )}
                            </Button>
                        </Box>
                    </Paper>
                </Grid>

                {/* Report Display Section */}
                <Grid item xs={12} md={8} lg={9}>
                    <Paper
                        elevation={0}
                        sx={{
                            p: {xs: 2, sm: 3},
                            minHeight: {xs: 300, sm: 400},
                            bgcolor: 'background.default',
                            border: `1px solid ${theme.palette.divider}`,
                            borderRadius: 2,
                            height: '100%'
                        }}
                    >
                        {reportType === 'summary' && (
                            <SummaryReport
                                data={reportData}
                                generateReportWithType={generateReport}
                                loading={loading}
                            />
                        )}
                        {reportType === 'behavior-graph' && (
                            loading ? (
                                <Box sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    height: '100%',
                                    minHeight: {xs: 150, sm: 200}
                                }}>
                                    <CircularProgress />
                                </Box>
                            ) : reportData ? (
                                <BehaviorGraph data={reportData} />
                            ) : (
                                <Box sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    height: '100%',
                                    minHeight: {xs: 150, sm: 200},
                                    color: 'text.secondary',
                                    textAlign: 'center',
                                    px: 2
                                }}>
                                    <Typography>
                                        Select options and generate a report to view data
                                    </Typography>
                                </Box>
                            )
                        )}
                        {reportType === 'behavior-time-graph' && (
                            loading ? (
                                <Box sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    height: {xs: 300, sm: 400},
                                    width: '100%'
                                }}>
                                    <CircularProgress />
                                </Box>
                            ) : (
                                <BehaviorTimeGraph data={reportData} />
                            )
                        )}
                    </Paper>
                </Grid>
            </Grid>
        </Container>
    );
};

export default Reports;