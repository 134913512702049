import React, { useMemo, useEffect, useRef, useState } from 'react';
import { Box, TextField, Button, Typography, useTheme, Paper, Stack, FormGroup, FormControlLabel, Checkbox } from '@mui/material';
import TemplateSelector, { templates } from '../../TemplateSelector';

// Auto-expanding textarea component
const AutoExpandingTextarea = ({ textareaRef, value, onChange, theme, onHeightChange }) => {
    const hiddenDivRef = useRef(null);
    const containerRef = useRef(null);
    
    // Update the height of the textarea whenever the value changes
    useEffect(() => {
        if (hiddenDivRef.current) {
            // Set the content of the hidden div to match the textarea
            // Replace new lines with <br> to ensure proper height calculation
            hiddenDivRef.current.innerHTML = value.replace(/\n/g, '<br>') + '&nbsp;';
            
            // Get the scrollHeight of the hidden div and set the textarea height
            const scrollHeight = hiddenDivRef.current.scrollHeight;
            if (textareaRef.current) {
                const newHeight = Math.max(100, scrollHeight);
                textareaRef.current.style.height = newHeight + 'px';
                
                // Call the onHeightChange callback with the new height and container element
                if (onHeightChange && containerRef.current) {
                    onHeightChange(newHeight, containerRef.current);
                }
            }
        }
    }, [value, textareaRef, onHeightChange]);
    
    return (
        <div ref={containerRef} style={{ position: 'relative', width: '100%' }}>
            <textarea
                ref={textareaRef}
                value={value}
                onChange={(e) => onChange(e.target.value)}
                placeholder="Type your query here..."
                style={{
                    width: '100%',
                    minHeight: '100px',
                    padding: '12px',
                    borderRadius: '4px',
                    border: `1px solid ${theme.palette.divider}`,
                    backgroundColor: theme.palette.background.paper,
                    fontFamily: 'inherit',
                    fontSize: '1rem',
                    lineHeight: '1.5',
                    resize: 'none', // Disable manual resizing
                    outline: 'none',
                    boxSizing: 'border-box',
                    overflow: 'hidden' // Hide scrollbars
                }}
            />
            {/* Hidden div used to calculate the height */}
            <div
                ref={hiddenDivRef}
                style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    visibility: 'hidden',
                    height: 0,
                    overflow: 'hidden',
                    width: '100%',
                    padding: '12px',
                    fontSize: '1rem',
                    fontFamily: 'inherit',
                    lineHeight: '1.5',
                    boxSizing: 'border-box',
                    whiteSpace: 'pre-wrap',
                    wordWrap: 'break-word'
                }}
            />
        </div>
    );
};

const allSubjects = ['Mathematics', 'English', 'Science', 'Science and Technology', 'HSIE', 'PDHPE', 'Technology and Applied Studies', 'Creative Arts'];
const allStages = ['Early Stage 1', 'Stage 1', 'Stage 2', 'Stage 3', 'Stage 4', 'Stage 5', 'Stage 6', 'Life Skills'];

// Define which stages are available for each subject
const subjectStageMapping = {
    'Science': ['Stage 4', 'Stage 5', 'Stage 6', 'Life Skills'],
    'Science and Technology': ['Early Stage 1', 'Stage 1', 'Stage 2', 'Stage 3', 'Life Skills']
};

const SearchSection = ({
    query,
    selectedSubject,
    selectedStage,
    selectedTemplate,
    textareaRef,
    onQueryChange,
    onSubjectChange,
    onStageChange,
    onTemplateChange,
    options,
    onOptionsChange,
    loading,
    onSubmit,
    isMobile
}) => {
    const theme = useTheme();
    const [buttonPosition, setButtonPosition] = useState({ top: 0, height: 0 });
    
    // Handle textarea height changes to position the Generate button
    const handleTextareaHeightChange = (height, containerElement) => {
        if (containerElement) {
            // Just store the height of the textarea for the button container to use
            setButtonPosition({
                height: height
            });
        }
    };
    
    // Get available stages based on selected subject
    const availableStages = useMemo(() => {
        if (!selectedSubject) {
            return allStages;
        }
        
        // If the subject has specific stage restrictions
        if (selectedSubject === 'Science') {
            return subjectStageMapping['Science'];
        } else if (selectedSubject === 'Science and Technology') {
            return subjectStageMapping['Science and Technology'];
        }
        
        // For all other subjects, show all stages
        return allStages;
    }, [selectedSubject]);
    
    // Clear stage selection if it's not valid for the selected subject
    React.useEffect(() => {
        if (selectedStage && selectedSubject && !availableStages.includes(selectedStage)) {
            onStageChange(null);
        }
    }, [selectedSubject, selectedStage, availableStages, onStageChange]);
    
    // Helper function for handling option changes
    const handleOptionChange = (option) => {
        onOptionsChange({
            ...options,
            [option]: !options[option]
        });
    };

    return (
        <Box>
            {/* Subject Selection Buttons */}
            <Box sx={{ mb: 1 }}>
                <Typography 
                    variant="subtitle1" 
                    sx={{ 
                        mb: 1,
                        color: theme.palette.text.primary,
                        fontWeight: 600
                    }}
                >
                    Select a Subject
                </Typography>
                <Paper
                    elevation={0}
                    sx={{
                        p: 1.5,
                        bgcolor: theme.palette.background.default,
                        borderRadius: 2
                    }}
                >
                    <Stack 
                        direction="row" 
                        spacing={0.5} 
                        sx={{ 
                            flexWrap: 'wrap', 
                            gap: 0.75,
                            '& > button': {
                                fontSize: '0.85rem'
                            }
                        }}
                    >
                        {allSubjects.map(subject => (
                            <Button
                                key={subject}
                                variant={selectedSubject === subject ? "contained" : "outlined"}
                                onClick={() => onSubjectChange(subject)}
                                size="small"
                                sx={{
                                    borderRadius: 2,
                                    textTransform: 'none',
                                    fontWeight: 500,
                                    boxShadow: 'none',
                                    py: 0.5,
                                    '&:hover': {
                                        boxShadow: 'none'
                                    }
                                }}
                            >
                                {subject}
                            </Button>
                        ))}
                    </Stack>
                </Paper>
            </Box>

            {/* Stage Selection Buttons - Only shown if a subject is selected */}
            {selectedSubject && (
                <Box sx={{ mb: 1 }}>
                    <Typography 
                        variant="subtitle1" 
                        sx={{ 
                            mb: 1,
                            color: theme.palette.text.primary,
                            fontWeight: 600
                        }}
                    >
                        Select a Stage
                    </Typography>
                    <Paper
                        elevation={0}
                        sx={{
                            p: 1.5,
                            bgcolor: theme.palette.background.default,
                            borderRadius: 2
                        }}
                    >
                        <Stack 
                            direction="row" 
                            spacing={0.5} 
                            sx={{ 
                                flexWrap: 'wrap', 
                                gap: 0.75,
                                '& > button': {
                                    fontSize: '0.85rem'
                                }
                            }}
                        >
                            {availableStages.map(stage => (
                                <Button
                                    key={stage}
                                    variant={selectedStage === stage ? "contained" : "outlined"}
                                    onClick={() => onStageChange(stage)}
                                    size="small"
                                    sx={{
                                        borderRadius: 2,
                                        textTransform: 'none',
                                        fontWeight: 500,
                                        boxShadow: 'none',
                                        py: 0.5,
                                        '&:hover': {
                                            boxShadow: 'none'
                                        }
                                    }}
                                >
                                    {stage}
                                </Button>
                            ))}
                        </Stack>
                    </Paper>
                </Box>
            )}

            {/* Template Selector - Only shown if subject and stage are selected */}
            {selectedSubject && selectedStage && (
                <Box sx={{ mb: 1 }}>
                    <TemplateSelector
                        selectedTemplate={selectedTemplate}
                        onTemplateChange={onTemplateChange}
                        options={options}
                        onOptionsChange={onOptionsChange}
                    />
                </Box>
            )}

            {/* Query Input and Generate Button */}
            <Box sx={{ mt: 2 }}>
                <Typography 
                    variant="subtitle1" 
                    sx={{ 
                        mb: 1,
                        color: theme.palette.text.primary,
                        fontWeight: 600
                    }}
                >
                    Your Query
                </Typography>
                <Paper
                    elevation={0}
                    sx={{
                        p: 1.5,
                        bgcolor: theme.palette.background.default,
                        borderRadius: 2
                    }}
                >
                    <Box 
                        sx={{ 
                            display: 'flex', 
                            gap: isMobile ? 1 : 2,
                            alignItems: 'stretch',
                            flexDirection: isMobile ? 'column' : 'row'
                        }}
                    >
                        <Box sx={{ flexGrow: 1 }}>
                            <div
                                style={{
                                    position: 'relative',
                                    width: '100%'
                                }}
                            >
                                <AutoExpandingTextarea 
                                    textareaRef={textareaRef}
                                    value={query}
                                    onChange={onQueryChange}
                                    theme={theme}
                                    onHeightChange={handleTextareaHeightChange}
                                />
                            </div>
                        </Box>
                        <Box sx={{ 
                            position: 'relative',
                            width: isMobile ? '100%' : '100px',
                            ml: isMobile ? 0 : 1,
                            mt: isMobile ? 1 : 0,
                            display: 'flex',
                            alignItems: isMobile ? 'center' : 'flex-end',
                            alignSelf: 'stretch',
                            height: isMobile ? 'auto' : (buttonPosition.height > 0 ? `${buttonPosition.height}px` : 'auto')
                        }}>
                            <Box sx={{
                                position: isMobile ? 'relative' : 'sticky',
                                bottom: 0,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: isMobile ? 'stretch' : 'flex-start',
                                width: '100%'
                            }}>
                                <Button
                                    variant="contained"
                                    disabled={!query.trim() || !selectedSubject || !selectedStage || !selectedTemplate || loading}
                                    sx={{ 
                                        minWidth: isMobile ? '100%' : 100,
                                        height: 48,
                                        boxShadow: 'none',
                                        borderRadius: 2,
                                        '&:hover': {
                                            boxShadow: 'none'
                                        }
                                    }}
                                    onClick={onSubmit}
                                >
                                    Generate
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                </Paper>
            </Box>
        </Box>
    );
};

export default SearchSection;