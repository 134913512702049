import React, { useState, useEffect } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    Typography,
    Box,
    CircularProgress,
} from '@mui/material';
import { School as SchoolIcon } from '@mui/icons-material';
import { getLogoUrl } from '../../utils/logoHelper';
import { cacheSchoolLogo, getCachedLogo, clearCachedLogo } from '../../utils/schoolLogoCache';

const SchoolSelector = ({
    open,
    schools = [],
    onSelect,
    onClose,
    loading = false,
    title = "Select School"
}) => {
    const [logoUrls, setLogoUrls] = useState({});

    // Load logo URLs when schools change
    useEffect(() => {
        const loadLogoUrls = async () => {
            try {
                const urls = {};
                for (const school of schools) {
                    try {
                        // Try to get from cache first
                        const cached = getCachedLogo(school.id);
                        if (cached) {
                            urls[school.id] = cached;
                            continue;
                        }

                        // If not in cache and school has a logo URL, cache it
                        if (school.logoUrl) {
                            const imageData = await cacheSchoolLogo(school.id, school.logoUrl);
                            if (imageData) {
                                urls[school.id] = imageData;
                            }
                        } else {
                            // If no logo URL, try to get one
                            const url = await getLogoUrl(school.id);
                            if (url) {
                                const imageData = await cacheSchoolLogo(school.id, url);
                                if (imageData) {
                                    urls[school.id] = imageData;
                                }
                            }
                        }
                    } catch (err) {
                        console.warn(`Failed to load logo for school ${school.id}:`, err);
                    }
                }
                setLogoUrls(urls);
            } catch (err) {
                console.error('Failed to load logos:', err);
            }
        };
        
        if (schools.length) {
            loadLogoUrls();
        }
    }, [schools]);

    if (!schools.length && !loading) {
        return null;
    }

    return (
        <Dialog 
            open={open} 
            onClose={onClose}
            maxWidth="sm"
            fullWidth
            PaperProps={{
                sx: {
                    borderRadius: 2,
                }
            }}
        >
            <DialogTitle sx={{ 
                bgcolor: 'primary.lighter',
                color: 'primary.dark',
                fontWeight: 600,
                display: 'flex',
                alignItems: 'center',
                gap: 1
            }}>
                <SchoolIcon sx={{ color: 'primary.main' }} />
                {title}
            </DialogTitle>
            <DialogContent>
                {loading ? (
                    <Box sx={{ 
                        display: 'flex', 
                        justifyContent: 'center',
                        alignItems: 'center',
                        py: 4
                    }}>
                        <CircularProgress />
                    </Box>
                ) : (
                    <List sx={{ pt: 1 }}>
                        {schools.map((school) => (
                            <ListItem 
                                key={school.id}
                                disablePadding
                            >
                                <ListItemButton
                                    onClick={() => onSelect(school)}
                                    sx={{
                                        borderRadius: 1,
                                        mb: 1,
                                        py: 1,
                                        '&:hover': {
                                            bgcolor: 'primary.lighter',
                                        }
                                    }}
                                >
                                    <Box sx={{ 
                                        display: 'flex', 
                                        alignItems: 'center',
                                        gap: 2,
                                        width: '100%'
                                    }}>
                                        {logoUrls[school.id] ? (
                                            <Box
                                                component="img"
                                                src={logoUrls[school.id]}
                                                alt={`${school.name} logo`}
                                                onError={(e) => {
                                                    console.log('Image load error, attempting to use direct URL');
                                                    // Clear cache and try direct URL
                                                    clearCachedLogo(school.id);
                                                    if (school.logoUrl && e.target.src !== school.logoUrl) {
                                                        e.target.src = school.logoUrl;
                                                    } else {
                                                        // If direct URL also fails, show SchoolIcon
                                                        e.target.style.display = 'none';
                                                        e.target.nextSibling.style.display = 'block';
                                                    }
                                                }}
                                                sx={{
                                                    width: 40,
                                                    height: 40,
                                                    objectFit: 'contain',
                                                    borderRadius: 1,
                                                    bgcolor: 'background.paper',
                                                    p: 0.5
                                                }}
                                            />
                                        ) : null}
                                        <SchoolIcon 
                                            sx={{ 
                                                width: 40,
                                                height: 40,
                                                color: 'primary.main',
                                                display: logoUrls[school.id] ? 'none' : 'block'
                                            }} 
                                        />
                                        <ListItemText
                                            primary={
                                                <Typography variant="subtitle1">
                                                    {school.name}
                                                </Typography>
                                            }
                                        />
                                    </Box>
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </List>
                )}
            </DialogContent>
        </Dialog>
    );
};

export default SchoolSelector;