import React from 'react';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText
} from '@mui/material';

const ClassSelector = ({ classes, selectedClass, onChange, disabled, error, helperText }) => {
  return (
    <FormControl fullWidth error={error} disabled={disabled}>
      <InputLabel id="class-select-label">Class</InputLabel>
      <Select
        labelId="class-select-label"
        id="class-select"
        value={selectedClass || ''}
        label="Class"
        onChange={(e) => onChange(e.target.value)}
      >
        <MenuItem value="">
          <em>Select a class</em>
        </MenuItem>
        {classes && classes.length > 0 ? (
          classes.map((classItem) => (
            <MenuItem key={classItem._id} value={classItem._id}>
              {classItem.className}
            </MenuItem>
          ))
        ) : (
          <MenuItem value="" disabled>
            No classes available
          </MenuItem>
        )}
      </Select>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

export default ClassSelector;