import React, { useEffect } from 'react';
import { Link, Navigate } from 'react-router-dom';
import '../../css/LandingPage.css';
import { isAuthenticated } from "../../auth";
import AOS from 'aos';
import 'aos/dist/aos.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import mascotImage3 from '../../assets/bg-guy3.png';
import mascotImage2 from '../../assets/bg-guy2.png';
import mascotImage from '../../assets/bg-guy.png';
import logo512 from '../../assets/bg-guy.png';

const LandingPage = () => {
    useEffect(() => {
        AOS.init({
            duration: 1000,
            once: true,
        });
    }, []);

    // if (isAuthenticated()) {
    //     return <Navigate to="/dash" />;
    // }

    return (
        <div className="landing-page">
            <section className="hero">
                <img src={mascotImage3} alt="Mascot3" className="mascot top-left" />
                <h1 data-aos="fade-up">The Complete Educational Management System</h1>
                <p data-aos="fade-up" data-aos-delay="200">Streamline assessment, documentation, and reporting with a system that adapts to your curriculum</p>
                <div className="hero-buttons" data-aos="fade-up" data-aos-delay="400">
                    <Link to="/contact" className="cta-button primary">Get Started</Link>
                    <Link to="/demo" className="cta-button secondary">Watch Demo</Link>
                </div>
                <div 
                    className="scroll-indicator" 
                    data-aos="fade-up" 
                    data-aos-delay="600"
                    onClick={() => {
                        document.querySelector('.app-overview').scrollIntoView({ behavior: 'smooth' });
                    }}
                >
                    <div className="scroll-arrow">
                        <i className="fas fa-chevron-down"></i>
                    </div>
                    <span>Scroll to explore</span>
                </div>
            </section>

            <section className="app-overview">
                <div className="overview-content">
                    <div className="overview-text" data-aos="fade-right">
                        <h2>Designed for Educators Worldwide</h2>
                        <p>Tammoo Manager adapts to any educational system by incorporating your specific curriculum requirements and school ethos. Our comprehensive solution helps you focus on what matters most - your students.</p>
                    </div>
                    <div className="overview-image" data-aos="fade-left">
                        <img src={logo512} alt="Tammoo Dashboard" className="dashboard-preview" />
                    </div>
                </div>
            </section>

            <section className="key-features">
                <h2 data-aos="fade-up">Key Features</h2>
                
                <div className="feature-category" data-aos="fade-up">
                    <h3 className="category-title"><i className="fas fa-pencil-alt"></i> Assessment & Documentation</h3>
                    <div className="features-container">
                        <Feature
                            icon="fas fa-microphone-alt"
                            title="Live Note Taking"
                            description="Record real-time observations with quick templates for different subjects"
                        />
                        <Feature
                            icon="fas fa-chart-bar"
                            title="Behavioral Analytics"
                            description="Track patterns and trends in student conduct with visual insights"
                        />
                        <Feature
                            icon="fas fa-file-alt"
                            title="Comprehensive Reporting"
                            description="Generate professional reports with automatic integration of assessment data"
                        />
                    </div>
                </div>
                
                <div className="feature-category" data-aos="fade-up" data-aos-delay="100">
                    <h3 className="category-title"><i className="fas fa-book"></i> Curriculum & Planning</h3>
                    <div className="features-container">
                        <Feature
                            icon="fas fa-project-diagram"
                            title="Curriculum Integration"
                            description="Support for multiple national and international curricula with smart mapping"
                        />
                        <Feature
                            icon="fas fa-file-contract"
                            title="Document Generation"
                            description="Create lesson plans, subject overviews, and assessment tasks aligned with your curriculum"
                        />
                        <Feature
                            icon="fas fa-calendar-alt"
                            title="Adaptive Planning"
                            description="Modify lesson plans in real-time for unexpected situations while maintaining learning objectives"
                        />
                    </div>
                </div>
                
                <div className="feature-category" data-aos="fade-up" data-aos-delay="200">
                    <h3 className="category-title"><i className="fas fa-users"></i> Collaboration & Communication</h3>
                    <div className="features-container">
                        <Feature
                            icon="fas fa-search"
                            title="Research Hub"
                            description="Access topic research, teaching strategies, and subject-specific resources"
                        />
                        <Feature
                            icon="fas fa-envelope"
                            title="Professional Communication"
                            description="Generate formal emails, meeting documents, and student achievement updates"
                        />
                        <Feature
                            icon="fas fa-user-check"
                            title="School Ethos Integration"
                            description="Customize templates with school branding and align with school-specific policies"
                        />
                    </div>
                </div>
            </section>

            <section className="use-cases">
                <h2 data-aos="fade-up">For Every Educator</h2>
                <div className="use-cases-container">
                    <div className="use-case" data-aos="fade-up">
                        <div className="use-case-icon">
                            <i className="fas fa-chalkboard-teacher"></i>
                        </div>
                        <h3>Classroom Teachers</h3>
                        <p>Streamline daily documentation, create curriculum-aligned lesson plans, and generate professional reports with ease.</p>
                    </div>
                    <div className="use-case" data-aos="fade-up" data-aos-delay="100">
                        <div className="use-case-icon">
                            <i className="fas fa-user-graduate"></i>
                        </div>
                        <h3>Special Education</h3>
                        <p>Create individualized education plans, track progress, and implement differentiated assessment strategies.</p>
                    </div>
                    <div className="use-case" data-aos="fade-up" data-aos-delay="200">
                        <div className="use-case-icon">
                            <i className="fas fa-school"></i>
                        </div>
                        <h3>School Leaders</h3>
                        <p>Ensure consistency across your school, align with educational standards, and maintain comprehensive documentation.</p>
                    </div>
                </div>
            </section>

            <section className="how-it-works">
                <h2 data-aos="fade-up">How It Works</h2>
                <div className="timeline-steps">
                    <div className="timeline-progress"></div>
                    <div className="timeline-container">
                        <TimelineStep
                            number="1"
                            title="Setup"
                            description="Import curriculum framework and customize to your school's requirements"
                            icon="fas fa-cog"
                        />
                        <TimelineStep
                            number="2"
                            title="Document"
                            description="Create plans, take notes, and record assessments with ease"
                            icon="fas fa-edit"
                        />
                        <TimelineStep
                            number="3"
                            title="Analyze"
                            description="Process data to identify patterns and meaningful insights"
                            icon="fas fa-chart-pie"
                        />
                        <TimelineStep
                            number="4"
                            title="Report"
                            description="Generate professional documentation aligned with your curriculum"
                            icon="fas fa-file-alt"
                        />
                        <TimelineStep
                            number="5"
                            title="Adapt"
                            description="Refine teaching approach to support individual student needs"
                            icon="fas fa-sync-alt"
                        />
                    </div>
                </div>
            </section>

            <section className="differentiation">
                <h2 data-aos="fade-up">Inclusive Education Support</h2>
                <div className="differentiation-content">
                    <div className="differentiation-text" data-aos="fade-right">
                        <h3>Supporting Every Student's Journey</h3>
                        <p>Tammoo Manager provides comprehensive tools for differentiation and inclusion, helping you accommodate:</p>
                        <ul>
                            <li><i className="fas fa-check-circle"></i> Learning difficulties and disabilities</li>
                            <li><i className="fas fa-check-circle"></i> Gifted and talented extensions</li>
                            <li><i className="fas fa-check-circle"></i> English as Additional Language/Dialect (EAL/D)</li>
                            <li><i className="fas fa-check-circle"></i> Cultural and religious considerations</li>
                            <li><i className="fas fa-check-circle"></i> Medical conditions and physical limitations</li>
                        </ul>
                        <p>Create modified assessment tasks, adjusted success criteria, and specialized feedback templates to ensure every student can succeed.</p>
                    </div>
                    <div className="differentiation-image" data-aos="fade-left">
                        <img src={mascotImage2} alt="Inclusive Education" className="inclusion-image" />
                    </div>
                </div>
            </section>

            <section className="testimonials">
                <img src={mascotImage2} alt="Mascot" className="mascot bottom-right" />
                <h2 data-aos="fade-up">What Educators Are Saying</h2>
                <div className="testimonial-container">
                    <blockquote className="testimonial" data-aos="fade-up" data-aos-delay="100">
                        <p>"Tammoo Manager has revolutionized how I document student progress and create reports. The curriculum integration saves me hours of planning time each week!"</p>
                        <cite>- Sarah Johnson, 5th Grade Teacher, Westfield Elementary</cite>
                    </blockquote>
                    <blockquote className="testimonial" data-aos="fade-up" data-aos-delay="200">
                        <p>"As a special education coordinator, I can now easily create individualized plans that align with our curriculum standards. The differentiation tools are exceptional."</p>
                        <cite>- Michael Chen, SPED Coordinator, Riverdale Academy</cite>
                    </blockquote>
                    <blockquote className="testimonial" data-aos="fade-up" data-aos-delay="300">
                        <p>"The document generation features have ensured consistency across our entire school. Our accreditation process was smoother than ever thanks to Tammoo."</p>
                        <cite>- Dr. Amelia Rodriguez, Principal, International School of Excellence</cite>
                    </blockquote>
                </div>
            </section>

            <section className="faq">
                <h2 data-aos="fade-up">Frequently Asked Questions</h2>
                <div className="faq-container">
                    <div className="faq-item" data-aos="fade-up">
                        <h3><i className="fas fa-question-circle"></i> Can Tammoo Manager adapt to our specific curriculum?</h3>
                        <p>Yes! Tammoo Manager supports multiple national and international curricula, including custom frameworks. You can easily import and set up your specific curriculum requirements.</p>
                    </div>
                    <div className="faq-item" data-aos="fade-up">
                        <h3><i className="fas fa-question-circle"></i> How secure is our school and student data?</h3>
                        <p>We implement industry-leading security measures to protect all your data. Our system is fully compliant with major educational data protection regulations.</p>
                    </div>
                    <div className="faq-item" data-aos="fade-up">
                        <h3><i className="fas fa-question-circle"></i> Can we customize the system to match our school branding?</h3>
                        <p>Absolutely! You can customize document templates with your school logo, colors, and incorporate your school values and mission statements.</p>
                    </div>
                    <div className="faq-item" data-aos="fade-up">
                        <h3><i className="fas fa-question-circle"></i> Is training provided for our staff?</h3>
                        <p>Yes, we offer comprehensive training options including live sessions, video tutorials, and detailed documentation to ensure your team can maximize the benefits of Tammoo Manager.</p>
                    </div>
                </div>
            </section>

            <section className="cta">
                <img src={mascotImage} alt="Mascot" className="mascot bottom-left" />
                <h2 data-aos="fade-up">Ready to Transform Your Educational Management?</h2>
                <p data-aos="fade-up" data-aos-delay="100">Join thousands of educators worldwide who are saving time and improving outcomes with Tammoo Manager.</p>
                <div className="cta-buttons" data-aos="fade-up" data-aos-delay="200">
                    <Link to="/contact" className="cta-button">Get Started</Link>
                    <Link to="/pricing" className="cta-button secondary">View Pricing</Link>
                </div>
            </section>

            <footer>
                <div className="footer-content">
                    <div className="footer-section">
                        <h3>Contact Us</h3>
                        <p><i className="fas fa-envelope"></i> hello@tammoo.com</p>
                        <p><i className="fas fa-phone"></i> +61 (419) 663349</p>
                        <p><i className="fas fa-map-marker-alt"></i> 123 Education Ave, Suite 500<br />Northern Rivers, NSW 2460</p>
                    </div>
                    <div className="footer-section">
                        <h3>Resources</h3>
                        <ul>
                            <li><Link to="/help">Help Center</Link></li>
                            <li><Link to="/blog">Blog</Link></li>
                            <li><Link to="/webinars">Webinars</Link></li>
                            <li><Link to="/documentation">Documentation</Link></li>
                        </ul>
                    </div>
                    <div className="footer-section">
                        <h3>Legal</h3>
                        <ul>
                            <li><Link to="/privacy">Privacy Policy</Link></li>
                            <li><Link to="/terms">Terms of Service</Link></li>
                            <li><Link to="/security">Security</Link></li>
                            <li><Link to="/compliance">Compliance</Link></li>
                        </ul>
                    </div>
                </div>
                <div className="social-media">
                    <a href="#" aria-label="Facebook"><i className="fab fa-facebook-f"></i></a>
                    <a href="#" aria-label="Twitter"><i className="fab fa-twitter"></i></a>
                    <a href="#" aria-label="LinkedIn"><i className="fab fa-linkedin-in"></i></a>
                    <a href="#" aria-label="Instagram"><i className="fab fa-instagram"></i></a>
                    <a href="#" aria-label="YouTube"><i className="fab fa-youtube"></i></a>
                </div>
                <div className="copyright">
                    <p>&copy; {new Date().getFullYear()} Tammoo Manager. All rights reserved.</p>
                </div>
            </footer>
        </div>
    );
};

const Feature = ({ icon, title, description }) => (
    <div className="feature" data-aos="fade-up">
        <i className={icon}></i>
        <h3>{title}</h3>
        <p>{description}</p>
    </div>
);

const Step = ({ number, title, description }) => (
    <div className="step" data-aos="fade-up">
        <div className="step-number">{number}</div>
        <h3>{title}</h3>
        <p>{description}</p>
    </div>
);

const TimelineStep = ({ number, title, description, icon }) => (
    <div className="timeline-step" data-aos="fade-up">
        <div className="timeline-icon">
            <i className={icon}></i>
        </div>
        <div className="timeline-content">
            <div className="timeline-number">{number}</div>
            <h3>{title}</h3>
            <p>{description}</p>
        </div>
    </div>
);

export default LandingPage;
